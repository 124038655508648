<template>
    <div class="container">
        <overlay-scrollbars class="scroll-when-not-initialized" style="max-height: 100%; width: 100%;" :options="profileScrollbarOptions">
            <div class="profile">
                <div class="profile__menu">
                    <div class="faq__call-operator">
                        <div class="profile__deposit faq__header">
                            <div class="faq__operator-btn-wrapper">
                            <util-button @click="openOperator" :isBig="true" height="50px" variant="blue" class="default-btn faq__operator-btn"><span class="icon-support faq__operator-icon"></span> {{ $t('faq.talk_to_operator') }} <span class="icon-right-arrow faq__operator-arrow"></span></util-button>
                            </div>
                            <div class="faq__mobile-nav show-lg">
                                <selectable-control ref="selectable_control" btnClass="selectable-control__btn_full selectable-control__btn_mobile selectable-control__btn_medium-dark selectable-control__btn_h50" :optionsClass="`selectable-control__options_dark selectable-control__options_full selectable-control__options_h50`">
                                    <template v-slot:button>
                                        <span :class="`${activeMenuOption.icon} profile__nav-icon profile__nav-icon_mobile profile__nav-icon_mobile-active`"></span>
                                        <span class="selectable-control__currency">{{ $t('faq.menu.' + activeMenuOption.title) }}</span>
                                    </template>
                                    <template v-slot:options>
                                        <a v-for="link in menu" :key="link.tab" @click.stop="changeTab(link.tab, true)" class="selectable-control__option">
                                            <span :class="`${link.icon} profile__nav-icon profile__menu-option-icon_mobile`"></span>
                                            {{ $t('faq.menu.' + link.title) }}
                                        </a>
                                    </template>
                                </selectable-control>
                            </div>
                            <div class="deposit__warnings faq__warnings mt-10">
                                <div class="deposit__warning">
                                    <span class="icon-warning deposit__warning-icon"></span> {{ $t('faq.make_sure') }}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="profile__nav">
                        <div class="profile__nav-item" v-for="link in menu" :key="link.tab">
                            <button @click="changeTab(link.tab, false)" class="profile__nav-link" :class="{'profile__nav-link_active': (tab == link.tab)}">
                                <span :class="`${link.icon} profile__nav-icon`"></span>
                                {{ $t('faq.menu.' + link.title) }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="profile__body">
                    <div class="support__page" v-if="tab == 'general'">
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow">
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q1') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.general.a1')">
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q2') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.general.a2">
                                        <template v-slot:forbidden>
                                            <div class="text__subheading text_light_bold">
                                                {{ $t('chat.disallowed') }}
                                            </div>
                                        </template>
                                        <template v-slot:rules>
                                            <div class="mt-10">
                                                <ul class="text__list">
                                                    <li v-for="i in 7" :key="i">{{ $t('chat.rule_' + i) }}</li>
                                                </ul>
                                            </div>
                                        </template>
                                        <template v-slot:banned>
                                            <div class="mt-10">
                                                {{ $t('faq.answers.general.a2_banned') }} 
                                                {{ $t('chat.be_respectful') }} 
                                                <span class="text_light_bold">
                                                    {{ $t('chat.take_a_break') }}
                                                </span>
                                                </div>
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10">
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q3') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.general.a3">
                                        <template v-slot:features>
                                            <div class="mt-10">
                                                <ul class="text__list">
                                                        <li>
                                                            <button @click.stop="$router.push({query: {...$route.query, m: 'bonus'}}).catch(()=>{})" class="default-link mr-5">{{ $t('faq.answers.general.a3_promocodes') }} </button>
                                                            <i18n path="bonus.follow_us">
                                                                <template #value>
                                                                    <a target="_blank" rel="noopener" :href="$store.state.settings.social_links.vkontakte.link" class="mr-5 default-link text_flex">
                                                                        <span class="icon-vk mr-3"></span>
                                                                        <span class="footer__social-name">{{$store.state.settings.social_links.vkontakte.title}}</span>
                                                                    </a>
                                                                    <a target="_blank" rel="noopener" :href="$store.state.settings.social_links.telegram.link" class="default-link text_flex">
                                                                        <span class="icon-telegram mr-3"></span>
                                                                        <span class="footer__social-name">{{$store.state.settings.social_links.telegram.title}}</span>
                                                                    </a>
                                                                
                                                                </template>
                                                            </i18n>
                                                        </li>
                                                        <li>
                                                            <button @click.stop="$router.push({query: {...$route.query, m: 'deposit'}})" class="default-link">{{ $t('faq.answers.general.a3_boost') }}</button>
                                                        </li>
                                                        <li>{{ $t('faq.answers.general.a3_airdrop') }}</li>
                                                        <li>
                                                            <button @click.stop="$router.push({query: {...$route.query, m: 'tasks'}}).catch(()=>{})" class="default-link">{{ $t('faq.answers.general.a3_tasks') }}</button>
                                                        </li>
                                                </ul>
                                            </div>
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q4') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.general.a4')">     
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q5') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.general.a5">
                                        <template v-slot:provably>
                                            <button class="default-link" @click.stop="$router.push({query: {...$route.query, m: 'fairness'}}).catch(()=>{})">{{ $t('faq.answers.general.a5_provably') }}</button>
                                        </template>
                                        <template v-slot:randomness>
                                            <span class="text_light_bold">{{ $t('faq.answers.general.a5_randomness') }}</span>
                                        </template>
                                        <template v-slot:inability>
                                            <span class="text_light_bold">{{ $t('faq.answers.general.a5_inability') }}</span>
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q6') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.general.a6">
                                        <template v-slot:forgot>
                                            <span class="text_light_bold">"{{ $t('auth.forgot_password') }}"</span>
                                        </template>
                                        <template v-slot:contact>
                                            <contact-support-link />.
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q7') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.general.a7')">
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q8') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.general.a8')">
                               
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q9') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.general.a9">
                                        <template v-slot:forbidden>
                                            <span class="text_light_bold">{{ $t('faq.answers.general.a9_forbidden') }}</span>
                                        </template>
                                        <template v-slot:contact>
                                            <contact-support-link />
                                        </template>
                                    </i18n>
                                   
                                </div>
                            </template>
                        </accordeon>


                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.general.q10') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.general.a10">
                                        <template v-slot:reward>
                                            <span class="text_light_bold">{{ $t('faq.answers.general.a10_reward') }}</span>
                                        </template>
                                        <template v-slot:contact>
                                            <contact-support-link />
                                        </template>
                                    </i18n>
                                
                                </div>
                            </template>
                        </accordeon>
                    </div>

                    <div class="support__page" v-if="tab == 'deposit'">
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.deposit.q1') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.deposit.a1">
                                        <template v-slot:time>
                                            <span class="text_light_bold">{{ $t('faq.answers.deposit.a1_time') }}</span>
                                        </template>
                                        <template v-slot:contact>
                                            <contact-support-link />
                                        </template>
                                    </i18n>
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.deposit.q2') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.deposit.a2')">
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.deposit.q3') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="bonus.follow_us">
                                        <template #value>
                                            <a target="_blank" rel="noopener" :href="$store.state.settings.social_links.vkontakte.link" class="mr-5 default-link text_flex">
                                                <span class="icon-vk mr-3"></span>
                                                <span class="footer__social-name">{{$store.state.settings.social_links.vkontakte.title}}</span>
                                            </a>
                                            <a target="_blank" rel="noopener" :href="$store.state.settings.social_links.telegram.link" class="default-link text_flex">
                                                <span class="icon-telegram mr-3"></span>
                                                <span class="footer__social-name">{{$store.state.settings.social_links.telegram.title}}</span>
                                            </a>
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>
                    </div>

                    <div class="support__page" v-if="tab == 'withdraw'">
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.withdraw.q1', {error: $t('withdraws.cant_request')}) }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.withdraw.a1">
                                        <template #tries>
                                            <div class="mt-10 mb-10">
                                                <ul class="text__list">
                                                    <li v-for="i in 3" :key="i" v-html="$t('faq.answers.withdraw.a1_t' + i)"></li>
                                                </ul>
                                            </div>
                                        </template>
                                        <template #contact>
                                            <contact-support-link />
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.withdraw.q2') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.withdraw.a2')">

                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.withdraw.q3') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.withdraw.a3')">
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.withdraw.q4', {receive: $t('withdraws.receive')}) }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.withdraw.a4')">
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.withdraw.q5') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.withdraw.a5')">
                                    
                                </div>
                            </template>
                        </accordeon>
                    </div>

                    <div class="support__page" v-if="tab == 'crash'">
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.crash.q1', {cashout: $t('crash.cashout')}) }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.crash.a1')">
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.crash.q2') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <ul class="text__list">
                                        <li>{{ $t('faq.answers.crash.a2_refresh') }}</li>
                                        <li>{{ $t('faq.answers.crash.a2_check') }} <router-link tag="a" :to="{name: 'profile', query: {t: 'history'}}" class="default-link">{{ $t('profile.menu.game_history') }}</router-link></li>
                                    </ul>
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.crash.q3') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.crash.a3')">
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.crash.q4') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.crash.a4">
                                        <template #impossible>
                                            <span class="text_light_bold">{{ $t('faq.answers.crash.a4_impossible') }}</span>
                                        </template>
                                        <template #change>
                                            <router-link tag="a" :to="{name: 'profile', query: {t: 'account'}}" class="default-link">{{ $t('faq.answers.crash.a4_change') }}</router-link>
                                        </template>
                                        <template #contact>
                                            <contact-support-link />
                                        </template>
                                        <template #compensate>
                                            <span class="text_light_bold">{{ $t('faq.answers.crash.a4_compensate') }}</span>
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>
                    </div>
                    
                    <div class="support__page" v-if="tab == 'website'">
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.website.q1', {too_many_requests: $t('errors.too_many_requests')}) }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.website.a1')">
                                    
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.website.q2') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.website.a2">
                                        <template #contact>
                                            <contact-support-link />
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>
                    
                    </div>

                    <div class="support__page" v-if="tab == 'bonus'">
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.bonus.q1') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    {{ $t('bonus.promocode_footer') }}.
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.bonus.q2', {limit_exhausted: $t('bonus.limit_exhausted')}) }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <div class="mb-10">
                                        <ul class="text__list">
                                            <li class="">{{ $t('profile.levels.bonus_rule_1') }}</li>
                                            <li class="">
                                                <i18n path="profile.levels.bonus_rule_2">
                                                        <template #promocode>
                                                            <span class="text_light_bold">{{ $t('profile.levels.promocode') }}</span>
                                                        </template>
                                                        <template #increase>
                                                            <span class="text_light_bold">{{ $t('profile.levels.increase') }}</span>
                                                        </template>
                                                    </i18n>
                                            </li>
                                            <li class="">
                                                <i18n path="profile.levels.bonus_rule_3">
                                                        <template #deposit>
                                                            <span class="text_light_bold">{{ $t('profile.levels.deposit') }}</span>
                                                        </template>
                                                        <template #decrease>
                                                            <span class="text_light_bold">{{ $t('profile.levels.decrease') }}</span>
                                                        </template>
                                                    </i18n>
                                            </li>
                                            <li class="">
                                                <i18n path="profile.levels.bonus_rule_4">
                                                        <template #level>
                                                            <span class="text_light_bold">{{ $t('profile.levels.level') }}</span>
                                                        </template>
                                                        <template #reset>
                                                            <span class="text_light_bold">{{ $t('profile.levels.reset') }}</span>
                                                        </template>
                                                    </i18n>
                                            </li>
                                        </ul>
                                    </div>
                                    {{ $t('faq.answers.bonus.a2_see') }} <router-link tag="a" :to="{name: 'profile', query: {t: 'levels'}}" class="default-link">{{ $t('profile.menu.levels') }}</router-link>
                                </div>
                            </template>
                        </accordeon>


                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.bonus.q3') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.bonus.a3')">
                                    
                                </div>
                            </template>
                        </accordeon>
                    </div>

                    <div class="support__page" v-if="tab == 'partnership'">
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.partnership.q1') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text">
                                    <i18n path="faq.answers.partnership.a1">
                                        <template #partnership>
                                            <router-link tag="a" :to="{name: 'profile', query: {t: 'partnership'}}" class="default-link">{{ $t('profile.menu.partnership') }}</router-link>
                                        </template>
                                        <template #percent>
                                            <span class="text_light_bold">{{ $t('faq.answers.partnership.a1_percent') }}</span>
                                        </template>
                                        <template #invitational>
                                            <span class="text_light_bold">{{ $t('faq.answers.partnership.a1_invitational') }}</span>
                                        </template>
                                    </i18n>
                                </div>
                            </template>
                        </accordeon>
                        
                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.partnership.q2') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.partnership.a2')"> 
                                </div>
                            </template>
                        </accordeon>

                        <accordeon :key="Math.random()" class="dark-panel dark-panel_no-padding dark-panel_less-shadow mt-10" >
                            <template v-slot:header>
                                <div class="flex-align py-7">
                                    <span class="accordeon__text text_semi text_dark-gray">{{ $t('faq.questions.partnership.q3') }}</span>
                                </div>
                            </template>
                            <template v-slot:body>
                                <div class="text__text" v-html="$t('faq.answers.partnership.a3')">
                                    
                                </div>
                            </template>
                        </accordeon>
                      

                    </div>
                </div>
            </div>
        </overlay-scrollbars>
    </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import Accordeon from '@/misc/Accordeon.vue';
import UtilButton from '@/misc/Button.vue';
import Chatra from '@chatra/chatra';
import ContactSupportLink from '@/misc/ContactSupportLink.vue';
import SelectableControl from '@/misc/SelectableControl.vue';

export default {
    metaInfo() {
      return {
        title: this.$t('nav.support'),
      }
    },
    data() {
        return {
            profileScrollbarOptions: {
                className : 'os-theme-medium',
                nativeScrollbarsOverlaid : {
                    showNativeScrollbars   : false,
                    initialize             : false 
                },
            },
            menu: [
                {
                    title: 'general',
                    icon: 'icon-faq',
                    tab: 'general'
                },
                {
                    title: 'deposit',
                    icon: 'icon-wallet',
                    tab: 'deposit'
                },
                {
                    title: 'withdraw',
                    icon: 'icon-atm',
                    tab: 'withdraw'
                },
                {
                    title: 'crash',
                    icon: 'icon-crash',
                    tab: 'crash'
                },
                {
                    title: 'website',
                    icon: 'icon-web-site',
                    tab: 'website'
                },

                {
                    title: 'bonus',
                    icon: 'icon-bonus',
                    tab: 'bonus'
                },
                {
                    title: 'partnership',
                    icon: 'icon-partners',
                    tab: 'partnership'
                },
            ]
        }
    },
    components: {
        Accordeon, UtilButton,
        ContactSupportLink, SelectableControl
    },
   
    methods: {
        openOperator() {
            Chatra('openChat', true)
        },
        changeTab(tab, closeDropdown) {
            this.$store.commit('faq/SET', {key: 'tab', value: tab});
            if (closeDropdown) {
                this.$refs.selectable_control.hide();
            }
        },
    },
    computed: {
        ...mapState('faq', ['tab']),
        activeMenuOption() {
            // if (!this.tab) return this.menu[0];
            return this.menu.find(x => x.tab == this.tab);
        }
    },
}
</script>

<style>

</style>