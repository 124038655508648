<template>
    <div class="input__wrapper">
        <input autocomplete="off" type="text" class="input-field" :class="addClass" :placeholder="placeholder" :value="value" @input="$emit('input', $event.target.value)">
    </div>
</template>

<script>
export default {    
    props: ['value', 'placeholder', 'addClass']
}
</script>

