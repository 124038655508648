import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    sm: 480,
    md: 1079,
    lg: 1350,
    xlg: 1650,
    xxlg: Infinity
  }
})