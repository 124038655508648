<template>
<div>
    <div class="modal__body">
        <div class="tasks">
            <div v-for="task in tasks" :key="task.id">
                <social-media-task :task="task" v-if="task.client_name === 'tasks.social_media'"></social-media-task>
                <deposit-task class="mt-20" :task="task" v-if="task.client_name === 'tasks.deposit'"></deposit-task>
                <repost-task class="mt-20" :task="task" v-if="task.client_name === 'tasks.repost_vk'"></repost-task>
            </div>
        </div>
    </div>
    <div class="modal__footer mt-7">
        <div class="bonus__footer">
                <div> 
                    <span>{{ $t('tasks.footer') }} </span>
                </div> 
        </div>
    </div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import SocialMediaTask from './SocialMediaTask.vue';
import DepositTask from './DepositTask.vue';
import RepostTask from './RepostTask.vue';

export default {
    components: {
        SocialMediaTask, DepositTask, RepostTask
    },
    computed: {
        ...mapState('bonus', {tasks: 'tasks'})
    }
}
</script>

<st
Buttonyle>

</style>