import i18n from '@/i18n';
import Vue from 'vue';
import router from '../../router';


export const namespaced = true;

export const state = {
    lang: localStorage.getItem('lang') || 'RU',
    crash_visibility: localStorage.getItem('crash_visibility') || 'on',
    bets_visibility: localStorage.getItem('bets_visibility') || 'on',
    avatars_visibility: localStorage.getItem('avatars_visibility') || 'on',
    usernames_visibility: localStorage.getItem('usernames_visibility') || 'on',
    min_item_price: 25,
    social_links: {
        vkontakte: {
            
        },
        telegram: {

        },
        email: {
            
        }
    },
    other_links: {
        telegram_bot: {

        }
    },
    online: 0,
    settingsModalOpened: false,
    maintenance: localStorage.getItem('maintenance') || 'off',
};

export const mutations = {
    SET_LANG(state, lang) {
        state.lang = lang;
        localStorage.setItem('lang', lang);
        i18n.locale = lang;
    },
    SET_SETTING(state, {key, value}) {
        state[key] = value;
    },
    SET_SOCIAL_LINKS(state, links) {
        for (const key in links) {
            if (state.social_links.hasOwnProperty(links[key].name)) {
                Vue.set(state.social_links, links[key].name, links[key]);
            }
            else if (state.other_links.hasOwnProperty(links[key].name)) {
                Vue.set(state.other_links, links[key].name, links[key]);
            }
        }
    }
};

export const actions = {
    setAndStore({ commit }, {key, value}) {
        commit('SET_SETTING', {key: key, value: value});
        localStorage.setItem(key, value);
    },
    setLang({ commit }, lang) {
        commit('SET_LANG', lang);
    },
    setLastEnteredCoeff({ commit }, coeff) {
        localStorage.setItem('last_coeff', coeff);
    },
    saveCurrencyRates({ commit }, currency_rates) {
        localStorage.setItem('currency_rates', JSON.stringify(currency_rates));
    },
    setCrashVisibility({ commit }, value) {
        commit('SET_SETTING', {key: 'crash_visibility', value: value});
        localStorage.setItem('crash_visibility', value);
    },
    toggleCrashVisibility({ state, dispatch }) {
        if (state.crash_visibility == 'on') {
            dispatch('setCrashVisibility', 'off');
        } 
        else {
            dispatch('setCrashVisibility', 'on');
        }
    },
};

export const getters = {
    currentGameMode: (state) => {
        switch (router.currentRoute.name) {
            case 'index': 
                return 'crash';
            default:
                return 'crash';
        }
    },
    crashGraphIsVisible: (state) => {
        return state.crash_visibility == 'on';
    },
    social_links_without_email: (state) => {
        const { email, ...no_email } = state.social_links;
        return no_email;
    },
    social_links_only_email: (state) => {
        const { email, ...no_email } = state.social_links;
        return email;
    },

    bets_visibility: (state) => {
        return state.bets_visibility == 'on';
    },
    avatars_visibility: (state) => {
        return state.avatars_visibility == 'on';
    },
    usernames_visibility: (state) => {
        return state.usernames_visibility == 'on';
    },
    under_maintenance: (state) => {
        return state.maintenance === 'on';
    }
};