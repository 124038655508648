import router from "@/router";
import { getError } from "@/util";
import AuthService from "@/services/authService";
import i18n from '@/i18n';
import { DateTime } from "luxon";
 
export const namespaced = true;

let cachedUser = JSON.parse(localStorage.getItem('user')) || null;
if (cachedUser) cachedUser.balance = null;

export const state = {
    user: cachedUser || null,
    loading: false,
    error: null,
};

export const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_BALANCE(state, balance) {
        state.user.balance = balance;
    },
    MUTE_USER(state, { timestamp, reason }) {
        state.user.mute_timestamp = timestamp;
        state.user.mute_reason = reason;
    },
    UNMUTE_USER(state) {
        state.user.mute_timestamp = 0;
        state.user.mute_reason = null;
    },
    SET_USERNAME(state, value) {
        state.user.username = value;
    },
    SET_TRADE_LINK(state, value) {
        state.user.trade_link = value;
    },
    SET_LOGIN(state, value) {
        state.user.login = value;
    },
    SET_EMAIL(state, value) {
        state.user.email = value;
    },
    SET_LEVEL_DATA(state, { level, progress }) {
        state.user.level = level;
        state.user.level_progress = progress;
    }
};

export const actions = {
    getAuthUser({ commit, dispatch }) {
        commit("SET_LOADING", true);
        return AuthService.getAuthUser()
          .then((response) => {
            commit("SET_USER", response.data.data);
            dispatch("setGuest", { value: "isNotGuest" });
            dispatch("cacheUser", { value: response.data.data });
            
            dispatch("stash/fetchUserStash", null, {root: true});
            return response.data.data;
          })
          .catch((error) => {
            commit("SET_USER", null);
            dispatch("setGuest", { value: "isGuest" });
            dispatch("cacheUser", { value: null });
            commit("SET_ERROR", getError(error));
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
    },


    logout({ commit, dispatch }) {
        return AuthService.logout()
          .then(() => {
            commit("SET_USER", null);
            dispatch("setGuest", { value: "isGuest" });
            dispatch("cacheUser", { value: null });
            if (router.currentRoute.name !== 'index') {
                router.push('/');
            }
            router.go();
          })
          .catch((error) => {
            commit("SET_ERROR", getError(error));
          });
    },

    setGuest(context, { value }) {
        localStorage.setItem("guest", value);
    },

    changeUsername({ commit, dispatch, state }, username){
        commit('SET_USERNAME', username);
        dispatch('cacheUser', {value: state.user});
    },

    changeLogin({commit, dispatch, state}, login) {
        commit('SET_LOGIN', login);
        dispatch('cacheUser', {value: state.user});
    },

    changeTradeLink( { commit, dispatch, state }, link) {
        commit('SET_TRADE_LINK', link);
        dispatch('cacheUser', {value: state.user});
    },

    changeEmail( { commit, dispatch, state }, value) {
        commit('SET_EMAIL', value);
        dispatch('cacheUser', {value: state.user});
    },

    cacheUser(context, { value }) {
        localStorage.setItem('user', JSON.stringify(value));
    },

    updateBalance({ commit }, value) {
        commit('SET_BALANCE', value);
    },

    updateLevel({ commit }, { level, progress }) {
        commit('SET_LEVEL_DATA', { level, progress });
    },
    

    muteAction({ commit }, data) {
        if (data.t == 'mute') {
            commit('MUTE_USER', { timestamp: data.ts, reason: data.r });
        }
        if (data.t == 'unmute') {
            commit('UNMUTE_USER');
        }
    }
};

export const getters = {
    userId: (state, getters) => {
        if (!getters.loggedIn) return '';
        return state.user.id;
    },
    authUser: (state) => {
        return state.user;
    },
    balanceInDollars: (state) => {
        return state.user.balance != null ? state.user.balance.convertToDollars() : i18n.t('util.loading');
    },
    isAdmin: (state, getters) => {
        return getters.loggedIn && state.user.roles.includes('admin');
    },
    isModer: (state, getters) => {
        return getters.loggedIn && state.user.roles.includes('moder');
    },
    error: (state) => {
        return state.error;
    },
    loading: (state) => {
        return state.loading;
    },
    loggedIn: (state) => {
        return !!state.user;
    },
    guest: () => {
        const storageItem = localStorage.getItem("guest");
        if (!storageItem) return false;
        if (storageItem === "isGuest") return true;
        if (storageItem === "isNotGuest") return false;
    },
    muted: (state, getters) => {
        if (!getters.loggedIn) return false;
        return state.user.mute_timestamp >= DateTime.now().toSeconds();
    },
    tradeLink: (state) => {
        if (!state.user) return null;
        return state.user.trade_link;
    },
    banned: (state) => {
        if (!state.user) return false;
        return state.user.banned;
    },
    steamID: (state) => {
        const _idx = state.user.providers.findIndex(p => p.provider === 'steam');
        if (_idx > -1) return state.user.providers[_idx].provider_id;
        return null;
    },
    providers: (state)  => {
        return state.user.providers;
    },
    email: (state)  => {
        return state.user.email;
    },
    email_verified: (state) => {
        return state.user.email_verified_at != null;
    },
    level: (state)  => {
        return state.user.level;
    }
};