<template>
    <div class="main" :class="{'usernames_blured': !$store.getters['settings/usernames_visibility']}" v-if="!$store.getters['auth/banned'] && !$store.getters['settings/under_maintenance']">
        <site-header/>
        
        <mobile-menu class="show-lg" />
        <div class="global-wrapper">
            <mq-layout :mq="['sm', 'md']">
                <market-modal v-if="$store.getters['auth/loggedIn']"/>
            </mq-layout>
            <div class="sidebar__wrapper">
                <div class="sidebar">
                    <vertical-bar />
                </div>
            </div>
            <div class="content" :class="{shrinked: $store.state.chat.chatOpened}">
                <router-view />
               
            </div>
            <div class="right-bar__wrapper" :class="{opened: $store.state.chat.chatOpened, opened_mobile: $store.state.chat.chatOpenedMobile}">
                <div class="right-bar">
                    <chat />
                </div>
            </div>
     
        </div>
        <div class="portal">
            <auth-modal />
            <chat-rules-modal />
            <airdrop-modal />
            <bonus-modal />
            <profile-setting />
            <logout-modal />
            <crash-game-modal />
            <forgot-password-modal />
            <reset-password-modal />
            <deposit />
            <fairness-modal />
            <seeds-modal />
            <settings-modal />
            <qiwi-modal />
            <link-telegram />
        </div>
    </div>
    <account-blocked v-else-if="$store.getters['auth/banned']" ></account-blocked>
    <maintenance v-else />
</template>

<script>
    import siteHeader from "./header/Header";
    import AuthModal from '@/components/login/AuthModal';
    import Chat from "./chat/Chat";
    import VerticalBar from './menus/VerticalBar.vue';
    import SidebarPanel from './inventory/SidebarPanel.vue';
    import MarketModal from '@/components/market/MarketModal.vue';
    import LogoutModal from '@/components/profile/LogoutModal.vue';
    import BonusModal from '@/components/bonuses/BonusModal.vue';
    import ChatRulesModal from '@/components/chat/ChatRulesModal.vue';
    import AirdropModal from '@/components/airdrop/AirdropModal.vue';
    import ProfileSetting from '@/components/profile/ProfileSetting.vue';
    import CrashGameModal from '@/components/profile/CrashGameModal.vue';
    import ForgotPasswordModal from '@/components/account/ForgotPasswordModal.vue';
    import LinkTelegram from '@/components/account/LinkTelegram.vue';
    import ResetPasswordModal from '@/components/account/ResetPasswordModal.vue';
    import * as API from "@/services/API";
    import i18n from '@/i18n';
    import Deposit from './deposit/Deposit.vue';
    import QiwiModal from './deposit/QiwiModal.vue';
    import FairnessModal from '@/components/fair/FairnessModal.vue';
    import SeedsModal from '@/components/fair/SeedsModal.vue';
    import SettingsModal from '@/components/header/SettingsModal.vue';
    import MobileMenu from '@/components/footer/MobileMenu.vue';
    import Logo from '@/components/header/Logo.vue';
    import Maintenance from '@/pages/index/Maintenance.vue';
    import AccountBlocked from '@/pages/index/AccountBlocked.vue';
    import Chatra from '@chatra/chatra';
    import cfg from '@/_config';

    export default {
        components: {
            AuthModal,
            Logo,
            siteHeader,
            Chat,
            AccountBlocked,
            VerticalBar,
            SidebarPanel,
            MarketModal,
            ChatRulesModal,
            AirdropModal,
            BonusModal,
            ProfileSetting,
            LogoutModal,
            CrashGameModal,
            ForgotPasswordModal,
            ResetPasswordModal,
            Deposit,
            FairnessModal,
            SeedsModal,
            MobileMenu,
            SettingsModal,
            Maintenance,
            QiwiModal,
            LinkTelegram
        },

        created() {
            this.$store.dispatch("auth/getAuthUser")
                        .then(response => {
                            if (response) {
                                this.$socket.client.emit('init', {
                                    uuid: response.uuid
                                });
                                Chatra('setIntegrationData', {
                                    name: this.$store.getters['auth/authUser'].username,
                                    uuid: response.uuid
                                });
                            }
                        });

            this.$store.dispatch('chat/load');

            this.$store.dispatch('airdrop/init');

            this.$store.dispatch('notifications/load');
            
            API.apiClient
                    .get('app')
                    .then(response => {
                        this.$store.dispatch("market/loadMarket", response.data.data.state.last_items_update);
                        this.$store.dispatch("settings/saveCurrencyRates", response.data.data.state.currency_rates);
                       
                        this.$store.commit("settings/SET_SOCIAL_LINKS", response.data.data.social_links);
                        this.$store.commit("settings/SET_SETTING", {key: 'min_item_price', value: response.data.data.min_item_price});
                    })
                    .catch(() => {
                        this.$store.dispatch("market/loadMarket", 0);
                    });

 
        },
        methods: {
        },
        sockets: {
            connect: function () {
                if (this.$route.name === 'index') {
                    this.$socket.client.emit('join', 'crash');

                    if (this.$store.getters['auth/loggedIn']) {
                        this.$socket.client.emit('init', {
                            uuid: this.$store.getters['auth/authUser'].uuid
                        });
                    }

                }
            },
            
            crash_autocashout: function(data) {
                this.$store.dispatch('stash/fetchUserStash');
            },

            gifted_item: function(data) {
                this.$store.dispatch('notification/show', {type: 'success', message: i18n.t('util.gifted')}, { root: true });
                this.$store.dispatch('stash/fetchUserStash');
            },

            level_progress: function(data) {
                this.$store.dispatch('auth/updateLevel', data, {root: true});
            },

            new_notification(data) {
                this.$store.dispatch('notifications/add', data, {root: true});
            },

            online(data) {
                this.$store.commit('settings/SET_SETTING', {key: 'online', value: data});
            }
        }
    }
</script>
