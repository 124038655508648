import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';

export const namespaced = true;

const getDefaultState = () => {
    return {
        loading: false,
        active: false,
        timeLeft: 0,

        req_games: {
            value: 0,
            needed: 0,
            satisfied: false
        },
        req_messages: {
            satisfied: false
        },
        req_deposit: {
            hours: 0,
            satisfied: false
        },
        is_participating: false,
        modalOpened: false,
        hours: '00',
        minutes: '00',
        seconds: '00',
        priceMin: 0,
        priceMax: 0,
        winnersCount: 0
    }
}



export const state = getDefaultState

export const mutations = {
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_TIME_LEFT(state, value) {
        state.timeLeft = value;
    },
    SET_REQ_GAMES(state, value) {
        state.req_games = value;
    },
    SET_REQ_MESSAGES(state, value) {
        state.req_messages = value;
    },
    SET_REQ_DEPOSIT(state, value) {
        state.req_deposit = value;
    },
    SET_PARTICIPATING(state, value) {
        state.is_participating = value;
    },
    SET_ACTIVE(state, value) {
        state.active = value;
    },
    SET_MODAL_OPENED(state, value) {
        state.modalOpened = value;
    },
    SET_HOURS(state, value) {
        state.hours = value;
    },
    SET_MINUTES(state, value) {
        state.minutes = value;
    },
    SET_SECONDS(state, value) {
        state.seconds = value;
    },
    SET_PRICE_MIN(state, value) {
        state.priceMin = value;
    },
    SET_PRICE_MAX(state, value) {
        state.priceMax = value;
    },
    SET_WINNERS_COUNT(state, value) {
        state.winnersCount = value;
    }
};

export const actions = {
    init({ commit }) {
        commit('SET_LOADING', true);

        API.apiClient
            .get('airdrop/data')
            .then(response => {
                if (response.data.data.active == 1) {
                    commit('SET_ACTIVE', response.data.data.active);
                    commit('SET_TIME_LEFT', response.data.data.timeLeft);
                    commit('SET_REQ_GAMES', response.data.data.req_games);
                    commit('SET_REQ_MESSAGES', response.data.data.req_messages);
                    commit('SET_REQ_DEPOSIT', response.data.data.req_deposit);
                    commit('SET_PARTICIPATING', response.data.data.is_participating);

                    commit('SET_PRICE_MIN', response.data.data.price_min);
                    commit('SET_PRICE_MAX', response.data.data.price_max);
                    commit('SET_WINNERS_COUNT', response.data.data.winners_count);
                }
                else {
                    commit('RESET');
                }
            })
            .finally(() => {
                commit('SET_LOADING', false);
            });

    },

    socket_airdropRc({commit}, data) {
        commit('SET_REQ_GAMES', data.req_games);
        commit('SET_REQ_MESSAGES', data.req_messages);
        commit('SET_REQ_DEPOSIT', data.req_deposit);
        commit('SET_PARTICIPATING', data.is_participating);
    },
    
    socket_airdropNg({ dispatch, commit }) {
        commit('SET_TIME_LEFT', 0);
        setTimeout(() => dispatch('init'), 1000);
    }
};

export const getters = {
    satisfiedRequirementsCount: (state, getters, rootState, rootGetters) => {
        return +rootGetters['auth/loggedIn'] +state.req_games.satisfied +state.req_messages.satisfied +state.req_deposit.satisfied;
    }
};