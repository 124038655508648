<template>
    <div class="market__open-btn">
        <button class="default-btn default-btn_high mt-10" @click="toggleMarket">{{ $t('itempicker.store') }}</button>
    </div>
</template>

<script>
export default {
    methods: {
        toggleMarket() {
            if (this.$store.state.market.marketOpened) {
                this.$store.dispatch('market/closeMarket');
            }
            else {
                this.$store.dispatch('market/openMarket');
            }
        }
    }
}
</script>
