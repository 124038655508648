import Vue from 'vue';

import _axios from './axios';
import _os from './overlayScrollbars';
import _sockets from './sockets';
import _toastification from './toastification';
import _chatra from './chatra';
import _vuemq from './vue-mq';

import VueEllipseProgress from 'vue-ellipse-progress';
import Clipboard from 'v-clipboard'
import Maska from 'maska'

Vue.use(Maska)
Vue.use(VueEllipseProgress);
Vue.use(Clipboard)