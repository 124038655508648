<template>
    <div class="inventory">
        <stats />
        <div class="inventory__tabs history__tabs mt-10">
           <div class="modal__tab flex-align modal__tab_active">
                <span class="icon-crash modal__tab-icon"></span> <span>{{ $t('crash.name') }}</span>
           </div>
           <div class="modal__tab flex-align modal__tab_disabled">
                <span class="icon-mines modal__tab-icon"></span> <span>{{ $t('mines.name') }}</span>
           </div>
           <div class="modal__tab flex-align modal__tab_disabled">
                <span class="icon-wheel modal__tab-icon"></span> <span>{{ $t('wheel.name') }}</span>
           </div>
       </div>
       <div class="history__body mt-15" v-if="!loading">
           <bet class="history__bet" v-for="bet in paginatedHistory" :key="bet.id" :bet="bet">
               
           </bet>
       </div>
       
        <loader class="mt-30" v-else></loader>

        <div class="history__controls" v-if="loading || (hasNextPage || hasPreviousPage)">
               <button @click="previousPage" :disabled="!hasPreviousPage || loading" class="history__control">
                   <span class="icon-left-arrow mr-7"></span> {{ $t('util.prev') }}
               </button>
                <button @click="nextPage" :disabled="!hasNextPage || loading" class="history__control">
                        {{ $t('util.next') }} <span class="icon-right-arrow ml-7"></span>
                </button>
        </div>
  
        <div v-if="paginatedHistory.length === 0 && !loading" class="profile__inventory_empty" >
            <div class="market-empty__pic">
                <span class="icon-lifebuoy"></span>
            </div>
            <div class="market-empty__text">
                {{ $t('profile.history.empty') }}
            </div>
        </div>
     
    </div>
</template>

<script>
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import { mapState } from 'vuex';
import Stats from './Stats.vue';
import Loader from '@/misc/Loader.vue';
import * as API from '@/services/API';
import Bet from './Bet.vue';

export default {
    components: { Stats, Loader, Bet },
    data() {
        return {
            history: [],
            loading: false,
            currentPage: 1,
            perPage: 0,
            maxPage: 1,
            finalPage: -1
        }
    },
    methods: {
        _loadHistory(page) {
            this.loading = true;
            return API.apiClient.get('profile/history/crash', {
                params: {
                    page: page
                }
            })
            .then(response => {
                if (response.data.success) {
                    this.perPage = response.data.data.per_page;
                    if (response.data.data.next_page_url == null) this.finalPage = page;
                    this.history.push(...response.data.data.data);
                }
                else {
                    const errorData = processAPIError(response);
                    this.$store.dispatch('notification/showError', errorData);
                }
            })
            .catch(error => {
                const errorData = processValidationError(error);
                this.$store.dispatch('notification/showError', errorData);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        firstPage() {
            this._loadHistory(1);
        },
        nextPage() {
            if (this.currentPage + 1 <= this.maxPage) {
                this.currentPage++;
            }
            else {
                this._loadHistory(this.currentPage + 1).then(() => {
                    this.currentPage++
                    this.maxPage = Math.max(this.maxPage, this.currentPage);
                });
            }
            
        },
        previousPage() {
            this.currentPage--;
        }
    },
    created() {
        this.firstPage();
    },
    computed: {
        ...mapState('profile', ['historyIsLoading']),
        paginatedHistory() {
            return this.history.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
        },
        hasPreviousPage() {
            return this.currentPage > 1;
        },
        hasNextPage() {
            return this.currentPage != this.finalPage;
        }
    }
}
</script>

<style>

</style>