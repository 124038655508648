<template>
    <div class="mobile-menu">
      <div class="mobile-menu__contents">
        
        <transition name="slide-down-fade">
          <div v-show="moreMenuOpened" v-click-outside="closeMoreMenu" class="mobile-menu__submenu mobile-menu__submenu_more">
            <div class="mobile-menu__submenu__vertical-divider"></div>
            <div class="mobile-menu__submenu-item">
              <button @click.stop="openModal('bonus')" class="mobile-menu__submenu-item__link mobile-menu__submenu-item_first-in-row">
                <div class="mobile-menu__submenu-item__link-wrapper">
                  <span class="mobile-menu__submenu-item__link-icon icon-bonus"></span>
                  <div class="mobile-menu__submenu-item__link__right">
                    <div class="mobile-menu__submenu-item__link-name mobile-menu__submenu-item__link-name_no-margin">{{ $t('nav.bonus') }}</div>
                  </div>
                </div>
              </button>
            </div>


            <div class="mobile-menu__submenu-item mobile-menu__submenu-item_last-in-row">
              <button @click="goto('support')" class="mobile-menu__submenu-item__link" :class="{'mobile-menu__link_active': $route.name == 'support'}">
                <div class="mobile-menu__submenu-item__link-wrapper">
                  <span class="mobile-menu__submenu-item__link-icon icon-support"></span>
                  <div class="mobile-menu__submenu-item__link__right">
                    <div class="mobile-menu__submenu-item__link-name mobile-menu__submenu-item__link-name_no-margin">{{ $t('nav.support') }}</div>
                  </div>
                </div>
              </button>
            </div>



            <div class="mobile-menu__submenu-item">
              <button @click.stop="openModal('fairness')" class="mobile-menu__submenu-item__link mobile-menu__submenu-item_first-in-row">
                <div class="mobile-menu__submenu-item__link-wrapper">
                  <span class="mobile-menu__submenu-item__link-icon icon-fair"></span>
                  <div class="mobile-menu__submenu-item__link__right">
                    <div class="mobile-menu__submenu-item__link-name mobile-menu__submenu-item__link-name_no-margin">{{ $t('nav.fair') }}</div>
                  </div>
                </div>
              </button>
            </div>

            <div class="mobile-menu__submenu-item mobile-menu__submenu-item_last-in-row">
              <button class="mobile-menu__submenu-item__link">
                <div class="mobile-menu__submenu-item__link-wrapper">

                </div>
              </button>
            </div>



            

          <div class="mobile-menu__submenu-item mobile-menu__submenu-item_social mobile-menu__submenu-item_last-in-row">
              <language :optionsClass="`selectable-control__options_top`" :btnClass="`selectable-control__btn_dark`" />
              <sound class="sound__mobile ml-7" />
              <settings class="ml-7 btn__icon_big" @click="closeMoreMenu" />
              <icon-btn class="social__icon_medium ml-7" :href="link.link" :icon="link.icon_name" v-for="link in $store.state.settings.social_links" :key="link.id">
              </icon-btn>
          </div> 
      


          </div>
        </transition>
        <button @click.stop="goto('index')" tag="a"  class="mobile-menu__link" :class="{'mobile-menu__link_active': $route.name == 'index' && !modaledPageOpened}">
            <span class="icon-crash mobile-menu__link-icon"></span>
            {{ $t('crash.name') }}
        </button>

        <button @click.stop="toggleMarket" class="mobile-menu__link" :class="{'mobile-menu__link_active': marketOpened}">
            <span class="icon-shopping-basket mobile-menu__link-icon"></span>
            {{ $t('mobile.market') }}
        </button>

        <button @click.stop="toggleChat" class="mobile-menu__link" :class="{'mobile-menu__link_active': chatOpened}">
            <span class="icon-chat mobile-menu__link-icon"></span>
            {{ $t('mobile.chat') }}
        </button>

        <button @click.stop="goto('profile')" class="mobile-menu__link" :class="{'mobile-menu__link_active': $route.name == 'profile' && !modaledPageOpened}">
            <span class="icon-account mobile-menu__link-icon"></span>
            {{ $t('mobile.profile') }}
        </button>


        <a @click.stop="toggleMoreMenu" data-submenu="mobile-menu__submenu_more" class="open-submenu mobile-menu__link" :class="{'mobile-menu-more__link_active': moreMenuOpened}">
          <span class="icon-more mobile-menu__link-icon"></span>
          {{ $t('mobile.more') }}
        </a>
      </div>
  </div>
</template>

<script>
import Button from '../../misc/Button.vue';
import Language from '../../misc/Language.vue';
import IconBtn from '@/misc/IconBtn.vue';
import Sound from '@/misc/Sound.vue';
import Settings from '../header/Settings.vue';

export default {
  components: { Button, Language, IconBtn, Settings, Sound },
  data() {
    return {
      moreMenuOpened: false
    }
  },
  computed: {
    chatOpened() {
        return this.$store.state.chat.chatOpenedMobile;
    },
    marketOpened() {
      return this.$store.state.market.marketOpened;
    },
    modaledPageOpened() {
      return this.marketOpened || this.chatOpened;
    }
  },
  methods: {
      closeMoreMenu() {
        this.moreMenuOpened = false;
      },
      toggleMoreMenu() {
        this.moreMenuOpened = !this.moreMenuOpened;
      },
      goto(name) {
        this.$store.commit('chat/CLOSE_CHAT', null, {root: true});
        this.$store.commit('market/SET_MARKET_OPENED_STATE', false, {root: true});
        this.closeMoreMenu();
        this.$router.push({name: name}).catch(() => {});
      },
      openModal(modal) {
          this.$router.push({query: {...this.$route.query, m: modal}}).catch(()=>{});
          this.closeMoreMenu();
      },
      toggleMarket() {
          this.closeMoreMenu();
          if (!this.$store.getters['auth/loggedIn']) {
              this.$router.push({query: {...this.$route.query, m: 'login'}});
          }
          else {
              this.$store.commit('chat/CLOSE_CHAT', null, {root: true});
              if (this.$store.state.market.marketOpened) {
                  this.$store.dispatch('market/closeMarket');
              }
              else {
                  this.$store.dispatch('market/openMarket');
              }
          }
      },
      toggleChat() {
        this.closeMoreMenu();
        this.$store.commit('market/SET_MARKET_OPENED_STATE', false, {root: true});
        this.$store.commit('chat/TOGGLE_CHAT');
      }
  }
}
</script>

<style>

</style>