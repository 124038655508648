<template>
      <overlay-scrollbars class="profile__stats-scrollbar" style="max-width: 100%; max-height: 100%" :options="scrollbarOptions">
  <div class="profile__stats">
      
      <div class="profile__stat dark-panel">
          <div class="profile__stat-icon">
              <span class="icon-bag"></span>
          </div>
          <div class="">
            <div class="profile__stat-value">${{ best_win.convertToDollars() }}</div>
            <div class="profile__stat-what">{{ $t('profile.stats.best_win') }}</div>
          </div>
      </div>
      <div class="profile__stat dark-panel">
          <div class="profile__stat-icon">
              <span class="icon-all"></span>
          </div>
          <div class="">
            <div class="profile__stat-value">${{ total_bets.convertToDollars() }}</div>
            <div class="profile__stat-what">{{ $t('profile.stats.total_bets') }}</div>
          </div>
      </div>
      <div class="profile__stat dark-panel">
          <div class="profile__stat-icon">
              <span class="icon-deagle"></span>
          </div>
          <div class="">
            <div class="profile__stat-value">{{ best_winstreak }}</div>
            <div class="profile__stat-what">{{ $t('profile.stats.best_winstreak') }}</div>
          </div>
      </div>
      
      <div class="profile__stat dark-panel">
          <div class="profile__stat-icon">
              <span class="icon-award-symbol"></span>
          </div>
          <div class="">
            <div class="profile__stat-value">{{ games_played }}</div>
            <div class="profile__stat-what">{{ $t('profile.stats.games_played') }}</div>
          </div>
      </div>
       <div class="profile__stat dark-panel">
          <div class="profile__stat-icon">
              <span class="icon-profit"></span>
          </div>
          <div class="">
            <div class="profile__stat-value">x{{ best_multiplier.toFixed(2) }}</div>
            <div class="profile__stat-what">{{ $t('profile.stats.best_multiplier') }}</div>
          </div>
      </div>
      <div class="profile__stat dark-panel">
          <div class="profile__stat-icon">
              <span class="icon-scale"></span>
          </div>
          <div class="">
            <div class="profile__stat-value">{{ win_ratio.toFixed(2) }}%</div>
            <div class="profile__stat-what">{{ $t('profile.stats.win_ratio') }}</div>
          </div>
      </div>
     
  </div>
      </overlay-scrollbars>
</template>

<script>
import * as API from '@/services/API';
export default {
    data() {
        return {
            win_ratio: 0,
            best_multiplier: 0,
            games_played: 0,
            best_winstreak: 0,
            total_bets: 0,
            best_win: 0,
            scrollbarOptions: {
                className : 'os-theme-thin-light',
            }
        }
    },
    created() {
        API.apiClient.get('profile/stats')
        .then(response => {
            if (response.data.success) {
                this.win_ratio          = response.data.data.win_ratio;
                this.best_multiplier    = response.data.data.best_multiplier;
                this.games_played       = response.data.data.games_played;
                this.best_winstreak     = response.data.data.best_win_streak;
                this.total_bets         = response.data.data.total_bets_amount;
                this.best_win           = response.data.data.best_win;
            }
        })
    }
}
</script>

<style>

</style>