<template>
  <div class="loader loader__big"></div>
</template>

<script>

export default {

}
</script>

<style>
    .loader__big {
        font-size: 5px;
    }
</style>