<template>
    <div class="container">
        <user-stash />
        <div class="game__wrapper" style="position: relative">
            <overlay-scrollbars class="scroll-when-not-initialized" style="max-height: 100%;" :options="scrollbarOptions" ref="main-scrollbar">
                <div class="game">
                    <crash />
                    <site-footer />
                </div>
            </overlay-scrollbars>
        </div>
    

    </div>
</template>

<script>
import UserStash from '../../components/inventory/UserStash';
import Crash from '../../components/crash/Crash.vue';
import SiteFooter from '../../components/footer/Footer.vue';
import Loader from '@/misc/Loader.vue';
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
        return {
            scrollbarOptions: {
                className : 'os-theme-medium',
                nativeScrollbarsOverlaid : {
                    showNativeScrollbars   : false,
                    initialize             : false 
                },
            }
        }
    },
    created() {
        this.$store.dispatch("crash/init");
    },
    
    computed: {
        ...mapState('crash', { gameDataLoaded: 'dataLoaded' }),
        
    },
    components: {
        UserStash,
        Crash,
        SiteFooter,
        Loader
    },
}

</script>
