<template>
    <div class="login__login-tab">
        <social-auth></social-auth>
        <div class="login__or">
            <span class="login__or-text">{{ $t('util.or') }}</span>
        </div>
        <form @submit.prevent="gValidate">
            <div class="login__form">
                <div class="login__error" v-if="hasError">
                    {{ error }}
                </div>
                <div class="login__field bet__input-wrapper">
                    <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="loginError">
                        <div class="bet__input-validation-msg">{{loginError}}</div>
                    </div>
                    <login-input :placeholder="$t('auth.login_placeholder')" v-model="login" :addClass="`input-field_login ${loginError ? 'input-field_error' : ''}`"/>
                </div>
                <div class="login__field bet__input-wrapper mt-10">
                    <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="passwordError">
                        <div class="bet__input-validation-msg">{{passwordError}}</div>
                    </div>
                    <login-input :placeholder="$t('auth.password_placeholder')" v-model="password" type="password" :addClass="`input-field_login ${passwordError ? 'input-field_error' : ''}`"/>
                    <div class="login__password-reset">
                        <button type="button" class="forgot-password" @click.stop="$router.push({query: {...$route.query, m: 'forgot'}})">
                            {{ $t('auth.forgot_password') }}
                        </button>
                    </div>
                </div>
                <div class="login__field bet__input-wrapper">
                    <vue-recaptcha
                        ref="recaptcha"
                        size="invisible"
                        :sitekey="sitekey"
                        @verify="loginUser"
                        @expired="onCaptchaExpired"
                    />
                </div>
            </div>
            <div class="login__button">
                <button type="submit" class="default-btn default-btn_high" :disabled="isLoggingIn">
                    <div v-if="!isLoggingIn">{{ $t('auth.login') }}</div>
                    <div v-else class="loader"></div>
                </button>
            </div>
        </form>
        <div class="login__consent">
            {{ $t('auth.by_clicking') }} <router-link tag="a" class="default-link" :to="{name: 'terms'}">{{ $t('nav.terms') }}</router-link> 
        </div>
    </div>
</template>

<script>
import LoginInput from '../login/LoginInput.vue';
import AuthService from '@/services/authService';
import SocialAuth from './SocialAuth.vue';
import VueRecaptcha from 'vue-recaptcha';
import config from '@/_config';
import { validateEmail, processValidationError, processAPIError } from '@/util';

export default {
    components: {
        LoginInput,
        SocialAuth,
        VueRecaptcha 
    },
    computed: {
        hasError() {
            return !!this.error;
        },
        sitekey() {
            return config.recaptcha.sitekey;
        }
    },
    data() {
        return {
            login: '',
            password: '',
            error: '',
            loginError: '',
            passwordError: '',
            isLoggingIn: false,
        }
    },
    methods: {
        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        },
        gValidate() {
            if (this.validate()) {
                this.isLoggingIn = true;
                this.error = '';
                this.$refs.recaptcha.execute();
            }
        },
        async loginUser(recaptchaToken) {
               
                const payload = {
                    login: this.login,
                    password: this.password,
                    captcha: recaptchaToken
                };
                this.error = '';
                this.isLoggingIn = true;
                try {
                    await AuthService.login(payload);
                    this.$router.push('/');
                    this.$router.go();
                } catch (error) {
                    const errorData = processValidationError(error);
                    if (['errors.too_many_requests', 'captcha.failed'].includes(errorData[0])) {
                        this.$store.dispatch('notification/showError', errorData, {root: true});
                    }
                    else {
                        this.error = this.$t('auth.auth_error');
                    }
                }
                finally {
                    this.isLoggingIn = false;
                    this.onCaptchaExpired();
                }
            
        },
        validate() {
            this.loginError = this.validateLogin();
            this.passwordError = this.validatePassword();
            return !this.loginError && !this.passwordError;
        },
        validateLogin() {
            if (!this.login.trim()) {
                return this.$t('validation.login_not_empty');
            }
            return '';
        },
        validatePassword() {
            if (!this.password.trim()) {
                return this.$t('validation.password_not_empty');
            }
            return '';
        },
    },
    watch: {
        login() {
            if (!this.validateLogin()) {
                this.loginError = '';
            }
        },
        password() {
            if (!this.validatePassword()) {
                this.passwordError = '';
            }
        },
    }
}
</script>
