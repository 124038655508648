<template>
    <div class="crash__bets" v-if="bets.length > 0 && $store.getters['settings/bets_visibility']">
        <overlay-scrollbars ref="crash-bet-scrollbar" style="width: 100%;" :options="crashBetsScrollbarOptions">
            <div class="crash-scrollbale">

    
                <transition-group name="slide-right-fade">
                        <crash-bets-element v-for="bet in bets" :key="bet.id" :bet="bet" />
                </transition-group>

            </div>
        </overlay-scrollbars>
    </div>

     <div class="crash__bets crash__bets_empty" v-else-if="!$store.getters['settings/bets_visibility']">
    
                <div class="market-empty__pic">
                    <span class="icon-empty"></span>
                </div>
                <div class="market-empty__text">
                    
                    {{ $t('util.bets_hidden') }}
                </div>
        
    </div>
    <div class="crash__bets crash__bets_empty" v-else>
    
                <div class="market-empty__pic">
                    <span class="icon-empty"></span>
                </div>
                <div class="market-empty__text">
                    
                    {{ $t('util.no_bets') }}
                </div>
        
    </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import MiniBet from '../../misc/MiniBet.vue';
import CrashBetsElement from './CrashBetsElement.vue';
export default {
    data() {
        return {
            crashBetsScrollbarOptions: {
                className : 'os-theme-light-track crash-bets-scrollbar',
                scrollbars: {
                    autoHide: 'move'
                }
            }
        }
    },
    computed: {
        ...mapGetters('crash', {bets: 'bets'})
    },
    
    components: {
        CrashBetsElement,
        MiniBet
    },
}
</script>

