<template>
    <div class="items-wrapper">
        <overlay-scrollbars :options="scrollbarOptions"
                            ref="scrollbar">
            <div class="skins__wrapper">
                <skin v-for="item in items" :key="(item.marketItem ? -item.id : item.pivot_id)" :item="item" @select="itemSelected" @unselect="itemUnselected" :itemClass="itemClass"/>
            </div>
        </overlay-scrollbars>
    </div>
</template>

<script>
import skin from '../../misc/Skin.vue'
import { mapState } from 'vuex'

export default {
    props: ['items', 'itemClass'],
    components: {
        skin
    },
    data() {
        return {
            scrollbarOptions: {
                className : 'os-theme-thin-light itemlist-scrollbar',
                callbacks: {
                    onScroll: this.scroll
                },
                scrollbars: {
                    autoHide: 'move'
                },
                overflowBehavior : {
                    x : "hidden",
                    y : "scroll"
                }
            }
        }
    },
    methods: {
        scroll(event) {
            const element = event.target;
            if ((element.scrollHeight - element.scrollTop) - element.clientHeight <= 120)
            {
                this.$emit('scrolledToBottom');
            }
            this.updateAtTheBottom();
        },
        updateAtTheBottom() {
            const scrollInfo = this.$refs.scrollbar.osInstance().scroll();
            this.$emit('atTheBottom', (scrollInfo.position.y - scrollInfo.max.y) >= -5);
        },
        itemSelected(event) {
            this.$emit('itemSelected', event);
        },
        itemUnselected(event) {
            this.$emit('itemUnselected', event);
        },
        scrollTop() {
             this.$refs.scrollbar.osInstance().scroll([0, 0])
        },
    },
    mounted() {
        this.updateAtTheBottom();
    },
    watch: {
        items() {
            
        }
    }
}
</script>

<style>

</style>