<template>
  <div class="game__container">
        <div class="game__area">
            <mq-layout mq="lg+">
                <crash-bet class="hide-lg" />
            </mq-layout>
            <crash-game />
        </div>

        <div class="game__footer" >
            <div class="game__footer-content">
                <crash-stats class="hide-lg" />
                <crash-line />
            </div>
            <mq-layout :mq="['sm', 'md']">
                <crash-bet class="mt-15 show-lg hidden" />
                <items-picker-mobile />
            </mq-layout>
            <crash-stats class="full-width show-lg"/>
        </div>
        

        <crash-bets v-if="$store.state.crash.dataLoaded" />
        <div style="min-height: 100px;" v-else>
            <loader style="margin-top: 30px;" />
        </div>
    </div>
</template>

<script>
import CrashBet from '../../components/crash/CrashBet';
import CrashGame from '../../components/crash/CrashGame';
import CrashLine from '../../components/crash/CrashLine';
import CrashStats from '../../components/crash/CrashStats';
import CrashBets from '../../components/crash/CrashBets';
import Loader from '../../misc/Loader.vue';
import ItemsPickerMobile from '@/components/inventory/ItemsPickerMobile';

export default {
    components: {
        CrashBet,
        CrashGame,
        CrashLine,
        CrashStats,
        CrashBets,
        Loader,
        ItemsPickerMobile
    },
}
</script>

<style>

</style>