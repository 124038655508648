<template>
    <label :class="{'active': currentState}" class="toggle__button">
        <input type="checkbox" :disabled="disabled" v-model="checkedValue">
        <span class="toggle__switch"></span>
    </label>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        defaultState: {
            type: Boolean,
            default: false
        },
        controlState: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentState: this.defaultState
        }
    },

    watch: { 
      	controlState: {
                handler: function(newVal, oldVal) {
                    this.currentState = newVal;
                },
                immediate: true
          }
    },
    computed: {
        checkedValue: {
            get() {
                return this.currentState;
            },
            set(newValue) {
                this.currentState = newValue;
                this.$emit('change', newValue);
            }
        }
    }
}
</script>

<style scoped>
.toggle__button {
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
}
.toggle__button input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
}
.toggle__button .toggle__switch {
    display: inline-block;
    height: 20px;
    border-radius: 14px;
    width: 40px;
    background: #303342;
    position: relative;
    margin-left: 10px;
    transition: all .25s;
}
.toggle__button .toggle__switch::after, 
.toggle__button .toggle__switch::before {
    content: "";
    position: absolute;
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    left: 3px;
    top: 50%;
    transform: translateY(-50%) translateX(0);
    transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
}
.toggle__button .toggle__switch::after {
    background: #8f94a1;
}
.toggle__button .toggle__switch::before {
    background: #8f94a1;
    opacity:0;
}
.active .toggle__switch {
    background: #5ccf36;
}
.active .toggle__switch::after,
.active .toggle__switch::before{
    transform:translateX(40px - 18px);
}
.active .toggle__switch::after {
    left: 22px;
    background: #fff;
}
</style>