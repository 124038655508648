<template>
    <div class="">
        <div class="items-picker__mobile" v-if="loggedIn">
            <div class="items-picker__mobile-panel">
                <div class="items-picker__mobile-balance">
                    <div class="items-picker__mobile-amount">
                        <div class="items-picker__mobile-name">{{ $t('itempicker.balance') }}</div>
                        <div class="items-picker__mobile-value">$<span class="items-picker__balance-number">{{ balanceInDollars }}</span></div>
                    </div>
                    <div class="items-picker__mobile-amount mt-5">
                        <div class="items-picker__mobile-name">{{ $t('itempicker.selected') }}</div>
                        <div class="items-picker__mobile-value">${{ userStashSelectedAmount.convertToDollars() }}</div>
                    </div>
                </div>
                <toggle class="ml-auto" @change="setAllInSelection" :controlState="userStashSelectedAll"/>
            </div>
            <button class="items-picker__mobile-inventory" :class="{'opened': pickerOpened}" @click="togglePicker">
                <span class="icon-rifle"></span>
            </button>
        </div>
        <modal-caller-button class="default-btn_high default-btn_small-text mt-10" modal="login" v-else>{{ $t('itempicker.guest_mobile') }}</modal-caller-button>
        
        <transition name="fade">
            <div v-show="pickerOpened">
                <item-list v-if="userStash.length > 0" class="items-list__mobile" @atTheBottom="scrolledToBottom = $event"  :class="{'items-list__mobile_no-shadow': scrolledToBottom, 'opened': pickerOpened}" :items="userStash" @itemSelected="itemSelected" @itemUnselected="itemUnselected" />
                <div class="items-empty__mobile-wrapper"  v-else>
                    <div class="market-empty">
                        <div class="market-empty__pic">
                            <span class="icon-lifebuoy"></span>
                        </div>
                        <div class="market-empty__text">
                            {{ $t('itempicker.empty') }}
                        </div>
                    </div>
                </div>
                
                <market-open-btn />


            </div>
        </transition>
    </div>
</template>

<script>
import toggle from '@/misc/Toggle.vue'
import ItemList from '@/components/inventory/ItemList.vue'
import ModalCallerButton from '@/misc/ModalCallerButton.vue';
import MarketOpenBtn from '@/components/market/MarketOpenBtn.vue';
import UtilButton from '@/misc/Button';

import { mapGetters, mapState } from 'vuex'
import Loader from '@/misc/Loader.vue';

export default {
    components: {
        toggle,
        ItemList,
        ModalCallerButton,
        Loader,
        UtilButton,
        MarketOpenBtn
    },
    data() {
        return {
            scrolledToBottom: false,
            pickerOpened: false
        }
    },
    methods: {
        setAllInSelection(c) {
            this.$store.dispatch(c ? 'stash/selectAll' : 'stash/unselectAll');
        },
        itemSelected(item) {
            this.$store.dispatch('stash/addItemToBet', item);
        },
        itemUnselected(item) {
            this.$store.dispatch('stash/removeItemFromBet', item);
        },
        togglePicker() {
            if (!this.pickerOpened && this.loaded) {
                this.pickerOpened = true;
            }
            else if (this.pickerOpened) {
                this.pickerOpened = false;
            }
        }
    },
    computed: {
        ...mapState('stash', {
            userStash: 'userStash',
            userStashSelectedAmount: 'userStashSelectedAmount',
            loaded: 'loaded',
        }),
        ...mapGetters('stash', {
            userStashSelectedAll: 'userStashSelectedAll'
        }),
        ...mapGetters('auth', ['loggedIn', 'balanceInDollars']),
        ...mapState('auth', ['user'])
    }
}
</script>

<style>

</style>