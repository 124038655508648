<template>
    <modal :show="showModal" @close="closeModal" maxWidth="400px" closeClass="modal__close_with-header"> 
       <div class="modal__header modal__tabs">
           <div class="modal__tab modal__tab_active flex-align">
                <span class="icon-account modal__tab-icon"></span> <span>{{ $t('profile.edit.username') }}</span>
           </div>
       </div>
       <div class="modal__body">
           <div class="text__box">
               <div class="text__emoji">
                   <img alt="☝" width="32" height="32" class="imga" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABLUExURUdwTMx9DN+WC+ahEs+BCr9qCb1qDdqNCc9+CtuQDMBtDdOECcRyDf/TUv/VXP/KPfi9Kv/PR/zENOmkEfS1Ie+uGeKaC6dWDoVBDbzwoFUAAAANdFJOUwBgpds9If7+/oWW38RdDrlaAAAD3ElEQVRYw+2Y2XKjMBBFLRZJ2Mbahf//S+d2N+DM1EwJBz/kYfohRSXh0OttweXyng1KDZfzNvYxBNeN5znzAzZPZ0EqPhjkz0bXOQY9nDoJ6p2E5qazKRLQI3afAc3x+imPToOieOSn/6B/DewHPQqf8ohB4QeB/IdA1Yf5R4FUze4kaBwG6LQyG+ibQzt0fUp9NyhTuGzBv6dHo+q6TlFqsvfR+3Q3yX8DpPrifS7d2GUfnXPRV5u8Ow5CPighg8H90cVyv+XoAtaZKyvIHQJNfc65n8Z78Q73O/iBzMww5231lO1DHnUFj3fO35eEizmEmGxyxJmDN/VwaAOcp4fHChD5AZBeQXM09XDVphL5Hld0dRJQwtWMgHBZkxNQ344sy02h8O0bUpZHSImukKwDIJmmOWRtCAlQtl9A4SGg5nlkEjHFDrQmzDIPL1BhUHgTZNk3gIzJlPfgInuE/Ps0HAShbMbiTEVVy2jogt7KpZjkHgT0qXkcUTQO9K/F6sx3p2q0qTXVWg13lKOxm9qSUwo/PFkNRiqJDb/0NHgJRUVkvrRBRu6EGwuc8IgtUIQSMPo80wT60uzIqzWIAhy7aINGltS/jHIG73ITNFltAQDHVq1NDH+SqIwllWZHTkAsWsOdUvAzkxCFzebNK59urUaaarVaawtRzGZZEjIM82yyHWnoMMmqCZIq4d6czIIOIsv807v5ReobLTlJ7xEHLWCsrVx8piPOte1J5BrpVgkQDoVACHNBGakXUcziJfc8yq1xI1B+gapdns+F7KnNSiIO5Vs1tnIhTkTTZQqIivhczSJzq1ZCABpTQiC/bh8ioaPQDIvQUEpSTclRAzTSMiPVp4dmQSHlFslanpb2kkTWBF0AilId0gDyinMNz9Dzhf7C+gtxUa11JA5RswSOT2rPlVsXHElSMarVSCuIh91R0jP349pJvKKw95IZmmcgWtDzVh1CcTcQb80e+Lk5bYPhVT1LcYgEFJmMmxwCIEi1JSTjjZ+7u8QogUlbMAdz2FTtiTLxIrFEut0vJx2WalNHSLX9TnqspPCFRJWstX34G29plbP5d6filivqLXvgPbsTl9wq+huIIYJBtx85aQ085jtJQG7rg3UAD734dzXxqY9JYcsRc0RbjD12PB5vG2nX/dUhdgejcj/4cUSxHArK/YVjD3/RmIxh/ReUdOTOsfaNDxoTLVxBSQ9uikLi9NaLiLqx3LN8xz0s1rg330OGO61uPofI6YQwpG7vvxgpQvEukn1E3ujrd74cjcP1frO7aX2/fv8D1DgodWVT6vSXNeAuH4ActV/7wHR+znGeDAAAAABJRU5ErkJggg==">
               </div>
               <div class="text__box-content text__text">
                   {{ $t('profile.edit.warning') }}
               </div>
           </div>
           <div class="promocode mt-15">
               <div class="bet__input-validation" v-if="hasErrors">
                    <div class="bet__input-validation-msg">{{ error }}</div>
                </div>
                <input autocomplete="off" :class="{'input-field_error': hasErrors}" v-model="username" type="text" class="input-field input-field_login promocode__input" @input="validate" @blur="validate">
                <button @click.stop="change" class="promocode__activate" :disabled="loading">
                    <div v-if="!loading">{{ $t('profile.edit.change') }}</div> 
                    <div v-else class="loader"></div>
                </button>
            </div>
       </div>

    </modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '../../misc/Modal';

export default {
    data() {
        return {
            username: '',
            min: 3,
            max: 16,
            error: ''
        }
    },
    components: {
        Modal
    },
    computed: {
        showModal() {
            return this.$store.state.profile.editModalOpened;
        },
        ...mapState('profile', {loading: 'usernameUpdating'}),
        ...mapGetters('profile', {c_username: 'username'}),
        hasErrors() {
            return this.error.trim() !== '';
        }

    },
    methods: {
        validate() {
            if (this.username.length < this.min) this.error = this.$t('validation.min_length', {value: this.min});
            else if (this.username.length > this.max) this.error = this.$t('validation.max_length', {value: this.max});
            else if (this.username.match(/[^A-Za-z0-9-_]+/)) this.error = this.$t('validation.alpha_dash')
            else {
                this.error = '';
            }
            return this.error === '';
        },

        change() {
            if (this.validate() && this.username !== this.$store.getters['auth/authUser'].username)
                this.$store.dispatch('profile/changeUsername', this.username);
        },
        closeModal() {
            this.$store.commit('profile/SET', {key: 'editModalOpened', value: false});
        }
    },
    watch: {
        showModal(val) {
            if (val) this.username = this.$store.getters['auth/authUser'].username;
            this.error = '';
        },
        c_username(val) {
            this.username = val;
        }
    }
}
</script>

<style>

</style>