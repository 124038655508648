<template>
    <div class="container">
        <overlay-scrollbars class="scroll-when-not-initialized" style="max-height: 100%; width: 100%;" :options="profileScrollbarOptions">
            <div class="profile">
                <div class="profile__menu">
                    <div class="profile__info">
                        <div class="profile__user">
                            <div class="profile__picture">
                                <avatar :src="authUser.picture" :size="75" />
                            </div>
                            <div class="profile__details">
                                <div class="profile__username text_light_bold">
                                    <div class="profile__username-text">
                                        {{ authUser.username }}
                                    </div>
                            
                
                                    <button class="profile__unique-id" v-clipboard="() => `#${authUser.id}`" v-clipboard:success="() => $store.dispatch('notification/show', {type: 'success', message: $t('util.copied')})">
                                        ID: #{{ authUser.id }}
                                    </button>
                                </div>
                                <div class="profile__balance">
                                    <span class="profile__currency">
                                        $
                                    </span>
                                    {{ balanceInDollars }}
                                </div>
                                <div class="profile__level">
                                    <rank-badge :rank="authUser.level" class="mb-5 mt-10"/>
                                    <progressbar :progress="authUser.level_progress" :rank="authUser.level" />
                                </div>
                            </div>
                        </div>
                        <div class="profile__deposit">
                            <util-button :isBig="true" variant="blue"  class="mr-10 default-btn" @click.stop="$router.push({query: {...$route.query, m: 'deposit'}})">{{ $t('header.deposit') }}</util-button>
                            <util-button @click.stop="openEditModal" width="40px" class="text_gray default-btn" style="min-width: 40px;" variant="gray"><span class="icon-settings" ></span></util-button>
                        </div>
                        <div class="profile__dropdown show-lg">
                
                            <selectable-control ref="selectable_control" btnClass="selectable-control__btn_full selectable-control__btn_mobile selectable-control__btn_medium-dark selectable-control__btn_h50" :optionsClass="`selectable-control__options_dark selectable-control__options_full selectable-control__options_h50`">
                                <template v-slot:button>
                                    <span :class="`${activeMenuOption.icon} profile__nav-icon profile__nav-icon_mobile profile__nav-icon_mobile-active`"></span>
                                    <span class="selectable-control__currency">{{ $t('profile.menu.' + activeMenuOption.title) }}</span>
                                </template>
                                <template v-slot:options>
                            
                                    <a v-for="link in menu" :key="link.tab" @click.stop="goto(link.tab)" class="selectable-control__option">
                                        <span :class="`${link.icon} profile__nav-icon profile__menu-option-icon_mobile`"></span>
                                        {{ $t('profile.menu.' + link.title) }}
                                    </a>

                                    <a class="selectable-control__option" @click.stop="logout">
                                        <span :class="`icon-log-out profile__nav-icon profile__menu-option-icon_mobile`"></span>

                                        {{ $t('profile.menu.log_out') }}
                                    </a>
            
                                </template>
                            </selectable-control>


                        </div>
                    </div>
                    <div class="profile__nav">
                        <div class="profile__nav-item" v-for="(link, index) in menu" :key="link.tab">
                            <a @click.stop="$router.push({query: {...$route.query, t: link.tab}}).catch(()=>{})" class="profile__nav-link" :class="{'profile__nav-link_active': (tab == link.tab || (!tab && index === 0))}">
                                <span :class="`${link.icon} profile__nav-icon`"></span>
                                {{ $t('profile.menu.' + link.title) }}
                            </a>
                        </div>
                        
                        <div class="profile__nav-item">
                            <button href="#" class="profile__nav-link" @click.stop="$store.commit('profile/SET', {key: 'logOutModalOpened', value: true})">
                                <span class="icon-log-out profile__nav-icon"></span>
                                {{ $t('profile.menu.log_out') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="profile__body">
                    <inventory v-if="!tab || tab == 'inventory'" />
                    <history v-if="tab == 'history'" />
                    <account v-if="tab == 'account'" />
                    <levels v-if="tab == 'levels'" />
                    <partnership v-if="tab == 'partnership'" />
                </div>
            </div>
        </overlay-scrollbars>
    </div>
</template>

<script>
import avatar from '../../misc/Avatar';
import progressbar from '../../misc/Progressbar';
import RankBadge from '../../misc/RankBadge.vue';
import UtilButton from '@/misc/Button.vue';
import Inventory from './Inventory.vue';
import History from '@/components/history/History.vue';
import Levels from '@/components/levels/Levels.vue';
import SelectableControl from '@/misc/SelectableControl.vue';

import { mapGetters } from 'vuex';
import Account from '@/components/account/Account.vue';
import Partnership from '../../components/partnership/Partnership.vue';

export default {
    metaInfo() {
      return {
        title: this.$t('profile.name', {name: this.$store.getters['auth/authUser'].username}),
      }
    },
    data() {
        return {
            profileScrollbarOptions: {
                className : 'os-theme-medium',
                nativeScrollbarsOverlaid : {
                    showNativeScrollbars   : false,
                    initialize             : false 
                },
            },
            menu: [
                {
                    title: 'inventory',
                    icon: 'icon-rifle',
                    tab: 'inventory'
                },
                {
                    title: 'game_history',
                    icon: 'icon-history',
                    tab: 'history'
                },
                {
                    title: 'account',
                    icon: 'icon-account',
                    tab: 'account'
                },
                {
                    title: 'levels',
                    icon: 'icon-levels-1',
                    tab: 'levels'
                },
                {
                    title: 'partnership',
                    icon: 'icon-partners',
                    tab: 'partnership'
                },

            ]
        }
    },
    components: {
        avatar, progressbar, RankBadge, UtilButton, Inventory, History, Account, Levels,
        Partnership, SelectableControl
    },
   
    methods: {
        openEditModal() {
            this.$store.commit('profile/SET', {key: 'editModalOpened', value: true});
        },
        goto(tab) {

            this.$router.push({query: {...this.$route.query, t: tab}}).catch(()=>{});
            this.$refs.selectable_control.hide();

        },

        logout() {
            this.$store.commit('profile/SET', {key: 'logOutModalOpened', value: true});
            this.$refs.selectable_control.hide();

        }
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'balanceInDollars']),
        tab() {
            return this.$route.query.t;
        },
        activeMenuOption() {
            if (!this.tab) return this.menu[0];
            return this.menu.find(x => x.tab == this.tab);
        }
    },
}
</script>

<style>

</style>