<template>
    <div class="accordeon">
        <div class="accordeon__header" :class="opened ? 'accordeon__header_opened' : 'accordeon__header_closed'" @click="opened =! opened">
            <div class="accordeon__header-content">
                <slot name="header"></slot>
            </div>
            <span class="icon-right-arrow accordeon__arrow" :class="{'accordeon__arrow_rotated' : opened}"></span>
        </div>
        <div class="accordeon__body" v-show="opened">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            opened: false
        }
    },
    methods: {
        close() {
            this.opened = false;
        }
    }
}
</script>

<style>

</style>