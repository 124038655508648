  
import Vue from "vue"
import Vuex from "vuex";

import * as auth from '@/store/modules/Auth';
import * as market from '@/store/modules/Market';
import * as stash from '@/store/modules/Stash';
import * as notification from '@/store/modules/Notification';
import * as modal from '@/store/modules/Modal';
import * as chat from '@/store/modules/Chat';
import * as settings from '@/store/modules/Settings';
import * as crash from '@/store/modules/Crash';
import * as airdrop from '@/store/modules/Airdrop';
import * as bonus from '@/store/modules/Bonus';
import * as profile from '@/store/modules/Profile';
import * as inventory from '@/store/modules/Inventory';
import * as deposit from '@/store/modules/Deposit';
import * as fairness from '@/store/modules/Fairness';
import * as notifications from '@/store/modules/Notifications';
import * as faq from '@/store/modules/Faq';
import * as sound from '@/store/modules/Sound';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: false,
    modules: {
      auth,
      market,
      stash,
      notification,
      modal,
      chat,
      settings,
      crash,
      airdrop,
      bonus,
      profile,
      inventory,
      deposit,
      fairness,
      notifications,
      faq,
      sound
    }
})