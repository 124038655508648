<template>
    
    <div class="enter-message">
        <div class="enter-message__contents">
            <div class="enter-message__area">
                <textarea v-if="loggedIn && !muted"  @keydown.enter.exact.prevent
                            @keyup.enter.exact="send"
                            @keydown.enter.shift.exact="newline" 
                            class="enter-message__textarea" 
                            v-model="message"  
                            :placeholder="$t('chat.message_placeholder')">
                </textarea>
                <div class="chat__mute" v-else-if="loggedIn">
                    <div class="chat__mute_date">
                        {{ $t('chat.you_muted', {value: beautifyTimestamp(user.mute_timestamp) }) }}
                    </div>
                    <div class="chat__mute_reason">
                        {{ $t('chat.reason', {value: $t(user.mute_reason) }) }}
                    </div>
                </div>
                <div class="chat__guest" v-else>{{ $t('itempicker.guest') }}</div>
            </div>
            <div v-if="loggedIn && !muted" class="enter-message__controls">
                <button  :class="{disabled: !messageNotEmpty}" @click="send" class="enter-message__send">
                    <span class="icon-send-msg"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from "luxon";
import { beautifyTimestamp } from '@/util';
export default {
    data() {
        return {
         
        }
    },
    methods: {
        beautifyTimestamp: beautifyTimestamp,
        newline() {
        },
        send() {
            if (this.messageNotEmpty) {
                this.$store.dispatch('chat/send', this.message);
                this.message = '';
                this.$emit('onSend');
            }
        }
    },
    computed: {
        message: {
            get: function () {
                return this.$store.state.chat.message;
            },

            set: function (value) {
                if (value != this.$store.state.chat.message)
                    this.$store.commit('chat/SET_MESSAGE', value)
            }
        },
        ...mapGetters('auth', {loggedIn: 'loggedIn', user: 'authUser', muted: 'muted'}),
        messageNotEmpty() {
            return this.message && this.message.trim();
        }
    }
}
</script>
