<template>
    <button class="icon-btn chat__toggle" @click="toggleChat" :class="{active: $store.state.chat.chatOpened}">
        <span class="icon-chat"></span>
    </button>
</template>

<script>
export default {
    methods: {
        toggleChat() {
            this.$store.commit('chat/TOGGLE_CHAT', null, {root: true});
        }
    }
}
</script>
