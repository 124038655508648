<template>
    <div class="account" >
        <div class="account__body" v-if="!loading">
            <email class="mt-10"/>
            <password-auth class="mt-35" v-if="!login"/>
            <password-update class="mt-35" v-else />
            <social-media class="mt-35"/>
        </div>
        
        <loader class="mt-20" v-else/>
    </div>
    
</template>

<script>

import PasswordAuth from './PasswordAuth.vue';
import PasswordUpdate from './PasswordUpdate.vue';
import SocialMedia from './SocialMedia.vue';
import Loader from '@/misc/Loader.vue';
import * as API from '@/services/API';
import Email from './Email.vue';

export default {
    components: { Loader, PasswordAuth, Loader, PasswordUpdate, SocialMedia, Email },
    data() {
        return {
            loading: false,
            providers: []
        }
    },
    created() {

    },
    computed: {
        login() {
            return this.$store.getters['auth/authUser'].login;
        }
    },
}

</script>

<style>

</style>