import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';

export const namespaced = true;

const getDefaultState = () => {
    return {
        loading: false,
        notifications: []
    }
}

export const state = getDefaultState

export const mutations = {
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET(state, {key, value}) {
        state[key] = value;
    },
    ADD_NOTIFICATION(state, notification) {
        state.notifications.unshift(notification);
        state.notifications = state.notifications.slice(0, 15);
    },
    READ_ALL(state) {
        state.notifications.forEach((notification) => notification.is_read = true);
    }
};

export const actions = {
    load({ commit, dispatch }) {
        commit('SET', {key: 'loading', value: true});
        API.apiClient.get('notifications')
        .then(response => {
            if (response.data.success) {
                commit('SET', {key: 'notifications', value: response.data.data.notifications});
            }
         
        })
        .catch(error => {
           
        })
        .finally(() => {
            commit('SET', {key: 'loading', value: false});
        });
    },
    read({ commit, dispatch, getters }) {
        if (getters.hasUnreadNotifications) {
            API.apiClient.post('notifications').then((response) => {
                if (response.data.success) {
                    commit('READ_ALL');
                }
            });
        }
    },
    add({ commit, dispatch }, notification) {
        commit('ADD_NOTIFICATION', notification);
        if (notification.title.includes('deposit')) {
            dispatch('auth/updateBalance', notification.params.balance,  { root: true });
            dispatch('sound/play', 'deposit', { root: true });
            dispatch('stash/fetchUserStash', null, { root: true });
        }
    }
};

export const getters = {
    hasUnreadNotifications: (state) => {
        return state.notifications.some((notification => notification.is_read == false));
    } 
};