<template>
    <div class="crash__input">
        <coeff-input :disabled="autoCashoutLocked" v-model="coeff" :min="min" :max="max" :default="coeff" :settingMode="editMode" @setting="enableEditMode" @confirmSetting="confirmSetting" @cancelSetting="cancelSetting"/>
        <bet-helper :disabled="autoCashoutLocked" @change="onHelperChange" :helpers="helpersCopy" :editMode="editMode" />
    </div>
</template>

<script>
import CoeffInput from '../../components/bet/CoeffInput.vue';
import BetHelper from '../../components/bet/BetHelper.vue';
import { mapGetters, mapState } from 'vuex'
export default {
    props: ['min', 'max'],
    data() {
        return {
            // helpers: [1.2, 2, 5, 10, 50],
            // helpersCopy: [1.2, 2, 5, 10, 50],
            // editMode: false
        }
    },
    components: {
        CoeffInput,
        BetHelper
    },
    computed: {
        coeff: {
            get () {
                return this.$store.state.crash.autoCashout
            },
            set (value) {
                this.$store.commit('crash/SET_AUTO_CASHOUT', value)
            }
        },
        helpers: {
            get () {
                return this.$store.state.crash.helpers
            },
            set (value) {
                this.$store.commit('crash/SET', {key: 'helpers', value: value})
            }
        },
        helpersCopy: {
            get () {
                return this.$store.state.crash.helpersCopy
            },
            set (value) {
                this.$store.commit('crash/SET', {key: 'helpersCopy', value: value})
            }
        },
        editMode: {
            get () {
                return this.$store.state.crash.editMode
            },
            set (value) {
                this.$store.commit('crash/SET', {key: 'editMode', value: value})
            }
        },
         ...mapGetters('crash', { betIsPlaced: 'betIsPlaced', canCashout: 'canCashout', autoCashoutLocked: 'autoCashoutLocked' }),
    },
    mounted() {
        // let _it = localStorage.getItem('crash_helper');
        // if (_it != null) {
        //     this.helpers = JSON.parse(_it);
        //     this.helpersCopy = JSON.parse(_it);
        // }
    },
    methods: {
        onHelperChange(e) {
            this.coeff = e;
            this.$store.dispatch('settings/setLastEnteredCoeff', this.coeff);
        },
        enableEditMode() {
            this.$store.commit('crash/ENABLE_EDIT_MODE');
        },
        cancelSetting() {
            this.$store.commit('crash/CANCEL_SETTING');
        },
        confirmSetting() {
            this.$store.commit('crash/CONFIRM_SETTING');
        }
    }
}
</script>

