<template>
    <div class="chat" :class="{opened: $store.state.chat.chatOpened, opened_mobile: $store.getters['chat/chatOpenedMobile']}">
        <div class="chat__header">
            <div class="chat__rules">
                <button @click.stop="$store.commit('chat/SET_RULES_OPENED', true)" class="chat__rules-btn">
                    {{ $t('chat.rules') }}
                </button>
            </div>
            <div class="chat__online">
                <div class="chat__lang-wrapper">
                    <chat-lang />
                </div>
            </div>
            <button class="chat__close show-lg" @click="$store.commit('chat/CLOSE_CHAT')">
                <span class="icon-cancel"></span>
            </button>
        </div>
        <div class="chat__airdrop">
            <airdrop />
            
        </div>
        <div class="chat__body">
            <overlay-scrollbars style="max-height: 100%;" ref="scrollbar" :options="scrollbarOptions">
                <div class="chat__messages">
                    <message v-for="message in messages" :key="message.id" :message="message" />
                </div>
            </overlay-scrollbars>
            <transition name="slide-right-fade">
                <div class="chat__control" v-show="showScrollDown">
                    <button class="chat__scrolldown" @click="scrollBottom">
                        <span class="icon-down-arrow"></span>
                        <span class="scrolldown__messages" v-if="newMessages">{{ newMessages }}</span>
                    </button>
                </div>
            </transition>
        </div>
        <div class="chat__footer">
            <enter-message @onSend="scrollBottom"/>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import Language from '../../misc/Language';
import ChatLang from './ChatLang.vue';
import EnterMessage from './EnterMessage.vue';
import Message from './Message.vue';
import ChatRulesModal from './ChatRulesModal.vue';
import Airdrop from '../airdrop/Airdrop.vue';

export default {
    data() {
        return {
            scrollbarOptions: {
                callbacks: {
                    onScrollStop: this.scrollStop
                },
                className : 'os-theme-light-track'
            },
            showScrollDown: false
        }
    },
    computed: {
        ...mapState('chat', {loaded: 'loaded', lang: 'chatLang'}),
        ...mapGetters('chat', {messages: 'messages'}),
        newMessages: {
            get() {
                return this.$store.state.chat.newMessages;
            },
            set(value) {
                this.$store.commit('chat/SET_NEW_MESSAGES', value);
            }
        }
    },
    mounted() {
        this.scrollBottom();
    },
    methods: {
        scrollBottom() {
            this.$refs.scrollbar.osInstance().scroll([0, '100%'])
            this.showScrollDown = false;
            this.newMessages = 0;
        },
        scrolledToBottom() {
            const scrollInfo = this.$refs.scrollbar.osInstance().scroll();
            return (scrollInfo.position.y - scrollInfo.max.y) >= -120;
        },
        scrollStop(event) {
            const element = event.target;
            const dist = (element.scrollHeight - element.scrollTop) - element.clientHeight;
            if (dist >= 120)
            {
                this.showScrollDown = true;
            }
            else {
                this.showScrollDown = false;
            }
            if (dist <= 30) {
                this.newMessages = 0;
            }
        }
    },
    watch: {
        loaded(val) {
            if (val) {
                this.scrollBottom();
            }
        },
        lang() {
            this.scrollBottom();
        },
        messages() {
            Vue.nextTick(() => {
                if (this.scrolledToBottom()) {
                    this.scrollBottom()
                }
            });
        }
    },
    components: {
        Language,
        ChatLang,
        Message,
        EnterMessage,
        ChatRulesModal,
        Airdrop
    }
}
</script>

        