<template>
  <button v-if="!gameDataLoaded" :disabled="!canCashout" class="default-btn default-btn_high default-btn_play mt-25">
        <div>
            {{ $t('util.loading') }}
        </div>
    </button>

    
    <button v-else-if="!betIsPlaced" @click="bet" :disabled="loading || userStashSelectedAmount == 0 || gameIsInProgress"  class="default-btn default-btn_high default-btn_play mt-25">
        <div v-if="gameIsInProgress">
            <div v-if="betIsWin">
                {{ $t('crash.you_won')  }} <span class="market-modal__buy-btn-price">${{ parseFloat(winningBet.win_value).convertToDollars() }}</span>
            </div>
            <div v-else>{{ $t('crash.game_in_progress') }}</div>
            
        </div>
        <div v-else>
            <div v-if="!loading">
                {{ $t('crash.play') }} <span class="market-modal__buy-btn-price">(${{ userStashSelectedAmount.convertToDollars() }})</span>
            </div>
            <div v-else class="loader"></div>
        </div>
    </button>

    <button v-else :disabled="!canCashout || loadingCashout" @click="makeCashout" class="default-btn default-btn_high default-btn_play mt-25">
        <div v-if="!loadingCashout">
            {{ $t('crash.cashout') }} <span class="market-modal__buy-btn-price">${{ cashout.convertToDollars() }}</span>
        </div>
        <div v-else class="loader"></div>
    </button>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import config from '@/_config';

export default {
    computed: {
        ...mapState('crash', { loading: 'placingBetInProgress', loadingCashout: 'cashoutInProgress', gameDataLoaded: 'dataLoaded', userBet: 'userBet' }),
        ...mapState('stash', { userStashSelectedAmount: 'userStashSelectedAmount' }),
        ...mapGetters('crash', { betIsPlaced: 'betIsPlaced', gameIsInProgress: 'gameIsInProgress', canCashout: 'canCashout', cashout: 'cashout', winningBet: 'winningBet', betIsWin: 'betIsWin' }),
        minCashout() {
            return config.crash.minCashout;
        },
        maxCashout() {
            return config.crash.maxCashout;
        }
    },
    methods: {
        bet() {
            this.$store.dispatch('crash/bet');
        },
        
        makeCashout() {
            this.$store.dispatch('crash/cashout');
        },

    }
}
</script>

<style>

</style>