<template>
    <nav class="navigation">
        <button @click.stop="$router.push({query: {...$route.query, m: 'bonus'}}).catch(()=>{})" class="navigation__link">
            <span class="icon-bonus navigation__icon"></span>
            <span class="navigation__title">{{ $t('nav.bonus') }}</span>
        </button>

        <button @click.stop="$router.push({query: {...$route.query, m: 'fairness'}}).catch(()=>{})" class="navigation__link">
            <span class="icon-fair navigation__icon"></span>
            <span class="navigation__title">{{ $t('nav.fair') }}</span>
        </button>
    </nav>
</template>

<script>
export default {
    
}
</script>