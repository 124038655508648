<template>
    <button class="default-btn" :class="stylingClasses" :style="styles" @click="$emit('click', $event)"><slot></slot></button>
</template>

<script>
export default {
    props: {
        'addClass': String,
        'isBig': {
            type: Boolean,
            default: false
        },
        'height': {
            type: String,
            default: null
        },
        'width': {
            type: String,
            default: null
        },
        'px': {
            type: String,
            default: '0px'
        },
        'variant': {
            type: String,
            default: 'whole'
        }
    },
    computed: {
        stylingClasses() {
            return `${this.addClass} default-btn_${this.variant}`
        },
        styles() {
            let styles = {};
            if (this.width) styles.width = this.width;
            if (this.height) styles.height = this.height;
            styles.padding = '0 ' + this.px;
            return styles;
        }
    }
}
</script>

