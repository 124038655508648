<template>
  <router-link tag="a" :to="{name: 'support'}" class="icon-btn">
      <span class="icon-support"></span>
  </router-link>
</template>

<script>
export default {
}
</script>
