<template>
    <div class="skin__wrapper" :class="computeClass">
        <div class="skin" @click="toggleSelection" :class="{active: itemIsSelected()}">
            <div class="skin__image-wrapper">
                <img :src="pathToItemImage(item.id)" class="skin__image" alt="">
            </div>
            <div class="skin__info">
                <div class="skin__weapon">{{ item.item_name }}</div>
                <div class="skin__name">{{ item.skin_name }}</div>
                <div class="skin__condition">{{ exterior }}</div>
            </div>
            <div class="skin__description">
                <div class="skin__additional-info">
                    <div class="skin__stattrack" v-if="item.stattrak">
                        ST&trade;
                    </div>
                </div>
                <div class="skin-price">
                    <span class="skin-price__currency">$</span>
                    <span class="skin-price__value">{{ item.price.convertToDollars() }}</span>
                    <span class="skin-price__value" v-if="!item.marketItem && item.is_bonus">
                        <span class="skin__bonus">
                            <span class="icon-bonus"></span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/_config';

export default {
    data() {
        return {
            // selected: false
        }
    },
    props: ['item', 'itemClass'],
    methods: {
        itemIsSelected() {
            if (this.item.marketItem) return this.item.selected;
            return this.selected;
        },
        toggleSelection() {
            if (!this.itemIsSelected()) {
                this.$emit('select', this.item);
            }
            else {
                this.$emit('unselect', this.item);
            }
        }
    },
    computed: {
        selected() {
            // if (this.item.marketItem) {
            //     return this.item.selected;
            // }
            return this.$store.state.stash.userStashSelected.find(x => x.pivot_id == this.item.pivot_id) !== undefined; 
        },
        ...mapGetters('market', ['residue']),
        exterior() {
            return this.exteriorName(this.item.exterior)
        },
        computeClass() {
            let _cl = 'skin__wrapper_' + this.rarityClass(this.item.rarity);
            if (this.itemClass) _cl += ' ' + this.itemClass;
            if (this.item.marketItem && !this.itemIsSelected() && this.residue < this.item.price) _cl += ' skin__wrapper_disabled'; 
            return _cl; 
        }
    },
    watch: {
    
    }
}
</script>
