<template>
    <div class="select__wrapper" @click="showOptions = !showOptions" v-click-outside="hideOptions">
        <div class="select" :class="selectClass">
            <span class="select__selected" :class="{'select__selected_not-default': initial != selected.value}">{{ selected.title }}</span>
            <span class="icon-down-arrow select__arrow" :class="arrowClass"></span>
        </div>
            
        <transition enter-active-class="animate__animated animate__fadeIn animate__faster"
                    leave-active-class="animate__animated animate__fadeOut animate__faster">
            <div class="select__options" v-if="showOptions">
                <overlay-scrollbars :options="scrollbarOptions">
                    <div class="select__scrollable">
                        <div class="select__option" v-for="option in options" :key="option.value">
                            <button class="select__option-btn" :class="{selected: option.value == selected.value}" @click="selectOption(option)">{{ option.title }}</button>
                        </div>
                    </div>
                </overlay-scrollbars>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showOptions: false,
            scrollbarOptions: {
                className : 'os-theme-thin-light select-input-scrollbar'
            },
            initial: null
        }
    },
    computed: {
        selected() {
            return this.options.find(option => option.value === this.value);
        },
        arrowClass() {
            return this.showOptions ? 'select__arrow_up' : '';
        },
        selectClass() {
            return this.showOptions ? 'active' : '';
        }
    },
    created() {
        this.initial = this.value;
    },
    props: {
        options: Array,
        value: [String, Number],
        maxHeight: {
            type: String,
            default: '300px'
        }
    },
    methods: {
        selectOption(option) {
            this.$emit('input', option.value)
        },
        hideOptions() {
            this.showOptions = false;
        }
    }
}
</script>
