<template>
    <router-link tag="a" :to="{name: 'profile'}">
        <div class="mini-profile">
            <avatar :src="avatar" :size="size" />
            <div class="mini-profile__info">
                <div class="mini-profile__username">{{ name }}</div>
                <rank-badge :rank="rank" class="mb-5 mt-10"/>
                <progressbar :progress="rankProgress" :rank="rank" />
            </div>
        </div>
    </router-link>
</template>

<script>
import avatar from '../../misc/Avatar';
import progressbar from '../../misc/Progressbar';
import RankBadge from '../../misc/RankBadge.vue';
export default {
    props: [
        'avatar', 'name', 'size', 'rank', 'rank-progress'
    ],
    components: {
        avatar, progressbar, RankBadge
    }
}
</script>