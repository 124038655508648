<template>
    <div class="input__wrapper">
        <input :type="type" class="input-field" :class="addClass" :placeholder="placeholder" :value="value" @input="$emit('input', $event.target.value)">
    </div>
</template>

<script>
export default {    
    props: {
        value: String, 
        placeholder: String, 
        type: {
            default: 'text'
        },
        addClass: String
    }
}
</script>