import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Messages from './messages.js';

Vue.use(VueI18n);

let lang = localStorage.getItem('lang') || 'RU';
const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: lang,
    messages: Messages
});

export default i18n;