<template>
    <div v-if="ping !== null">
        <div class="crash__ping">
        {{ $t('crash.ping') }}: <span class="crash__ping-val" :class="`crash__ping-val_${pingConditionClass}`">{{ ping }}</span> ms
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
         
        }
    },
    mounted() {

    },

    computed: {
         pingConditionClass() {
             if (this.ping <= 120) return 'good';
             if (this.ping <= 250) return 'medium';
             return 'bad';
         },
         ...mapState('crash', ['ping'])
    }
}
</script>

<style>

</style>