<template>
    <a target="_blank" :href="href" rel="noopener" v-if="href" class="btn__icon">
        <span :class="`icon-${icon}`"></span>
    </a>
    <button @click.stop="$emit('click')" v-else class="btn__icon">
        <span :class="`icon-${icon}`"></span>
    </button>
</template>

<script>
export default {
    props: ['icon', 'href'],
}
</script>

<style>

</style>