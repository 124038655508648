<template>
    <modal :show="showModal" @close="closeModal" maxWidth="500px" closeClass="modal__close_with-header" class="modal__crash-modal"> 
        <div v-if="!loading && !hasError && showModal">
            <div class="modal__header modal__tabs">
                <div class="modal__tab modal__tab_active flex-align">
                        <span class="icon-crash modal__tab-icon"></span> <span>Crash #{{id}}</span>
                </div>
            </div>
            <div class="modal__body">
                <div class="game__details">
                    <div class="game__detail">
                        <span>{{ $t('util.date') }}: </span> <span class="game__detail-value">{{ date }}</span>
                    </div>
                   
                </div>
                <div class="game__footer-stats game__details-stats mb-10">
                    <div class="game__stat">
                        <span class="icon-users game__stat-icon"></span>
                        <span class="game__stat-value">{{ game.players_count }}</span>
                    </div>
                    <div class="game__stat">
                        <span class="icon-deagle game__stat-icon"></span>
                        <span class="game__stat-value">{{ game.items_count }}</span>
                    </div>
                    <div class="game__stat">
                        <span class="icon-bag game__stat-icon"></span>
                        <span class="game__stat-value">${{ game.bank.convertToDollars() }}</span>
                    </div>
                </div>
                <div class="" style="position: relative">
                <crash-game-graph :value="value" :state="state"/>
                <div class="crash__label crash__modal-label" :class="{'crash__label_crashed': state == 2}">
                   x{{ value.toFixed(2) }}
                </div>
                </div>
                <div v-if="bet != null">
                    <div class="text__subheading mb-10">{{ $t('profile.history.your_bet') }}:</div>
                    <mini-bet :bet="bet" class="history__bet"/>
                </div>
            </div>
            <div class="modal__footer mt-7">
                <div class="game__check">
                    <button @click.stop="$router.push({query: {...$route.query, m: 'fairness-check', server_seed: game.server_seed, client_seed: game.client_seed, selected_game: $store.getters['crash/getModeID']}}).catch(()=>{})" class="game__check-btn"><span class="icon-fair"></span> {{ $t('fairness.prove') }}</button>
                </div>
            </div>
        </div>
        <div v-else-if="loading" style="padding: 50px 0;">
            <loader />
        </div>
        <div class="modal__body modal__body_error" v-else>
            {{ $t('profile.history.game_not_found') }}
        </div>
        
    </modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '../../misc/Modal';
import CrashGameGraph from './CrashGameGraph.vue';
import Loader from '@/misc/Loader.vue';
import * as API from '@/services/API';
import { beautifyDateTime } from '@/util';
import UtilButton from '@/misc/Button.vue';
import MiniBet from '../../misc/MiniBet.vue';

export default {
    data() {
        return {
            loading: false,
            game: null,
            bet: null,
            hasError: false
        }
    },

    components: {
        Modal, Loader, CrashGameGraph, UtilButton,
        MiniBet
    },
    computed: {
        state() {
            if (!this.bet) return 0;
            if (this.bet.status == 1) {
                return this.bet.is_win ? 1 : 2;
            }
            return 0;
        },
        value() {
            return this.game.multiplier;
        },
        date() {
            return beautifyDateTime(this.game.created_at);
        },
        showModal() {
            return this.$store.state.modal.activeModal === 'crash';
        },
        id() {
            return this.$route.query.id;
        }
    },
    methods: {
        closeModal() {
            this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                    ...this.$route.query,
                    id: undefined
                }
            })
        },
        load () {
            this.loading = true;
            return API.apiClient.get('profile/details/crash/' + this.id)
                .then(response => {
                    if (response.data.success) {
                        this.game = response.data.data.game;
                        this.bet = response.data.data.bet;
                    }
                    else {
                        this.hasError = true;
                    }
                })
                .catch(err => {
                    this.hasError = true;
                })
                .finally(() => this.loading = false);
        }
    },
    watch: {
       showModal: {
           immediate: true,
           handler(val) {
                if (val) this.load();
            }
       }
    }
}
</script>

<style>

</style>