<template>
    <div class="input-field__wrapper">
        <label for="input-field_crash" class="input-field__label">{{ $t('bet.auto_cashout') }}</label>
        <div class="input-wrapper__mobile">
            <div class="bet__input-validation" v-if="hasErrors">
                <div class="bet__input-validation-msg">{{error}}</div>
            </div>
            <div class="bet__input-wrapper mb-7">
                
                <input autocomplete="off" type="text" :disabled="disabled" inputmode="numeric" class="input-field input-field_bet" id="input-field_crash" :class="{'input-field_error': hasErrors}" :value="value" @input="input" @blur="blur" ref="input"/>
                <div class="bet__input-setting hide-lg">
                    <button class="bet__input-setting-start" @click="startSetting" v-if="!settingMode"><span class="icon-settings"></span></button>
                    <div class="bet__input-setting-controls" v-else>
                        <button class="bet__input-setting-start bet__input-setting_cancel" @click="cancelSetting"><span class="icon-cancel"></span></button>
                        <button class="bet__input-setting-start bet__input-setting_confirm" @click="confirmSetting"><span class="icon-confirm"></span></button>
                    </div>
                </div>
                
                <div class="input-suffix">
                    <span class="input-suffix__stub">{{ value }}</span>
                    <span class="input-suffix__val" :class="{'input-suffix__val_disabled': disabled}">x</span>
                </div>
            </div>
            <crash-button class="crash-button__mobile show-lg" />
        </div>
    </div>
    
</template>

<script>
import CrashButton from "../crash/CrashButton.vue"

export default {
    components: { CrashButton },
    data() {
        return {
            hasErrors: false,
            error: '',
         
        }
    },
    props: ['value', 'min', 'max', 'default', 'disabled', 'settingMode'],
    methods: {
        startSetting() {
            // this.settingMode = true;
            this.$emit('setting');
        },
        cancelSetting() {
            // this.settingMode = false;
            this.$emit('cancelSetting');
        },
        confirmSetting() {
            // this.settingMode = false;
            this.$emit('confirmSetting');
        },
        input(event) {
            let num = event.target.value;
            num = this.regexToNumber(num);
            this.$refs.input.value = num;
            this.$emit('input', num);
            this.validate(num);
        },
        blur(event) {
            let num = parseFloat(this.$refs.input.value) || this.default;
            num = Math.max(this.min, num);
            num = Math.min(this.max, num);
            num = num.toFixed(2);
            this.$refs.input.value = num;
            this.$emit('input', num);
            this.validate(num);
        },
        validate(val) {
            let num = parseFloat(val) || 0;
            if (num < this.min || num > this.max) {
                this.hasErrors = true;
                if (num > this.max) {
                    this.error = this.$t('validation.max_value', {value: this.max});
                }   
                if (num < this.min) {
                    this.error = this.$t('validation.min_value', {value: this.min});
                }   
            }
            else {
                this.error = '';
                this.hasErrors = false;
                this.$store.dispatch('settings/setLastEnteredCoeff', num);
            }
        }
    },
    mounted() {
        this.blur();
    }
}
</script>

