<template>
  <div class="progress-bar">
      <div class="progress-value" :class="'mini-profile__rank_' + rankColor" :style="{width: Math.min(100, progress) + '%'}">
      </div>
  </div>
</template>

<script>
export default {
    props: ['progress', 'rank'],
    computed: {
        rankColor() {
            if (this.rank == 1) return 'gray';
            if (this.rank <= 3) return 'blue';
            if (this.rank <= 5) return 'darkblue';
            if (this.rank <= 7) return 'pink';
            if (this.rank <= 9) return 'green';
            return 'red';
        }
    }
}
</script>
