import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';


export const namespaced = true;

export const state = {
    userStash: [],
    userStashSelectedAmount: 0,
    userStashSelected: [],
    userStashSelectedIDs: [],
    loaded: false,
    loading: false,
    _pedestalItems: [],
    _pedestalItemsFromGame: [],
    _pedestalItemsWin: []
};

export const mutations = {
    SET_STASH(state, stash) {
        state.userStash = stash;
    },

    SET_LOADED(state, value) {
        state.loaded = value;
    },

    SET_LOADING(state, value) {
        state.loading = value;
    },


    ADD_ITEM_TO_BET(state, item) {
        state.userStashSelectedAmount += item.price;
        state.userStashSelected.push(item);
    },

    REMOVE_ITEM_FROM_BET(state, item) {
        state.userStashSelectedAmount -= item.price;
        state.userStashSelected = state.userStashSelected.filter(x => x.pivot_id != item.pivot_id);
    },

    SELECT_ALL(state) {
        state.userStashSelectedAmount = 0;
        state.userStashSelected.splice(0);
        let sum = 0;
        let userStashSelected = [];
        state.userStash.forEach(it => {
            sum += it.price;
            userStashSelected.push(it);
        });
        state.userStashSelectedAmount = sum;
        state.userStashSelected = userStashSelected;
    },

    UNSELECT_ALL(state) {
        state.userStashSelectedAmount = 0;
        state.userStashSelected.splice(0);
    },

    SET_PEDESTAL_ITEMS_FROM_GAME(state, items) {
        state._pedestalItemsFromGame  = items;
    },

    SET_PEDESTAL_ITEMS_WIN(state, items) {
        state._pedestalItemsWin  = items;
    }
};

export const actions = {
    addItemToBet({ state, commit, dispatch }, item) {
        if (state.userStashSelected.length < config.market.max_stash_items_per_transaction) {
            commit('market/UNSELECT_ALL_ITEMS', null, { root: true });
            commit('ADD_ITEM_TO_BET', item);
        }
        else {
            dispatch('notification/show', 
            {
                type: 'error',
                message: i18n.t('errors.max_stash_items_per_transaction', {count: config.market.max_stash_items_per_transaction})}, 
                { root: true }
            );
        }
        
    },
    removeItemFromBet({ commit, state, dispatch }, item) {
        commit('market/UNSELECT_ALL_ITEMS', null, { root: true });
        commit('REMOVE_ITEM_FROM_BET', item);

    },
    selectAll({ commit, state, dispatch }) {
        commit('market/UNSELECT_ALL_ITEMS', null, { root: true });
        commit('SELECT_ALL');

    },
    unselectAll({ commit, state, dispatch }) {
        commit('market/UNSELECT_ALL_ITEMS', null, { root: true });
        commit('UNSELECT_ALL');
    },

    fetchUserStash({ commit, dispatch, state }) {
        if (!state.loading) {
            commit('SET_LOADING', true);
            API.apiClient
                .get('items/get')
                .then(response => {
                    dispatch('setStash', response.data.data.items);
                })
                .catch(error => {
                    dispatch('notification/show', {type: 'error', message: i18n.tc('itempicker.cant_load')}, {root: true})
                    commit('SET_STASH', []);
                })
                .finally(() => {
                    commit('SET_LOADED', true)
                    commit('SET_LOADING', false);
                    // SET_PROP({prop: 'loading', value: false});
                });
        }
        
    },
    addItemsToStash({ commit, state }, items) {
        let stashItems = state.userStash.slice(0);
        items.forEach((el) => {
            el.selected = false;
            el.marketItem = false;
            stashItems.push(el);
        });
        stashItems.sort((a, b) => b.price - a.price);
        // dispatch('unselectAll');
        commit('SET_STASH', stashItems);
    },
    setStash({ commit, dispatch }, items) {
        let _rawItems = items.map(item => ({...item})).sort((a, b) => b.price - a.price);
        _rawItems.forEach((el) => {
            el.selected = false;
            el.marketItem = false;
        });
        dispatch('unselectAll');
        commit('SET_STASH', _rawItems);
    }
};

export const getters = {
    userStashSelectedAll: state => {
        return (state.userStashSelected.length === state.userStash.length) && state.userStash.length > 0;
    },
    pedestalItem: state => {
        return state.userStashSelected[0];
    },
    pedestalItems: state => {
        if (state._pedestalItemsWin.length > 0) return state._pedestalItemsWin; 
        if (state._pedestalItemsFromGame.length > 0) return state._pedestalItemsFromGame; 
        return state.userStashSelected; 
    },
    isPedestalLocked: state => {
        if (state._pedestalItemsWin.length > 0) return false;
        if (state._pedestalItemsFromGame.length > 0) return true;
        return false;
    }
};