<template>
    <div class="input__wrapper">
        <input autocomplete="off" type="text" inputmode="decimal" class="input-field" @blur="$emit('blur', $event)" :class="addClass" :placeholder="placeholder" :value="value" @input="onInput" ref="input">
    </div>
</template>

<script>
export default {
    props: ['placeholder', 'value', 'addClass'],
    methods: {
        onInput(event) {
            let num = event.target.value;
            num = this.regexToNumber(num);
            this.$emit('input', num);
            this.$refs.input.value = num;
        }
    }
}
</script>

