<template>
  <div class="inventory">
      <trade-link />
      <div class="inventory__tabs mt-15">
           <div class="modal__tab flex-align" @click="changeTab('inventory')" :class="{'modal__tab_active': activeTab === 'inventory'}">
                <span class="icon-rifle modal__tab-icon"></span> <span>{{ $t('profile.inventory.name') }}</span>
           </div>
           <div  class="modal__tab flex-align" @click="changeTab('withdraws')" :class="{'modal__tab_active': activeTab === 'withdraws', 'modal__tab_has-new': hasNewCompletedWithdraws}">
                <span class="icon-checked modal__tab-icon"></span> <span>{{ $t('profile.inventory.withdraws') }}</span>
           </div>
       </div>
       <div v-if="activeTab === 'inventory'" class="">
            <div class="inventory__body" v-if="!inventoryIsLoading">
                <!-- <inventory-skin v-for="withdraw in pendingWithdraws" :key="withdraw.id" :item="withdraw.item" type='pendingWithdraw' :withdraw="withdraw" class='pending-withdraw__skin'/>
                <inventory-skin @sell="sell" @withdraw="withdraw" v-for="item in userStash" :key="(item.marketItem ? -item.id : item.pivot_id)" :item="item"/> -->
                <inventory-skin v-for="element in inventory" 
                                :key="element.item ? element.id : element.pivot_id" 
                                :type="element.item ? 'pendingWithdraw' : 'skin'" 
                                :withdraw="element.item ? element : null" 
                                :class="element.item ? 'pending-withdraw__skin' : ''" 
                                @sell="sell" 
                                @withdraw="withdraw" 
                                :item="element.item ? element.item : element"
                                />
                
            </div>
            
            <loader class="mt-30 inventory__body-loading" v-else></loader>

            <div class="profile__inventory_empty" v-if="inventory.length == 0 && !inventoryIsLoading">
                <div class="market-empty__pic">
                    <span class="icon-lifebuoy"></span>
                </div>
                <div class="market-empty__text">
                    {{ $t('itempicker.empty') }}
                </div>
            </div>
       </div>

        <div v-else>
            <div class="inventory__body" v-if="!inventoryIsLoading">
                <withdrawn-skin v-for="withdraw in completedWithdraws" 
                                :key="withdraw.id" 
                                :withdraw="withdraw" 
                                :item="withdraw.item"
                                />
                
            </div>
            
            <loader class="mt-30 inventory__body-loading" v-else></loader>

            <div class="profile__inventory_empty" v-if="completedWithdraws.length == 0 && !inventoryIsLoading">
                <div class="market-empty__pic">
                    <span class="icon-lifebuoy"></span>
                </div>
                <div class="market-empty__text">
                    {{ $t('profile.inventory.no_withdraws') }}
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import TradeLink from './TradeLink.vue';
import InventorySkin from '@/misc/InventorySkin.vue';
import WithdrawnSkin from '@/misc/WithdrawnSkin.vue';
import Loader from '@/misc/Loader.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    data() {
        return {
            activeTab: 'inventory'
        }
    },
    computed: {
        ...mapState('stash', {
            userStash: 'userStash'
        }),
        ...mapState('profile', ['pendingWithdraws', 'inventoryIsLoading', 'completedWithdraws', 'hasNewCompletedWithdraws']),
        ...mapGetters('profile', ['inventory']),
    },
    created() {
        this.$store.dispatch('profile/loadInventory');
    },
    methods: {
        sell(item) {
            this.$store.dispatch('profile/sellItem', item);
        },
        withdraw(item) {
            this.$store.dispatch('profile/withdrawItem', item);
        },
        changeTab(tab) {
            if (tab != this.activeTab) {
                this.activeTab = tab;
            } 
            if (this.activeTab === 'withdraws') {
                this.$store.commit('profile/SET', {key: 'hasNewCompletedWithdraws', value: false}, {root: true});
            }
        }
    },
    components: {
        TradeLink,
        InventorySkin,
        Loader,
        WithdrawnSkin
    }
}
</script>

<style>

</style>