<template>
    <button class="logo" @click="goto('index')">
        <!-- <span class="hide-lg">CS.RIP</span>
        <span class="show-lg">RIP</span> -->
        <img src="/static/images/logo.svg" alt="" class="logo__img hide-lg" />
        <img src="/static/images/logo_mob.svg" alt="" class="logo__img logo__img_mobile show-lg" />
    </button>
</template>

<script>
export default {
    methods: {
        goto(name) {
            this.$store.commit('chat/CLOSE_MOBILE_CHAT');
            this.$store.commit('market/SET_MARKET_OPENED_STATE', false, {root: true});
            this.$router.push({name: name}).catch(() => {});
        }
    }
}
</script>