<template>
    <div class="promocode">
        <input autocomplete="off" v-model="code" @keyup.enter.exact="activate" type="text" :placeholder="$t('bonus.promo_placeholder')" class="input-field input-field_login promocode__input">
        <button @click.stop="activate" class="promocode__activate" :disabled="loading || !promocode.result">
            <div v-if="!loading">{{ $t('bonus.activate') }}</div> 
            <div v-else class="loader"></div>
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState('bonus', {loading: 'promocodeActivating', promocode: 'promocode'}),
        code: {
            get() {
                return this.$store.state.bonus.code;
            },
            set(value) {
                this.$store.commit('bonus/SET_CODE', value);
            }
        }
    },
    methods: {
        activate() {
            if (this.code && !this.loading && this.promocode.result)
                this.$store.dispatch('bonus/activatePromo');
        }
    }
}
</script>

<style>

</style>