<template>
    <div class="account__add-auth">
        <div class="heading mb-20">{{ $t('profile.account.update_password') }}</div>
        <div>
            <label class="input-field__label">{{ $t('profile.account.label_login') }}</label>
            <input type="text" :value="login" class="input-field input-field_login full-width" disabled>
        </div>
        <div class="flex-align mt-20 flex-column-md">
            <field class="full-width mr-15 account_first-col" v-model="password" :error="password_error" type="password">{{ $t('profile.account.label_new_password') }}</field>
            <field class="full-width" v-model="password_confirmation" :error="password_confirmation_error" type="password_confirmation">{{ $t('profile.account.label_password_new_confirmation') }}</field>
        </div>
        <div class="flex-align mt-20 flex-column-md">
            <field class="full-width mr-15 account_first-col" v-model="current_password" :error="current_password_error" type="current_password">{{ $t('profile.account.label_current_password') }}</field>
            <div class="full-width account__save-changes-block">
                <label for="" class="input-field__label">
                    {{ $t('util.apply_changes') }}
                </label>
                <util-button @click="submit" variant="blue" :disabled="loading">
                    <div v-if="!loading">
                        {{ $t('util.save') }}
                    </div>
                    <div class="loader" v-else></div>
                </util-button>
            </div>
            
        </div>
        
    </div>
</template>

<script>

import { validateLogin, validatePassword, validatePasswordConfirmation, processValidationError, processAPIError } from '@/util';
import Field from './Field.vue';
import UtilButton from '@/misc/Button.vue';
import * as API from '@/services/API';
import AuthService from '@/services/authService';
import Vue from 'vue';

export default {
    components: { Field, UtilButton },

    data() {
        return {
            loading: false,

            password: '',
            password_confirmation: '',
            current_password: '',

            password_error: '',
            password_confirmation_error: '',
            current_password_error: '',
        }
    },

    created() {
        
    },

    computed: {
        login() {
            return this.$store.getters['auth/authUser'].login;
        }
    },

    methods: {
        validateCurrentPassword() {
            if (!this.current_password.trim()) {
                return this.$t('validation.password_not_empty');
            }
            return '';
        },
        validatePassword() {
            return validatePassword(this.password);
        },
        validatePasswordConfirmation() {
            return validatePasswordConfirmation(this.password_confirmation, this.password);
        },
        validate() {
            this.current_password_error = this.validateCurrentPassword();
            this.password_error = this.validatePassword();
            this.password_confirmation_error = this.validatePasswordConfirmation();
            return !this.current_password_error && !this.password_error && !this.password_confirmation_error;
        },
        submit() {
            if (this.validate()) {
                const payload = {
                    current_password: this.current_password,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                };
                this.loading = true;
                AuthService.updatePassword(payload)
                .then(response => {
                    this.$store.dispatch('notification/show', {type: 'success', message: this.$t('profile.account.success')}, { root: true });
                    this.current_password = '';
                    this.password = '';
                    this.password_confirmation = '';

                    Vue.nextTick(() => {
                        this.current_password_error = '';
                        this.password_error = '';
                        this.password_confirmation_error = '';
                    })
                    
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    this.$store.dispatch('notification/showError', errorData, {root: true});
                })
                .finally(() => {
                    this.loading = false;
                });
            }
            
        }
    },

    watch: {
        current_password() {
            this.current_password_error = this.validateCurrentPassword();
        },

        password() {
            this.password_error = this.validatePassword();
            this.password_confirmation_error = this.validatePasswordConfirmation();

        },
        password_confirmation() {
            this.password_confirmation_error = this.validatePasswordConfirmation();
        }
    }
}
</script>

<style>

</style>