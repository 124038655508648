<template>
    <div class="flex-stretch">
        <div class="purse__body purse__body_dark full-width">
            <div class="purse__currency">$</div>
            <div class="purse__balance">{{ referral_balance.convertToDollars() }}</div>
        </div>
        <button @click="withdraw" class="promocode__activate promocode__activate_partnership promocode__activate_accent" style="height: 45px" :disabled="loading || referral_balance < min_withdraw">
            <div v-if="!loading">{{ $t('profile.partnership.widthdraw') }}</div> 
            <div class="loader" v-else></div>
        </button>
    </div>
</template>

<script>
import * as API from '@/services/API';
import { processAPIError, processValidationError } from "@/util";

export default {
    data() {
        return {
            loading: false,
            referral_balance: this.balance
        }
    },
    props: ['balance', 'min_withdraw'],
    methods: {
        withdraw() {
            this.loading = true;
            API.apiClient.post('profile/partnership')
            .then(response => {
                if (response.data.success) {
                    this.$store.dispatch('notification/show', {type: 'success', message: this.$t('profile.partnership.withdrawed')}, { root: true });
                    this.$store.dispatch('auth/updateBalance', response.data.data.balance, { root: true });
                    this.referral_balance = 0;
                }
                else {
                    const errorData = processAPIError(response);
                    this.$store.dispatch('notification/showError', errorData);
                }
            })
            .catch(error => {
                const errorData = processValidationError(error);
                this.$store.dispatch('notification/showError', errorData);
            })
            .finally(() => {
                this.loading = false;
            });

        }
    }
}
</script>

<style>

</style>