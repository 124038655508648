<template>
    <div class="skin__wrapper inventory__skin" :class="computeClass">
        
        <div class="skin__overlay" v-if="type === 'pendingWithdraw'">
            <div class="skin__overlay-content" v-if="withdraw.status === 0">
                {{ $t('withdraws.requesting') }}
            </div>
            <div class="skin__overlay-content" v-if="withdraw.status === 1">
                <div class="skin__timer">
                    {{ minutes }}:{{ seconds }}
                </div>
                <div class="">
                    {{ $t('withdraws.waiting') }}
                </div>
            </div>
            <div class="skin__receive" v-if="withdraw.status === 2">
                <div class="skin__timer">
                    {{ minutes }}:{{ seconds }}
                </div>
                <a class="default-btn skin__receive-btn" target="_blank" rel="noopener" :href="tradeOfferLink">{{ $t('withdraws.receive') }}</a>
            </div>
        </div>
        <div class="skin">
            <div class="skin__image-wrapper">
            <img :src="pathToItemImage(item.id)" class="skin__image" alt="">
            <div class="skin__actions" v-if="!item.is_bonus && type === 'skin'">
                <button class="skin__action skin__action_take" @click="$emit('withdraw', item)" :disabled="itemIsWithdrawing">
                    <div v-if="!itemIsWithdrawing">{{ $t('withdraws.take') }}</div> 
                    <div v-else class="loader loader_mini"></div>
                </button>
                <button class="skin__action skin__action_sell" @click="$emit('sell', item)" :disabled="itemIsSelling">
                    <div v-if="!itemIsSelling">{{ $t('withdraws.sell') }}</div> 
                    <div v-else class="loader loader_mini"></div>
                </button>
            </div>
            </div>
            <div class="skin__info">
                <div class="skin__weapon">{{ item.item_name }}</div>
                <div class="skin__name">{{ item.skin_name }}</div>
                <div class="skin__condition">{{ exterior }}</div>
            </div>
            
            <div class="skin__description">
                <div class="skin__additional-info">
                    <div class="skin__stattrack" v-if="item.stattrak">
                        ST&trade;
                    </div>
                </div>
                <div class="skin-price">
                    <span class="skin-price__currency">$</span>
                    <span class="skin-price__value">{{ item.price.convertToDollars() }}</span>
                    <span class="skin-price__value" v-if="!item.marketItem && item.is_bonus">
                        <span class="skin__bonus">
                            <span class="icon-bonus"></span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import config from '@/_config';

export default {
    data() {
        return {
            selected: false,
            hours: '00',
            minutes: '00',
            seconds: '00',
            interval: null
        }
    },
    props: {
        'item': {
            type: Object
        },
        'itemClass': {
            type: String
        },
        'type': {
            default: 'skin'
        },
        'withdraw': {
            default: null
        }
    },
    methods: {
        startTimer(expiration_ts) {
            if (this.interval) clearInterval(this.interval);
            let expiration = new Date(expiration_ts * 1000),
                diff,
                hours,
                minutes,
                seconds;

            if (expiration >= Date.now()) {
                const timer = () => {
                    diff = (expiration - Date.now()) / 1000;
                    hours   = (diff / 3600) | 0;
                    minutes = ((diff - hours * 3600) / 60) | 0;
                    seconds = (diff % 60) | 0;

                    if (hours < 0) hours = 0;
                    if (minutes < 0) minutes = 0;
                    if (seconds < 0) seconds = 0;
                    
                    hours   = hours < 10 ? "0" + hours : hours;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;
                    
                    this.hours = hours;
                    this.minutes = minutes;
                    this.seconds = seconds;

                    if (diff <= 0) {
                        if (this.interval) clearInterval(this.interval);
                    }
                };
                timer();
                this.interval = setInterval(timer, 1000);
            }

        }
    },
    computed: {
        ...mapState('profile', ['itemIsSelling', 'itemIsWithdrawing']),
        exterior() {
            return this.exteriorName(this.item.exterior)
        },
        status() {
            if (this.withdraw) return this.withdraw.status;
            return -1;
        },
        tradeOfferLink() {
            if (this.withdraw && this.withdraw.trade_id) {
                return `https://steamcommunity.com/tradeoffer/${this.withdraw.trade_id}`
            }
        },
        computeClass() {
            let _cl = 'skin__wrapper_' + this.rarityClass(this.item.rarity);
            if (this.itemClass) _cl += ' ' + this.itemClass;
            return _cl; 
        }
    },
    watch: {
        status: {
            immediate: true,
            handler:  function(newVal) {
                if (this.type === 'pendingWithdraw' && newVal === 1) {
                    this.startTimer(this.withdraw.expiration);
                }
                if (this.type === 'pendingWithdraw' && newVal === 2) {
                    this.startTimer(this.withdraw.receiving);
                }
            },
        }
    }
}
</script>
