<template>
  <div class="container">
    <overlay-scrollbars
      style="max-height: 100%; width: 100%"
      :options="termsScrollbarOptions"
    >
      <div class="terms">
        <div class="terms__body">
            <div class="text__heading mb-30">{{ $t("nav.privacy") }}</div>
            <div class="" v-html="$t('privacy.content', {url: url, name: name, update: '2021-08-20'})"></div>
          
        </div>
      </div>
    </overlay-scrollbars>
  </div>
</template>

<script>
import config from "@/_config";
        

export default {
  data() {
    return {
      termsScrollbarOptions: {
        className: "os-theme-medium",
      },
    };
  },
  computed: {
    url() {
      return config.app.url;
    },
    name() {
      return config.app.name;
    },
  },
};
</script>

<style>
</style>