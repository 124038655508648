<template>
   <img :src="src" alt="" :style="{width: size + 'px', height: size + 'px'}" class="avatar">
</template>

<script>
export default {
    props: [
        'src', 'size'
    ]
}
</script>
