<template>
  <modal :show="showModal" @close="closeModal" maxWidth="400px" closeClass="modal__close_with-header"> 
       <div class="modal__header">
           {{ $t('profile.account.link_telegram') }}
       </div>
       <div class="modal__body">
           <div class="text__text">
            <i18n path="profile.account.telegram_instruction">
                <template #bot>
                    <a :href="tgBotLink" class="default-link" target="_blank" rel="noopener">{{ tgBotName }}</a>
                </template>
            </i18n>
           </div>
           <copy-field class="mt-10" :value="tgCommand" />           
       </div>

        <div class="mt-5 mb-20">
            <a :href="tgBotLink" target="_blank" rel="noopener" class="button-like-link">
                {{ $t('profile.account.open_telegram') }}
            </a>
        </div>
        
       
    </modal>
</template>

<script>
import Modal from '@/misc/Modal';
import UtilButton from '@/misc/Button';
import CopyField from '@/misc/CopyField.vue';
export default {
    components: {
        Modal,
        UtilButton,
        CopyField
    },
    computed: {
        showModal() {
            return this.$store.state.profile.linkTelegramModalOpened;
        },
        tgCommand() {

            return '/link ' +  this.$store.getters['auth/userId'];
        },
        tgBotName() {
            return '@' + this.$store.state.settings.other_links.telegram_bot.link;
        },
        tgBotLink() {
            return 'https://t.me/' + this.$store.state.settings.other_links.telegram_bot.link;
        }
    },
    methods: {
        closeModal() {
            this.$store.commit('profile/SET', {key: 'linkTelegramModalOpened', value: false});
        }
    }
}
</script>

<style>

</style>