import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';

export const namespaced = true;

export const state = {
    chatLang: localStorage.getItem('chatLang') || 'RU',
    chatOpened: localStorage.getItem('chatOpened') === 'true',
    chatOpenedMobile: false,
    chat: {
        RU: [],
        ENG: []
    },
    newMessages: 0,
    loading: false,
    sending: false,
    loaded: false,
    message: '',
    taggedUsers: [],
    rulesOpened: false,
};

export const mutations = {
    SET_CHAT_LANG(state, lang) {
        state.chatLang = lang;
        localStorage.setItem('chatLang', lang);
    },

    TOGGLE_CHAT(state) {
        state.chatOpened = !state.chatOpened;
        state.chatOpenedMobile = !state.chatOpenedMobile;
        localStorage.setItem('chatOpened', state.chatOpened);
    },

    CLOSE_CHAT(state) {
        state.chatOpened = false;
        state.chatOpenedMobile = false;
    },

    CLOSE_MOBILE_CHAT(state) {
        state.chatOpenedMobile = false;
    },

    SET_LOADING(state, value) {
        state.loading = value;
    },

    SET_LOADED(state, value) {
        state.loaded = value;
    },

    SET_SENDING(state, value) {
        state.sending = value;
    },

    SET_CHAT(state, { channel, value }) {
        Vue.set(state.chat, channel, value);
    },

    ADD_NEW_MESSAGE_TO_CHAT(state, { channel, value }) {
        state.chat[channel].push(value);
    },

    DELETE_OLD_MESSAGES_FROM_CHAT(state, channel) {
        state.chat[channel].splice(0, Math.max(0, state.chat[channel].length - 51));
    },
    DELETE_MESSAGE(state, { channel, id }) {
        const idx = state.chat[channel].findIndex((msg) => msg.id === id);
        if (idx > -1)
            state.chat[channel].splice(idx, 1);
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
    TAG_USER(state, user) {
        state.taggedUsers.push(user);
    },
    CLEAR_TAGGED(state) {
        state.taggedUsers = [];
    },
    SET_RULES_OPENED(state, value) {
        state.rulesOpened = value;
    },
    SET_NEW_MESSAGES(state, value) {
        state.newMessages = value;
    }
};

export const actions = {
    setChatLang({ commit }, lang) {
        commit("SET_CHAT_LANG", lang);
    },


    load({ commit, dispatch }) {
        commit('SET_LOADING', true);
        API.apiClient
            .get('chat/get')
            .then(response => {
                for (const channel in response.data.data) {
                    const value = response.data.data[channel];
                    dispatch('setChat', {channel, value});
                }
            })
            .finally(() => {
                commit('SET_LOADING', false);
                commit('SET_LOADED', true);
            });
    },
    tag({state, commit, rootGetters}, message) {
        const hasInTagged = state.taggedUsers.some(user => user.id === message.user_id);
        const hasInMessage =  state.message.includes(`@${message.username}`);
        if ((hasInTagged && hasInMessage) || message.user_id == rootGetters['auth/userId']) return;

        if (!hasInTagged) {
            commit('TAG_USER', {
                'id': message.user_id, 
                'username': message.username
            });
        }
        
        let msg = state.message;
        const whitespaceChars = [' ', '\t', '\n'];
        if (msg.length > 0 && !whitespaceChars.includes(msg.slice(-1))) msg += ' ';
        msg += `@${message.username} `;

        commit('SET_MESSAGE', msg);
    },
    send({state, commit, dispatch}, text) {
        if (!state.sending) {
            API.apiClient
                .post('chat/send', {
                    text: text,
                    channel: state.chatLang,
                    tagged: state.taggedUsers.filter(user => text.includes(user.username)).map(user => user.id).slice(0, 5)
                })
                .then(response => {
                    if (!response.data.success) {
                        const errorData = processAPIError(response);
                        dispatch('notification/showError', errorData, {root: true});
                    }
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    dispatch('notification/showError', errorData, {root: true});
                })
                .finally(() => {
                    commit('SET_SENDING', false);
                    commit('CLEAR_TAGGED');
                });
            }
        commit('SET_SENDING', true);
        
    },

    delete({ commit, dispatch }, message_id) {
        API.apiClient
                .post('chat/delete', {
                    id: message_id,
                    channel: state.chatLang
                })
                .then(response => {
                    if (!response.data.success) {
                        const errorData = processAPIError(response);
                        dispatch('notification/showError', errorData, {root: true});
                    }
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    dispatch('notification/showError', errorData, {root: true});
                })
    },

    mute({ commit, dispatch }, message_id) {
        API.apiClient
                .post('chat/mute', {
                    id: message_id,
                    channel: state.chatLang
                })
                .then(response => {
                    if (!response.data.success) {
                        const errorData = processAPIError(response);
                        dispatch('notification/showError', errorData, {root: true});
                    }
                    else {
                        dispatch('notification/show', {type: 'success', message: i18n.t('chat.muted', { value: beautifyTimestamp(response.data.data.timestamp) } )}, { root: true });
                    }
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    dispatch('notification/showError', errorData, {root: true});
                })
    },

    unmute({ commit, dispatch }, message_id) {
        API.apiClient
                .post('chat/unmute', {
                    id: message_id,
                    channel: state.chatLang
                })
                .then(response => {
                    if (!response.data.success) {
                        const errorData = processAPIError(response);
                        dispatch('notification/showError', errorData, {root: true});
                    }
                    else {
                        dispatch('notification/show', {type: 'success', message: i18n.t('chat.unmuted')}, { root: true });
                    }
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    dispatch('notification/showError', errorData, {root: true});
                })
    },

    setChat({ commit }, {channel, value}) {
        commit('SET_CHAT', { channel, value });
    },

    addNewMessage({ commit, state }, {channel, value}) {
        commit('ADD_NEW_MESSAGE_TO_CHAT', {channel, value});
        commit('DELETE_OLD_MESSAGES_FROM_CHAT', channel);
        if (channel === state.chatLang)
            commit('SET_NEW_MESSAGES', state.newMessages + 1);
    },

    clearChat({ dispatch, commit }, channel) {
        dispatch('setChat', {channel: channel, value: []});
        commit('SET_NEW_MESSAGES', 0);
    },

    

    socket_chatNew({dispatch}, message) {
        dispatch('addNewMessage', {channel: message.channel, value: message});
    },

    socket_chatClear({ dispatch }, data) {
        dispatch('clearChat', data.channel);
    },

    socket_chatDelete({ commit }, { channel, id }) {
        commit('DELETE_MESSAGE', { channel, id });
    },

    socket_chatMute({ dispatch }, data) {
        dispatch('auth/muteAction', data, {root: true});
    }
};

export const getters = {
    messages: (state) => {
        return state.chat[state.chatLang];
    }
};