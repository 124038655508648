<template>
  <div class="game__footer-stats">
        <div class="game__stat">
            <span class="icon-users game__stat-icon"></span>
            <span class="game__stat-value">{{ playersCount }}</span>
        </div>
        <div class="game__stat">
            <span class="icon-deagle game__stat-icon"></span>
            <span class="game__stat-value">{{ itemsCount }}</span>
        </div>
        <div class="game__stat">
            <span class="icon-bag game__stat-icon"></span>
            <span class="game__stat-value">${{ itemsValue.convertToDollars() }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters('crash', {
            playersCount    : 'playersCount',
            itemsCount      : 'itemsCount',
            itemsValue      : 'itemsValue'
        })
    }
}
</script>
