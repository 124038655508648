<template>
    <modal :show="showModal" @close="closeModal" maxWidth="600px" closeClass="modal__close_with-header"> 
       <div class="modal__header modal__tabs">
           <div @click.stop="$router.push({query: {...$route.query, m: 'bonus'}})" class="modal__tab flex-align" :class="{'modal__tab_active': activeTab === 'promocode'}">
                <span class="icon-bonus modal__tab-icon"></span> <span>{{ $t('bonus.promocode') }}</span>
           </div>
           <div @click.stop="$router.push({query: {...$route.query, m: 'tasks'}})" class="modal__tab flex-align" :class="{'modal__tab_active': activeTab === 'tasks', 'modal__tab_has-new': hasUnclaimedTasks}">
                <span class="icon-tasks modal__tab-icon"></span> <span>{{ $t('bonus.tasks') }}</span>
           </div>
       </div>

       <div v-if="loading" class="modal__loading">
           <loader />
       </div>
       <div v-else>
            <promocode-tab v-if="activeTab === 'promocode'"/>
            <task-tab v-if="activeTab === 'tasks'"/>
       </div>


    </modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '../../misc/Modal';
import PromocodeTab from './PromocodeTab.vue';
import TaskTab from './TaskTab.vue';
import Loader from '@/misc/Loader.vue';


export default {
    components: {
        Modal,
        PromocodeTab,
        Loader,
        TaskTab
    },
    computed: {
        activeModal() {
            return this.$store.state.modal.activeModal;
        },
        showModal() {
            return this.activeModal === 'bonus' ||  this.activeModal === 'tasks';
        },
        
        ...mapState('bonus', {
            activeTab: 'activeTab',
            loading: 'loading'
        }),
        ...mapGetters('bonus', {
            hasUnclaimedTasks: 'hasUnclaimedTasks'
        })
    },

    created() {
        // this.$store.dispatch('bonus/loadPromocodeTab');
    },

    data() {
        return {
           
        }
    },
    methods: {
        closeModal() {

        },
        changeTab(tab) {

        }
    },
    watch: {
        activeModal: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal === 'bonus') this.$store.dispatch('bonus/loadPromocodeTab');
                if (newVal === 'tasks') this.$store.dispatch('bonus/loadTasksTab');
            }
        },

    }
}
</script>