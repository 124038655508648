<template>
    <div class="fairness">
        <div class="input-field__wrapper">
            <label for="" class="input-field__label">{{ $t('fairness.header_game') }}</label>
            <select-input class="select__wrapper_light"  v-model="selected_game" :options="games"/>
        </div>
        <div class="input-field__wrapper mt-15">
            <label for="" class="input-field__label">{{ $t('fairness.header_server_seed') }}</label>
            <text-input v-model="server_seed" addClass="input-field_login"/>
        </div>
        <div class="input-field__wrapper mt-15">
            <label for="" class="input-field__label">{{ $t('fairness.header_client_seed') }}</label>
            <text-input v-model="client_seed" addClass="input-field_login"/>
        </div>

        <div class="fairness__graphical">
            <div class="fairness__graphical_crash" v-if="selected_game == 1">
                <crash-game-graph :updatable="true" :value="result" :state="1" />
                <div class="crash__label" style="top: 50%">
                    x{{ result.toFixed(2) }}
                    </div>
                </div>
        </div>

        <div class="fairness__section mt-15">
            <div class="text__subheading">
                {{ $t('fairness.seeds_to_bytes') }} 
            </div>
            <div class="fairness__stb mt-7">
                <div v-for="(bytes, index) in bytesCollection" :key="index">
                    <div class="fairness__formula">
                        HMAC_SHA512(SHA256(server_seed), client_seed:0)
                    </div>
                    <overlay-scrollbars style="max-width: 100%;" :options="scrollbarOptions">
                        
                        <table class="fairness__table mt-7">
                            <tbody>
                                <tr>
                                    <td v-for="(byte, index2) in bytesCollection[index]" :key="index2" class="text_center" :class="{'inactive': index * 128 + index2 >= importantBytesCount * 2, 'no-padding': index2 == 0}">
                                        <span v-if="index2 % 2 == 1">
                                            {{ bytesCollection[index][index2 - 1] }}{{ bytesCollection[index][index2] }}
                                        </span>
                                    </td>
                                </tr> 
                                <tr>
                                    <td v-for="(byte, index2) in bytesCollection[index]" :key="index2" class="text_bold text_center" :class="{'inactive': index * 128 + index2 >= importantBytesCount * 2}">
                                        <span v-if="index2 % 2 == 1">
                                            {{ parseInt(bytesCollection[index][index2 - 1].concat(bytesCollection[index][index2]), 16) }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </overlay-scrollbars>
                </div>
            </div>
        </div>

        <div class="fairness__section mt-15">
            <div class="text__subheading">
                {{ $t('fairness.bytes_to_numbers') }} 
            </div>
            <div class="fairness__btn mt-7">
                <overlay-scrollbars style="max-width: 100%;" :options="scrollbarOptions">
                    <table class="fairness__table" v-for="(chunk, index) in b2n" :key="index">
                        <tbody>                
                            <tr>                    
                                <td class="text_center fair-accent text-bold fairness__formula text_bold" colspan="4">({{ chunk.numberArray.join(', ') }}) -&gt; [{{ chunk.from }}, ..., {{ chunk.to }}] = {{ chunk.result }}</td>
                            </tr>
                            <tr v-for="(number, index2) in chunk.numberArray" :key="index2">
                                <td class="text_center">{{ index2 ? '+' : '' }}</td>
                                <td>{{ (number / Math.pow(256, index2 + 1)).toFixed(18) }}</td>
                                <td class="text-right inactive">({{ padWithZeros(number) }} / (256 ^ {{ index2 + 1 }}))</td>
                            </tr>
                            <tr>
                                <td class="text_center">=</td>
                                <td>{{ calcNumbersTo01(chunk.numberArray) }}</td>
                                <td class="text-right inactive">(* {{ chunk.multiplier }})</td>
                            </tr>
                            <tr>
                                <td class="text_center">=</td>
                                <td class="text_bold">{{ chunk.result }}.<span class="text_inactive">{{ (calcNumbersTo01(chunk.numberArray) * chunk.multiplier).toFixed(18).toString().split('.')[1] }}</span></td>
                                <td class="text-right inactive"></td>
                            </tr>
                        </tbody>
                    </table>
                </overlay-scrollbars>
            </div>
        </div>

        <div class="fairness__section mt-15">
            <div class="text__subheading">
                {{ $t('fairness.numbers_to_result') }} 
            </div>
            <div class="fairness__ntr mt-7">
                <div class="fairness__formula fairness__formula_white">
                    result = max(1000000 / ({{ raw_result }} + 1) * 0.97, 1) = {{ result.toFixed(2) }}
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
import SelectInput from '@/components/filters/SelectInput.vue'
import TextInput from '../filters/TextInput.vue'
import NumericInput from '../filters/NumericInput.vue'
import jsSHA from 'jssha';
import {debounce} from 'throttle-debounce';
import CrashGameGraph from '@/components/profile/CrashGameGraph.vue';

export default {
    data() {
        return {
            scrollbarOptions: {
                className : 'os-theme-thin-light',
                scrollbars: {
                    autoHide: 'move'
                }
            },
            games: [
                {title: 'Crash', value: 1},
            ],

            bytesCollection: [],
            importantBytesCount: 0,
            b2n: [],
            raw_result: 0,
            result: 0,


        }
    },

    computed: {
        server_seed: {
            get() {
                return this.$route.query.server_seed || '';
            },
            set(val) {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {
                        ...this.$route.query,
                        server_seed: val
                    }
                })
            }
        },
        client_seed: {
            get() {
                return this.$route.query.client_seed || '';
            },
            set(val) {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {
                        ...this.$route.query,
                        client_seed: val
                    }
                })
            }
        },
        selected_game: {
            get() {
                if (this.$route.query.selected_game == undefined) {
                    return 1;
                }
                return parseInt(this.$route.query.selected_game);
            },
            set(val) {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {
                        ...this.$route.query,
                        selected_game: val
                    }
                })
            }
        },
    },
    created() {
        this.calc();
    },
    methods: {
        calc() {
            this.bytesCollection = [];
            this.b2n = [];
            if (this.selected_game === 1) {
                this.calculateCrash();
            }
        },
        calculate: debounce(200,
            function ()  {
                this.calc()
            }
        ),

        calcNumbersTo01(numbers) {
            return numbers.reduce(function(accumulator, currentValue, index3, array) { return accumulator + currentValue / Math.pow(256, index3 + 1); }, 0)
        },
        
        padWithZeros(number) {
            if (number <= 999) {
                return ("00" + number).slice(-3);
            }
            return number;
        },

        createHash(input) {
            const hash = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            hash.update(input);
            return hash.getHash("HEX");
        },

        createHmac(key, message) {
            const hmac = new jsSHA("SHA-512", "TEXT", {
                hmacKey: { value: key, format: "TEXT" },
            });
            hmac.update(message);
            return hmac.getHash("HEX");
        },

        seedsToBytes(serverSeed, clientSeed, cursor) {
            const hmacKey = this.createHash(serverSeed);
            const hmacMessage = clientSeed + ":" + cursor;
            return this.createHmac(hmacKey, hmacMessage);
        },

        calculateCrash() {
            this._generateCrashNumber();
        },

        hexToDec(bytes) {
            let numbers = [];
            let buffer = '';
            for (var i = 0; i < bytes.length; i++) {
                buffer += bytes[i];
                if (i % 2 == 1) {
                    const num = parseInt(buffer, 16);
                    numbers.push(num);
                    buffer = '';
                }
            }
            return numbers;
        },

        eightBytesToNumber(numbers) {
            let value = 0;
            let pw = 256;
            for (let i = 0; i < 8; i++) {
                value += numbers[i] / pw;
                pw *= 256;
            }
            if (value == 1) value -= Number.EPSILON;
            return value;
        },

        _generateCrashNumber() {
            this.bytesCollection.push(this.seedsToBytes(this.server_seed, this.client_seed, 0));
            this.importantBytesCount = 8;
    
            let h2d = this.hexToDec(this.bytesCollection[0]);
            let tmp = [];
            for (let i = 0; i < 8; i++) {
                tmp.push(h2d[i]);
            }
            const _ind = this.eightBytesToNumber(tmp);
            const multiplier = 1000000;
            const result = Math.floor(_ind * multiplier);
            this.b2n.push({
                'numberArray': tmp,
                'from': 0,
                'to': 999999,
                'result': result,
                'multiplier': multiplier
            });
            return this._displayCrashNumbersToOutcome(result);
        },

        _displayCrashNumbersToOutcome(number) {
            this.raw_result = number;
            this.result = Math.max((1000000 / (number + 1) * (1 - 0.03)), 1);
            return this.result;
        }
    },
    components: {SelectInput, TextInput, NumericInput, CrashGameGraph},
    watch: {
        server_seed: {
            immediate: true,
            handler(val) {
                this.calculate()
            }
        },
        client_seed: {
            immediate: true,
            handler(val) {
                this.calculate()
            }
        },
        selected_game: {
            immediate: true,
            handler(val) {
                this.calculate()
            }
        }
    }
}
</script>

        TextInput
<style>

</style>