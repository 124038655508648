<template>
    <div class="selectable-control selectable-control_chat" v-click-outside="hideOptions">
        <button type="button" class="selectable-control__btn" @click="optionsShown = !optionsShown" >
            <span class="selectable-control__selected-option">
                <div class="selectable-control__selected-option-image-wrapper">
                    <img class="selectable-control__selected-option-image" :src="langImage" alt="">
                </div>
                <!-- <span class="icon-down-arrow selectable-control__arrow"></span> -->
                <div class="chat-online">
                    <span class="chat-online__indicator"></span>
                    <span class="chat-online__value">{{ $store.state.settings.online }}</span>
                </div>
            </span>
        </button>

        <div class="selectable-control__options" v-show="optionsShown">
            <button class="selectable-control__option" @click="changeLang('RU')">
                <img src="/static/images/russia.svg" class="selectable-control__option-image" alt="">
                <span class="chat-online__opt-lang">Русский</span>
            </button>
            <button class="selectable-control__option" @click="changeLang('ENG')">
                <img src="/static/images/united-states.svg" class="selectable-control__option-image" alt="">
                <span class="chat-online__opt-lang">English</span>
            </button>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            optionsShown: false
        }
    },
    methods: {
        hideOptions() {
            if (this.optionsShown) this.optionsShown = false;
        },

        changeLang(lang) {
            this.$store.dispatch('chat/setChatLang', lang, {root: true});
            this.hideOptions();
        }
    },
    computed: {
        langImage() {
            if (this.$store.state.chat.chatLang == 'RU') {
                return '/static/images/russia.svg';
            }
            if (this.$store.state.chat.chatLang == 'ENG') {
                return '/static/images/united-states.svg';
            }
        }
    }
    
}
</script>