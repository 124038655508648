<template>
  <div class="aside">
        <div class="aside__content">
            <sidebar-panel />
        </div>
        <mq-layout mq="lg+">
            <market-modal v-if="$store.getters['auth/loggedIn']"/>
        </mq-layout>
    </div>
</template>

<script>
import SidebarPanel from '../../components/inventory/SidebarPanel';
import MarketModal from '../../components/market/MarketModal';
export default {
    components: {
        SidebarPanel,
        MarketModal
    }
}
</script>

