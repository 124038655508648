<template>
    <div class="vertical-bar__wrapper">
        <div class="vertical-bar">
            <div class="vertical-bar__navigation">
                <div class="vertical-bar__link-wrapper">
                    <router-link tag="a" :to="{name: 'index'}" class="link-to-mode" :class="{active: $route.name == 'index'}">
                        <span class="icon-crash mode__icon"></span>
                        <div class="mode__name">{{ $t('crash.name') }}</div>
                    </router-link>
                </div>
                <div class="vertical-bar__link-wrapper disabled-btn">
                    <router-link tag="a" :to="{name: 'mines'}" class="link-to-mode" :class="{active: $route.name == 'mines'}">
                        <span class="icon-mines mode__icon"></span>
                        <div class="mode__name">{{ $t('mines.name') }}</div>
                    </router-link>
                </div>
                <div class="vertical-bar__link-wrapper disabled-btn">
                    <div class="link-to-mode">
                        <span class="icon-wheel mode__icon"></span>
                        <div class="mode__name">{{ $t('wheel.name') }}</div>
                    </div>
                </div>

                <div class="vertical-bar__link-wrapper" style="margin-top: auto;">
                    <a target="_blank" :href="$store.state.settings.social_links.vkontakte.link" class="link-to-mode">
                        <span class="icon-vk mode__icon"></span>
                        
                    </a>
                </div>
                <div class="vertical-bar__link-wrapper">
                    <a target="_blank" :href="$store.state.settings.social_links.telegram.link" class="link-to-mode">
                        <span class="icon-telegram mode__icon"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>
