<template>
    <div class="selectable-control" v-click-outside="hide">
        <button type="button" :class="btnClass" class="selectable-control__btn" @click="shown = !shown" >
            <span class="selectable-control__selected-option">
                <slot name="button"/>
                <span class="icon-down-arrow selectable-control__arrow" :class="{active: shown}"></span>
            </span>
        </button>

            <div class="selectable-control__options" :class="optionsClass" v-show="shown">
        <overlay-scrollbars :options="scrollbarOptions">
                <slot name="options"/>
        </overlay-scrollbars>
            </div>

    </div>
</template>

<script>
export default {
    props: ['btnClass', 'optionsClass'],
    data() {
        return {
            shown: false,
            scrollbarOptions: {
                className : 'os-theme-medium selectable-control-scrollbar'
            },
        }
    },
    methods: {
        hide() {
            if (this.shown) this.shown = false;
        },
    }
}
</script>

<style>

</style>