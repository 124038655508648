export default {
    app: {
        name: 'CS.RIP',
        url: process.env.NODE_ENV === 'development' ? 'https://rip.test:8080' : 'https://cs.rip',
        v: '1.05',
        year: '2021'
    },
    backend: {
        auth_url: process.env.NODE_ENV === 'development' ? 'https://rip.test' : 'https://app.cs.rip',
        api_url: process.env.NODE_ENV === 'development' ? 'https://rip.test/api' : 'https://app.cs.rip/api',
        node_url_main: process.env.NODE_ENV === 'development' ? 'https://rip.test' : 'https://app.cs.rip',
        node_url_crash: process.env.NODE_ENV === 'development' ? 'https://rip.test:8443' : 'https://app.cs.rip:8443',
        wss_url_crash: process.env.NODE_ENV === 'development' ? 'wss://rip.test:8443' : 'wss://app.cs.rip:8443',
    },
    auth: {
        min_password_lenght: 8,
        min_login_lenght: 3,
        max_login_length: 16
    },
    recaptcha: {
        sitekey: process.env.NODE_ENV === 'development' ? '6Lcvz00dAAAAALl1H6zxcg3DLzNcUEGvLPMuLdQY' : '6LdJ-2IdAAAAAKugjFBFG4NmSSTSAECITq6W_7ZH'
    },
    market: {
        max_market_items_per_transaction: 20,
        max_stash_items_per_transaction: 1024
    },
    crash: {
        maxInitialCoeff: 3,
        minCashout: 1.02,
        maxCashout: 970000,
        crashProvider: 'ws',
        crashWebsocketReconnecting: true,
        timer: 10,

        frequency: 150,
        timerFrequency: 200,
        emulatedFrequency: 50,
        emulatedFrequencyTimer: 25,
        emulatedDelayOnCrash: 100,

        pingModulo: 2147483647,
        pingEvery: 3000,
        pingMessageCode: 255,
        pongMessageCode: 256

    },
    chatra: {
        api_key: process.env.NODE_ENV === 'development' ? '3tdxJhmdL79x8nvZB' : '3tdxJhmdL79x8nvZB'
    }
}