<template>
    <div class="phone__input">
        <div class="bet__input-validation bet__input-validation_small" v-if="hasErrors">
            <div class="bet__input-validation-msg">{{ error }}</div>
        </div>
        <div class="smartphone-icon"><span class="icon-smartphone"></span></div>
        <input v-bind:value="value" @input="input" type="text" v-maska="current_mask" placeholder="+7 912 000-00-00" :class="`input-field input-field_login ${hasErrors ? 'input-field_error': ''}`">
    </div>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            prefixes: ["375", "994", "91", "7", "77", "380", "44", "9955", "370", "992", "66", "998", "507", "374", "371", "90", "905", "373", "972", "84", "372", "82", "996", "79", "995", "37", "84"],
            error: ''
        }
    },
    methods: {
        validate() {
            if (/^[0-9]{10,15}$/.test(this.pure_phone_number)) {
                this.error = '';
            } 
            else {
                this.error = this.$t('deposit.wrong_phone_number');
            }
            return this.error == '';
        },
        input(event) {
            this.validate();
            this.$emit('input', event.target.value);
        }
    },
    computed: {
        hasErrors() {
            return this.error.trim() !== '';
        },
        
        pure_phone_number() {
            return this.value.replace(/\D/g,'');
        },
        current_prefix() {
            return this.prefixes.find(p => this.pure_phone_number.startsWith(p)) || '';
        },
        current_mask() {
            if (this.current_prefix.length == 1) return '+# ### ###-##-##';
            if (this.current_prefix.length == 2) return this.current_prefix == "90" ? '+## ### ### ####' : '+## ## ### ## ##';
            if (this.current_prefix.length == 3 || this.current_prefix.length == 4) return '+### ## ###-##-##';
            return '+# ### ###-##-##';
        },
    },
}
</script>

<style>

</style>