<template>
    <header class="header">
        <div class="header__primary">
            <div class="header__brand">
                <logo />
            </div>
            <div class="header__controls header__controls_margined-left hide-lg">

                <sound class="mr-7"/>
                <settings class="mr-7 btn__icon_header btn__icon_big" />
                <language /> 
            </div>
        </div>
        <div class="header__navigation hide-lg">
            <navigation class="hide-xlg" />
            <mobile-navigation class="show-xlg" />
        </div>
        <div class="header__authorized" v-if="isLoggedIn">
            <div class="header__purse">
                <purse />
            </div>
            <div class="user__mini-profile hide-lg">
                <miniprofile :avatar="authUser.picture" :name="authUser.username" size="50" :rank="authUser.level" :rank-progress="authUser.level_progress"/>
            </div>
        </div>
        <div class="header__login" v-else>
            <modal-caller-button variant="gray" class="mr-10 header__login-btn" px="20px" modal="login">{{ $t('auth.login') }}</modal-caller-button>
            <modal-caller-button px="20px" modal="signup" class="header__login-btn">{{ $t('auth.signup') }}</modal-caller-button>
        </div>
        
        <div class="header__user-misc hide-lg">
            <notifications class="mr-10" v-if="isLoggedIn"/>
            <support class="mr-10 hide-lg" />
            <chat-toggle class="hide-lg" />
        </div>
        <div class="show-lg ml-10 flex-align" v-if="isLoggedIn">
            <notifications />
        </div>
    </header>
</template>

<script>
import sound from "@/misc/Sound";
import language from '@/misc/Language';
import logo from './Logo';
import navigation from './Navigation';
import MobileNavigation from './MobileNavigation.vue';
import purse from './Purse';
import miniprofile from './MiniProfile';
import support from '@/misc/Support';
import notifications from './Notifications';
import ChatToggle from '@/components/chat/ChatToggle.vue';
import utilButton from '@/misc/Button';
import { mapGetters } from 'vuex';
import ModalCallerButton from '@/misc/ModalCallerButton.vue';
import Settings from './Settings.vue';

export default {
    components: {
        sound,
        language,
        logo,
        navigation, 
        purse,
        miniprofile,
        support,
        notifications,
        ChatToggle,
        utilButton,
        ModalCallerButton,
        MobileNavigation,
        Settings
    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters["auth/loggedIn"];
        },
        ...mapGetters('auth', ['authUser'])
    },
    mounted() {
    },
    methods: {
        loginShow() {
            this.$router.push('?m=login');
        }
    },
    sockets: {
        online: function (online) {
            this.online = online;
        }
    }
}
</script>
