<template>
    <div class="account__add-auth">
        <div class="heading mb-20">{{ $t('profile.account.add_password_auth') }}</div>
        <div class="flex-align flex-column-md">
            <field class="full-width mr-15 account_first-col" v-model="login" :error="login_error" type="login">{{ $t('profile.account.label_login') }}</field>
            <field class="full-width" v-model="password" :error="password_error" type="password">{{ $t('profile.account.label_password') }}</field>
        </div>
        <div class="flex-align mt-20 flex-column-md">
            <field class="full-width mr-15 account_first-col" v-model="password_confirmation" :error="password_confirmation_error" type="password_confirmation">{{ $t('profile.account.label_password_confirmation') }}</field>
            <div class="full-width account__save-changes-block">
                <label for="" class="input-field__label">
                    {{ $t('util.apply_changes') }}
                </label>
                <util-button  @click="submit" variant="blue" :disabled="loading">
                    <div v-if="!loading">
                        {{ $t('util.save') }}
                    </div>
                    <div class="loader" v-else></div>
                </util-button>
            </div>
            
        </div>
        
    </div>
</template>

<script>

import { validateLogin, validatePassword, validatePasswordConfirmation, processValidationError, processAPIError } from '@/util';
import Field from './Field.vue';
import UtilButton from '@/misc/Button.vue';
import * as API from '@/services/API';

export default {
    components: { Field, UtilButton },

    data() {
        return {
            loading: false,
            login: '',
            password: '',
            password_confirmation: '',
            login_error: '',
            password_error: '',
            password_confirmation_error: ''
        }
    },

    created() {
        
    },

    methods: {
        validateLogin() {
            return validateLogin(this.login);
        },
        validatePassword() {
            return validatePassword(this.password);
        },
        validatePasswordConfirmation() {
            return validatePasswordConfirmation(this.password_confirmation, this.password);
        },
        validate() {
            this.login_error = this.validateLogin();
            this.password_error = this.validatePassword();
            this.password_confirmation_error = this.validatePasswordConfirmation();
            return !this.login_error && !this.password_error && !this.password_confirmation_error;
        },
        submit() {
            if (this.validate()) {
                const payload = {
                    login: this.login,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                };
                this.loading = true;
                API.apiClient.post('user/addLoginAuthorization', payload)
                .then(response => {
                    if (response.data.success) {
                        this.$store.dispatch('notification/show', {type: 'success', message: this.$t('profile.account.success')}, { root: true });
                        this.$store.dispatch('auth/changeLogin', response.data.data.login, {root: true});
                    }
                    else {
                        const errorData = processAPIError(response);
                        this.$store.dispatch('notification/showError', errorData, {root: true});
                    }
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    this.$store.dispatch('notification/showError', errorData, {root: true});
                })
                .finally(() => {
                    this.loading = false;
                });
            }
            
        }
    },

    watch: {
        login() {
            this.login_error = this.validateLogin();
        },

        password() {
            this.password_error = this.validatePassword();
            this.password_confirmation_error = this.validatePasswordConfirmation();

        },
        password_confirmation() {
            this.password_confirmation_error = this.validatePasswordConfirmation();
        }
    }
}
</script>

<style>

</style>