<template>
  <modal :show="showModal" @close="closeModal" maxWidth="400px" closeClass="modal__close_with-header"> 
       <div class="modal__header">
           {{ $t('auth.reset_password') }}
       </div>
       <form @submit.prevent="gValidate">
        <div class="modal__body">
            <div style="position: relative" class="full-width">
                    <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="error">
                            <div class="bet__input-validation-msg">{{ error }}</div>
                    </div>
        
                    <input autocomplete="off" v-model="email" type="text" :placeholder="$t('profile.account.email')" class="input-field email-input input-field_login" :class="`${error ? 'input-field_error' : ''}`">
                </div>
                <div class="login__field bet__input-wrapper">
                    <vue-recaptcha
                        ref="recaptcha"
                        size="invisible"
                        :sitekey="sitekey"
                        @verify="reset"
                        @expired="onCaptchaExpired"
                    />
                </div>
            <div class="modal__button">
                    <util-button variant="blue" class="mt-20" width="150px" px="20px" type="submit" :disabled="loading">
                        <div v-if="!loading">
                            {{ $t('auth.reset_button') }}
                        </div>
                        <div class="loader" v-else></div>
                    </util-button>
            </div>
            
        </div>
       </form>
    </modal>
</template>

<script>

import Modal from '../../misc/Modal';
import UtilButton from '@/misc/Button';
import { validateEmail, processValidationError, processAPIError } from '@/util';
import AuthService from '@/services/authService';
import VueRecaptcha from 'vue-recaptcha';
import config from '@/_config';

export default {
    data() {
        return {
            email: '',
            error: '',
            loading: false
        }
    },
    components: {
        Modal,
        UtilButton,
        VueRecaptcha
    },
    computed: {
        showModal() {
            return this.$store.state.modal.activeModal == 'forgot';
        },
        sitekey() {
            return config.recaptcha.sitekey;
        }
    },
    methods: {
        closeModal() {

        },
        validate() {
            return !validateEmail(this.email);
        },
        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        },
        gValidate() {
            if (this.validate()) {
                this.loading = true;
                this.$refs.recaptcha.execute();
            }
        },
        reset(recaptchaToken) {
            // if (this.validate()) {
                this.loading = true;
                const payload = {
                    email: this.email,
                    locale: navigator.language || navigator.userLanguage,
                    captcha: recaptchaToken
                }
                AuthService.forgotPassword(payload)
                .then(response => {
                    this.$store.dispatch('notification/show', {type: 'success', message: this.$t('auth.reset_link_sent')}, { root: true });      
                    this._closeModal();
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    this.$store.dispatch('notification/showError', errorData, {root: true});
                })
                .finally(() => {
                    this.loading = false;
                    this.onCaptchaExpired();
                });
            // }

        },
        _closeModal() {
            if (this.$router.currentRoute.query.m) {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {
                        ...this.$route.query,
                        m: undefined,
                    }
                });
            }
        }
    },
    watch: {
        email(val) {
            this.error = validateEmail(val);
        },
    }
}
</script>

<style>

</style>