import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';
import { DateTime } from 'luxon';

export const namespaced = true;

const getDefaultState = () => {
    return {
        editModalOpened: false,
        usernameUpdating: false,
        logOutModalOpened: false,
        linkTelegramModalOpened: false,
        itemIsSelling: false,
        itemIsWithdrawing: false,
        pendingWithdraws: [],
        completedWithdraws: [],
        hasNewCompletedWithdraws: false,
        inventoryIsLoading: false,
        historyIsLoading: false,
        gameDetailsLoading: false
    }
}



export const state = getDefaultState

export const mutations = {
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET(state, {key, value}) {
        state[key] = value;
    },
    UPDATE_WITHDRAW(state, {id, key, value}) {
        const idx = state.pendingWithdraws.findIndex(w => w.id === id);
        if (idx > -1) {
            state.pendingWithdraws[idx][key] = value;
        }
    },
    REMOVE_WITHDRAW(state, {id}) {
        const idx = state.pendingWithdraws.findIndex(w => w.id === id);
        if (idx > -1)
            state.pendingWithdraws.splice(idx, 1);
    }
};

export const actions = {
    loadInventory({commit, dispatch}) {
        commit('SET', {key: 'inventoryIsLoading', value: true});
        API.apiClient.get('profile/inventory')
        .then(response => {
            if (response.data.success) {
                dispatch('stash/setStash', response.data.data.items, { root: true });
                dispatch('setPendingWithdraws', response.data.data.pending_withdraws);
                dispatch('setCompletedWithdraws', response.data.data.completed_withdraws);
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'inventoryIsLoading', value: false});
        });
    },

    loadHistory({commit, dispatch}) {
        commit('SET', {key: 'historyIsLoading', value: true});
        API.apiClient.get('profile/history/crash')
        .then(response => {
            if (response.data.success) {
                // dispatch('setHistory', response.data.data.pending_withdraws);
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'historyIsLoading', value: false});
        });
    },  
    changeUsername({commit, dispatch}, username) {
        commit('SET', {key: 'usernameUpdating', value: true});
        API.apiClient.post('user/update', {
            username: username
        })
        .then(response => {
            if (response.data.success) {
                dispatch('notification/show', {type: 'success', message: i18n.t('profile.edit.success')}, { root: true });
                dispatch('auth/changeUsername', response.data.data.username, { root: true });
                commit('SET', {key: 'editModalOpened', value: false})
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'usernameUpdating', value: false});
        });
    },
    sellItem({commit, dispatch}, item) {
        commit('SET', {key: 'itemIsSelling', value: true});
        API.apiClient.post('items/sell', {
            id: item.pivot_id
        })
        .then(response => {
            if (response.data.success) {
                dispatch('notification/show', {type: 'success', message: i18n.t('profile.inventory.successful_sell')}, { root: true });
                dispatch('stash/setStash', response.data.data.items, { root: true });
                dispatch('auth/updateBalance', response.data.data.balance, { root: true });
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'itemIsSelling', value: false});
        });
    },
    withdrawItem({commit, dispatch}, item) {
        commit('SET', {key: 'itemIsWithdrawing', value: true});
        API.apiClient.post('items/withdraw', {
            id: item.pivot_id
        })
        .then(response => {
            if (response.data.success) {
                dispatch('stash/setStash', response.data.data.items, { root: true });
                dispatch('setPendingWithdraws', response.data.data.pending_withdraws);
            }
            else {
                if (response.data.error.includes('too_many_failed')) {
                    let ts = parseInt(response.data.error.split('-')[1]);
                    let dt = DateTime.fromSeconds(ts);
                    response.data.error = response.data.error.replace(ts, beautifyTimestamp(ts))
                }
                if (response.data.error.includes('withdraws.min_deposit')) {
                    let sum = parseInt(response.data.error.split('-')[1]);
                    response.data.error = response.data.error.replace(sum, sum.convertToDollars());
                }
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            console.log(error)
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'itemIsWithdrawing', value: false});
        });
    },
    setPendingWithdraws({ commit, dispatch }, withdraws) {
        let _rawWithdraws = withdraws.map(w => ({...w})).sort((a, b) => b.item.price - a.item.price);
        commit('SET', {key: 'pendingWithdraws', value: _rawWithdraws});
    },
    setCompletedWithdraws({ commit, dispatch }, withdraws) {
        let _rawWithdraws = withdraws.map(w => ({...w})).sort((a, b) => b.item.price - a.item.price);
        commit('SET', {key: 'completedWithdraws', value: _rawWithdraws});
    },
    showGame(id) {
        commit('SET', {key: 'gameDetailsLoading', value: true});
        API.apiClient.post('crash/history', {
            id: id
        })
        .then(response => {
            if (response.data.success) {
                
            }
            
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'gameDetailsLoading', value: false});
        });
    },
    socket_withdraw({ commit, dispatch }, data) {
        if (data.status === 4) {
            dispatch('notification/showError', [data.reason, {}], {root: true});
            dispatch('stash/setStash', data.items, { root: true });
            dispatch('setPendingWithdraws', data.pending_withdraws);
        }

        if (data.status === 1) {
            commit('UPDATE_WITHDRAW', {id: data.id, key: 'status', value: data.status});
            commit('UPDATE_WITHDRAW', {id: data.id, key: 'expiration', value: data.expiration});
        }

        if (data.status === 2) {
            commit('UPDATE_WITHDRAW', {id: data.id, key: 'receiving', value: data.receiving});
            commit('UPDATE_WITHDRAW', {id: data.id, key: 'trade_id', value: data.trade_id});
            commit('UPDATE_WITHDRAW', {id: data.id, key: 'status', value: data.status});
            dispatch('notification/show', {type: 'success', message: i18n.t('withdraws.check_inventory')}, { root: true });
        }

        if (data.status === 3) {
            commit('REMOVE_WITHDRAW', {id: data.id});
            // dispatch('notification/show', {type: 'success', message: i18n.t('withdraws.successful_withdraw')}, { root: true });
            dispatch('setCompletedWithdraws', data.completed_withdraws);
            commit('SET', {key: 'hasNewCompletedWithdraws', value: true});
            
        }
    }
};

export const getters = {
    username(state, getters, rootState, rootGetters) {
        if (rootGetters['auth/loggedIn']) {
            return rootGetters['auth/authUser'];
        }
        return '';
    },
    inventory(state, getters, rootState, rootGetters) {
        let inventory = [];
        rootState.stash.userStash.forEach(item=>inventory.push(item));
        state.pendingWithdraws.forEach(item=>inventory.push(item));
   
        inventory.sort((a, b) => {
            
            if (a.price === b.price) {
                const a_name = a.item ? a.item.market_hash_name : a.market_hash_name;
                const b_name = b.item ? b.item.market_hash_name : b.market_hash_name;
                if (a_name === b_name) 
                    return b.pivot_id - a.pivot_id;
                return a_name.localeCompare(b_name);
            }
            return b.price - a.price;
        });
        return inventory;
    }
};