<template>
    <div class="skin__wrapper inventory__skin" :class="computeClass">
        <div class="skin">
            <div class="skin__image-wrapper">
            <img :src="pathToItemImage(item.id)" class="skin__image" alt="">
            </div>
            <div class="skin__info">
                <div class="skin__weapon">{{ item.item_name }}</div>
                <div class="skin__name">{{ item.skin_name }}</div>
                <div class="skin__condition">{{ exterior }}</div>
            </div>
            
            <div class="skin__description">
                <div class="skin__additional-info">
                    <div class="skin__stattrack" v-if="item.stattrak">
                        ST&trade;
                    </div>
                </div>
                <div class="skin-price">
                    <span class="skin-price__currency">$</span>
                    <span class="skin-price__value">{{ item.price.convertToDollars() }}</span>
                    <span class="skin-price__value">
                        <span class="skin__success">
                            <span class="icon-checked"></span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import config from '@/_config';

export default {
    data() {
        return {

        }
    },
    props: {
        'item': {
            type: Object
        },
        'itemClass': {
            type: String
        },
        'withdraw': {
            default: null
        }
    },

    computed: {
       
        exterior() {
            return this.exteriorName(this.item.exterior)
        },
        status() {
            if (this.withdraw) return this.withdraw.status;
            return -1;
        },
        
        computeClass() {
            let _cl = 'skin__wrapper_' + this.rarityClass(this.item.rarity);
            if (this.itemClass) _cl += ' ' + this.itemClass;
            return _cl; 
        }
    }
}
</script>
