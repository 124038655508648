<template>
  <modal :show="showModal" @close="closeModal" maxWidth="400px" closeClass="modal__close_with-header"> 
       <div class="modal__header modal__tabs">
           <div class="modal__tab modal__tab_active flex-align">
                <span class="icon-setting modal__tab-icon"></span> <span>{{ $t('settings.header') }}</span>
           </div>
       </div>
       <div class="modal__body modal__body_no-padding">
            <div class="striped__bg">
                <div class="striped__bg-stripe">
                    <div class="setting__option">
                        <div class="setting__name">
                            {{ $t('settings.show_bets') }}
                        </div>
                        <div class="setting__value">
                            <div class="setting__toggle">
                                <toggle @change="setSetting('bets_visibility')" :controlState="bets_visibility" />
                            </div>
                        </div>
                    </div>

                    <div class="setting__option">
                        <div class="setting__name">
                            {{ $t('settings.show_avatars') }}
                        </div>
                        <div class="setting__value">
                            <div class="setting__toggle">
                                <toggle @change="setSetting('avatars_visibility')" :controlState="avatars_visibility" />
                            </div>
                        </div>
                    </div>

                    <div class="setting__option">
                        <div class="setting__name">
                            {{ $t('settings.show_usernames') }}
                        </div>
                        <div class="setting__value">
                            <div class="setting__toggle">
                                <toggle @change="setSetting('usernames_visibility')" :controlState="usernames_visibility" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>

    </modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '@/misc/Modal';
import Toggle from '@/misc/Toggle.vue';

export default {
    components: {
        Modal,
        Toggle
    },
 
    computed: {
        showModal() {
            return this.$store.state.settings.settingsModalOpened;
        },
        ...mapGetters('settings', ['bets_visibility', 'avatars_visibility', 'usernames_visibility'])
    },
    methods: {
        closeModal() {
            this.$store.commit('settings/SET_SETTING', {key: 'settingsModalOpened', value: false});
        },
        setSetting(setting) {
            const val = this[setting] ? 'off' : 'on';
            this.$store.dispatch('settings/setAndStore', {key: setting, value: val})
        }
    }
}
</script>

<style>

</style>