<template>
    <div class="items-picker__wrapper">
        <div class="items-picker__panel" v-if="loggedIn">
            <div class="items-picker__balance">
                <div class="items-picker__balance-text">{{ $t('itempicker.balance') }}</div>
                <div class="items-picker__balance-value">$<span class="items-picker__balance-number">{{ balanceInDollars }}</span></div>
            </div>
            <div class="items-picker__balance">
                <div class="items-picker__balance-text">{{ $t('itempicker.selected') }}</div>
                <div class="items-picker__balance-value">$<span class="items-picker__selected-number">{{ userStashSelectedAmount.convertToDollars() }}</span></div>
            </div>
            <div class="items-picker__all-in">
                <toggle @change="setAllInSelection" :controlState="userStashSelectedAll"/>
            </div>
        </div>

        <div class="items-picker__list-wrapper" v-if="loggedIn">
            <item-list :items="userStash" @itemSelected="itemSelected" @itemUnselected="itemUnselected" />

            <div v-if="!loaded" class="market-empty">
                <div class="market-empty__pic">
                    <loader />
                </div>
                <div class="market-empty__text">
                    {{ $t('util.loading') }}
                </div>
            </div>

            <div class="market-empty" v-else-if="userStash.length == 0">
                <div class="market-empty__pic">
                    <span class="icon-lifebuoy"></span>
                </div>
                <div class="market-empty__text">
                    {{ $t('itempicker.empty') }}
                </div>
            </div>
        </div>
        <div class="items-picker__list-wrapper" v-else>
            <div class="market-empty">
                <div class="market-empty__pic">
                    <span class="icon-lifebuoy"></span>
                </div>
                <div class="market-empty__text">
                    <div>{{ $t('itempicker.guest') }}</div>
                    <modal-caller-button modal="login" width="150px" class="mt-15">{{ $t('auth.login') }}</modal-caller-button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import toggle from '../../misc/Toggle.vue'
import ItemList from '../inventory/ItemList.vue'
import ModalCallerButton from '@/misc/ModalCallerButton.vue';

import { mapGetters, mapState } from 'vuex'
import Loader from '../../misc/Loader.vue';

export default {
    components: {
        toggle,
        ItemList,
        ModalCallerButton,
        Loader
    },
    methods: {
        setAllInSelection(c) {
            this.$store.dispatch(c ? 'stash/selectAll' : 'stash/unselectAll');
        },
        itemSelected(item) {
            this.$store.dispatch('stash/addItemToBet', item);
        },
        itemUnselected(item) {
            this.$store.dispatch('stash/removeItemFromBet', item);
        }
    },
    computed: {
        ...mapState('stash', {
            userStash: 'userStash',
            userStashSelectedAmount: 'userStashSelectedAmount',
            loaded: 'loaded',
        }),
        ...mapGetters('stash', {
            userStashSelectedAll: 'userStashSelectedAll'
        }),
        ...mapGetters('auth', ['loggedIn', 'balanceInDollars']),
        ...mapState('auth', ['user'])
    }
}
</script>

