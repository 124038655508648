<template>
    <div class="login__field bet__input-wrapper">
        <label class="input-field__label"><slot></slot></label>
        <div style="position:relative">
            <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="error">
                <div class="bet__input-validation-msg">{{ error }}</div>
            </div>
            <login-input :placeholder="placeholder" :value="value" @input="$emit('input', $event)" :type="inputType" v-model="field" :addClass="`input-field_login ${error ? 'input-field_error' : ''}`" />
        </div>
        
    </div>
</template>

<script>
import LoginInput from '@/components/login/LoginInput.vue';

export default {
    components: {
        LoginInput
    },
    props: ['type', 'error', 'value'],
    data() {
        return {
            field: ''
        }
    },
    computed: {
        label() {
            return this.$t('profile.account.label_' + this.type);
        },
        inputType() {
            return (this.type === 'password' || this.type === 'password_confirmation' || this.type === 'current_password') ? 'password' : 'text';
        },
        placeholder() {
            if (this.type === 'password') return this.$t('auth.register_password_placeholder');
            if (this.type === 'current_password') return this.$t('profile.account.current_password_placeholder');
            if (this.type === 'login') return this.$t('auth.register_login_placeholder');
            if (this.type === 'password_confirmation') return this.$t('auth.register_password_confirm');
        }
    },
    watch: {
        value(val) {
            if (this.field != val) this.field = val;
        }
    }
}
</script>

<style>

</style>