<template>
    <transition name="slide-left-fade">
        <div class="market-modal__wrapper" v-show="opened">
            <div class="market-modal__modal">
                <div class="market-modal__header">
                    <div class="market-modal__title">{{ $t('market.title') }}</div>
                    <div class="market-modal__close">
                        <button class="market-modal__close-btn" @click="$store.dispatch('market/closeMarket')"><span class="icon-cancel"></span></button>
                    </div>
                </div>
                <div class="market-modal__body">
                    <div class="market-modal__summary">
                        <div class="market-modal__summary-item">
                            <div class="market-modal__summary-item-title">{{ $t('market.user_pay') }}</div>
                            <div class="market-modal__summary-item-value market-modal__summary-item_selected">$<span class="market-modal__summary-number market-modal__summary-number_selected">{{ userPay.convertToDollars() }}</span></div>
                        </div>
                        <div class="market-modal__summary-item">
                            <div class="market-modal__summary-item-title">{{ $t('market.order_total') }}</div>
                            <div class="market-modal__summary-item-value market-modal__summary-item_total">$<span class="market-modal__summary-number market-modal__summary-number_total">{{ cartTotal.convertToDollars() }}</span></div>
                        </div>
                        <div class="market-modal__summary-item">
                            <div class="market-modal__summary-item-title">{{ $t('market.residue') }}</div>
                            <div class="market-modal__summary-item-value market-modal__summary-item_residue">$<span class="market-modal__summary-number market-modal__summary-number_residue">{{ residue.convertToDollars() }}</span></div>
                        </div>
                    </div>
                    <div class="market-modal__filters">
                        <text-filter class="mr-7 market-name-filter" v-model="nameFilter" :placeholder="$t('filters.name')" />
                        <numeric-filter class="mr-7 market-price-filter" v-model="priceFilter" :placeholder="$t('filters.price')" />
                        <select-filter max-height="240px" class="mr-7 market-type-filter" v-model="typeFilter"  :options="types"/>
                        <div class="market-more-filters">
                            <button class="market-more-filters__btn" 
                                    :class="{active: allFiltersVisible}" 
                                    @click="allFiltersVisible = !allFiltersVisible">
                                <span class="icon-more"></span>
                            </button>
                        </div>
                        <div class="flex-break"></div>
                        <transition name="slide-fade">
                            <div class="market-more-filters__content" v-show="allFiltersVisible">
                                <select-filter :max-height="'215px'" class="mr-7 market-rarity-filter" v-model="rarityFilter" :options="rarities" />
                                <select-filter :max-height="'215px'" class="mr-7 market-exterior-filter" v-model="exteriorFilter" :options="exteriors" />
                                <div class="market-reset-filters">
                                    <button class="market-reset-filters__btn" @click="resetFilters">
                                        <span class='icon-trash market-reset-filters__icon'></span>
                                    </button>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="market-modal__window-wrapper">
                        <div class="market-modal__window">
                            <item-list :items="displayedMarketItems" @itemSelected="itemSelected" @itemUnselected="itemUnselected" @scrolledToBottom="loadMoreItems" itemClass="w25" ref="itemList"/>
                        </div>
                        <div class="market-empty" v-if="displayedMarketItems.length == 0">
                            <div class="market-empty__pic">
                                <span class="icon-lifebuoy"></span>
                            </div>
                            <div class="market-empty__text">
                                {{ $t('market.empty') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="market-modal__footer">
                    <div class="market-modal__buy">
                        <button @click="purchase" class="market-modal__buy-btn default-btn default-btn_high" :disabled="cartTotal == 0 || loading" :class="{'default-btn_disabled': cartTotal == 0}">
                            <div v-if="!loading">{{ $t('market.buy') }} <span class="market-modal__buy-btn-price">(${{ cartTotal.convertToDollars() }})</span></div> 
                            <div v-else class="loader"></div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TextFilter from '../filters/TextInput.vue';
import NumericFilter from '../filters/NumericInput.vue';
import SelectFilter from '../filters/SelectInput.vue';
import ItemList from '../inventory/ItemList.vue';

export default {
    components: {
        ItemList,
        TextFilter,
        NumericFilter,
        SelectFilter
    },      
    data() {
        return {
            scrollbarOptions: {
                className : 'os-theme-thin-light'
            },
            allFiltersVisible: false,
            timerID: null,
            nameFilter: '',
            priceFilter: '',
            typeFilter: -1,
            rarityFilter: -1,
            exteriorFilter: -1,
            types: [
                {title: this.$t('market.types.all'), value: -1},
                {title: this.$t('market.types.weapon'), value: 2},
                {title: this.$t('market.types.gloves'), value: 9},
                {title: this.$t('market.types.graffiti'), value: 8},
                {title: this.$t('market.types.collectible'), value: 1},
                {title: this.$t('market.types.container'), value: 3},
                {title: this.$t('market.types.pass'), value: 4},
                {title: this.$t('market.types.key'), value: 5},
                {title: this.$t('market.types.gift'), value: 6},
                {title: this.$t('market.types.music_kit'), value: 7},
                {title: this.$t('market.types.other'), value: 0}
            ],
            rarities: [
                {title: this.$t('market.rarities.all'), value: -1},
                {title: this.$t('market.rarities.contraband'), value: 0},
                {title: this.$t('market.rarities.master'), value: 1},
                {title: this.$t('market.rarities.superior'), value: 2},
                {title: this.$t('market.rarities.distinguished'), value: 3},
                {title: this.$t('market.rarities.exotic'), value: 4},
                {title: this.$t('market.rarities.covert'), value: 5},
                {title: this.$t('market.rarities.industrial_grade'), value: 6},
                {title: this.$t('market.rarities.milspec_grade'), value: 7},
                {title: this.$t('market.rarities.restricted'), value: 8},
                {title: this.$t('market.rarities.classified'), value: 9},
                {title: this.$t('market.rarities.extraordinary'), value: 10},
                {title: this.$t('market.rarities.base_grade'), value: 11},
                {title: this.$t('market.rarities.consumer_grade'), value: 12},
                {title: this.$t('market.rarities.high_grade'), value: 13},
                {title: this.$t('market.rarities.remarkable'), value: 14},
                {title: this.$t('market.rarities.exceptional'), value: 15},
            ],
            exteriors: [
                {title: this.$t('market.exteriors.all'), value: -1},
                {title: this.$t('market.exteriors.not_painted'), value: 0},
                {title: this.$t('market.exteriors.battlescarred'), value: 1},
                {title: this.$t('market.exteriors.factory_new'), value: 2},
                {title: this.$t('market.exteriors.fieldtested'), value: 3},
                {title: this.$t('market.exteriors.minimal_wear'), value: 4},
                {title: this.$t('market.exteriors.wellworn'), value: 5},
            ],
        }
    },
    methods: {
        itemSelected(item) {
            this.$store.dispatch('market/addToCart', item);
        }, 
        itemUnselected(item) {
            this.$store.dispatch('market/removeFromCart', item);
        },
        loadMoreItems() {
            this.$store.dispatch('market/loadMoreMarketItems');
        },
        applyFilters() {
            const filters = {
                name: this.nameFilter,
                price: this.priceFilter * 100,
                type: this.typeFilter,
                rarity: this.rarityFilter,
                exterior: this.exteriorFilter
            };
            clearTimeout(this.timerID);
            this.timerID = setTimeout(() => {
                this.$store.dispatch('market/filterItems', filters);
                this.$refs.itemList.scrollTop();
            }, 250);
        },
        resetFilters() {
            this.nameFilter = this.priceFilter = '';
            this.typeFilter = this.rarityFilter = this.exteriorFilter = -1;
            this.applyFilters();
        },
        purchase() {
            this.$store.dispatch('market/purchase');
        }
    },
    computed: {
        ...mapGetters('auth', {user: 'authUser'}),
        ...mapGetters('market', { displayedMarketItems: 'displayedMarketItems', residue: 'residue' }),
        // ...mapGetters({ residue: 'residue' }),

        ...mapState('market', { cartTotal: 'cartTotal', loading: 'purchaseInProgress' }),
        ...mapState('stash', { userStashSelectedAmount: 'userStashSelectedAmount' }),
        
        userPay() {
            return this.userStashSelectedAmount + this.user.balance;
        },

        opened() {
            return this.$store.state.market.marketOpened;
        },

    },
    watch: {
        nameFilter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.applyFilters();
            }
        },
        priceFilter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.applyFilters();
            }
        },
        typeFilter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.applyFilters();
            }
        },
        rarityFilter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.applyFilters();
            }
        },
        exteriorFilter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.applyFilters();
            }
        },
        opened(newVal) {
            if (newVal)
                this.applyFilters();
        },
        userPay() {
            if (this.opened)
                this.applyFilters();
        }
    }
}
</script>

