<template>
    <div class="deposit__history">
       <div class="history__body deposit__history-body" v-if="!loading && paginatedDeposits.length > 0">
           <div class="deposit__instance" v-for="deposit in paginatedDeposits" :key="deposit.uuid" >

               <accordeon class="deposit__instance-accordeon dark-panel dark-panel_no-padding dark-panel_less-shadow" ref="accordeon">
                    <template v-slot:header>
                        <div class="flex-align">
                            <img width="25" :src="`/static/images/${deposit.deposit_method.icon}.png`">
                            <div class="ml-10 deposit__instance-method">
                                <div class="deposit__instance-method-title text_medium text_light_bold">{{ $t('deposit.methods.' + deposit.method_title)}}</div>
                                <div class="deposit__instance-date text_dark-gray mt-5 deposit__instance-date">{{ beautifyDepositDate(deposit.created_at) }}</div>
                            </div>
                            <div class="text_light_bold deposit__instance-amount">
                               <span>{{ deposit.raw_amount.convertToDollars() }} $</span> 
                               <span class="ml-5 deposit__instance-bonus" v-if="deposit.bonus_value > 0"> +{{ deposit.bonus_value.convertToDollars() }} $</span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:body>
                        <div class="game__details">
                            <div class="game__detail">
                                <span>{{ $t('deposit.history.payment_id') }}: </span> <span class="game__detail-value">{{ deposit.uuid }}</span>
                            </div>   

                            <div class="game__detail">
                                <span>{{ $t('deposit.history.payed') }}: </span> <span class="game__detail-value">{{ deposit.raw_amount.convertToDollars() }} $</span>
                            </div>
                            
                            <div class="game__detail">
                                <span>{{ $t('deposit.history.credited') }}: </span> <span class="game__detail-value">{{ deposit.amount.convertToDollars() }} $</span>
                            </div>
                        </div>
                    </template>
            </accordeon>
              
           </div>
       </div>
       
        <div class="loader__placeholder" v-else-if="loading">
            <loader class="mt-30"></loader>
        </div>
        <div class="history__controls" v-if="loading || (hasNextPage || hasPreviousPage)">
               <button @click="previousPage" :disabled="!hasPreviousPage || loading" class="history__control">
                   <span class="icon-left-arrow mr-7"></span> {{ $t('util.prev') }}
               </button>
                <button @click="nextPage" :disabled="!hasNextPage || loading" class="history__control">
                        {{ $t('util.next') }} <span class="icon-right-arrow ml-7"></span>
                </button>
        </div>

    <div v-if="paginatedDeposits.length === 0 && !loading" class="profile__inventory_empty mb-30" >
            <div class="market-empty__pic">
                <span class="icon-lifebuoy"></span>
            </div>
            <div class="market-empty__text">
                {{ $t('deposit.history.empty') }}
            </div>
        </div>
     
     
    </div>
</template>

<script>
import Accordeon from '../../misc/Accordeon.vue';
import { processAPIError, processValidationError, beautifyTimestamp, beautifyDateTime } from "@/util";
import { mapState } from 'vuex';
import Loader from '@/misc/Loader.vue';
import * as API from '@/services/API';

export default {
    components: { Loader, Accordeon },
    data() {
        return {
            deposits: [],
            loading: false,
            currentPage: 1,
            perPage: 0,
            maxPage: 1,
            finalPage: -1
        }
    },
    methods: {
        beautifyDepositDate(date) {
            return beautifyDateTime(date);
        },
        _load(page) {
            this.loading = true;
            return API.apiClient.get('deposit/history', {
                params: {
                    page: page
                }
            })
            .then(response => {
                if (response.data.success) {
                    this.perPage = response.data.data.per_page;
                    if (response.data.data.next_page_url == null) this.finalPage = page;
                    this.deposits.push(...response.data.data.data);
                }
                else {
                    const errorData = processAPIError(response);
                    this.$store.dispatch('notification/showError', errorData);
                }
            })
            .catch(error => {
                const errorData = processValidationError(error);
                this.$store.dispatch('notification/showError', errorData);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        firstPage() {
            this._load(1);
        },
        nextPage() {
            if (this.currentPage + 1 <= this.maxPage) {
                this.currentPage++;
            }
            else {
                this._load(this.currentPage + 1).then(() => {
                    this.currentPage++
                    this.maxPage = Math.max(this.maxPage, this.currentPage);
                });
            }
            
        },
        previousPage() {
            this.currentPage--;
        }
    },
    created() {
        this.firstPage();
    },
    computed: {
        paginatedDeposits() {
            return this.deposits.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
        },
        hasPreviousPage() {
            return this.currentPage > 1;
        },
        hasNextPage() {
            return this.currentPage != this.finalPage;
        }
    }
}
</script>

<style>

</style>