import axios from "axios";
import store from "@/store";
import config from '@/_config';

export const authClient = axios.create({
  baseURL: config.backend.auth_url,
  withCredentials: true,
});

authClient.interceptors.response.use(
  (response) => {
    store.dispatch('settings/setAndStore', {key: 'maintenance', value: 'off'});
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 503) {
        store.dispatch('settings/setAndStore', {key: 'maintenance', value: 'on'});
      }
      else {
        store.dispatch('settings/setAndStore', {key: 'maintenance', value: 'off'});
      }

      if ([401, 419].includes(error.response.status) && store.getters["auth/authUser"] && !store.getters["auth/guest"]) {
        store.dispatch("auth/logout");
      }
    }
    
    return Promise.reject(error);
  }
);

export default {
  async login(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/login", payload);
  },
  logout() {
    return authClient.post("/logout");
  },
  async forgotPassword(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/forgot-password", payload);
  },
  getAuthUser() {
    return authClient.get("/api/user/auth");
  },
  async resetPassword(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/reset-password", payload);
  },
  updatePassword(payload) {
    return authClient.put("/user/password", payload);
  },
  async registerUser(payload) {
    await authClient.get("/sanctum/csrf-cookie");
    return authClient.post("/register", payload);
  },
  sendVerification(payload) {
    return authClient.post("/email/verification-notification", payload);
  },
  updateUser(payload) {
    return authClient.put("/user/profile-information", payload);
  },
};