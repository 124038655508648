import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';

export const namespaced = true;

const getDefaultState = () => {
    return {
        activeTab: 'promocode',
        loading: false,

        promocode: {},
        promocodeLoaded: false,
        promocodeActivating: false,
        code: '',

        tasks: [],
        tasksLoaded: false,
        taskClaiming: false,
        hasUnclaimedTasks: false

    }
}

export const state = getDefaultState

export const mutations = {
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET(state, {key, value}) {
        state[key] = value;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_ACTIVE_TAB(state, value) {
        state.activeTab = value;
    },
    SET_PROMOCODE_LOADED(state, value) {
        state.promocodeLoaded = value;
    },
    SET_PROMOCODE(state, value) {
        state.promocode = value;
    },
    SET_PROMO_ACTIVATING(state, value) {
        state.promocodeActivating = value;
    },
    SET_CODE(state, value) {
        state.code = value;
    },
    SET_TASKS(state, value) {
        state.tasks = value;
    },
    SET_TASKS_LOADED(state, value) {
        state.tasksLoaded = value;
    },
    SET_TASK_CLAIMED(state, id) {
        const idx = state.tasks.findIndex(task => task.id === id);
        if (idx > -1) state.tasks[idx].progress.claimed = true;
    }
};

export const actions = {
    loadPromocodeTab({ commit, state }) {
        commit('SET_LOADING', true);
        commit('SET_ACTIVE_TAB', 'promocode');
        API.apiClient
            .get('bonus/requirements')
            .then(response => {
                commit('SET_PROMOCODE', response.data.data.promocode);
                commit('SET', {key: 'hasUnclaimedTasks', value: response.data.data.hasUnclaimedTasks});
            })
            .finally(() => {
                commit('SET_LOADING', false);
                commit('SET_PROMOCODE_LOADED', true);
            });
    },

    loadTasksTab({ commit, state }) {
        commit('SET_LOADING', true);
        commit('SET_ACTIVE_TAB', 'tasks');
        API.apiClient
            .get('tasks')
            .then(response => {
                commit('SET_TASKS', response.data.data.tasks);
            })
            .finally(() => {
                commit('SET_LOADING', false);
                commit('SET_TASKS_LOADED', true);
            });
    },

    claimTask({ dispatch, commit }, id) {
        return API.apiClient
            .post('tasks/claim', {
                id: id
            })
            .then(response => {
                if (response.data.success) {
                    commit('SET_TASK_CLAIMED', id);
                    dispatch('notification/show', {type: 'success', message: i18n.t('tasks.successful_activation')}, { root: true });
                    dispatch('stash/setStash', response.data.data.items, { root: true });
                    dispatch('auth/updateBalance', response.data.data.balance, { root: true });
                }
                else {
                    const errorData = processAPIError(response);
                    dispatch('notification/showError', errorData, {root: true});
                }
          
            })
            .catch(error => {
                const errorData = processValidationError(error);
                dispatch('notification/showError', errorData, {root: true});
            })
            .finally(() => {
      
            });
    },

    activatePromo({ commit, state, rootState, dispatch }, code) {
        if (!state.promocodeActivating) {
            commit('SET_PROMO_ACTIVATING', true);
            
            API.apiClient.post('bonus/activate', {
                code: state.code
            })
            .then(response => {
                if (response.data.success) {
                    commit('SET_CODE', '');
                    dispatch('notification/show', {type: 'success', message: i18n.t('bonus.successful_activation')}, { root: true });
                    dispatch('stash/setStash', response.data.data.items, { root: true });
                    dispatch('auth/updateBalance', response.data.data.balance, { root: true });
                }
                else {
                    const errorData = processAPIError(response);
                    dispatch('notification/showError', errorData, {root: true});
                }
            })
            .catch(error => {
                const errorData = processValidationError(error);
                dispatch('notification/showError', errorData, {root: true});

            })
            .finally(() => {
                commit('SET_PROMO_ACTIVATING', false);  
            });
        }
    }
};

export const getters = {
    hasUnclaimedTasks: (state) => {
        if (!state.tasksLoaded) return state.hasUnclaimedTasks;
        return state.tasks.some((task) => !task.progress.claimed);
    }
};