<template>
    <div class="crash__bet crash__bet_history" :class="`crash__bet_${getStatus(result)}`" @click.stop="$router.push({query: {...$route.query, m: 'crash', id: bet.game.id}})">
        <div class="crash__bet-history">
            <div class="crash__bet-date">
                {{ date }}
            </div>
            <button class="crash__bet-details">
                {{ $t('profile.history.details') }}
            </button>

            
            
        </div>
        <div class="crash__bet-show-more show-md">
            <span class="icon-visibility-on"></span>
        </div>
        <div class="crash__bet-sum">
            $<span class="crash__bet-sum-val">{{ bet.bet_items_value.convertToDollars() }}</span>
        </div>

        <mq-layout :mq="['xxlg']">
            <div class="crash__bet-bet-skins">
                <div class="crash__bet-bet-skin" v-for="(item, index) in bet.items.slice(0, 3)" :key="index" >
                    <mini-skin :item="item" v-if="index <= 2" />   
                    <div class="crash__bet-bet-more" v-if="index == 2 && bet.items.length > 3">+{{ bet.items.length - 3 }}</div> 
                </div> 
            </div>
        </mq-layout>

        <mq-layout :mq="['sm', 'md', 'lg',  'xlg']">
            <div class="crash__bet-bet-skins">
                <div class="crash__bet-bet-skin" v-for="(item, index) in bet.items.slice(0, 1)" :key="index" >
                    <mini-skin :item="item" v-if="index <= 0" />   
                    <div class="crash__bet-bet-more" v-if="index == 0 && bet.items.length > 1">+{{ bet.items.length - 1 }}</div> 
                </div> 
            </div>
        </mq-layout>

        <div class="crash__bet-bet-skins crash__bet-bet-skins_win">
            <div class="crash__bet-bet-skin" v-if="result === 1">
                <mini-skin v-if="bet.win_item" :item="bet.win_item"/>    
            </div> 
        </div>
        <div class="crash__bet-status" :class="`crash__bet-status_${getStatus(result)}`">
            <div class="crash__bet-status__title" v-if="result == 1">x{{ bet.cashout_at.toFixed(2) }}</div>    
            <div class="crash__bet-status__title" v-else>x{{ bet.game.multiplier.toFixed(2) }}</div>    
        </div>
        <div class="crash__bet-result-wrapper">
            <div class="crash__bet-result" v-if="result == 1">
                $<span class="crash__bet-result-val">{{ bet.win_value.convertToDollars() }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import MiniSkin from '@/misc/MiniSkin.vue';
import { DateTime } from 'luxon';
import { beautifyDateTime } from '@/util';
export default {
    props: ['bet'],
    methods: {
        getStatus(result) {
            if (result == 0) return 'playing';
            if (result == 1) return 'won';
            if (result == 2) return 'lost';
        }  
    },
    computed: {
        result() {
            if (this.bet.status == 1) {
                return this.bet.is_win ? 1 : 2;
            }
            return 0;
        },
        date() {
            return beautifyDateTime(this.bet.created_at);
        }
    },
    components: {
       MiniSkin
    },
}
</script>

<style>

</style>