<template>
    <button @click="openOperator" class="default-link">{{ $t('faq.answers.contact') }}</button>
</template>

<script>
import Chatra from '@chatra/chatra';

export default {
    methods: {
        openOperator() {
            Chatra('openChat', true)
        },
    }
}
</script>

<style>

</style>