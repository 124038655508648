<template>
  <div id="app" style="width: 100%; height: 100%;">
      <layout></layout>
  </div>
</template>

<script>
import Layout from '@/components/Layout.vue';
import config from '@/_config.js';
export default {
    components: {
      Layout
    },
    metaInfo() {
      return {
        title: this.$t('crash.name'),
        titleTemplate: '%s | ' + config.app.name
      }
    },
}
</script>

<style lang="scss">
    @import "./sass/app.scss";
</style>