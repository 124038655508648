<template>
    <div class="login__register-tab">
            <social-auth></social-auth>
            <div class="login__or">
                <span class="login__or-text">{{ $t('util.or') }}</span>
            </div>
        <form @submit.prevent="gValidate">
            <div class="login__form">
                <div class="login__error" v-if="hasError">
                    {{ error }}
                </div>
                <div class="login__field bet__input-wrapper">
                    <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="loginError">
                        <div class="bet__input-validation-msg">{{loginError}}</div>
                    </div>
                    <login-input :placeholder="$t('auth.register_login_placeholder')" v-model="login" :addClass="`input-field_login ${loginError ? 'input-field_error' : ''}`" />
                </div>
                <div class="login__field mt-10 bet__input-wrapper">
                    <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="passwordError">
                        <div class="bet__input-validation-msg">{{passwordError}}</div>
                    </div>
                    <login-input :placeholder="$t('auth.register_password_placeholder')" v-model="password" type="password" :addClass="`input-field_login ${passwordError ? 'input-field_error' : ''}`"/>
                </div>
                <div class="login__field mt-10 bet__input-wrapper">
                    <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="passwordConfirmationError">
                        <div class="bet__input-validation-msg">{{passwordConfirmationError}}</div>
                    </div>
                    <login-input :placeholder="$t('auth.register_password_confirm')" v-model="passwordConfirmation" type="password" :addClass="`input-field_login ${passwordConfirmationError ? 'input-field_error' : ''}`"/>
                </div>
                <div class="login__field bet__input-wrapper">
                    <vue-recaptcha
                        ref="recaptcha"
                        size="invisible"
                        :sitekey="sitekey"
                        @verify="registerUser"
                        @expired="onCaptchaExpired"
                    />
                </div>
            </div>
            <div class="login__button">
                <button type="submit" class="default-btn default-btn_high" :disabled="isRegistering">
                    <div v-if="!isRegistering">{{ $t('auth.signup') }}</div>
                    <div v-else class="loader"></div>
                </button>
              
            </div>
        </form>
        <div class="login__consent">
            {{ $t('auth.by_clicking') }} <router-link tag="a" class="default-link" :to="{name: 'terms'}">{{ $t('nav.terms') }}</router-link> 
        </div>
    </div>
    
</template>

<script>
import LoginInput from '../login/LoginInput.vue';
import AuthService from '@/services/authService';
import { getError, validateLogin, validatePassword, validatePasswordConfirmation, processValidationError } from '@/util';
import SocialAuth from './SocialAuth.vue';
import VueRecaptcha from 'vue-recaptcha';
import config from '@/_config';

export default {
    components: {
        LoginInput,
        SocialAuth,
        VueRecaptcha 
    },
    computed: {
        hasError() {
            return !!this.error;
        },
        sitekey() {
            return config.recaptcha.sitekey;
        }
    },
    data() {
        return {
            login: '',
            password: '',
            passwordConfirmation: '',
            loginError: '',
            passwordError: '',
            passwordConfirmationError: '',
            error: '',
            isRegistering: false,
        }
    },
    methods: {
        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        },
        gValidate() {
            if (this.validate()) {
                this.isRegistering = true;
                this.error = '';
                this.$refs.recaptcha.execute();
            }
        },
        async registerUser(recaptchaToken) {
            // if (this.validate()) {
                this.isRegistering = true;
                this.error = '';
                const payload = {
                    login: this.login,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    referrer_token: localStorage.getItem('rp__referral__code'),
                    captcha: recaptchaToken
                };
                try {
                    await AuthService.registerUser(payload);
                    const authUser = await this.$store.dispatch("auth/getAuthUser");
                    if (authUser) {
                        localStorage.removeItem('rp__referral__code');
                        this.$router.push("/");
                        this.isRegistering = false;
                        this.$router.go();
                    } else {
                        const error = Error(
                            "Unable to fetch user after register, check your API settings."
                        );
                        throw error;
                    }
                } catch (error) {
                    const errorData = processValidationError(error);
                    if (['validation.unique'].includes(errorData[0])) {
                        this.loginError = this.$t('auth.login_taken');
                    }
                    else if (['errors.too_many_requests', 'captcha.failed'].includes(errorData[0])) {
                        this.$store.dispatch('notification/showError', errorData, {root: true});
                    }
                    else {
                        this.error = this.$t('auth.wrong_data');
                    }
                    this.isRegistering = false;
                    
                }
                finally {
                    this.onCaptchaExpired();
                }
            // }
        },

        validate() {
            this.loginError = this.validateLogin();
            this.passwordError = this.validatePassword();
            this.passwordConfirmationError = this.validatePasswordConfirmation();
            return !this.loginError && !this.passwordError && !this.passwordConfirmationError;
        },
        validateLogin() {
            return validateLogin(this.login);
        },
        validatePassword() {
            return validatePassword(this.password);
        },
        validatePasswordConfirmation() {
            return validatePasswordConfirmation(this.passwordConfirmation, this.password);
        }
    },
    watch: {
        login() {
            this.loginError = this.validateLogin();
        },
        password() {
            this.passwordError = this.validatePassword();
        },
        passwordConfirmation() {
            this.passwordConfirmationError = this.validatePasswordConfirmation();
        }
    }
}
</script>
