<template>
    <div class="purse">
        <div class="purse__body">
            <div class="purse-title__wrapper">
            <div class="purse__title">
                {{ $t('header.balance') }}
            </div>
            <div class="flex-align">
                <div class="purse__currency">
                $
                </div>
                <div class="purse__balance">
                    {{ balanceInDollars }}
                    <div ref="purse_anim" class="purse__animation">
                        {{ difference.convertToDollars() }}
                    </div>  
                </div>
            </div>
            </div>
            

            <button class="purse__refill hide-sm" @click.stop="$router.push({query: {...$route.query, m: 'deposit'}})">
                {{ $t('header.deposit') }}
            </button>
            <button class="purse__refill show-sm" @click.stop="$router.push({query: {...$route.query, m: 'deposit'}})">
                <span class="icon-wallet"></span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    data() {
        return {
            difference: 0,
            timerID: null
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', ['balanceInDollars']),
        balance() {
            return this.user.balance;
        }
    },
    watch: {
        balance(newVal, oldVal) {
            if (oldVal != null) {
                this.difference = newVal - oldVal;
                if (this.difference) {
                    const className = this.difference < 0 ? 'decrease' : 'increase';
                    this.$refs.purse_anim.classList.add(className);
                    clearTimeout(this.timerID);
                    this.timerID = setTimeout(() => this.$refs.purse_anim.classList.remove(className), 600);
                }
            }
        }
    }
}
</script>