<template>
    <div class="email">
        <div style="position: relative" class="full-width">
            <div class="bet__input-validation bet__input-validation_right bet__input-validation_small" v-if="error">
                    <div class="bet__input-validation-msg">{{ error }}</div>
            </div>
            <input autocomplete="off" :value="email" @input="email = $event.target.value" :disabled="email_verified" type="text" :placeholder="$t('profile.account.email_placeholder')" class="input-field email-input input-field_login promocode__input" :class="`${error ? 'input-field_error' : ''}`">
        </div>
        <button v-if="!email_verified" @click.stop="confirm" class="promocode__activate" :disabled="loading">
            <div v-if="!loading">{{ $t('profile.account.email_confirm') }}</div> 
            <div v-else class="loader"></div>
        </button>
        <button v-else class="promocode__activate email_verified" disabled>
            {{ $t('profile.account.email_verified') }}
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthService from '@/services/authService';
import { validateEmail, processValidationError, processAPIError } from '@/util';

export default {
    data() {
        return {
            loading: false,
            email: this.value,
            error: ''
        }
    },
    props: ['value'],
    computed: {
        ...mapGetters('auth', ['email_verified'])
    },
    methods: {
        validateEmail() {
            return validateEmail(this.email);
        },
        validate() {
            this.error = this.validateEmail();
            return !this.error;
        },
        confirm() {
            if (this.validate()) {
                this.loading = true;
                const payload = {
                    email: this.email,
                    locale: navigator.language || navigator.userLanguage
                };
                AuthService.updateUser(payload)
                    .then(response => {
                        this.$store.dispatch('notification/show', {type: 'success', message: this.$t('profile.account.link_sent')}, { root: true });           
                        this.$store.dispatch('auth/changeEmail', this.email, { root: true });           
                    })
                    .catch(error => {
                        const errorData = processValidationError(error);
                        this.$store.dispatch('notification/showError', errorData, {root: true});
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    },
    watch: {
        email(val) {
            this.error = this.validateEmail();
        }
    }
}
</script>

<style>

</style>