<template>
    <div class="">
        <div class="heading mb-20">{{ $t('profile.account.social_media') }}</div>
        <div class="linked-social-media">
            <div v-for="provider in providers" :key="provider.provider" class="mt-15">
                <label class="input-field__label">{{ $t('profile.account.your_account') }} {{ $t('profile.account.provider_' + provider.provider) }}</label>
                <input type="text" :value="getLinkToAccount(provider)" class="input-field input-field_login full-width" readonly />
            </div>
        </div>
        <div>
            <label class="input-field__label mt-20">{{ $t('profile.account.link_another') }}</label>
            <div class="not-linked-social-media">
                <a class="login__social-btn"  :href="`${hostname}/auth/${provider}`" v-for="provider in available_providers" :key="provider">
                    <span :class="provider == 'vkontakte' ? 'icon-vk' : `icon-${provider}`"></span>
                </a>
                <span class="login__social-btn" @click.stop="$store.commit('profile/SET', {key: 'linkTelegramModalOpened', value: true})">
                    <span class='icon-telegram'></span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/_config';

export default {
    data() {
        return {
            all_providers: ['steam', 'vkontakte', 'twitch', 'google', 'facebook'],
            prefix_links: {
                'steam': 'https://steamcommunity.com/profiles/'
            }
        }
    },
    methods: {
        getLinkToAccount(provider) {
            if (provider.provider === 'steam') {
                return 'https://steamcommunity.com/profiles/' + provider.provider_id;
            }
            if (provider.provider === 'vkontakte') {
                return 'https://vk.com/id' +  provider.provider_id;
            }
            if (provider.provider === 'google') {
                return provider.email;
            }
            if (provider.provider === 'twitch') {
                return 'https://www.twitch.tv/' + provider.username.toLowerCase();
            }          
            if (provider.provider === 'facebook') {
                return 'http://www.facebook.com/profile.php?id=' + provider.provider_id;
            }  
        }
    },
    computed: {
        ...mapGetters('auth', ['providers']),
        available_providers() {
            return this.all_providers.filter(
                provider => !this.providers.some(
                        p => p.provider == provider
                    )
                )
        },
        hostname() {
            return config.backend.auth_url;
        }
    }
}
</script>

<style>

</style>