<template>
    <div class="input__wrapper">
        <label class="input-field__label mb-5">{{ label }}</label>
        <div class="promocode">
            <input autocomplete="off" readonly :value="value" class="input-field input-field_login promocode__input">
            <button class="copy__field" v-clipboard="() => value" v-clipboard:success="() => $store.dispatch('notification/show', {type: 'success', message: $t('util.copied')})">
                <span class="icon-copy"></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['value', 'label']
}
</script>

<style>

</style>