<template>
  <modal :show="showModal" @close="closeModal" maxWidth="400px" closeClass="modal__close_with-header"> 
       <div class="modal__header">
           <span class="icon-fair mr-7"></span> <span>{{ $t('fairness.seeds_control') }}</span>
       </div>
       
        <div class="modal__body">
            <copy-field :value="current_client_seed" :label="$t('fairness.current_client_seed')"/>
            <copy-field class="mt-10" :value="current_server_seed" :label="$t('fairness.current_server_seed')"/>

            <copy-field class="mt-25" :value="previous_client_seed" :label="$t('fairness.previous_client_seed')"/>
            <copy-field class="mt-10" :value="previous_server_seed" :label="$t('fairness.previous_server_seed')"/>
            <util-button @click.stop="calculatePrevious" class="mt-15" height="45px" variant="blue">
                <div>
                    {{ $t('fairness.calculate_previous') }}
                </div>
            </util-button>
        </div>
      
    </modal>
</template>

<script>

import Modal from '../../misc/Modal';
import CopyField from '@/misc/CopyField';
import UtilButton from '@/misc/Button.vue';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            seed: 'sdasf'
        }
    },
    components: {
        Modal, CopyField, UtilButton
    },
    computed: {
        showModal() {
            return this.$store.state.fairness.seedsModalOpened;
        },
        ...mapGetters('settings', ['currentGameMode']),
        
        current_client_seed() {
            return this.$store.getters[this.currentGameMode + '/current_client_seed'];
        },
        current_server_seed() {
            return this.$store.getters[this.currentGameMode + '/current_server_seed'];
        },
        previous_client_seed() {
            return this.$store.getters[this.currentGameMode + '/previous_client_seed'];
        },
        previous_server_seed() {
            return this.$store.getters[this.currentGameMode + '/previous_server_seed'];
        },
    },
    methods: {
        calculatePrevious() {
            this.closeModal();
            this.$router.push({query: {...this.$route.query, m: 'fairness-check', server_seed: this.previous_server_seed, client_seed: this.previous_client_seed, selected_game: this.$store.getters[this.currentGameMode + '/getModeID']}}).catch(()=>{})
        },
        closeModal() {
            this.$store.commit('fairness/SET', {key: 'seedsModalOpened', value: false})
        }
    }
}
</script>

<style>

</style>