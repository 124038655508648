<template>
    <icon-btn icon="setting" @click="handleClick" />
</template>

<script>
import IconBtn from '../../misc/IconBtn.vue'
export default {
  components: { IconBtn },
  methods: {
    handleClick() {
      this.$store.commit('settings/SET_SETTING', {key: 'settingsModalOpened', value: true});
      this.$emit('click');
    }
  }
}
</script>

<style>

</style>