import Vue from 'vue';
import Vuex from 'vuex'
import VueCookie from 'vue-cookie';
import router from '@/router';
import i18n from '@/i18n';
import store from '@/store';
import _bootstrap from '@/bootstrap';
import App from "@/App";
import "animate.css";
import './registerServiceWorker'

Vue.use(VueCookie);
Vue.use(Vuex);

new Vue({
  data: {

      crash_state: null,
      crash_coeff: 0,
      
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');


