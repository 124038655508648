import * as API from '@/services/API';
import i18n from '@/i18n';
import router from "@/router";
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';

export const namespaced = true;

const getDefaultState = () => {
    return {
        currency: 'RUB',
        available_methods: [],
        chosen_method: null,
        loading: false,
        invitational_bonus: 0,
        invitational_bonus_max_profit: 0,
        promocode: '',
        applied_promocode: '',
        boost_percent: 0,
        promocode_min_dep: 0,
        promocode_max_profit: 0,
        processing_deposit_request: false
    }
}

export const state = getDefaultState

export const mutations = {
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET(state, {key, value}) {
        state[key] = value;
    },
};

export const actions = {
    changeCurrency({ commit }, currency) {
        commit('SET', {key: 'chosen_method', value: null});
        commit('SET', {key: 'currency', value: currency});
    },
    deposit({ commit, state, dispatch, getters }, {amount, phone_number}) {
        commit('SET', {key: 'processing_deposit_request', value: true});
        API.apiClient.post('/deposit', {
            code: state.applied_promocode == '' ? null : state.applied_promocode,
            amount: Math.round(amount * 100),
            id: getters.chosen_method.id,
            phone_number: getters.chosen_method.is_phone_required ? phone_number : null
        })
        .then(response => {
            if (response.data.success) {
                if (response.data.data.type == 'external') {
                    window.location.href = response.data.data.link;
                }
                else {
                    router.push({query: {m: 'qiwi-payment', order_id: response.data.data.link}}).catch(()=>{})
                }
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'processing_deposit_request', value: false});
        });
    },
    tryPromocode({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => API.apiClient.post('deposit/promocode', {
            code: state.promocode
        })
        .then(response => {
            if (response.data.success) {
                dispatch('notification/show', {type: 'success', message: i18n.t('deposit.promocode_applied')}, { root: true });
                commit('SET', {key: 'boost_percent', value: response.data.data.boost_percent})
                commit('SET', {key: 'promocode_min_dep', value: response.data.data.min_dep});
                commit('SET', {key: 'promocode_max_profit', value: response.data.data.max_profit});
                commit('SET', {key: 'applied_promocode', value: response.data.data.code});
                resolve(response);
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
                reject();
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
            reject();
        }))
    },
    removePromocode({ commit, dispatch }) {
        dispatch('notification/show', {type: 'success', message: i18n.t('deposit.promocode_removed')}, { root: true });
        commit('SET', {key: 'boost_percent', value: 0})
        commit('SET', {key: 'promocode_min_dep', value: 0});
        commit('SET', {key: 'promocode_max_profit', value: 0});
        commit('SET', {key: 'applied_promocode', value: ''});
    },
    loadDepositInfo({ commit, state, dispatch }) {
        commit('SET', {key: 'loading', value: true});
        API.apiClient.get('deposit')
        .then(response => {
            if (response.data.success) {
                commit('SET', {key: 'available_methods', value: response.data.data.methods})
                commit('SET', {key: 'invitational_bonus', value: response.data.data.invitational.boost_percent});
                commit('SET', {key: 'invitational_bonus_max_profit', value: response.data.data.invitational.max_profit});
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET', {key: 'loading', value: false});
        });
    },
    selectMethod({commit, state, getters}, id) {
        const _idx = getters.filtered_methods.findIndex(m => m.id === id);
        if (_idx > -1) {
            commit('SET', {key: 'chosen_method', value: getters.filtered_methods[_idx]});
        }
    }
};

export const getters = {
    filtered_methods: (state) => {
        return state.available_methods.filter(method => method.currency === state.currency);
    },
    chosen_method: (state, getters)  => {
        if (!state.chosen_method) return getters.filtered_methods[0];
        return state.chosen_method; 
    }
};