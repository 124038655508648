import axios from "axios";
import store from "@/store";
import router from "@/router";
import config from '@/_config';

export const apiClient = axios.create({
  baseURL: config.backend.api_url,
  withCredentials: true, // required to handle the CSRF token
});

apiClient.interceptors.response.use(
  (response) => {
    if (store.getters['settings/under_maintenance']) {
      store.dispatch('settings/setAndStore', {key: 'maintenance', value: 'off'});
    }
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 503) {
        store.dispatch('settings/setAndStore', {key: 'maintenance', value: 'on'});
      }
      else {
        store.dispatch('settings/setAndStore', {key: 'maintenance', value: 'off'});
      }

      if ([401, 419].includes(error.response.status) && store.getters["auth/authUser"] && !store.getters["auth/guest"]) {
        store.dispatch("auth/logout");
      }
    }
    
    return Promise.reject(error);
  }
);