<template>
  <div class="game__footer-line">
    <overlay-scrollbars :options="crashLineOptions" style="max-width: 100%; height: 100%;">
        <div class="crash-history-coeffs" v-if="$store.state.crash.dataLoaded">
                <div v-for="game in history" @click.stop="$router.push({query: {...$route.query, m: 'crash', id: game.id}})" :key="game.id" class="crash-history-coeff" :class="`crash-history-coeff_t${multiplierTypeClass(game.multiplier)}` + ' ' + (animationsEnabled ? 'crash-history-coeff_animated' : '')">
                    <span class="crash-history-coeff__val">{{ game.multiplier.toFixed(2) }}x</span>
                </div>
        </div>
        <div class="crash-history-coeffs" v-else>
            <div v-for="i in 30"  :key="i" class="crash-history-coeff placeholder-item">
                <div class="animated-background"></div>
            </div>
        </div>
        
    </overlay-scrollbars>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            crashLineOptions: {
                className : 'os-theme-thin-light',
                scrollbars: {
                    autoHide: 'move'
                }
            }
        }
    },
    computed: {
        ...mapState('crash', {history: 'history', animationsEnabled: '_historyAnimations'}),
    },
    methods: {
        multiplierTypeClass(num) {
            if (num <= 1.20) return 1;
            if (num <= 2) return 2;
            if (num <= 4.25) return 3;
            if (num <= 7) return 4;
            if (num <= 10) return 5 ;
            return 6;
        }
    }
}
</script>

<style>

</style>