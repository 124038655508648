<template>
    <div class="">
        <div class="heading mb-20">{{ $t('profile.account.email') }}</div>
        <email-field :value="$store.getters['auth/email']"/>
    </div>
  
</template>

<script>
import EmailField from './EmailField.vue'
export default {
  components: { EmailField },

}
</script>

<style>

</style>