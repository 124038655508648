<template>
    <div class="modal__wrapper" v-show="show">
        <div class="modal" role="dialog">
            <div class="modal__dialog" :style="{'max-width': maxWidth}">
                <transition name="slide-fade">
                    <div class="modal__content" v-click-outside="close" @mousedown="mousedown" @mouseup="mouseup" v-if="show">
                        <button class="modal__close" :class="closeClass" @click="forceClose">
                            <span class="icon-cancel"></span>
                        </button>
                        <slot />
                    </div>
                </transition>
            </div>
        </div>
        <div class="modal__backdrop"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mouseOnModal: false
        }
    },
    props: {
        show: {
            type: Boolean
        },
        maxWidth: {
            default: '500px'
        },
        closeClass: {
            default: ''
        }
    },
    methods: {
        close() {
            if (this.mouseOnModal) {
                this.mouseOnModal = false;
                return;
            }
            this.forceClose();
        },
        forceClose() {
            if (this.$router.currentRoute.query.m) {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {
                        ...this.$route.query,
                        m: undefined,
                    }
                });
            }
            this.$emit('close');
        },
        mousedown() {
            this.mouseOnModal = true;
        },
        mouseup() {
            this.mouseOnModal = false;
        }
    }
}
</script>

