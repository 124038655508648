
export const namespaced = true;

export const state = {
    activeModal: null
};

export const mutations = {
    SET_ACTIVE_MODAL(state, modal) {
        state.activeModal = modal;
    },
    REMOVE_ACTIVE_MODAL(state) {
        state.activeModal = null;
    },
};

export const actions = {

};

export const getters = {

};