<template>
    <img :src="source" alt="">
</template>

<script>
import Identicon from 'identicon.js';
import jsSHA from 'jssha';
export default {
    computed: {
        source() {
            if (this.$store.getters['settings/avatars_visibility']) {
                return this.src;
            }
            return this.getIdenticon();
        }
    },
    methods: {
        getHash() {
            const hash = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            hash.update(this.unique);
            return hash.getHash("HEX");
        },
        getIdenticon() {
            const hash = this.getHash();
            const options = {
                size: 60,
                format: 'svg',
                margin: .15  
            };
            const data = new Identicon(hash, options).toString();
            return 'data:image/svg+xml;base64,' + data;
        }
    },
    props: ['src', 'unique']
}
</script>

<style>

</style>