import Vue from "vue";
import i18n from '@/i18n';

export const namespaced = true;

export const state = {
};

export const mutations = {
    
};

export const actions = {
    show({ dispatch, rootGetters }, { type, message }) {
        if (!rootGetters['auth/banned']) {
            const options = {
                timeout: 3000,
                toastClassName: 'toast__c'
            };
            if (type === 'error') {
                Vue.$toast.error(message, options);
                dispatch('sound/play', 'error_1', { root: true });
            }
            if (type === 'success') {
                Vue.$toast.success(message, options);
                dispatch('sound/play', 'success', { root: true });
            }
            if (type === 'info') {
                Vue.$toast.info(message, options);
            }
            if (type === 'warning') {
                Vue.$toast.warning(message, options);
            }
        }
    },

    showError({ dispatch }, errorData) {
        if (i18n.te(errorData[0])) {
            dispatch('show', { type: 'error', message: i18n.t(errorData[0], errorData[1]) });
        }
        else {
            dispatch('show', { type: 'error', message: i18n.t('errors.generic') });
        }
    }
};

export const getters = {

};