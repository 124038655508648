<template>
  <modal :show="showModal" @close="closeModal" maxWidth="400px" closeClass="modal__close_with-header"> 
       <div class="modal__header">
           {{ $t('auth.reset_password') }}
       </div>
       <form @submit.prevent="reset">
        <div class="modal__body">
                <field class="full-width" v-model="password" :error="password_error" type="password">{{ $t('profile.account.label_new_password') }}</field>
                <field class="full-width mt-20" v-model="password_confirmation" :error="password_confirmation_error" type="password_confirmation">{{ $t('profile.account.label_password_new_confirmation') }}</field>
            <div class="modal__button">
                    <util-button variant="blue" class="mt-20" width="150px" px="20px" type="submit" :disabled="loading">
                        <div v-if="!loading">
                            {{ $t('util.confirm') }}
                        </div>
                        <div class="loader" v-else></div>
                    </util-button>
            </div>
            
        </div>
       </form>
    </modal>
</template>

<script>

import Modal from '../../misc/Modal';
import UtilButton from '@/misc/Button';
import { validateEmail, validatePassword, validatePasswordConfirmation, processValidationError, processAPIError } from '@/util';
import AuthService from '@/services/authService';
import Field from './Field.vue';

export default {
    data() {
        return {
            password: '',
            password_confirmation: '',
            password_error: '',
            password_confirmation_error: '',
            loading: false,
            email: '',
            token: ''
        }
    },
    components: {
        Modal,
        UtilButton,
        Field
    },
    computed: {
        showModal() {
            return this.$store.state.modal.activeModal == 'reset-password';
        }
    },
    methods: {
        removeOtherQueryParams() {
            this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                    ...this.$route.query,
                    token: undefined,
                    email: undefined
                }
            }).catch(() => {})
        },
        closeModal() {
            this.removeOtherQueryParams();
        },

        validatePassword() {
            return validatePassword(this.password);
        },

        validatePasswordConfirmation() {
            return validatePasswordConfirmation(this.password_confirmation, this.password);
        },

        validate() {
            this.password_error = this.validatePassword();
            this.password_confirmation_error = this.validatePasswordConfirmation();
            return !this.password_error && !this.password_confirmation_error;
        },

        reset() {
            if (this.validate()) {
                this.loading = true;
                const payload = {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.token
                }
                AuthService.resetPassword(payload)
                .then(response => {
                    this.$store.dispatch('notification/show', {type: 'success', message: this.$t('auth.reset_successful')}, { root: true });      
                    this.removeOtherQueryParams();
                    this._closeModal();
                })
                .catch(error => {
                    const errorData = processValidationError(error);
                    this.$store.dispatch('notification/showError', errorData, {root: true});
                })
                .finally(() => {
                    this.loading = false;
                });
            }

        },
        _closeModal() {
            if (this.$router.currentRoute.query.m) {
                this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {
                        ...this.$route.query,
                        m: undefined,
                    }
                });
            }
        }
    },
    watch: {
        password() {
            this.password_error = this.validatePassword();
            this.password_confirmation_error = this.validatePasswordConfirmation();

        },
        password_confirmation() {
            this.password_confirmation_error = this.validatePasswordConfirmation();
        },
        showModal: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.email = this.$route.query.email;
                    this.token = this.$route.query.token;
                }
            }
        }
    }
}
</script>

<style>

</style>