import i18n from '@/i18n';
import Vue from 'vue';
import router from '../../router';


export const namespaced = true;

const path = '/static/sounds/';

const getFallbackSoundSetting = () => {
    if (window.innerWidth < 1079) return 'off';
    return 'on';
}

export const state = {
    sound: localStorage.getItem('sound') || getFallbackSoundSetting(),
    betSound: null,
    winSound: null,
    depositSound: null,
    crashSound: null,
    successSound: null,
    errorSound: null 
};

export const mutations = {
    SET(state, {key, value}) {
        state[key] = value;
    }
};

export const actions = {
    play({ commit, state, getters }, sound) {
        if (getters.soundOn) {
            const name = sound + 'Sound';
            if (!state[name]) {
                let audio = new Audio(path + sound + '.mp3');
                audio.volume = 0.08;
                commit('SET', {key: name, value: audio});
                audio.play();
            } 
            else {
                state[name].play();
            }
        }
    },
    toggle({ commit, getters }) {
        if (getters.soundOn) {
            commit('SET', {key: 'sound', value: 'off'});
            localStorage.setItem('sound', 'off');
        }
        else {
            commit('SET', {key: 'sound', value: 'on'});
            localStorage.setItem('sound', 'on');
        }
    }
};

export const getters = {
    soundOn: (state) => {
        return state.sound == 'on';
    }
};