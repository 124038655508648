<template>
    <div class="crash__bet" :class="`crash__bet_${getStatus(bet.result)}`">
        <div class="crash__bet-player">
            <player-avatar :src="bet.pic" class="crash__bet-avatar" :unique="bet.unique_id"/>
        </div>
        <div class="crash__bet-sum">
            $<span class="crash__bet-sum-val">{{ bet.items_value.convertToDollars() }}</span>
        </div>
        <mq-layout mq="lg+">
            <div class="crash__bet-bet-skins">
                <div class="crash__bet-bet-skin" v-for="(item, index) in bet.items.slice(0, 3)" :key="index" >
                    <mini-skin :item="item" v-if="index <= 2" />   
                    <div class="crash__bet-bet-more" v-if="index == 2 && bet.items.length > 3">+{{ bet.items.length - 3 }}</div> 
                </div> 
            </div>
        </mq-layout>

        <mq-layout :mq="['sm', 'md']">
            <div class="crash__bet-bet-skins">
                <div class="crash__bet-bet-skin" v-for="(item, index) in bet.items.slice(0, 1)" :key="index" >
                    <mini-skin :item="item" v-if="index <= 0" />   
                    <div class="crash__bet-bet-more" v-if="index == 0 && bet.items.length > 1">+{{ bet.items.length - 1 }}</div> 
                </div> 
            </div>
        </mq-layout>
        <div class="crash__bet-bet-skins crash__bet-bet-skins_win">
            <div class="crash__bet-bet-skin" v-if="bet.result === 1">
                <mini-skin v-if="bet.win_item" :item="bet.win_item"/>    
            </div> 
        </div>
        <div class="crash__bet-status" :class="`crash__bet-status_${getStatus(bet.result)}`">
            <div class="crash__bet-status__title" v-if="bet.result == 0">{{ $t('crash.in_game') }}</div>    
            <div class="crash__bet-status__title" v-else-if="bet.result == 1">x{{ bet.cashout.toFixed(2) }}</div>    
            <div class="crash__bet-status__title" v-else>x{{ bet.lost_at.toFixed(2) }}</div>    
        </div>
        <div class="crash__bet-result-wrapper" :class="{'crash__bet-result-wrapper_hidden': bet.result != 1}">
            <div class="crash__bet-result" v-if="bet.result == 1">
                $<span class="crash__bet-result-val">{{ bet.win_value.convertToDollars() }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import MiniSkin from '../../misc/MiniSkin.vue';
import PlayerAvatar from '../../misc/PlayerAvatar.vue';
export default {
    props: ['bet'],
    methods: {
        getStatus(result) {
            if (result == 0) return 'playing';
            if (result == 1) return 'won';
            if (result == 2) return 'lost';
        }  
    },
    components: {
       MiniSkin,
       PlayerAvatar
    },
}
</script>
        

<style>

</style>