<template>
    <div class="account__blocked">
        <logo class="mt-20;"></logo>
        <div class="cogs">
            <span class="icon-cog cog cog-1"></span>
            <span class="icon-cog cog cog-2"></span>
        </div>
       
        <pre class="maintenance__header">{{ $t('util.maintenance_header') }}</pre>
        <pre class="maintenance__text">{{ $t('util.maintenance_text') }}</pre>
    </div>
</template>

<script>
import Logo from '@/components/header/Logo.vue';

export default {
    components: {
        Logo
    } 
}
</script>

<style>

</style>