<template>
    <div class="bet__game-hints">
        <button @click.stop="$store.commit('fairness/SET', {key: 'seedsModalOpened', value: true})" class="bet__hint"><span class="icon-fair"></span></button>
        <button v-tooltip="{ content: $t('crash.perfomance') }" @click="$store.dispatch('settings/toggleCrashVisibility')" class="bet__hint bet__hint_second"><span :class="`icon-visibility-${$store.state.settings.crash_visibility}`"></span></button>
        
        <button v-if="!editMode"  @click="enableEditMode" class="bet__hint show-lg"><span class="icon-settings"></span></button>
        <button v-if="editMode"  @click="cancelSetting" class="bet__hint show-lg" style="font-size: 13px;"><span class="icon-cancel"></span></button>
        <button v-if="editMode"  @click="confirmSetting" class="bet__hint show-lg"><span class="icon-confirm"></span></button>

    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    methods: {
        enableEditMode() {
            this.$store.commit('crash/ENABLE_EDIT_MODE');
        },
        cancelSetting() {
            this.$store.commit('crash/CANCEL_SETTING');
        },
        confirmSetting() {
            this.$store.commit('crash/CONFIRM_SETTING');
        }
    },
    computed: {
        ...mapState('crash', {editMode: 'editMode'})
    }
}
</script>

<style>

</style>