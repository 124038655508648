<template>
    <div class="partnership" v-if="!loading">
        <div class="partnership__progress">
            <div class="partnership__circle">
            <vue-ellipse-progress
                    :progress="referral_progress"
                    color="#7579ff"
                    empty-color="#272b38"
                    :size="260"
                    :thickness="7"
                    :legend-value="3.5"
                    :empty-thickness="3"
                    animation="default 300"
                    fontSize="1.5rem"
                    font-color="white"
                    dot="12"
                    :legend="false"
                    >
                        <span slot="legend-caption">
                            <div class="levels__progress-desc">{{ $t('profile.partnership.level') }} {{ referral_level.level }}</div>
                            <div class="levels__progress-info levels__progress-info_smaller mt-10">${{ deposits_by_referrals.convertToDollars() }} / ${{ next_level ? next_level.deposits_sum.convertToDollars() : referral_level.deposits_sum.convertToDollars() }}</div>
                            <div class="levels__progress-desc" v-if="next_level">{{ $t('profile.partnership.to_level') }} {{ next_level.level }}</div>
                        </span>
                    </vue-ellipse-progress>
            </div>
            <div class="full-width partnership__data ml-25">
                <div class="dark-panel partnership__link mt-10">
                        <label for="" class="input-field__label">{{ $t('profile.partnership.your_link') }}</label>
                    <div class="promocode">
                        <input autocomplete="off" :value="referral_link" type="text" class="input-field promocode__input" readonly>
                        <button v-clipboard="() => referral_link" v-clipboard:success="() => $store.dispatch('notification/show', {type: 'success', message: $t('util.copied')})" class="promocode__activate">
                            <div>{{ $t('util.copy') }}</div> 
                        </button>
                    </div>
                </div>
                <div class="mt-10 flex-stretch partnership__stats">
                    <div class="vertical__stat dark-panel">
                        <div class="profile__stat-icon">
                            <span class="icon-account"></span>
                        </div>
                        <div class="">
                            <div class="profile__stat-value">{{ referrals_count }}</div>
                            <div class="profile__stat-what">{{ $t('profile.partnership.referrals') }}</div>
                        </div>
                    </div>

                    <div class="vertical__stat dark-panel">
                        <div class="profile__stat-icon">
                            <span class="icon-bag"></span>
                        </div>
                        <div class="">
                            <div class="profile__stat-value">${{ total_referral_balance.convertToDollars() }}</div>
                            <div class="profile__stat-what">{{ $t('profile.partnership.earned') }}</div>
                        </div>
                    </div>

                    <div class="vertical__stat dark-panel">
                        <div class="profile__stat-icon">
                            <span class="icon-discount"></span>
                        </div>
                        <div class="">
                            <div class="profile__stat-value">{{ final_referral_percent }}%</div>
                            <div class="profile__stat-what">{{ $t('profile.partnership.you_get') }}</div>
                        </div>
                    </div>
                    <div class="dark-panel partnership__claim full-width">
                        <label for="" class="input-field__label">{{ $t('profile.partnership.available') }}</label>
                        <referral-balance :balance="current_referral_balance" :min_withdraw="minimal_amount"/>
                       
                        <div class="text__text task__text_small mt-7">
                            {{ $t('profile.partnership.minimal_amount') }} — <span class="medium-light-text">{{ minimal_amount.convertToDollars() }}$</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="levels__info mt-30">
            <div class="heading mb-20 flex-align">  
                    <img alt="🤝" width="25" class="imga" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABaUExURUdwTOesH92dGLVvBdygIeOnIdWRC7pxAOexLKtkALVwBO60JeywG7t4Cf/cZf/aX/O5H//YWP/UTf7MN/jBJf/RQvvHLe2wGOGfDeeoE9qWCNONBMyEAsR8ARwbxNYAAAAOdFJOUwChjdxFacv+Gf6ou96FFPku9gAABKFJREFUWMPtl9l2ozAMhgMYDGQzYLz7/V9zfslk65Km07mYi6o9pyngD+mXLDm73a/92q/92v9m/bsLbV1VdSv6b2HafVeJhyti761SytrDvn2Z1e+V1jbUZUHfCyHag1ln2LouRh2q1/zq92ZZjFa+q1vEsz+EmJyez7AJv9O8GvWSW9VCr16M4VCsdyFFVThk9Hde9KF6o1ffdl19f6U1/PxEUWitAIrZL2X9CptnfKYXKXu4E1J00Su9v5H6Az1YlnGE1qXEgbGXiBqsCXeLXixXL9oqOKURyo1U0ctPp1MhEShkvxKH/7FKmwUkSIUop2lRh67rnC1Xsai6OrROxLmRbArEJo6PwYXgy6KZ9ZpmhKjMMk8ljvMsLgrNG+i0gRwFNrE/wRXRyvvZJb6zbGpwTrfg9qiXaeOwSir6lZOotfNQCKaBUlsot1QWDJ5sS2T8AAc2cRUAhLUGHOs0Jw0qK3dBTbeaKPov5kAuiU0AgrCtJgQNCmVPschks3bBE4oiLBfnDQMFyKUWtUCoYkxSKSgSxnFRljRAUwjvKYVcDytXWHFcaXKppq3Jd0mOEoiNKWIVJD9dDZEoumivrC1+TdsBLlWO7sErXe6TCPOCBOtLVTBmcyql4Mqbl42j2Kp+V0fnPFjUMvSFxHreYc6cCqBMyOkSobn4g7d6sRO4EYjlLzU83yGKPAXDGZ9VkvDKkrA3h5Std7ujzKQIwT4gEYU45+s2WlzOkUlsG4j2iRglWIC9J53LD9vVwckEmYMvwm6c0JaG0AAFv575dBfqtNpETtlLXD5e29KRUCUj73U6nx9Fo47p8DTJ6q0Pcbzr9qIZZI6fkk6nWZn50iRoI5JS0NU9Yl4gzT54tU5XkApSkrDdu1b+lETrUaXOcgdGaJgUaZBSNh+MhHZ4IK1vSdimiydUaVU2yGEYjh8NkyNI6c6niRN2Ot3xJuOiL7MLDXkYmg9nVH+Es29IWw3dsj+rmKOlnIc4jJ/NunGQXE73JG7A5VNxanFScr5C8+nM7MdhyKXEMWq26CZj0R5Ls+eGvPg85ADrPh++/SgdnH4MDh4pzHBTeiP1UCJBgieg3ZHarOf6vwrOuUcp29JHaWAhYymE+slxonFoD6q0HL0NWW73cEpGhTbKUyGgiNL4xKF+iBbPxXjd3KU4ySCNpNfgqotJHsXTA84gg7XYR4H7s/LQRpmtlWNQyeyReZxWcvfFCQf7JIYoUVA4alivUTW5oFgdOIWsppRl+wWIqxslO0CPdcEgIz2KV6XbO2wM7LHjl4c31CTtoWxxJFg1igWx4JjjNHOwNVDPx+f6XEhNMzYyWY0ZaGVCCZsF+4q8Ig4OYeOrJ9O+byQqCZmOCTFhWAdqYGiJqNUX5LlDUTvxVnuJE+C0xmw1zS6CwOpvnLo73rpOZjpFrM4iYUpRVxzHUXzn+C4adJPYNQnxodEbpN54FGr1/e8TNU6+gmrJGBto0OvY7du/+mrS7wRPVBUiUm9s1//11xyRqZ/4LnvaXu0PvjDV1Cw70UTv3Q8cosHSdTj5tA3Gl9j9zNgPUdf97p/YP8L82v9mfwAhhaKXkIrduQAAAABJRU5ErkJggg==">
                    <span class="ml-5">{{ $t('profile.partnership.referral_system') }}</span>
            </div>
            <ul class="airdrop-modal__requirements-list bonus-rules">
                    <li class="airdrop-modal__requirement">
                        <i18n path="profile.partnership.rule_1">
                            <template #link>
                                <span class="text_light_bold">{{ $t('profile.partnership.referral_link') }}</span>
                            </template>
                        </i18n>
                    </li>
                    <li class="airdrop-modal__requirement">
                        <i18n path="profile.partnership.rule_2">
                            <template #comission>
                                <span class="text_light_bold">{{ $t('profile.partnership.comission') }}</span>
                            </template>
                        </i18n>
                    </li>
                    <li class="airdrop-modal__requirement">{{ $t('profile.partnership.rule_3') }}
                    </li>
                    <li class="airdrop-modal__requirement">
                        <i18n path="profile.partnership.rule_4">
                            <template #bonus>
                                <span class="text_light_bold">{{ $t('profile.partnership.invitational') }}</span>
                            </template>
                        </i18n>
                    </li>
            </ul>
            <div class="level__requirement level__requirement_header mt-30">
                <div class="level__requirement-column level__requirement-column_level">{{ $t('profile.partnership.header_level') }}</div>
                <div class="level__requirement-column level__requirement-column_deposit-sum">{{ $t('profile.partnership.header_deposit_sum') }}</div>
                <div class="level__requirement-column level__requirement-column_bet-percent">{{ $t('profile.partnership.header_percent') }}</div>
                <div class="level__requirement-column level__requirement-column_limit_amount">{{ $t('profile.partnership.header_bonus') }}</div>
            </div>
            <div class="level_requirements">
                <div class="level__requirement dark-panel" :class="{'level__requirement_disabled': level.level > referral_level.level}" v-for="level in levels" :key="level.level">
                    <div class="level__requirement-column level__requirement-column_level">{{ level.level }}</div>
                    <div class="level__requirement-column level__requirement-column_deposit-sum">${{ level.deposits_sum.convertToDollars() }}</div>
                    <div class="level__requirement-column level__requirement-column_bet-percent">{{ level.comission_percent }}%</div>
                    <div class="level__requirement-column level__requirement-column_limit_amount">+{{ invitational_bonus }}% <span class="hide-md">{{ $t('profile.partnership.to_first_deposit') }}</span></div>
                </div>
             </div>
        </div>
    </div>
    <loader class="mt-30 mb-30" v-else />
</template>

<script>
import Loader from '../../misc/Loader.vue';
import ReferralBalance from './ReferralBalance.vue';
import * as API from '@/services/API';
import { mapGetters, mapState } from 'vuex';
import { processAPIError, processValidationError } from "@/util";

export default {
    components: { Loader, ReferralBalance },
    data() {
        return {
            loading: false,
            referral_link: '',
            deposits_by_referrals: 0,  
            referrals_count: 0,         
            current_referral_balance: 0,
            final_referral_percent: 0,
            total_referral_balance: 0,
            referral_level: {},
            referral_progress: 0,
            next_level: {},
            minimal_amount: 0,
            invitational_bonus: 0,
            levels: []
        }
    },

    created() {
        this.loading = true;
        return API.apiClient.get('profile/partnership')
        .then(response => {
            if (response.data.success) {
                this.referral_link = response.data.data.user_info.referral_link;
                this.deposits_by_referrals = response.data.data.user_info.deposits_by_referrals;
                this.referrals_count = response.data.data.user_info.referrals_count;
                this.current_referral_balance = response.data.data.user_info.current_referral_balance;
                this.final_referral_percent = response.data.data.user_info.final_referral_percent;
                this.total_referral_balance = response.data.data.user_info.total_referral_balance;
                this.referral_level = response.data.data.user_info.referral_level;
                this.referral_progress = response.data.data.user_info.referral_progress;
                this.next_level = response.data.data.user_info.next_level;
                this.minimal_amount = response.data.data.minimal_amount;
                this.invitational_bonus = response.data.data.invitational_bonus;
                this.levels = response.data.data.levels;
            }
            else {
                const errorData = processAPIError(response);
                this.$store.dispatch('notification/showError', errorData);
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            this.$store.dispatch('notification/showError', errorData);
        })
        .finally(() => {
            this.loading = false;
        });
    }
}
</script>

<style>

</style>