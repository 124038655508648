<template>
    <div :style="{'font-size': fontSize}" class="mini-profile__rank" :class="'mini-profile__rank_' + rankColor">{{ $t('rank.title') }} {{rank}}</div>
</template>

<script>
export default {
    props: {
        rank: Number,
        fontSize: {
            default: '10px',
            type: String
        }
    },
    computed: {
        rankColor() {
            if (this.rank == 1) return 'gray';
            if (this.rank <= 3) return 'blue';
            if (this.rank <= 5) return 'darkblue';
            if (this.rank <= 7) return 'pink';
            if (this.rank <= 9) return 'green';
            return 'red';
        }
    }
}
</script>
