<template>
    <div class="bet__helper">
        <div class="bet__help-wrapper" v-for="(helper, index) in helpers" :key="index">
            <input autocomplete="off" type="text" inputmode="numeric" class="bet__help-edit" :value="helper" v-if="editMode" @input="input($event, index)" :ref="`input-${index}`"/>
            <button :disabled="disabled" class="bet__help-btn" @click="clicked(helper)" v-else>{{ helper }}x</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['helpers', 'editMode', 'disabled'],
    methods: {
        clicked(number) {
            this.$emit('change', number.toFixed(2));
        },
        input(event, index) {
            let num = event.target.value;
            num = this.regexToNumber(num);
            this.$refs[`input-${index}`][0].value = num;
            this.helpers[index] = parseFloat(num);
        }
    }
}
</script>

<style>

</style>