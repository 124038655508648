<template>
    <modal :show="showModal" @close="closeModal" maxWidth="700px" closeClass="modal__close_with-header"> 
       <div class="modal__header modal__tabs">
           <div @click.stop="$router.push({query: {...$route.query, m: 'fairness'}})" class="modal__tab flex-align" :class="{'modal__tab_active': activeModal === 'fairness'}">
                <span class="icon-fair modal__tab-icon"></span> <span>{{ $t('fairness.fairness_header') }}</span>
           </div>
           <div @click.stop="$router.push({query: {...$route.query, m: 'fairness-check'}})" class="modal__tab flex-align" :class="{'modal__tab_active': activeModal === 'fairness-check'}">
                <span  class="icon-checked modal__tab-icon"></span> <span> {{ $t('fairness.check_header') }} </span>
           </div>
       </div>

       <div>
            <fairness-tab v-if="activeModal === 'fairness'"/>
            <fairness-check-tab v-if="activeModal === 'fairness-check'"/>
       </div>


    </modal>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import Modal from '@/misc/Modal';
import FairnessTab from './FairnessTab.vue';
import FairnessCheckTab from './FairnessCheckTab.vue';
import Loader from '@/misc/Loader.vue';

export default {
    components: {
        Modal,
        FairnessTab,
        Loader,
        FairnessCheckTab
    },
    computed: {
        activeModal() {
            return this.$store.state.modal.activeModal;
        },
        showModal() {
            return this.activeModal === 'fairness' ||  this.activeModal === 'fairness-check';
        }
    },

    created() {
       
    },

    data() {
        return {
        }
    },
    methods: {
        closeModal() {
            this.$router.replace({
                    ...this.$router.currentRoute,
                    query: {
                        ...this.$route.query,
                        client_seed: undefined,
                        server_seed: undefined,
                        selected_game: undefined,
                        id: undefined
                    }
                })
        }
    },
}
</script>