<template>
    <div class="boolean-control">
        <button class="boolean-control__btn" @click="$store.dispatch('sound/toggle')">
            <span class="icon-audio-on sound-icon" v-if="soundOn"></span>
            <span class="icon-audio-off sound-icon" v-else></span>
        </button>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters('sound', ['soundOn'])
    }
}
</script>