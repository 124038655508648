
import { processValidationError, processAPIError, processAPIErrorWithoutWrapper } from "@/util";
import config from '@/_config';
import * as API from '@/services/API';
import i18n from '@/i18n';
import Vue from 'vue';
import { useSound } from '@vueuse/sound'

const helper = localStorage.getItem('crash_helper');

export const namespaced = true;

const getDefaultState = () => {
    return {
        placingBetInProgress: false,
        cashoutInProgress: false,
        autoCashout: localStorage.getItem('last_coeff') || 2,
        userBet: null,
        state: null,
        coeff: 0,
        dataLoaded: false,
        history: [],
        _historyAnimations: false,
        init_state: null,

        bets: [],
        userBetInBets: [],

        current_client_seed: '',
        current_server_seed: '',
        previous_client_seed: '',
        previous_server_seed: '',

        helpers: helper ? JSON.parse(helper) : [1.2, 2, 5, 10, 50],
        helpersCopy: helper ? JSON.parse(helper) : [1.2, 2, 5, 10, 50],
        editMode: false,
        ping: null
    }
}

export const state = getDefaultState

export const mutations = {
    SET(state, {key, value}) {
        state[key] = value;
    },
    SET_PLACING_BET_IN_PROGRESS(state, value) {
        state.placingBetInProgress = value;
    },
    SET_CASHOUT_IN_PROGRESS(state, value) {
        state.cashoutInProgress = value;
    },
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET_DATA_LOADED(state, value) {
        state.dataLoaded = value;
    },
    SET_AUTO_CASHOUT(state, value) {
        state.autoCashout = value;
    },
    SET_USER_BET(state, value) {
        state.userBet = value;
    },
    SET_COEFF(state, value) {
        state.coeff = value;
    },
    SET_STATE(state, value) {
        state.state = value;
    },
    NEW_GAME(state) {
        state.coeff = 1;
    },
    SET_HISTORY(state, value) {
        state.history = value;
    },
    ADD_TO_HISTORY(state, value) {
        state.history.unshift(value);
        if (state.history.length >= 51)
            state.history.splice(50, state.history.length - 50);
    },
    SET_HISTORY_ANIMATIONS(state, value) {
        state._historyAnimations = value;
    },
    SET_BETS(state, value) {
        state.bets = value;
    },
    ADD_TO_BETS(state, value) {
        const idx = state.bets.findIndex(bet => bet.id === value.id);
        if (idx === -1) {
            state.bets.push(value);
        }
        else {
            Vue.set(state.bets, idx, value);
        }
    },
    SET_ALL_ACTIVE_BETS_LOST(state) {
        state.bets.forEach((bet, idx, arr) => {
            if (bet.result == 0) {
                Vue.set(arr, idx, Object.assign({}, bet, {
                    'result': 2,
                    'lost_at': state.coeff
                }));
            }
        });

        if (state.userBetInBets.length > 0 && state.userBet && state.userBet.status == 0) {
            if (state.userBet.auto_cashout <= state.coeff) {
                Vue.set(state.userBetInBets, 0, Object.assign({}, state.userBetInBets[0], {
                    'result'        : 1,
                    'cashout'       : state.userBet.auto_cashout,
                    'win_value'     : Math.floor(state.userBet.items_value * state.userBet.auto_cashout),
                    'win_item'      : null,
                }));
            }
            else {
                Vue.set(state.userBetInBets, 0, Object.assign({}, state.userBetInBets[0], {
                    'result': 2,
                    'lost_at': state.coeff
                }));
            }
        }

    },
    SET_USER_BET_IN_BETS(state, value) {
        state.userBetInBets = [value];
    },
    CLEAR_BETS(state) {
        state.userBetInBets = [];
        state.bets = [];
    },
    ENABLE_EDIT_MODE(state) {
        state.editMode = true;
    },
    CANCEL_SETTING(state) {
        state.editMode = false;
        state.helpersCopy = state.helpers.slice();
    },
    CONFIRM_SETTING(state) {
        let _localHelpersCopy = [];
        for (let i = 0; i < state.helpersCopy.length; ++i) {
            let num = parseFloat(state.helpersCopy[i]) || 0;
            num = Math.max(1.02, num);
            num = Math.min(970000, num);
            _localHelpersCopy.push(num);
        }
        state.helpersCopy = _localHelpersCopy;
        localStorage.setItem('crash_helper', JSON.stringify(state.helpersCopy));
        state.helpers = state.helpersCopy.slice();
        state.editMode = false;
    }
};

export const actions = {
    async changeState({ commit, dispatch, state }, new_state) {
        const prev_state = state.state;
        commit('SET_STATE', new_state);
        if (new_state == 0 && (prev_state !== null || state.init_state == 1)) {
            dispatch('setUserBet', null);
            commit('CLEAR_BETS');
            commit('NEW_GAME');
        }
    },
    
    reset({commit, dispatch}) {
        commit('RESET');
        dispatch('setPedestalItems', []);
        dispatch('setWinningItems', []);
    },
    crashed({ commit, dispatch }) {
        commit('SET_ALL_ACTIVE_BETS_LOST');
        dispatch('sound/play', 'crash_7', { root: true });

    },
    setHistory({commit}, history) {
        commit('SET_HISTORY', history);
        setTimeout(() => commit('SET_HISTORY_ANIMATIONS', true), 250);
    },
    setBets({ commit }, bets) {
        commit('SET_BETS', bets);
    },
    setLastCoeff({ commit }, coeff) {
        localStorage.setItem('lastCoeff', coeff);
    },
    setPedestalItems({commit, state}, items) {
        if (items.length == 0 || state.state == 0 || state.state == null) {
            commit('stash/SET_PEDESTAL_ITEMS_FROM_GAME', items, { root: true });
        }
    },

    setPossibleWinningItems({commit, state}, items) {
        if (items.length == 0 || state.state == 1) {
            commit('stash/SET_PEDESTAL_ITEMS_FROM_GAME', items, { root: true });
        }
    },

    setWinningItems({commit, state}, items) {
        commit('stash/SET_PEDESTAL_ITEMS_WIN', items, { root: true });
    },


    setUserBet({ commit, dispatch, rootGetters  }, bet) {
        commit('SET_USER_BET', bet);
        if (bet !== null) {
            commit('SET_AUTO_CASHOUT', parseFloat(bet.auto_cashout).toFixed(2));
            
       
      
            commit('SET_USER_BET_IN_BETS', {
                'id'            : bet.id,
                'user'          : rootGetters['auth/authUser'].username,
                'pic'           : rootGetters['auth/authUser'].picture,
                'unique_id'     : rootGetters['auth/authUser'].id,
                'result'        : (bet.is_win == 0 && bet.status == 1) ? 2 : bet.status,
                'cashout'       : bet.cashout,
                'win_value'     : bet.win_value,
                'win_item'      : bet.win_item,
                'items_value'   : bet.items_value,
                'items'         : bet.items 
            });

            dispatch('setPedestalItems', bet.items);
            if (bet.is_win) {
                dispatch('setWinningItems', [bet.win_item]);
            }
        }
        else {
            dispatch('setPedestalItems', []);
            dispatch('setWinningItems', []);
        }
    },
    bet({ commit, state, rootState, dispatch }) {
        if (state.placingBetInProgress) return;
        commit('SET_PLACING_BET_IN_PROGRESS', true);
        API.apiClient.post('crash/bet', {
            bet_items: rootState.stash.userStashSelected.map(item => item.pivot_id),
            auto_cashout: state.autoCashout
        })
        .then(response => {
            if (response.data.success) {
                dispatch('setUserBet', response.data.data.bet);
                dispatch('stash/setStash', response.data.data.items, { root: true });
                dispatch('sound/play', 'bet', { root: true });
            
                // dispatch('notification/show', {type: 'success', message: i18n.t('crash.successful_bet')}, { root: true });
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET_PLACING_BET_IN_PROGRESS', false);  
        });
    },

    cashout({ dispatch, state, rootGetters }) {
        let uuid = null; let sign = null;
        if (rootGetters['auth/authUser'] && rootGetters['auth/authUser'].uuid) uuid = rootGetters['auth/authUser'].uuid;
        if (state.userBet && state.userBet.sign) sign = state.userBet.sign;
        if (uuid && sign && this._vm.$socket.connected)
            dispatch('cashoutViaSocket', {uuid: uuid, sign: sign});
        else 
            dispatch('cashoutViaHttp')
    },

    cashoutViaSocket({ commit, dispatch, state, rootGetters }, {uuid, sign}) {
        if (state.cashoutInProgress) return;
        commit('SET_CASHOUT_IN_PROGRESS', true);
        this._vm.$socket.client.emit('cashout', {
            uuid: uuid,
            sign: sign
        });
    },

    socket_crashCashout({ commit, dispatch }, data) {
        if (data.success) {
            dispatch('setWinningItems', [data.data.bet.win_item]),
            dispatch('setUserBet', data.data.bet);
            dispatch('stash/setStash', data.data.items, { root: true });
            dispatch('sound/play', 'win_8', { root: true });

        }
        else {
            if (data.error) {
                if (data.error == 'crash.going_to_autocashout') {
                    dispatch('notification/show', {type: 'success', message: i18n.t(data.error)}, { root: true });
                }
                else {
                    const errorData = processAPIErrorWithoutWrapper(data.error);
                    dispatch('notification/showError', errorData, {root: true});
                }
            }
            else {
                const errorData = processValidationError(data);
                dispatch('notification/showError', errorData, {root: true});
            }
        }
        commit('SET_CASHOUT_IN_PROGRESS', false);  
    },

    cashoutViaHttp({ commit, dispatch, state }) {
        if (state.cashoutInProgress) return;
        commit('SET_CASHOUT_IN_PROGRESS', true);
        API.apiClient.post('crash/cashout')
        .then(response => {
            if (response.data.success) {
                dispatch('setWinningItems', [response.data.data.bet.win_item]),
                dispatch('setUserBet', response.data.data.bet);
                dispatch('stash/setStash', response.data.data.items, { root: true });
                dispatch('sound/play', 'win_8', { root: true });

            }
            else {
                if (response.data.error == 'crash.going_to_autocashout') {
                    dispatch('notification/show', {type: 'success', message: i18n.t(response.data.error)}, { root: true });
                }
                else {
                    const errorData = processAPIError(response);
                    dispatch('notification/showError', errorData, {root: true});
                }
                
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
        .finally(() => {
            commit('SET_CASHOUT_IN_PROGRESS', false);  
        });
    },

    init({ commit, state, rootState, dispatch }) {
        API.apiClient.get('crash/data')
        .then(response => {
            if (response.data.success) {
                dispatch('setUserBet', response.data.data.bet);
                dispatch('setHistory', response.data.data.history);
                dispatch('setBets', response.data.data.bets);
                commit('SET', {key: 'init_state', value: response.data.data.state})
                commit('SET', {key: 'current_client_seed', value: response.data.data.current_seeds.client});
                commit('SET', {key: 'current_server_seed', value: response.data.data.current_seeds.server});
            }
        })
        .finally(() => {
            commit('SET_DATA_LOADED', true);
        });
    },

    socket_crashBet({ commit, rootGetters }, data) {
        if (!rootGetters['auth/loggedIn'] || (data.data.unique_id != rootGetters['auth/authUser'].id)) {
            commit('ADD_TO_BETS', data.data);
        }
    }

};

export const getters = {
    getModeID() {
        return 1;
    },
    betIsPlaced: (state) => {
        return state.userBet != null && state.userBet.status == 0;
    },
    winningBet: (state) => {
        if (state.userBet != null && state.userBet.is_win) return state.userBet;
        return null;
    },
    betIsWin: (state, getters) => {
        return getters.winningBet != null;
    },
    gameIsInProgress: (state) => {
        return state.state != 0;
    },
    canCashout: (state, getters) => {
        return getters.betIsPlaced && state.state == 1;
    },
    autoCashoutLocked: (state, getters) => {
        return getters.betIsPlaced && (state.state == 0 || state.state == 1); 
    },
    cashout: (state, getters) => {
        if (getters.betIsPlaced) {
            return Math.floor(state.userBet.items_value * state.coeff);
        }
    },
    bets: (state) => {
        const sorted = [...state.bets].sort((a, b) => b.items_value - a.items_value).slice(0, 20);
        return state.userBetInBets.concat(sorted);
    },
    playersCount: (state) => {
        return state.bets.length + state.userBetInBets.length;
    },
    itemsCount: (state) => {
        const reducer = (acc, val) => acc + val.items.length;
        return state.bets.reduce(reducer, 0) + state.userBetInBets.reduce(reducer, 0);
    },
    itemsValue: (state) => {
        const reducer = (acc, val) => acc + val.items_value;
        return state.bets.reduce(reducer, 0) + state.userBetInBets.reduce(reducer, 0);
    },
    current_client_seed: (state) => {
        return state.current_client_seed;
    },
    current_server_seed: (state) => {
        return state.current_server_seed;
    },
    previous_client_seed: (state) => {
        if (state.history.length == 0) return '';
        return state.history[0].client_seed;
    },
    previous_server_seed: (state) => {
        if (state.history.length == 0) return '';
        return state.history[0].server_seed;
    },
};