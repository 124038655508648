<template>
  <div @click.stop="openModal" class="airdrop" :class="!active ? 'airdrop__not-active' : ''">
        <div class="airdrop__image">
            <span class="icon-airdrop airdrop__icon"></span>
        </div>
        <div class="airdrop__title">
            {{ $t('airdrop.name') }}
            <span v-if="!active">
                {{ $t('airdrop.not_active') }}
            </span>
        </div>

        

        <div v-if="active" class="airdrop__requirements">
            <div v-for="index in 4" :key="index" class="airdrop__requirement" :class="index <= satisfiedRequirementsCount ? 'airdrop__requirement_active' : ''"></div>
        </div>
        <div v-if="active" class="airdrop__timer">
            {{ minutes }} : {{ seconds }}
        </div>
        <span v-if="active" class="airdrop__arrow icon-right-arrow"></span>
     
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
    data() {
        return {
            timer: 0,
            
            interval: null
        }
    },
    computed: {
        ...mapState('airdrop', {timeLeft: 'timeLeft', active: 'active'}),
        ...mapGetters('airdrop', {satisfiedRequirementsCount: 'satisfiedRequirementsCount'}),
        hours: {
            get() {
                return this.$store.state.airdrop.hours;
            },
            set(val) {
                this.$store.commit('airdrop/SET_HOURS', val);
            } 
        },
        minutes: {
            get() {
                return this.$store.state.airdrop.minutes;
            },
            set(val) {
                this.$store.commit('airdrop/SET_MINUTES', val);
            } 
        },
        seconds: {
            get() {
                return this.$store.state.airdrop.seconds;
            },
            set(val) {
                this.$store.commit('airdrop/SET_SECONDS', val);
            } 
        }
    },
    created() {
        // this.startTimer(this.timeLeft);
    },
    methods: {
        openModal() {
            if (this.active)
                this.$store.commit('airdrop/SET_MODAL_OPENED', true);
        },
        startTimer(duration) {
            let start = Date.now(),
                diff,
                hours,
                minutes,
                seconds;

            if (duration > 0) {
                const timer = () => {
                    diff = duration - (((Date.now() - start) / 1000) | 0);
                    hours   = (diff / 3600) | 0;
                    minutes = ((diff - hours * 3600) / 60) | 0;
                    seconds = (diff % 60) | 0;

                    if (hours < 0) hours = 0;
                    if (minutes < 0) minutes = 0;
                    if (seconds < 0) seconds = 0;
                    
                    hours   = hours < 10 ? "0" + hours : hours;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    seconds = seconds < 10 ? "0" + seconds : seconds;
                    
                    this.hours = hours;
                    this.minutes = minutes;
                    this.seconds = seconds;

                    if (diff <= 0) {
                        if (this.interval) clearInterval(this.interval);
                    }
                };
                timer();
                this.interval = setInterval(timer, 1000);
            }

        }
    },
    watch: {
        timeLeft(val) {
            clearInterval(this.interval);
            this.startTimer(val);
        }
    }
}
</script>

<style>

</style>