<template>
    <div class="bet">
        <mq-layout mq="lg+">
            <pedestal class="hide-lg" />
        </mq-layout>
        <div class="bet__bet">
            <!-- <bet-sum-field /> -->
            <crash-input :min="minCashout" :max="maxCashout"/>
            <crash-button class="hide-lg" />

        </div>
        <div class="bet__footer-menu show-lg" @click="miscOpened = !miscOpened" :class="{'bet__footer-menu_active': miscOpened}">
                <span class="icon-down-arrow bet__footer-menu-arrow"></span>
        </div>

        <div class="show-lg">
            <transition name="slide-down">
                    <div class="bet__footer" v-show="miscOpened">
                        <crash-hints />
                    </div>
            </transition>
        </div>
        <div class="hide-lg">
            <div class="bet__footer">
                <crash-hints />
            </div>
        </div>
    </div>
</template>

<script>
import CrashInput from '../../components/crash/CrashInput.vue';
import Pedestal from '../../components/bet/Pedestal.vue';
import CrashButton from './CrashButton.vue';
import { mapGetters, mapState } from 'vuex'
import debounce from 'debounce';
import _ from 'lodash';
import config from '@/_config';
import {throttle} from 'throttle-debounce';
import CrashHints from './CrashHints.vue';

export default {
    data() {
        return {
            miscOpened: false
        }
    },
    components: {
        CrashInput,
        Pedestal,
        CrashButton,
        CrashHints
    },
    computed: {
        ...mapState('crash', { loading: 'placingBetInProgress', loadingCashout: 'cashoutInProgress', gameDataLoaded: 'dataLoaded', userBet: 'userBet' }),
        ...mapState('stash', { userStashSelectedAmount: 'userStashSelectedAmount' }),
        ...mapGetters('crash', { betIsPlaced: 'betIsPlaced', gameIsInProgress: 'gameIsInProgress', canCashout: 'canCashout', cashout: 'cashout', winningBet: 'winningBet', betIsWin: 'betIsWin' }),
        minCashout() {
            return config.crash.minCashout;
        },
        maxCashout() {
            return config.crash.maxCashout;
        }
    },
    methods:{
        bet() {
            this.$store.dispatch('crash/bet');
        },

        makeCashout() {
            this.$store.dispatch('crash/cashout');
        },

        findItemForPedestal: throttle(300,
            function (val)  {
                if (val) {
                    let item = this.$store.state.market.marketItems[this.$store.state.market.marketItems.length - this.binarySearch(this.$store.state.market.marketPrices, val) - 1];
                    this.$store.dispatch('crash/setPossibleWinningItems', [item]);
                }
            })    
        },
    watch: {
        cashout(val, oldVal) {
            if (val != oldVal)
                this.findItemForPedestal(val);
        }
    },
    
}
</script>

