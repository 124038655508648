<template>
    <div class="notifications" v-click-outside="hideNotifications">
        <button class="icon-btn notifications__btn" :class="{'notifications__btn_active': hasUnreadNotifications}" @click="toggle">
            <span class="icon-notification"></span>
        </button>
        <transition enter-active-class="animate__animated animate__fadeIn animate__faster"
                    leave-active-class="animate__animated animate__fadeOut animate__faster">
            <div class="notifications__list" v-show="notificationsVisible">
                <overlay-scrollbars
                style="max-height: 400px"
                :options="scrollbarOptions"
                >
                    <div class="notifications__scrollable" v-if="notifications.length > 0">
                        <div class="notification__item" v-for="(notification, index) in notifications" :key="index">
                            <div class="notification__name" :class="{'notification__name_unread': !notification.is_read}">{{ $t(notification.title) }}</div>
                            <div class="notification__body">{{ $t(notification.body, notification.params) }}</div>
                            <div class="notification__date">{{ beautifyDateTime(notification.created_at) }}</div>
                        </div>
                    </div>
                    <div class="notifications__scrollable notifications_empty" style="height: 150px;" v-else>
                        <div class="market-empty__pic">
                            <span class="icon-lifebuoy"></span>
                        </div>
                        <div class="market-empty__text">
                            {{ $t('notifications.empty') }}
                        </div></div>
                            </overlay-scrollbars>
                        </div>
        </transition>
        
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { beautifyDateTime } from '@/util';

export default {
    data(){
        return {
            scrollbarOptions: {
                className : 'os-theme-thin-light'
            },
            notificationsVisible: false,
            timeout: null
        }
    },
    computed: {
        ...mapState('notifications', ['notifications']),
        ...mapGetters('notifications', ['hasUnreadNotifications']),
    },
    methods: {
        toggle() {
            
            this.notificationsVisible = !this.notificationsVisible
        },
        beautifyDateTime(ts) {
            return beautifyDateTime(ts);
        },
        hideNotifications() {
            if (this.notificationsVisible) this.notificationsVisible = false;
        }
    },
    watch: {
        notificationsVisible(val) {
            if (val) {
                this.timeout = setTimeout(() => {
                    this.$store.dispatch('notifications/read');
                }, 200);
            }
            else {
                if (this.timeout) clearTimeout(this.timeout);
            }
        }
    }
}
</script>

