import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';

export const namespaced = true;

const getDefaultState = () => {
    return {
        loading: false,
        seedsModalOpened: false
    }
}

export const state = getDefaultState

export const mutations = {
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET(state, {key, value}) {
        state[key] = value;
    },
};

export const actions = {
    
};

export const getters = {

};