import Vue from 'vue';
import allItems from '../items.json';
import config from '@/_config';
import { DateTime } from 'luxon';
import i18n from '@/i18n';

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

Vue.mixin({
    methods: {
        rarityClass(rarity) {
            if (rarity == 11 || rarity == 12) return 'common';
            if (rarity == 6) return 'uncommon';
            if (rarity == 7 || rarity == 13 || rarity == 3) return 'rare';
            if (rarity == 8 || rarity == 14 || rarity == 15) return 'mythical';
            if (rarity == 9 || rarity == 4 || rarity == 2) return 'legendary';
            if (rarity == 5 || rarity == 10 || rarity == 1) return 'ancient';
            if (rarity == 0) return 'contraband';
        },
        exteriorName(exterior) {
            if (exterior == 0) return '';
            if (exterior == 1) return 'Battle-Scarred';
            if (exterior == 2) return 'Factory New';
            if (exterior == 3) return 'Field-Tested';
            if (exterior == 4) return 'Minimal Wear';
            if (exterior == 5) return 'Well-Worn';
        },
        randomItem() {
            return allItems[Math.floor(Math.random() * allItems.length)];
        },
        pathToItemImage(id) {
          return config.backend.auth_url + '/assets/static/skins/medium/' + id + '.png';
        },
        
        regexToNumber(input) {
          let output = input.replace(/[^0-9.]/g,'').split('.');
          return output.shift() + (output.length ? '.' + output.join('') : '');
        },

        binarySearch(array, sValue, ARG_start, ARG_len) {
          var start = (ARG_start === void 0 ? 0 : ARG_start) | 0;
          var len = (ARG_len === void 0 ? (array.length|0) - start : ARG_len) |0;
          len = len - 1 |0;
          
          if (len & 0x80000000) {
            const nCB = len & 0x80000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x40000000) {
            const nCB = len & 0xc0000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x20000000) {
            const nCB = len & 0xe0000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x10000000) {
            const nCB = len & 0xf0000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x8000000) {
            const nCB = len & 0xf8000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x4000000) {
            const nCB = len & 0xfc000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x2000000) {
            const nCB = len & 0xfe000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x1000000) {
            const nCB = len & 0xff000000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x800000) {
            const nCB = len & 0xff800000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x400000) {
            const nCB = len & 0xffc00000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x200000) {
            const nCB = len & 0xffe00000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x100000) {
            const nCB = len & 0xfff00000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x80000) {
            const nCB = len & 0xfff80000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x40000) {
            const nCB = len & 0xfffc0000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x20000) {
            const nCB = len & 0xfffe0000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x10000) {
            const nCB = len & 0xffff0000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x8000) {
            const nCB = len & 0xffff8000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x4000) {
            const nCB = len & 0xffffc000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x2000) {
            const nCB = len & 0xffffe000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x1000) {
            const nCB = len & 0xfffff000;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x800) {
            const nCB = len & 0xfffff800;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x400) {
            const nCB = len & 0xfffffc00;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x200) {
            const nCB = len & 0xfffffe00;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x100) {
            const nCB = len & 0xffffff00;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x80) {
            const nCB = len & 0xffffff80;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x40) {
            const nCB = len & 0xffffffc0;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x20) {
            const nCB = len & 0xffffffe0;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x10) {
            const nCB = len & 0xfffffff0;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x8) {
            const nCB = len & 0xfffffff8;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x4) {
            const nCB = len & 0xfffffffc;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x2) {
            const nCB = len & 0xfffffffe;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          if (len & 0x1) {
            const nCB = len & 0xffffffff;
            len ^= (len ^ (nCB-1)) & ((array[start+nCB|0] <= sValue |0) - 1 >>>0);
          }
          
          return start + len |0;
        }
    },
    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          timeout = null;
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }
})

Vue.filter('format_coef', {
    read: function (val) {
       return val.toFixed(2);
    },
    write: function (val, oldVal) {
      const number = +val.replace(/[^\d.,]/g, '');
      return isNaN(number) ? 0 : parseFloat(number.toFixed(2))
    }
  })

Number.prototype.convertToDollars = function() {
    return (this / 100).toFixed(2);
}

export const beautifyTimestamp = (ts) => {
  return DateTime.fromSeconds(ts).toFormat('dd.LL.yyyy HH:mm:ss')
}

export const validateLogin = (login) => {
  if (login.length < 3) {
      return i18n.t('validation.login_min_length', {value: 3});
  }
  if (login.length > 16) {
      return i18n.t('validation.login_max_length', {value: 16});
  }
  if (!(/^[a-z0-9_\-]+$/i.test(login))) {
      return i18n.t('validation.login_alpha_dash');
  }
  return '';
}

export const currencyConvertRate = (to) => {
    const _fallbackRates = {
      'RUB': 75,
      'UAH': 27,
      'EUR': 0.85,
      'BYN': 2.5,
      'KZT': 420
    };
    let rates = localStorage.getItem('currency_rates');
    if (rates) rates = JSON.parse(rates);
    else rates = _fallbackRates;

    if (rates[to]) return rates[to];

    return 1;
}

export const getCurrencySign = (currency) => {
    if (currency === 'RUB') return '₽';
    if (currency === 'USD') return '$';
    if (currency === 'UAH') return '₴';
    if (currency === 'EUR') return '€';
    return '$';
}

export const validatePassword = (password) => {
  if (password.length < 8) {
      return i18n.t('validation.password_min_length', {value: 8});
  }
  if (password.length > 100) {
      return i18n.t('validation.password_max_length', {value: 100});
  }
  const pwdRules = (password.search(/\d/) !== -1) 
                  && (password.search(/[a-z]/) !== -1) 
                  && (password.search(/[A-Z]/) !== -1);
  if (!pwdRules) {
      return i18n.t('validation.password_rules');
  }
  return '';
}

export const validatePasswordConfirmation = (passwordConfirmation, password) => {
  if (passwordConfirmation !== password) {
      return i18n.t('validation.passwords_match');
  }
  return '';
}

export const validateEmail = (email) => {
    if (! /\S+@\S+\.\S+/.test(email)) {
        return i18n.t('profile.account.wrong_email');
    }
    return '';
}



export const beautifyDateTime = (dt) => {
    return DateTime.fromISO(dt).toFormat('dd.LL.yyyy HH:mm:ss');
}

export const getError = (error) => {
  const errorMessage = "Whoops, some error occured. Our team is investigating...";

  if (!error.response) {
    console.error(`API ${error.config.url} not found`);
    return errorMessage;
  }
  if (process.env.NODE_ENV === "development") {
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  }
  if (error.response.data && error.response.data.errors) {
    return error.response.data.errors;
  }
  return errorMessage;
};

export const getErrorsFlatten = (error) => {
  const errorMessage = ['errors.generic'];

  if (!error.response) {
    return ['errors.internal'];
  }
  if (process.env.NODE_ENV === "development") {
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  }

  if (error.response.status === 429) {
      return ['errors.too_many_requests'];
  }
  if (error.response.status === 401) {
    return ['errors.forbidden'];
  }
  if (error.response.status === 403) {
    return ['errors.permission_denied'];
  }
  if (error.response.data && error.response.data.errors) {
    let _errors = [];
    for (let key in error.response.data.errors) {
        _errors.push(...error.response.data.errors[key]);
    }
    return _errors;
  }
  return errorMessage;
};

export const processAPIErrorWithoutWrapper = (error) => {
  return _processError(error);
}

export const processAPIError = (response) => {
    return _processError(response.data.error);
}

export const processValidationError = (error) => {
    let errors = getErrorsFlatten(error);
    return _processError(errors[0]);
}

const _processError = (error) => {
  let errorShort = error;
  let errorParams = {};
  if (errorShort.includes('-')) {
    errorParams['value'] = errorShort.split('-')[1].split('&')[0];
    errorShort = errorShort.split('-')[0];
  }
  if (error.includes('&')) {
    errorParams['value1'] = error.split('&')[1];
  }
  return [errorShort, errorParams];
}