<template>
    <div class="login__social">
        <a class="login__social-btn" :href="`${hostname}/auth/steam${referrer_token}`">
            <span class="icon-steam"></span>
        </a>
        <a class="login__social-btn" :href="`${hostname}/auth/vkontakte${referrer_token}`">
            <span class="icon-vk"></span>
        </a>
        <a class="login__social-btn" :href="`${hostname}/auth/twitch${referrer_token}`">
            <span class="icon-twitch"></span>
        </a>
        <a class="login__social-btn" :href="`${hostname}/auth/google${referrer_token}`">
            <span class="icon-google"></span>
        </a>
        <a class="login__social-btn" :href="`${hostname}/auth/facebook${referrer_token}`">
            <span class="icon-facebook"></span>
        </a>
    </div>
</template>

<script>
import config from '@/_config';

export default {
    data() {
        return {
            isLoggingIn: false
        }
    },
    computed: {
        hostname() {
            return config.backend.auth_url;
        },
        referrer_token() {
            if (localStorage.getItem('rp__referral__code')) {
                return '?referrer_token=' + encodeURIComponent(localStorage.getItem('rp__referral__code'));
            }
            return '';
        }
    }
}

</script>

<style>

</style>