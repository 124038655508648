<template>
    <modal :show="showModal" @close="closeModal" class="modal__login-wrapper"> 
        <div class="modal__login">
            <div class="login__image">
                <!-- <img src="/static/images/loginart.png" /> -->
            </div>
            <div class="login__content">
                <div class="login__header">
                    <div class="login__login login__header-option" :class="{'login__header-option_active' : showLogin}" @click.stop="!showLogin ? $router.push('?m=login') : {}">{{ $t('auth.login') }}</div>
                    <div class="login__register login__header-option" :class="{'login__header-option_active' : showSignUp}" @click.stop="!showSignUp ? $router.push('?m=signup') : {}">{{ $t('auth.signup') }}</div>
                </div>

                <login-tab v-if="showLogin"></login-tab>
                <register-tab v-else></register-tab>
            </div>
            
        </div>
    </modal>
</template>

<script>
import Modal from '../../misc/Modal';
import LoginInput from '../login/LoginInput.vue';
import LoginTab from './LoginTab';
import RegisterTab from './RegisterTab.vue';

export default {
    components: {
        Modal,
        LoginInput,
        LoginTab,
        RegisterTab
    },
    computed: {
        showLogin() {
            return this.$store.state.modal.activeModal === 'login';
        },
        showSignUp() {
            return this.$store.state.modal.activeModal === 'signup';
        },
        showModal() {
            return this.showLogin || this.showSignUp;
        }
    },
    data() {
        return {
           
        }
    },
    methods: {
        closeModal() {
            this.error = null;
            this.password = '';
            this.login = '';
            
        }
    }
}
</script>