export default {
    ENG: {
        header: {
            deposit: 'Deposit',
            fair: 'Provably fair',
            balance: 'Balance'
        },
        settings: {
          header: 'Settings',
          show_bets: 'Show bets',
          show_avatars: 'Show avatars',
          show_usernames: 'Show usernames',
        },
        nav: {
            home: 'Home',
            bonus: 'Bonus',
            support: 'Support',
            help: 'Help',
            fair: 'Provably fair',
            terms: 'Terms and conditions',
            privacy: 'Privacy policy',
        },

        airdrop: {
            name: 'Airdrop',
            not_active: 'is not active now',
            modal_description: 'When timer runs out, {count} random people out of pool are going to get random skin worth {price}',
            requirements: 'Requirements:',
            req_online: 'Be online',
            req_games: 'Play {value} games',
            req_chat: 'Be active in chat',
            hours: 'hours',
            req_messages: 'Send {value} messages in chat',
            req_deposit: 'Have at least one deposit in last {value}',
            your_status: 'Your status',
            status_not_in_pool: 'Not in the pool',
            status_in_pool: 'In the pool',
            won_in_airdrop: 'Just caught the {what} and received skins worth {value}',
            minutes: 'minutes',
            seconds: 'seconds' 
        },

        auth: {
            login: 'Sign in',
            login_placeholder: 'Enter login',
            password_placeholder: 'Enter password',
            forgot_password: 'Forgot your password?',
            signing_in: 'Signing in...',
            by_clicking: 'By clicking Log in, you agree to our',
            auth_error: 'You entered wrong credentials, please try again',
            
            signup: 'Sign up',
            register_login_placeholder: 'Enter login',
            register_password_placeholder: 'Enter password',
            register_password_confirm: 'Confirm password',
            signing_up: 'Signing up...',
            login_taken: 'This login is already taken',
            wrong_data: 'Seems like you entered incorrect data. Please try again or contact support',
            

            error_during_auth: 'Some error occured during authorization. Please, try again',
            already_linked: 'This account is already linked to other user',
            successfully_linked: 'Successfully linked account',
            email_verified: 'Email verified',
            reset_password: 'Password reset',
            reset_button: 'Reset',
            reset_link_sent: 'Reset link has been sent to your email. Please, check your inbox.',
            reset_successful: 'Your password has been reset. You can log in using new password'
        },
        passwords: {
            user: 'User with given email does not exist',
            throttled: 'We have already sent you reset link',
            token: 'Link expired. Please, request another reset link'
        },

        payment: {
          qiwi: {
            heading: 'QIWI payment',
            wallet_number: 'Wallet number for transfer',
            comment: 'Comment (mandatory!)',
            amount: 'Transfer sum (₽)',
            warning_1: 'You should fill transfer form <span class="text_light_bold">EXACTLY</span> like shown below, otherwise payment <span class="text_danger">WILL NOT BE PROCESSED</span>',
            warning_2: 'The value in the <span class="text_light_bold">"Amount"</span> field must match the value below <span class="text_light_bold">UP TO KOPECKS</span>',
            warning_3: 'To <span class="text_light_bold">change amount</span> or <span class="text_light_bold">repeat payment</span> - <span class="text_light_bold">CLOSE THIS WINDOW</span> and create order again',
            success: 'The order has been payed! May good luck be with you!',
            instruction: 'Go to your QIWI Wallet and make <span class="text_light_bold">a transfer to the QIWI wallet</span>, filling in the transfer details <span class="text_light_bold">exactly as shown below </span>',
            after_1: 'Payment will be credited automatically <span class="text_light_bold">within a few minutes</span>',
            status_text: 'Order status',
            status_waiting: 'Awaiting payment'
          }
        },

        captcha: {
          failed: 'You didn\'t pass CAPTCHA',
        },

        bonus: {
            requirements: 'Requirements',
            activate_below: 'activate code below',
            satisfy: 'To use promocodes, you need to satisfy all requirements',
            activate: 'Activate',
            promo_placeholder: 'Enter your code...',
            follow_us: 'We publish promocodes in our social media channels: {value}. Make sure to subscribe and allow notifications!',
            promocode: 'Promocode',
            tasks: 'Tasks',
            steam_linked: 'You need to link your steam account.',
            steam_public: 'Visibility of your steam account must be public.',
            steam_level_is_ok: 'Your level in steam must be at least {value}.',
            played_hours_is_ok: 'You have to play at least {value} hours in CS:GO.',
            app_in_username: 'You need to include {value} in your steam username.',
            link_here: 'Link here',
            having_problems: 'Having problems?',
            csgo_hours_help: 'Go to Steam Profile — Privacy Settings — Game Details and set it to Public',
            promocode_footer: 'You will need to log out and sign in again via Steam for changes to apply',
            app_in_username_help: 'After you changed your username, you will need to sign in again via Steam and wait for {value} minutes',
            promo_does_not_exits: 'This promocode does not exist',
            promo_activations_exhausted: 'Unfortunately, activations ended',
            already_used: 'You already used this promocode',
            not_all_requirements_satisfied: 'You need to fulfill all requirements to use promocodes',
            limit_exhausted: 'You have reached the limit of promocodes used. See more details in your profile',
            successful_activation: 'Promocode activated',
            sub_here: 'Subscribe here',
            wrong_code: 'Invalid code',
            promo_min_dep: 'Min deposit with promo: {value}',
            inventory_contains_bonus: 'You already have bonus item in your inventory. Please, use it first and then come back for more bonuses'
        },
        fairness: {
            prove: 'Prove fairness',
            fairness_header: 'Provably fair',
            check_header: 'Check',
            how_it_works: 'How it works',
            how_it_works_crash: 'Crash results',
            paragraph_1: 'We designed provably fair system to accomplish {goals}\
            First, we want to prove that the results of any game have been {before} the game started.\
            Second, we want to prove that results have been {randomly}, with no bias or ability to pre-choose it.',
            goals: 'two main goals.',
            generated_randomly: 'generated randomly',
            generated_before: 'generated before',
            paragraph_2: 'Before each round, we actually give you the input parameters for random number generator in obfuscated format.\
            Before the round, we give you 2 seeds: {client_seed} and {server_seed}.\
            Client seed is given unchanged, as it is.\
            Server seed is given obfuscated, you can see only first half of the server seed, the second half is revealed after the round ends.\
            Then, using both seeds, you can {reproduce_calculation} of game results and prove that the results were {pre_generated}.\
            Our random number generator takes 2 seeds as input parameters and produces result {deterministically} (same inputs produce same output) using following algorithm:',
            paragraph_2_client_seed: 'client seed',
            paragraph_2_server_seed: 'server seed',
            paragraph_2_reproduce_calculation: 'reproduce calculation',
            paragraph_2_pre_generated: 'fair and pre-generated',
            paragraph_2_deterministically: 'deterministically',
            paragraph_3: 'Here, {cursor}, but in case we need to generate more bytes, we repeat step 2 with cursor incremented by 1.\
            Generated bytes are from {random_distribution} (one can prove it by using properties of hash functions).\
            The bytes then converted into a sequence of {real_numbers}\
            These numbers are then used to generate game result.',
            paragraph_3_cursor: 'cursor is initially 0',
            paragraph_3_random_distribution: 'random distribution',
            paragraph_3_real_numbers: '8 real numbers in range [0..1].',
            paragraph_4: 'To generate result of the crash round, we take first random number from generated sequence and calculate the result using given formula, which produces exponentially distributed random numbers from interval {between}',
            seeds_to_bytes: 'Seeds to bytes',
            bytes_to_numbers: 'Bytes to numbers',
            numbers_to_result: 'Numbers to game results',
            paragraph_5: 'Since crash is a multiplayer game, we use constant client seed for each round (in one-player games, a personal client seed is used).\
            This client seed is the hash of {block}\
            {hash}. We have chosen it {elon} at random.',
            paragraph_5_block: 'Dogecoin block #3737532',
            paragraph_5_elon: 'because Elon Musk told us so',
            seeds_control: 'Seeds control',
            current_client_seed: 'Current client seed',
            current_server_seed: 'Current server seed (half)',
            previous_client_seed: 'Previous client seed',
            previous_server_seed: 'Previous server seed (full)',
            calculate_previous: 'Calculate previous game',
            header_game: 'Game',
            header_client_seed: 'Client seed',
            header_server_seed: 'Server seed',


        },
        notifications: {
            empty: 'No recent notifications',
            titles: {
                withdraw_sent: 'Item sent',
                withdraw_failed: 'Withdrawal failed',
                deposit: 'Successful deposit'
            },
            bodies: {
                withdraw_sent: 'Item {item} has been sent to you',
                withdraw_failed: 'Item {item} is unlocked, you can resend it',
                deposit: 'You successfully deposited ${amount}. May good luck be with you!'
            }
        },
        faq: {
            talk_to_operator: 'I need an operator',
            make_sure: 'Please, make sure to read FAQ below and contact operator only if your question is not listed.',
            questions: {
                general: {
                    q1: 'What information do you have access to when I am logged in via social accounts?',
                    q2: 'I received mute in chat',
                    q3: 'How do I get free money?',
                    q4: 'Can I register multiple accounts?',
                    q5: 'Do you have fair game system?',
                    q6: 'I forgot my password',
                    q7: 'Prices on your market differ from Steam market',
                    q8: 'I want to give money/skins to other user',
                    q9: 'I want to register another account',
                    q10: 'I found a bug'
                },
                deposit: {
                    q1: 'I have payed the invoice, but money did not arrive',
                    q2: 'I want to make a deposit, but you do not have my payment method listed',
                    q3: 'Where do I get promocodes for deposit',
                },
                withdraw: {
                    q1: 'I am getting error "{error}"',
                    q2: 'Item is not released after 5 minutes (timer hangs at 00:00)',
                    q3: 'I am getting error "You must wager your deposit to withdraw."',
                    q4: 'I click "{receive}" button, but it says that trade does not exist',
                    q5: 'Can I withdraw to an Steam account that does not belong to me?'
                },
                crash: {
                    q1: 'I clicked on "{cashout}", but it did not work',
                    q2: 'I am sure I won the game, but I did not receive a winning item',
                    q3: 'I experience perfomance or visual issues with graph',
                    q4: 'My items/balance are gone, but I did not make a bet'
                },
                website: {
                    q1: 'Why do I see "{too_many_requests}" error?',
                    q2: 'Website does not open or working unstable/slow'
                },
                bonus: {
                    q1: 'I completed all requirements but I still can not activate promocode',
                    q2: 'Why do I see error "{limit_exhausted}"?',
                    q3: 'I see an error when activating promocode'
                },
                partnership: {
                    q1: 'How does your partnership work?',
                    q2: 'What is the base for calculating referral comission?',
                    q3: 'Can I register multiple accounts as referrals of each other to use bonuses?'
                }
            },
            answers: {
                contact: 'contact support',
                general: {
                    a1: 'We use secure protocol <a class="default-link" href="https://en.wikipedia.org/wiki/OAuth" target="_blank" rel="noopener">OAuth 2.0</a> for social authorization.\
                    It means that you do not pass any credentials or sensitive data to our application.\
                    We only access your username, avatar and (in case of Steam) public information of your profile, such as number of hours played in CS:GO.\
                    We <span class="text_light_bold">do not have</span> access to your Steam inventory and we <span class="text_light_bold">can not perform</span> any actions on your behalf.',
                    a2: '{forbidden}\
                        {rules}\
                        {banned}',
                    a2_banned: 'If you do not follow these rules, you might get temporarely or pemanently muted.',
                    a3: 'We have several features available: {features}',
                    a3_promocodes: 'Promocodes.',
                    a3_boost: 'Deposit boost.',
                    a3_airdrop: 'Airdrops. Open chat and make sure you meet all requirements to participate.',
                    a3_tasks: 'Tasks.',
                    a4: 'No, having more than one account is <span class="text_light_bold">forbidden</span>',
                    a5: 'Yes, we have implemented {provably}, so that every user can follow result calculation process and prove both {randomness} and our {inability} or predict them.',
                    a5_provably: 'Provably Fair algorithm',
                    a5_randomness: 'randomness of results',
                    a5_inability: 'inability to manipulate',
                    a6: 'If you have linked any social account, you can log in via this account and then reset your password.\
                    If you have linked email, you can click on {forgot} button on the login form and get reset link to your email.\
                    In other cases, {contact}',
                    a7: 'We are synchronizing prices regularly, but due to the volatility of marketplaces, differences in prices can occur.\
                    <span class="text_light_bold">We do not take (or refund) any differences.</span>\
                    You can exchange your items to any other items from our marketplace <span class="text_light_bold">with zero comission</span> and then withdraw.',
                    a8: 'It is <span class="text_light_bold">impossible</span>',
                    a9: 'Having multiple accounts is {forbidden}.\
                    If you already have an account and want to create a new one, you must {contact}. This action implies that your current account will be deleted.',
                    a9_forbidden: 'forbidden',
                    a10: 'Please, {contact} and provide all related information. If possible, reproduce the bug on video.\
                    If bug is not visual, i.e. affects financial part or website functionality, we guarantee to give you a {reward}.\
                    Otherwise, reward will be given at the discretion of administration.',
                    a10_reward: 'decent money reward'
                },
                deposit: {
                    a1: 'If there is more than {time} delay after you have payed the invoice, {contact}.',
                    a1_time: '10 minutes',
                    a2: 'Please, try using one of the online wallets <span class="text_light_bold">(Piastrix, FKwallet)</span>. It is easy to open one and they have a huge variety of payment methods available.',
                },
                withdraw: {
                    a1: 'This error means that we do not have this item in stock and can not purchase it on marketplace because it is either out of stock or due to a technical difficulties.\
                    Also this error might occur if there are too many withdraws in queue.\
                    If you are getting this error, try to: \
                    {tries}\
                    If none of the above actions helped, {contact} and provide screenshots and name of item your are trying to withdraw.',
                    a1_t1: '<span class="text_light_bold">Wait for 3-4 minutes</span> and try again',
                    a1_t2: 'Try to withdraw <span class="text_light_bold">different skin</span> (at least 4-6 times)',
                    a1_t3: 'Wait for <span class="text_light_bold">20 minutes</span>',
                    a2: 'Due to fail of trade (either seller did not send it or you did not accept) timer might get locked at 00:00. If it happens, you (and seller) both have some extra time to trade.\
                    Otherwise, <span class="text_light_bold">item will be unlocked automatically within 30 minutes</span>.',
                    a3: 'To prevent abuse of our bonus system, we require you to wager every deposit you make, i.e. <span class="text_light_bold">place bets worth 100%</span> of its value.',
                    a4: 'Please, make sure that <span class="text_light_bold">your inventory is PUBLIC</span> in Steam privacy settings.\
                    Also, make sure that <span class="text_light_bold">your account does not have restrictions on exchange by Steam</span>.',
                    a5: 'No, it is <span class="text_light_bold">strictly prohibited</span> to withdraw to account that does not belong to you.'
                },
                crash: {
                    a1: 'Please note, that <span class="text_light_bold">Crash is extremely sensitive to a good internet connection</span>.\
                    You can estimate quality of your connection by looking at the "Ping" badge underneath the graph.\
                    If you cashout shortly before the crash, your request might not reach our servers in time and, therefore, will not be processed.\
                    Unfortunately, <span class="text_light_bold">we do not compensate your bet</span> in this case.\
                    We <span class="text_light_bold">highly recommend using auto-cashout</span> feature to avoid such problems. \
                    Auto-cashout will work even if your internet connection drops or your PC crashes.',
                    a2_refresh: 'Refresh your page manually (via Ctrl + F5)',
                    a2_check: 'Double check the game in your',
                    a3: 'Please, make sure you are using modern up-to-date web-browser. We higly recommend <span class="text_light_bold">Google Chrome and Firefox</span>.\
                    Also, make sure that your internet connection is not slowed down by proxy or VPN services.',
                    a4: 'You either withdrawed, sold, exchanged or bet your items. It is {impossible}.\
                    If you are sure it was not you, please, {change} as soon as possible\
                    or {contact} and ask to temporarely freeze your account until you resolve the security issue. Note, however, that {compensate}.\
                    You are fully responsible for the safety of your credentials and for the security of your account.',
                    a4_impossible: 'impossible for your inventory to change without your actions',
                    a4_change: 'change your password',
                    a4_compensate: 'we do not compensate your losses in such situations'
                },
                website: {
                    a1: 'This error means that you have made too many requests to our servers and you need to <span class="text_light_bold">wait a little bit</span> before you can interact with website again.',
                    a2: 'Please, {contact} and temporarely stop placing bets until the issue is resolved.'
                },
                bonus: {
                    a2_see: 'See more at',
                    a3: 'Please, <span class="text_light_bold">read carefully the content of the error</span>. In most cases, it will contain the exact answer, reason or instruction.'
                },
                partnership: {
                    a1: 'You have unique constant referral link (check it on {partnership} page).\
                    Each user which registers via your referral link becomes your referral.\
                    You earn up to {percent} of each of your referrals.\
                    Each of your referrals get an {invitational} after registration.',
                    a1_percent: '5% of each deposit',
                    a1_invitational: 'invitational bonus',
                    a2: 'The base is the sum of payment <span class="text_light_bold">before applying promocodes and bonuses</span>. So, if your referral makes a 100$ payment with +100% promocode, he gets 200$, but your comission is calculated as a percentage of 100$.',
                    a3: 'No, having more than one account is <span class="text_light_bold">forbidden</span>. You <span class="text_light_bold">will be banned</span> from the website for multiaccounting.'

                }

            },
            menu: {
                general: 'General',
                deposit: 'Issues with deposit',
                withdraw: 'Issues with withdrawal',
                crash: 'Crash',
                website: 'Issues with website',
                bonus: 'Bonuses',
                partnership: 'Partnership'

            }
        },
        tasks: {
            footer: 'More tasks coming soon, do not forget to check regularly',
            permanent: 'permanent',
            claim: 'Claim',
            claimed: 'Claimed',
            expired: 'Whoops, seems like this task has expired',
            social_media: {
                name: 'Link your social media',
                link_steam: 'Link your Steam account*.',
                link_steam_no_hint: 'Link your Steam account.',
                subscribe_vk: 'Subscribe to our VK community.',
                link_vk: 'Link your VK account.',
                additional: 'your Steam account must be public, level {level} or higher, with at least {hours} hours played in CS:GO'
            },
            deposit: {
              name: 'Add funds to your account',
              deposit: 'Make a deposit of {value} or more',
              additional: 'the amount is calculated excluding promocodes and bonuses'
            },
            repost_vk: {
              name: 'Share a post',
              repost: 'Share {link} on your VK wall**.',
              repost_no_hint: 'Share {link} on your VK wall',
              link: 'this post',
              additional: 'your VK profile should be open',
              wait: 'Wait for {value} hours.',
              link_tg: 'Link your Telegram.',
              subsribe_tg: 'Subscribe to our Telegram.',
            },
            check: 'Check',
            wrong_task: 'Wrong task provided',
            is_not_done: 'You did not fulfill all requirements',
            already_claimed: 'You already recieved an award',
            successful_activation: 'You received an award'
        },

        validation: {
            min_length: 'Min length is {value}',
            max_length: 'Max length is {value}',
            min_value: 'Min value is {value}',
            max_value: 'Max value is {value}',
            login_not_empty: 'Login can not be empty',
            password_not_empty: 'Password can not be empty',
            login_min_length: 'Login must contain at least {value} characters',
            login_max_length: 'Login must be maximum {value} characters',
            login_alpha_dash: 'Login must only contain letters, numbers and dashes',
            password_min_length: 'Password must contain at least {value} symbols',
            password_max_length: 'Password must be maximum {value} symbols',
            password_rules: 'Password must contain lowercase, uppercase letters and numbers',
            passwords_match: 'Passwords must match',
            alpha_dash: 'Only latin letters, numbers and dashes allowed',
            email: 'Invalid email provided'
        },

        mobile: {
          market: 'Market',
          chat: 'Chat',
          profile: 'Profile',
          more: 'More'
        },
        
        itempicker: {
            balance: 'Balance',
            selected: 'Selected',
            store: 'Skins market',
            empty: 'Your inventory is empty. Open Skins Market and purchase some items to get started.',
            guest: 'Please, sign in to start',
            guest_mobile: 'Sign in to start',
            cant_load: 'Can not load your inventory. Please, refresh the page'
        },
        profile: {
          name: '{name}\'s profile',

            edit: {
                username: 'Username',
                warning: 'Please, do not use any insulting or inappropriate username or we will have to disable this feature for you.',
                change: 'Change',
                success: 'Enjoy your new username',
                username_required: 'You did not provide new username',
                username_min: 'Minimum length of username: {value} characters',
                username_max: 'Maximum length of username: {value} characters',
                username_alpha_dash: 'Username should only consist of latin characters, dashes and hyphens',
            },
            menu: {
                inventory: 'Inventory',
                game_history: 'Game history',
                account: 'Account',
                levels: 'Levels/Bonuses',
                partnership: 'Partnership',
                log_out: 'Log out'
            },
            logout: {
                sure: 'Are you sure you want to log out?',
                multiaccounts: 'Please note that creating multiple accounts is forbidden'
            },
            inventory: {
                trade_link_placeholder: 'Enter your trade link',
                no_withdraws: 'You do not have completed withdrawals yet',
                change_link: 'Save',
                incorrect_link: 'Please, provide a valid trade link',
                trade_link_updated: 'Trade link updated successfully',
                trade_link_label: 'Your trade link',
                trade_link_cant_find: 'Can\'t find?',
                name: 'Inventory',
                withdraws: 'Withdrawals',
                wrong_sell_data: 'You did not provide item to sell',
                can_not_sell_bonus: 'You can not sell bonus item',
                successful_sell: 'Item sold'
            },
            stats: {
                best_win: 'Best win',
                total_bets: 'Total bets',
                best_winstreak: 'Best winstreak',
                games_played: 'Games played',
                best_multiplier: 'Best multiplier',
                win_ratio: 'Win ratio'
            },
            history: {
                empty: 'You did not play any games yet',
                game_not_found: 'Some error occured. Perhaps, the game is not finished or not created yet.',
                details: 'Details',
                your_bet: 'Your bet'
            },
            account: {
                add_password_auth: 'Add password authorization',
                label_login: 'Login',
                label_password: 'Password',
                label_current_password: 'Current password',
                label_new_password: 'New password',
                label_password_confirmation: 'Confirm password',
                label_password_new_confirmation: 'Confirm new password',
                email_verified: 'Verified',
                success: 'Account successfully updated',
                error: 'Something went wrong while updating account',
                update_password: 'Update password',
                wrong_current_password: 'The provided password does not match your current password.',
                current_password_placeholder: 'Enter current password',
                social_media: 'Link social media',
                provider_steam: 'Steam',
                provider_vkontakte: 'Vk',
                provider_twitch: 'Twitch',
                provider_google: 'Google',
                provider_facebook: 'Facebook',
                your_account: 'Your account in',
                link_another: 'Link another account',
                email: 'Your email',
                email_placeholder: 'Enter email',
                email_confirm: 'Verify',
                link_sent: 'Verification link has been sent to your email, please, check your inbox',
                email_taken: 'This email is already taken',
                wrong_email: 'Invalid email',
                link_telegram: 'Link Telegram',
                telegram_instruction: 'Send the command below to our Telegram bot {bot}',
                telegram_command_label: 'Command',
                open_telegram: 'Open Telegram'

            },
            levels: {
                bonuses_used: 'Bonuses used',
                bonus_rules: 'Bonus rules',
                levels: 'Levels',
                bonus_rule_1: 'You have certain limit on the total amount of bonuses you can get. When you exhaust this limit, you will be unable to use promocodes until you lower it down.',
                bonus_rule_2: 'Each time you use a {promocode}, you {increase} the scale of bonus usage by amount of the promocode.',
                bonus_rule_3: 'Each time you make a {deposit}, you {decrease} the scale of bonus usage by amount of the deposit, but scale will not go below 0.',
                bonus_rule_4: 'Each time you reach new {level}, the scale of bonus usage is {reset}, and the limit is increased according to the table below.',
                bonus_rule_5: 'Your level depends on how much money you bet in total. You can find out your current value on {history}.',
                promocode: 'promocode',
                increase: 'increase',
                decrease: 'decrease',
                deposit: 'deposit',
                level: 'level',
                reset: 'reset to 0',
                history_page_link: 'history page',
                header_level: 'Level',
                header_bet_sum: 'Bet sum',
                header_bonus_limit: 'Bonus limit'
            },
            partnership: {
                widthdraw: 'Withdraw',
                level: 'Level',
                to_level: 'To level',
                your_link: 'Your referral link',
                referrals: 'Referrals',
                earned: 'Earned',
                you_get: 'You get',
                available: 'Available balance',
                minimal_amount: 'Minimal amount to withdraw',
                referral_system: 'Referral system',
                referral_link: 'referral link',
                comission: 'comission',
                rule_1: 'User must register with your {link} to become your referral.',
                rule_2: 'You earn a {comission} from each deposit of your referrals.',
                rule_3: 'Comission percent depends on how much your referrals have deposited in total, according to the table below.',
                rule_4: 'Every referral gets {bonus}.',
                invitational: 'invitational bonus',
                header_level: 'Level',
                header_deposit_sum: 'Deposits sum',
                header_percent: 'Comission',
                header_bonus: 'Inv. bonus',
                to_first_deposit: 'to first deposit',
                withdrawed: 'Successfully withdrawed referral balance',
                withdrawal_blocked: 'You can not withdraw referral balance',
                min_withdraw: 'Minimum withdrawal amount - {value}'
            }
        },
        deposit: {
            deposit_header: 'Deposit',
            history_header: 'History',
            wrong_phone_number: 'Phone number is incorrect',
            inactive_method: 'This payment method is inactive',
            
            methods: {
                card: 'Bank card',
                card_2: 'Bank card #2',
                qiwi: 'QIWI',
                qiwi_2: 'QIWI #2',
                fk: 'FKWallet',
                piastrix: 'Piastrix',
                yoomoney: 'Yoomoney',
                skinpay: 'SkinPay',
                visa: 'Visa',
                mastercard: 'Mastercard',
                mir: 'Mir',
                advcash: 'Advcash',
                sber: 'Sberbank',
                onlinebank: 'Online Bank',
                perfectmoney: 'Perfect Money',
                tether: 'Tether ERC20',
                tether_trc20: 'Tether TRC20',
                bitcoin: 'Bitcoin',
                litecoin: 'Litecoin',
                ethereum: 'Ethereum',
                usdt_erc20: 'USDT (ERC20)',
                usdt_trc20: 'USDT (TRC20)',
                bitcoin_cash: 'Bitcoin Cash',
                dash: 'Dash',
                dogecoin: 'Dogecoin',
                zcash: 'ZCash',
                steampay: 'Steam Pay',
                skins: 'Pay with skins',
                skins_2: 'Pay with skins #2',
                google_pay: 'Google Pay',
                apple_pay: 'Apple Pay'
            },
            your_currency: 'Your currency',
            available_methods: 'Available methods',
            sum: 'Amount',
            invitational_bonus: 'Invitational bonus',
            have_promocode: 'Have promocode?',
            deposit: 'Deposit',
            promocode_applied: 'Promocode applied',
            promocode_removed: 'Promocode removed',
            min_dep_with_promo: 'Min deposit with promo: {value}',
            promocode_placeholder: 'Enter promocode',
            warning_1: 'Maximum bonus from the promocode',
            warning_2: 'Maximum invitational bonus',
            warning_3: 'Bonuses do not stack, only the most profitable one will be used. Invitational bonus will be lost in this case.',
            warning_4: 'Minimum deposit amount',
            boost_1: 'When replenishing using this method, a <span class="text_light_bold">+{percent}%</span> bonus will be added to the balance',
            history: {
                empty: 'You did not make any payments yet',
                payment_id: 'Payment ID',
                payed: 'Payed',
                credited: 'Credited'
            }
        },
        withdraws: {
            can_not_withdraw_bonus: 'Can not withdraw bonus skins',
            blocked: 'Withdraws blocked. Reason: {value}',
            empty_trade_link: 'Please, fill your trade link to withdraw',
            min_deposit: 'You must deposit at least ${value} to withdraw',
            wrong_data: 'Wrong data provided',
            cant_request: 'We can not acquire this skin right now. Please, try to withdraw another skin or try again later',
            expired: 'Withdrawal expired. You can resend your items',
            check_inventory: 'Skin has been sent to you, please, check your inventory',
            successful_withdraw: 'Withdrawal has been completed',
            requesting: 'Requesting skin...',
            waiting: 'Waiting for seller...',
            receive: 'Receive',
            take: 'Take',
            sell: 'Sell',
            too_many_failed: 'Withdraws are disabled until {value}. Reason: you have declined too many withdrawals recently',
            wager: 'You must wager your deposit to withdraw. To wager deposit, you need to bet total of {value} on multipliers not less than x{value1}',
            overprice: 'We can not acquire this skin right now. Please, try to withdraw another skin or try again later',
            trade_closed: 'Item cannot be sent to you. Please, make sure that your trade is open and available',
            max_pending: 'You can not withdraw more than {value} items at the same time'
        },
        chat: {
            rules: 'Chat rules',
            message_placeholder: 'Enter your message...',
            required: 'You can not send empty message',
            max: 'Maximum message length: {value} symbols',
            block: 'You have been banned from chat',
            links_not_allowed: 'Links are not allowed',
            not_found: 'Message not found',
            muted: 'Player muted until {value}',
            unmuted: 'Player unmuted',
            already_muted: 'Player already muted',
            can_not_mute_myself: 'Can not mute yourself',
            can_not_unmute_myself: 'Can not unmute yourself',
            rules_violation: 'Chat rules violation',
            reason: 'Reason: {value}',
            you_muted: 'Chat is blocked until {value}',
            disallowed: 'It is forbidden to:',
            rule_1: 'Insult others, demonstrate aggressive or disrespectful behavior',
            rule_1_1: 'Insult the website and employees',

            rule_2: 'Leak promocodes',
            rule_3: 'Spam',
            rule_4: 'Send links to external web pages',
            rule_5: 'Beg',
            rule_6: 'Fraud',
            rule_7: 'Sale goods or services',
            commands: 'You can use the following commands:',
            cmd_1: 'sends your in-game stats',
            cmd_2: 'sends your financial stats',
            be_respectful: 'Please, be respectful and kind to other players. Do not let anger and emotionality to take over you. If you are feeling that you are on a lose streak - ',
            take_a_break: 'take a break',
            play_cool: 'Play cool and may the good luck be with you.',
            deposits: 'Deposited',
            withdraws: 'Withdrawn',
            balance: 'Balance',
            best_multiplier: 'Best multiplier',
            best_winstreak: 'Best winstreak',
            best_win: 'Best win',
            total_bets: 'Total bets',
            min_deposit: 'You need to deposit at least {value}$ to write in chat'
        },
       
        market: {
            title: 'Skins Market',
            user_pay: 'You pay',
            order_total: 'Order total',
            residue: 'Residue',
            buy: 'Buy',
            empty: 'No items available for purchase. Please, choose more items from the inventory or replenish your balance.',
            successful_purchase: 'Successful purchase. Please, check your inventory',
            max_market_items_per_transaction: "Max number of items per transaction: {value}",
            max_stash_items_per_transaction: "Max number of inventory items per transaction: {value}",
            market_changed: 'Some of selected items out of stock. Please, refresh the page and try again',
            prices_changed: 'Prices of selected items have changed. Please, refresh the page and try again',
            can_not_change_bonus: 'Can not use bonus skins to purchase',
            types: {
              all: 'All types',
              weapon: 'Weapon',
              gloves: 'Gloves',
              graffiti: 'Graffiti',
              collectible: 'Collectible',
              container: 'Container',
              pass: 'Pass',
              key: 'Key',
              gift: 'Gift',
              music_kit: 'Music Kit',
              other: 'Other',
            },
            rarities: {
              all: 'All raritites',
              contraband: 'Contraband',
              master: 'Master',
              superior: 'Superior',
              distinguished: 'Distinguished',
              exotic: 'Exotic',
              covert: 'Covert',
              industrial_grade: 'Industrial Grade', 
              milspec_grade: 'Mil-Spec Grade', 
              restricted: 'Restricted', 
              classified: 'Classified',
              extraordinary: 'Extraordinary',
              base_grade: 'Base Grade', 
              consumer_grade: 'Consumer Grade', 
              high_grade: 'High Grade',
              remarkable: 'Remarkable', 
              exceptional: 'Exceptional', 
            },
            exteriors: {
              all: 'All exteriors', 
              not_painted: 'Not Painted', 
              battlescarred: 'Battle-Scarred', 
              factory_new: 'Factory New', 
              fieldtested: 'Field-Tested', 
              minimal_wear: 'Minimal Wear',
              wellworn: 'Well-Worn',
            }
        },  
        rank: {
            title: 'LVL'
        },
        filters: {
            name: 'Name',
            price: 'Price'
        },
        bet: {
            auto_cashout: 'Auto-cashout',
            items: 'items'
        },
        crash: {
            name: 'Crash',
            play: 'Play',
            in_game: 'In game',
            ping: 'Ping',
            successful_bet: 'Your bet is accepted',
            game_in_progress: 'Game in progress...',
            cashout: 'Cashout',
            successful_cashout: 'Successful cashout!',
            game_not_started: 'Game is not started',
            game_ened: 'Game is already ended',
            did_not_bet: 'You did not bet in this game',
            bet_inactive: 'You can not cashout this bet',
            can_not_cashout: 'You can not cashout this bet',
            min_cashout: 'Minimal cashout is x{value}',
            you_won: 'You won',
            going_to_autocashout: 'Your bet will auto-cashout in a few seconds...',
            wrong_auto_cashout: 'Wrong auto-cashout value provided',
            crash_bet_max_items: 'Max number of items per bet: {value}',
            can_not_mix_bonus: 'Can not bet bonus and usual skins together',
            perfomance: 'Having perfomance issues? Try to disable graph animation'
        },
        mines: {
            name: 'Mines'
        },
        wheel: {
            name: 'Wheel'
        },
        footer: {
            rights: 'All rights reserved',
            version: 'Version',
            about_us: 'About us',
            community: 'Community',
            email: 'Customer support email'
        },
        privacy: {
            content: '<div class="text__text">Last update: {update}</div>\
            <div class="text__text mt-15">\
              Please read this Privacy Policy carefully before using <a href="{url}" class="default-link">{url}</a>.\
            </div>\
            <div class="text__text mt-15">\
              We provide access to various games on our platform. By using our services, you trust us with your information. We understand that this is a big responsibility and we make every effort to protect your data and enable you to manage it.\
            </div>\
            <div class="text__text mt-15">\
              This Privacy Policy describes what information we collect, for what purpose, and how you can update, delete your data and manage it.\
            </div>\
  \
            <div class="text__subheading mt-30">\
              Information we collect\
            </div>\
            <div class="text__text mt-15">\
  The types of personal information we collect:\
            </div>\
            <div class="text__text mt-15">\
              <ul class="text__list">\
                <li>Username</li>\
                <li>E-mail address</li>\
                <li>IP address</li>\
                <li>Steam trade link (as well as the history of trade links to which we have sent you trade offers)</li>\
                <li>When you create an account via social networks, we save your username, profile photo, link to your personal page on this social network.</li>\
                <li>When you create an account via Steam, in addition to the information specified above, we also save the type of profile visibility (open, closed), the Steam level, the number of hours played in CS:GO.</li>\
              </ul>\
            </div>\
            <div class="text__text mt-15">\
              Each user who creates an account has a username and password.\
              <span class="text_light_bold">We store your password in an encrypted form (the hash), therefore no one but you knows your password</span>. Read more on \
              <a\
                href="https://en.wikipedia.org/wiki/Cryptographic_hash_function#Password_verification"\
                target="_blank"\
                rel="noopener"\
                class="default-link"\
                >Wikipedia</a\
              >\
            </div>\
            <div class="text__subheading mt-30">\
              How we use the information received from users\
            </div>\
            <div class="text__text mt-15">\
              By becoming a user or otherwise providing {name} with personal information, you agree that we collect and use your personal information to provide the services.\
            </div>\
            <div class="text__text mt-15">\
              You agree to provide your email address to a third party for marketing purposes.\
            </div>\
            <div class="text__text mt-15">\
              You agree to provide the game id, your username, bet, winning odds, and winnings for publication on our social networks.\
            </div>\
            <div class="text__text mt-15">\
              You agree that we have the right to disclose your personal information, but only for the purpose and in cases where such a requirement has been put forward by government bodies, in the manner prescribed by applicable law.\
            </div>\
            <div class="text__subheading mt-30">\
              We receive information from the user so that the user can:\
            </div>\
            <div class="text__text mt-15">\
              <ul class="text__list">\
                <li>use our services, in particular, play games and purchase virtual items;</li>\
                <li>write in the chat, communicate with other users;</li>\
                <li>accept Steam trade offers;</li>\
                <li>create, manage, close an account.</li>\
              </ul>\
            </div>\
            <div class="text__text mt-15">\
              By default, the user profile is closed. The User\
               has access to his profile in full. The User does not have\
               access to the profile of other users.\
            </div>\
            <div class="text__subheading mt-30">\
  We receive information from the user so that we can:\
            </div>\
            <div class="text__text mt-15">\
              <ul class="text__list">\
                <li> identify you as the user for safety and compliance with our legal obligations;</li>\
                <li>maintain the user account;</li>\
                <li>check the accuracy of the information provided by the user;</li>\
                <li>receive questions and comments from the user in order to respond to them and improve our services;</li>\
                <li>periodically send written messages about important changes in the service, technical update issues, etc.;</li>\
                <li>study the suspicion of illegal, fraudulent activities related to our services;</li>\
                <li>report to the regulatory authorities on the commission of crimes or suspected crimes, including money laundering or fraud;</li>\
                <li>increase the safety and reliability of our services;</li>\
                <li>perform other tasks that are necessary to fulfill contractual obligations to the user.</li>\
              </ul>\
            </div>\
            <div class="text__subheading mt-30">Marketing</div>\
            <div class="text__text mt-15">\
              From time to time, we may use your personal information to inform you about our products or services, or about promotions that, in our opinion, may be interesting or useful to you. If you no longer want to receive marketing or promotional materials from {name} in general, or in any specific form, you can contact the support operator.\
            </div>\
            <div class="text__subheading mt-30">\
              Access to personal information\
            </div>\
            <div class="text__text mt-15">\
              You can access the personal information collected by {name} by contacting the support operator.\
            </div>\
  \
            <div class="text__subheading mt-30">Information management</div>\
            <div class="text__text mt-15">\
              We will take all reasonable measures to ensure the safety of the collected, used, or disclosed personal information about the user.\
            </div>\
            <div class="text__text mt-15">\
             Access to such information can only be authorized persons.\
            </div>\
            <div class="text__text mt-15">\
              {name} will destroy the personal information that we own after it is no longer needed to use, store, or disclose, but not sooner than 5 years after its use.\
            </div>\
  \
            <div class="text__subheading mt-30">Security</div>\
            <div class="text__text mt-15">\
              You guarantee that you use all possible methods to preserve the information that you transmit to us.\
            </div>\
            <div class="text__text mt-15">\
              You are responsible for the security and access to your own computer, mobile device, or any other device used to access the site.\
            </div>\
  \
            <div class="text__text mt-15">\
              You are solely responsible for maintaining the confidentiality of your username, password, and any other account information.\
            </div>\
            <div class="text__text mt-15">\
              We will take all reasonable steps to protect your personal information from unauthorized use, loss, and unauthorized access, alteration, and disclosure, including using password-protected systems, databases, and Secure Socket Layer (SSL) technology.\
            </div>\
            <div class="text__text mt-15">\
              {name} employees, agents, and contractors are required to maintain the confidentiality of user personal information.\
            </div>\
            <div class="text__subheading mt-30">Changes to this policy</div>\
            <div class="text__text mt-15">\
              We change this Privacy Policy from time to time, but do not limit your rights described in it without your explicit consent. We always indicate the date of publication of the latest changes and provide access to previous versions. If the changes are significant, we will make a more noticeable message (in particular, we inform you about changes in the Privacy Policy of some services by email).\
            </div>',
        },
        terms: {
            content: '<div class="text__text">Last update: {update}</div>\
            \
                      <div class="text__text mt-15">\
                          These Terms and Conditions (hereinafter – «Agreement») regulate the contractual relationship between the parties, {name} (hereinafter -"{name}", "we" or "our"), from the one side and you, as the user (hereinafter -"user" or "your"), from the other side, hereinafter collectively referred to as "parties".\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        «{name}» - website located on the Internet at\
                        <a href="{url}" class="default-link">{url}</a>\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        "User" means any person who agrees with these Terms and Conditions.\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        By accepting this Agreement, the user thereby testifies and warrants that he/she carefully read this agreement before using our website <a href="{url}" class="default-link">{url}</a> (hereinafter - “website”) and undertakes to comply with and adhere to all conditions determined by parties to this Agreement.\
                      </div>\
            \
                      <div class="text__subheading mt-30">Subject matter</div>\
            \
                      <div class="text__text mt-15">\
                        {name} provides the services for organizing leisure and recreation in the game "{url}" to the user in accordance with the Agreement. Such services, in particular, mean the following: services for the purchase and sale of game equipment, maintaining records of significant information: movement on the game account, provision of measures for identification and security of users, development of software that is integrated into the playground and external applications, information and other services necessary for organizing the game and serving the user on the website.\
                      </div>\
            \
                      <div class="text__subheading mt-30">Rights and obligations of parties</div>\
            \
                      <div class="text__text mt-15">\
                        You are personally responsible for the conduct of your financial transactions and operations, {name} is not responsible for the financial transactions between users to transfer game equipment and game currency, as well as other game attributes.\
                      </div>\
                      <div class="text__text mt-15">\
                        You are personally responsible for all actions performed with the game equipment: purchase, sale, replenishment, and withdrawal, as well as for game actions on the website: creation, purchase-sale, operations with all game elements, and other game attributes and objects used for the gameplay.\
                      </div>\
                      <div class="text__text mt-15">\
                          You do not have the right to restrict the access of others to the games on the website; you must respectfully treat the users of the game, as well as {name}, its partners, and employees.\
                      </div>\
                      <div class="text__text mt-15">\
                        We ensure, without charging a fee, the user’s access to the website and to participate in the game.\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        We keep a record of game equipment on the user’s game account.\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        We provide the user with additional paid services, the list of which, as well as the procedure and conditions for use of which are determined by Agreement, the rules of the playground, and other announcements of {name}. At the same time, we have the right at any time to change the number and volume of offered paid services, their cost, name, type, and effect of use.\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        We are not responsible for the malfunctioning of the game software. We use the software “AS IS”. If we determine that during the game a malfunction (error) occurred on the platform, the results that occurred during the incorrect operation of the software can be canceled or adjusted at our discretion. The user agrees not to appeal to the {name} about the quality, quantity, order, and terms of the gaming opportunities and services provided to him/her.\
                      </div>\
            \
                      <div class="text__subheading mt-30">Restrictions</div>\
                      <div class="text__text mt-15">\
                        Psychological state. If you were at any time diagnosed with compulsive disorder or you feel that you might have a relevant problem, please leave our website immediately.\
                      </div>\
                      <div class="text__text mt-15">\
                        Age. You can play if you are over 18 years old. In some countries where the age limit is above 18 years, you must be of legal age in accordance with the laws of your country of citizenship in order to use our services.\
                      </div>\
            \
                      <div class="text__subheading mt-30">\
                        Account opening and maintaining your account\
                      </div>\
                      <div class="text__text mt-15">\
                        You must register an account on our website personally following the step-by-step instructions provided by the website.\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        By registering an account or if requested by the website, you agree to provide true, accurate, current, and complete information about yourself. In case of amendments, you agree to update this information.\
                      </div>\
                      <div class="text__text mt-15">\
                        You are fully responsible for any unauthorized use of your account by third parties in case of loss of the password and other information for entering the account, your disclosure of this information, improper storage of such information, accidental leak of the above information, hacking of your account, as well as in other cases. We advise you to take all possible measures to maintain the confidentiality of your nickname / username / or password. If you notice unauthorized use of your account, please let us know immediately.\
                      </div>\
                      <div class="text__text mt-15">\
                        If any person fraudulently obtains access to the account, we will terminate their membership immediately and take all necessary and appropriate actions under this Agreement and under applicable laws and regulations. We do not accept any liability arising from the fraudulent entry and use of the user account.\
                      </div>\
                      <div class="text__text mt-15">\
                        If your actions are considered as intentional fraud, we can seize all the funds in your account, deposited and won, and forcefully close the account.\
                      </div>\
                      <div class="text__text mt-15">\
                        You are allowed to have only one account; any duplicate accounts are forbidden, and you must inform us immediately if you have registered a duplicate account. All transactions made from any duplicate account will be made void. All the prizes and winning from duplicate accounts will be forfeited and all your accounts will be forcefully closed.\
                      </div>\
                      <div class="text__text mt-15">\
                        If you want to open a new account, you must contact our client support team. Once a new account is opened, under our supervision, the old one will be closed; your deposited funds and winnings will not be transferred.\
                      </div>\
                      <div class="text__text mt-15">\
                        Should you become aware of any possible errors or incompleteness in the software of the website, you agree to refrain from taking advantage of them. Moreover, you agree to report to the client support team about any error or incompleteness immediately. Should you fail to fulfill such obligations, we have the right to full compensation for all costs related to the error or incompleteness.\
                      </div>\
                      <div class="text__subheading mt-30">Payments</div>\
                      <div class="text__text mt-15">\
                        The currency of the account is USD. You may transfer funds in any currency, your account on the website will be credited with the converted sum of USD.\
                      </div>\
                      <div class="text__text mt-15">\
                        As a rule, we do not accept cash deposits and deposits/withdrawals from/to third parties accounts.\
                      </div>\
                      <div class="text__text mt-15">\
                        All the methods of payment will be available on the website; they may differ from country to country, as well as may be altered from time to time by the website.\
                      </div>\
                      <div class="text__text mt-15">\
                        It is your responsibility to settle the fees that may be charged by the payment processor, funds deposited to the account at the website must be cleared of all such charges.\
                      </div>\
                      <div class="text__subheading mt-30">Marketplace and User inventory</div>\
                      <div class="text__text mt-15">\
                        You can exchange the funds available on your account for\
                        virtual items on the Marketplace of the website.\
                      </div>\
                      <div class="text__text mt-15">\
                        The prices on the marketplace are set by the website and are not\
                         the official prices of the Steam marketplace, and may change from\
                         time to time.\
                      </div>\
                      <div class="text__text mt-15">\
                        By purchasing a virtual item, the user\
                         agrees with the price set by the website`s marketplace for\
                         the moment of purchase and disclaims claims \
                         if this price does not match the price of the Steam marketplace or with\
                         prices on other marketplaces.\
                      </div>\
                      <div class="text__text mt-15">\
                        Rewards for any actions you take on the Website are\
                         issued as virtual objects.\
                      </div>\
                      <div class="text__text mt-15">\
                        In the absence of violations of this Agreement on your part, you\
                         can exchange your virtual items for inventory items in\
                         Steam.\
                      </div>\
                      <div class="text__text mt-15">\
                        Such an exchange takes place at the rate set by our marketplace, exchange rate differences are not refundable. For the period of exchange, your\
                         virtual items are temporarily frozen.\
                      </div>\
                      <div class="text__text mt-15">\
                        In the event that you decline to exchange items from our Website,\
                         frozen virtual items will be unfrozen within 24\
                         hours since the creation of the exchange offer.\
                      </div>\
                      <div class="text__text mt-15">\
                        In the event that you decline an offer to trade from our Website\
                         too many times, we have the right to temporarily or permanently suspend\
                         the ability to exchange your virtual items.\
                      </div>\
                      <div class="text__subheading mt-30">General prohibitions and responsibilities</div>\
                      <div class="text__text mt-15">\
                        It is prohibited to use the website for reasons, which may be classified as defamatory, abusive, obscene, racist, sexist, homophobic, or discriminatory against any religion, or offensive. It is forbidden to use aggressive language or images; swearing, threatening, harassment, or abuse against any of other users.\
                      </div>\
                      <div class="text__text mt-15">\
                        It is forbidden in the chat: publishing promotional codes, spam, begging, any external links and wallets, insults of any kind, sales.\
                      </div>\
                      <div class="text__text mt-15">\
                        It is prohibited to sell, transfer and/or acquire accounts from other users.\
                      </div>\
                      <div class="text__text mt-15">\
                        It is prohibited to use external programs of any kind to gain advantages in games.\
                      </div>\
                      <div class="text__text mt-15">\
                        We do not allow fraud. If we think you have cheated us by:\
                      </div>\
                      <div class="text__text mt-15">\
                        <ul class="text__list">\
                          <li>working with other players for profit;</li>\
                          <li>using ways of getting winnings by cheating;</li>\
                          <li>doing credit card chargeback;</li>\
                          <li>carding;</li>\
                          <li>other types of cheating;</li>\
                          <li>giving us false information.</li>\
                        </ul>\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        Any software bugs detected by the user must be reported to the website to the client support team. It is strictly forbidden to abuse bugs to your advantage in games or at the disadvantage of other users. Such abuse will be classified as cheating.\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        The website reserves the right to suspend or to delete any game where a bug caused unfair results, incorrect behavior of the user, or the process in general, if the bug occurred before the stocks were acquired.\
                      </div>\
            \
                      <div class="text__text mt-15">\
                        We have the right to terminate this Agreement unilaterally, as well as to perform other actions that limit the possibilities in the game, in relation to the user or the group of users who are accomplices to violations of the terms of this Agreement. At the same time, all game attributes, game equipment located in the account and on the game account of the user or the group of users, as well as all expenses are non-refundable and not compensated, except if {name}, at its discretion, considers it appropriate to compensate the expenses of the user or the group users.\
                      </div>\
            \
                      <div class="text__subheading mt-30">Warranties</div>\
                      <div class="text__text mt-15">\
                        We waive any responsibility for the user’s losses connected with a misunderstanding of the principles and rules of operation of the website.\
                      </div>\
                      <div class="text__text mt-15">\
                        You are fully responsible for any unauthorized use of your account by third parties in case of loss of the password and other information for entering the account, your disclosure of this information, improper storage of such information, accidental leak of the above information, hacking of your account, as well as in other cases. We advise you to take all possible measures to maintain the confidentiality of your nickname / username / or password. If you notice unauthorized use of your account, please let us know immediately.\
                      </div>\
                      <div class="text__text mt-15">\
                        We are not responsible for price differences between the marketplace\
                         of the Website and other trading platforms (including the\
                         official Steam marketplace).\
                      </div>\
                      <div class="text__text mt-15">\
                        We do not guarantee constant and continuous access to the website and its services in case of technical malfunctions and/or unforeseen circumstances, including defective work or malfunctioning of internet providers, information servers, banking, and payment systems, as well as illegal actions of third persons.\
                      </div>\
                      <div class="text__text mt-15">\
                        The website is not responsible for violations that may arise as a result of any actions of any users for any purpose that violates any law.\
                      </div>\
                      <div class="text__text mt-15">\
                        We do not bear the tax burden for the user. The user undertakes to independently include the possible income received in the tax return in accordance with the legislation of the country of his/her residence.\
                      </div>\
                      <div class="text__text mt-15">\
                        The website disclaims any responsibility for failure to fulfill or delay in the fulfillment of any of our obligations under the Agreement caused by events that are not under our reasonable control, including, but not limited to, natural disasters, wars, civil commotion, interruption in networks or services public communications, industrial disputes or DDoS attacks and similar Internet attacks that have an adverse effect. The activity of the website is considered to be suspended for the period of force majeure, and we will have an extension of time for work during this period. We will make every reasonable effort to complete the force majeure event or find a solution by which our obligations can be fulfilled, despite the event.\
                      </div>\
            \
                      <div class="text__subheading mt-30">Changes to the terms and conditions</div>\
                      <div class="text__text mt-15">\
                        We can change our Agreement unilaterally. Where legally required, we will request your consent prior to implementing such changes.\
                      </div>\
                      <div class="text__text mt-15">\
                       The new version of the Agreement will enter into force immediately after your next visit or access to the Website.\
                      </div>\
                      <div class="text__text mt-15">\
                        By continuing to use the website, you agree to be bound by the Agreement as well as by the latest modifications to it. If you disagree with the Agreement you must not use or access the website and inform us in writing immediately. We recommend you to read our Agreement and keep yourself informed of our practices.\
                      </div>',
        },
        util: {
            date: 'Date',
            loading: 'Loading...',
            or: 'or',
            seconds: 's',
            gifted: 'You just earned a bonus item! You can read about bonus items on the Bonuses page',
            ok: 'OK',
            yes: 'Yes',
            no: 'No',
            next: 'Next',
            prev: 'Previous',
            save: 'Save',
            apply_changes: 'Apply changes',
            confirm: 'Confirm',
            copied: 'Copied to clipboard',
            copy: 'Copy',
            usd: 'USD',
            rub: 'RUB',
            min_value: 'Min value: {value}',
            max_value: 'Max value: {value}',
            apply: 'Apply',
            no_bets: 'No bets in this game',
            bets_hidden: 'Bets are hidden in settings',
            maintenance_header: 'Under maintenance',
            maintenance_text: 'Please, wait until we updating improving our systems. We will be back soon.',
            payment_success: 'Payment has been credited!'
        },
        errors: {
            min_bonus_multiplier: 'Can not cashout bonus skins on multiplier less than x{value}',
            balance_too_low: 'You have unsufficient balance',
            inventory_changed: 'Your inventory has changed. Please, refresh the page and try again',
            generic: 'Whoops, something went wrong. Please, refresh the page and try again',
            game_started: 'Can not place bets, game is already started',
            bet_already_placed: 'You already placed bet in current game',
            wrong_items: 'Seems like you did not select any items',
            too_many_requests: 'Not so fast',
            forbidden: 'Please, log in to continue',
            internal: 'Unknown server error occured. Our team is investigating...',
            permission_denied: 'You do not have enough permissions to perform this action',
            blocked: 'Your account has been blocked for rules violation',
            payment: 'Some error occured during the payment. Please, contact support'
        }
        
    },







    RU: {
      header: {
          deposit: 'Пополнить',
          fair: 'Честная игра',
          balance: 'Баланс'
      },
      settings: {
        header: 'Настройки',
        show_bets: 'Показывать ставки',
        show_avatars: 'Показывать аватарки',
        show_usernames: 'Показывать никнеймы',
      },
      nav: {
          home: 'Главная',
          bonus: 'Бонусы',
          support: 'Поддержка',
          help: 'Помощь',
          fair: 'Честная игра',
          terms: 'Условия и положения',
          privacy: 'Политика конфиденциальности',
      },
      airdrop: {
          name: 'Airdrop',
          not_active: 'сейчас неактивен',
          modal_description: 'По истечению времени, {count} случайных пользователей из пула получат случайный предмет стоимостью {price}',
          requirements: 'Требования:',
          hours: 'часа',
          req_online: 'Быть онлайн',
          req_games: 'Сыграть {value} игр',
          req_chat: 'Быть активным в чате',
          req_deposit: 'Иметь хотя бы одно пополнение за последние {value}',
          your_status: 'Ваш статус',
          status_not_in_pool: 'Не в пуле',
          status_in_pool: 'В пуле',
          won_in_airdrop: 'Словили {what} и получили призы стоимостью {value}',
          minutes: 'минут',
          seconds: 'секунд'
      },
      auth: {
        login: 'Войти',
        login_placeholder: 'Введите логин',
        password_placeholder: 'Введите пароль',
        forgot_password: 'Забыли пароль?',
        signing_in: 'Входим...',
        by_clicking: 'Продолжая, Вы принимаете ',
        auth_error: 'Вы ввели неправильные логин и/или пароль, попробуйте еще раз',
        
        signup: 'Регистрация',
        register_login_placeholder: 'Придумайте логин',
        register_password_placeholder: 'Придумайте пароль',
        register_password_confirm: 'Введите пароль еще раз',
        signing_up: 'Регистрируем...',
        login_taken: 'Этот логин занят',
        wrong_data: 'Похоже, Вы ввели неправильные данные. Попробуйте еще раз или свяжитесь с поддержой',

        error_during_auth: 'При авторизации произошла ошибка. Пожалуйста, попробуйте еще раз',
        already_linked: 'Данный аккаунт уже был привязан другим пользователем',
        successfully_linked: 'Аккаунт успешно привязан',
        email_verified: 'Email подтвержден',
        reset_password: 'Сброс пароля',
        reset_button: 'Сбросить',
        reset_link_sent: 'Ссылка для сброса пароля отправлена на ваш email',
        reset_successful: 'Пароль сброшен. Вы можете войти используя новый пароль'
      },
      passwords: {
        user: 'Пользователь с таким email не найден',
        throttled: 'Мы уже отправили ссылку на сброс',
        token: 'Ссылка устарела. Пожалуйста, запросите новую ссылку для сброса'
      },
      captcha: {
        failed: 'Вы не прошли проверку CAPTCHA',
      },
      bonus: {
        requirements: 'Требования',
        activate_below: 'активируйте ниже',
        satisfy: 'Чтобы активировать промокод, Вам необходимо выполнить все требования',
        activate: 'Активировать',
        promo_placeholder: 'Введите код...',
        follow_us: 'Мы публикуем промокоды в наших социальных сетях: {value}. Не забудьте подписаться и включить оповещения!',
        promocode: 'Промокод',
        tasks: 'Задания',
        steam_linked: 'Привязать аккаунт Steam.',
        steam_public: 'Видимость аккаунта должна быть публичной.',
        steam_level_is_ok: 'Уровень в Steam должен быть {value} и выше.',
        played_hours_is_ok: 'Вы должны сыграть как минимум {value} часов в CS:GO.',
        app_in_username: 'Добавить {value} в никнейм Steam.',
        link_here: 'Привязать здесь',
        having_problems: 'Есть проблемы?',
        csgo_hours_help: 'Перейдите в профиль Steam — Настройки приватности — Доступ к игровой информации — Открытый',
        promocode_footer: 'Чтобы изменения применились, необходимо перезайти на сайт через Steam',
        app_in_username_help: 'После изменения никнейма, необходимо перезайти через Steam и подождать {value} минут',
        promo_does_not_exits: 'Промокод не существует',
        promo_activations_exhausted: 'К сожалению, активации закончились',
        already_used: 'Вы уже активировали этот промокод',
        not_all_requirements_satisfied: 'Вам необходимо выполнить все требования чтобы активировать промокод',
        limit_exhausted: 'Вы достигли лимита промокодов. Подробнее о лимите в вашем профиле',
        successful_activation: 'Промокод активирован',
        sub_here: 'Подписаться здесь',
        wrong_code: 'Неверный код',
        promo_min_dep: 'Минимальный депозит с промокодом: {value}',
        inventory_contains_bonus: 'В вашем инвентаре уже есть бонусный предмет. Пожалуйста, используйте его, а затем возвращайтесь за новым бонусом'
      },
      fairness: {
        prove: 'Проверить честность',
        fairness_header: 'Честная игра',
        check_header: 'Проверка',
        how_it_works: 'Как это работает?',
        how_it_works_crash: 'Результаты Crash',
        paragraph_1: 'Мы спроектировали систему честной игры для достижения {goals}\
        Во-первых, мы хотим доказать, что результаты любой игры были {before} начала игры.\
        Во-вторых, мы хотим доказать, что результаты были {randomly}, без отклонений от случайного распределения',
        goals: 'двух главных целей.',
        generated_randomly: 'сгенерированы случайно',
        generated_before: 'сгенерированы до',
        paragraph_2: 'Перед каждым раундом мы показываем Вам входные параметры генератора случайных чисел в обфусцированной форме.\
        Перед раундом, мы даем Вам 2 сида: {client_seed} и {server_seed}.\
        Клиентский сид дан в неизмененном виде.\
        Серверный сид дан в обсфусцированном виде, Вы можете видеть только первую половину сида, вторая же половина откроется после завершения раунда.\
        После этого, используя оба сида, Вы можете {reproduce_calculation} результатов игры и убедиться, что они {pre_generated}.\
        Наш генератор случайных чисел принимает 2 сида в качестве входных параметров и выдает {deterministically} результат (это значит, что одни и те же входные параметры всегда дают один и тот же результат) по следующему алгоритму:',
        paragraph_2_client_seed: 'клиентский сид',
        paragraph_2_server_seed: 'серверный сид',
        paragraph_2_reproduce_calculation: 'воспроизвести расчет',
        paragraph_2_pre_generated: 'сгенерированы до начала игры и абсолютно случайны',
        paragraph_2_deterministically: 'детерминированный',
        paragraph_3: 'Здесь, {cursor}, но в случае, если необходимо сгенерировать большее количество байт, мы повторяем шаг 2 с курсором, увеличенным на 1.\
        Сгенерированные байты принадлежат {random_distribution} (это можно доказать, используя свойства хеш-функций).\
        Полученные байты затем конвертируются в {real_numbers}\
        Данные числа затем используются для генерации результата игры.',
        paragraph_3_cursor: 'курсор изначально равен 0',
        paragraph_3_random_distribution: 'случайному распределению',
        paragraph_3_real_numbers: '8 вещественных чисел в интервале [0..1].',
        paragraph_4: 'Чтобы сгенерировать результат раунда Crash, мы берем только первое случайное число из сгенерированной последовательности и подставляем его в формулу ниже, что дает экспоненциально распределенные случайные числа из интервала {between}',
        seeds_to_bytes: 'Сиды в байты',
        bytes_to_numbers: 'Байты в числа',
        numbers_to_result: 'Числа в результат игры',
        paragraph_5: 'Поскольку Crash является мультиплеерной игрой, мы используем постоянный клиентский для каждого раунда (в соло режимах используется персональный клиентский сид).\
        Этот сид представляет собой хеш {block}\
        {hash}. Мы выбрали данный блок {elon} случайно.',
        paragraph_5_block: 'Dogecoin блока #3737532',
        paragraph_5_elon: 'потому что нам так сказал Илон Маск',
        seeds_control: 'Контроль сидов',
        current_client_seed: 'Текущий клиентский сид',
        current_server_seed: 'Текущий серверный сид (половина)',
        previous_client_seed: 'Предыдущий клиентский сид',
        previous_server_seed: 'Предыдущий серверный сид (полный)',
        calculate_previous: 'Проверить предыдущую игру',
        header_game: 'Режим',
        header_client_seed: 'Клиентский сид',
        header_server_seed: 'Серверный сид',
      },
      notifications: {
        empty: 'Нет новых уведомлений',
        titles: {
            withdraw_sent: 'Предмет отправлен',
            withdraw_failed: 'Вывод не удался',
            deposit: 'Успешное пополнение'
        },
        bodies: {
            withdraw_sent: 'Предмет {item} был отправлен Вам',
            withdraw_failed: 'Предмет {item} разблокирован, возможность вывода возобновлена',
            deposit: 'Вы пополнили ${amount}. Удачных игр!'
        }
      },
      faq: {
        talk_to_operator: 'Мне нужен оператор',
        make_sure: 'Убедительная просьба, ознакомьтесь с нижеприведенным FAQ, прежде чем задавать вопрос оператору.',
        questions: {
            general: {
                q1: 'К какой информации вы имеете доступ, когда я авторизуюсь через соц. сети?',
                q2: 'Я получил мут в чате',
                q3: 'Как получить бесплатные деньги на счет?',
                q4: 'Могу ли я регистрировать несколько аккаунтов?',
                q5: 'У вас все честно?',
                q6: 'Я забыл свой пароль',
                q7: 'Цены в вашем магазине отличаются от Steam Market',
                q8: 'Я хочу передать средства/предметы другому пользователю',
                q9: 'Я хочу создать другой аккаунт',
                q10: 'Я нашел баг'
            },
            deposit: {
                q1: 'Я оплатил счет, но средства не зачислились',
                q2: 'Я хочу пополнить, но у вас нет данного способа оплаты',
                q3: 'Где взять промокоды на пополнение?',
            },
            withdraw: {
                q1: 'Я получаю ошибку "{error}"',
                q2: 'Предмет не разблокирован после 5 минут ожидания (таймер висит на 00:00)',
                q3: 'Я получаю ошибку "Вам необходимо отыграть свой депозит."',
                q4: 'Я жму кнопку "{receive}", но пишет, что трейда не существует',
                q5: 'Можно ли выводить предметы на чужой Steam аккаунт?'
            },
            crash: {
                q1: 'Я нажал "{cashout}", но высветилась ошибка',
                q2: 'Я уверен что выиграл, но не получил предмет в инвентарь',
                q3: 'Я испытываю визуальные баги или проблемы с производительностью графика',
                q4: 'Мои предметы/баланс исчезли, но я не делал ставок'
            },
            website: {
                q1: 'Почему я вижу ошибку "{too_many_requests}"?',
                q2: 'Сайт не открывается или работает медленно'
            },
            bonus: {
                q1: 'Я выполнил все требования, но до сих пор не могу активировать промокод',
                q2: 'Почему я вижу ошибку "{limit_exhausted}"?',
                q3: 'Появляется ошибка при активации промокода'
            },
            partnership: {
                q1: 'Как работает ваша реферальная система?',
                q2: 'Что является базой расчета реферальных отчислений?',
                q3: 'Могу ли я регистрировать несколько аккаунтов и использовать рефералку для получения бонусов?'
            }
        },
        answers: {
            contact: 'свяжитесь с поддержкой',
            general: {
                a1: 'Мы используем безопасный протокол <a class="default-link" href="https://ru.wikipedia.org/wiki/OAuth" target="_blank" rel="noopener">OAuth 2.0</a> для социальной авторизации.\
                Это значит, что Вы не передаете никаких реквизитов для входа и прочей конфиденциальной информации нашему приложению.\
                Мы имеем доступ только к Вашему имени пользователя, фотографии профиля, и (в случае со Steam) публичной информации Вашего профиля, такой как количество часов, сыгранных в CS:GO.\
                Мы <span class="text_light_bold">не имеем</span> доступа к Вашему инвентарю в Steam и мы <span class="text_light_bold">не можем совершать</span> никаких действий от Вашего лица.',
                a2: '{forbidden}\
                    {rules}\
                    {banned}',
                a2_banned: 'Если Вы нарушаете данные правила, мы имеем право временно или навсегда заблокировать Вам возможность писать в чат.',
                a3: 'Есть несколько способов: {features}',
                a3_promocodes: 'Промокоды.',
                a3_boost: 'Промокоды на пополнение.',
                a3_airdrop: 'Airdrop. Откройте чат и убедитесь, что выполнены все требования для участия.',
                a3_tasks: 'Задания.',
                a4: 'Нет, иметь более одного аккаунта <span class="text_light_bold">запрещено</span>',
                a5: 'Да, мы внедрили {provably}, и поэтому любой пользователь может проверить расчет результатов любой игры и убедиться как в {randomness}, так и в нашей {inability} или предсказывать их.',
                a5_provably: 'алгоритм Честной Игры',
                a5_randomness: 'случайности результатов',
                a5_inability: 'невозможности ими манипулировать',
                a6: 'Если Вы привязывали какую-либо социальную сеть к аккаунту, зайдите через данную социальную сеть, перейдите в профиль и сбросьте свой пароль.\
                Если вы привязывали свой Email, нажмите на кнопку {forgot} на странице входа и сбросьте пароль через ссылку, отправленную вам на Email.\
                В остальных случаях, {contact}',
                a7: 'Мы синхронизуем цены регулярно, но ввиду волатильности торговых площадок, цены могут отличаться.\
                <span class="text_light_bold">Мы не компенсируем (и не забираем) данную разницу.</span>\
                Вы можете обменять предметы в нашем Маркете <span class="text_light_bold">без комиссии</span>, и затем осуществить вывод.',
                a8: 'Данная возможность <span class="text_light_bold">не предусмотрена</span>.',
                a9: 'Иметь несколько аккаунтов {forbidden}.\
                Если у Вас уже есть аккаунт, но Вы хотите создать другой, {contact}. Это подразумевает, что Ваш текущий аккаунт будет удален.',
                a9_forbidden: 'запрещено',
                a10: 'Пожалуйста, {contact} и предоставьте полную информацию. Если возможно, воспроизведите баг на видео.\
                Если баг не визуальный, то есть затрагивает финансовую или функциональную часть, мы гарантируем {reward}.\
                В случае, если баг визуальный, вознаграждение выдается на усмотрение администрации.',
                a10_reward: 'щедрое денежное вознаграждение'
            },
            deposit: {
                a1: 'Если задержка поступления превышает {time}, {contact}.',
                a1_time: '10 минут',
                a2: 'Пожалуйста, воспользуйтесь одним из универсальных онлайн-кошельков <span class="text_light_bold">(Piastrix, FKwallet)</span>. Их очень легко открыть и они обладают большим набором доступных способов пополнения.',
            },
            withdraw: {
                a1: 'This error means that we do not have this item in stock and can not purchase it on marketplace because it is either out of stock or due to a technical difficulties.\
                Also this error might occur if there are too many withdraws in queue.\
                If you are getting this error, try to: \
                {tries}\
                If none of the above actions helped, {contact} and provide screenshots and name of item your are trying to withdraw.',
                a1: 'Эта ошибка означает, что данного предмета нет в наличии, и мы не можем приобрести его на торговой площадке (ввиду отсутствия его в наличии или технических проблем).\
                Также эта ошибка может означать, что очередь на вывод слишком велика.\
                Если Вы получаете данную ошибку, попробуйте: \
                {tries}\
                Если ничего из этого не помогло, {contact} и предоставьте скриншоты ошибки и название предмета, который Вы пытаетесь вывести.',
                a1_t1: '<span class="text_light_bold">Подождать 3-4 минуты</span> и попробовать снова',
                a1_t2: 'Вывести <span class="text_light_bold">другой предмет</span> (как минимум 4-6 раз)',
                a1_t3: 'Подождать <span class="text_light_bold">20 минут</span>',
                a2: 'Из-за неудачного трейда (либо продавец не отправил предмет, либо вы не приняли оффер) таймер может заморозиться на 00:00. Если это происходит, Вы (и продавец) получаете немного дополнительного времени чтобы успеть совершить трейд.\
                Если этого не произойдет, <span class="text_light_bold">предмет разблокируется автоматически в течении 30 минут</span>.',
                a3: 'Для борьбы с абузом бонусной системы, мы требуем отыгрыша депозита, то есть Вам необходимо <span class="text_light_bold">сделать ставок на сумму 100%</span> от суммы пополнения.',
                a4: 'Пожалуйста, убедитесь что Ваш  <span class="text_light_bold">инвентарь открытый</span> в настройках приватности профиля Steam.\
               Также, убедитесь, что <span class="text_light_bold">что у Вас нет ограничения на обмен в Steam</span>.',
                a5: 'Выводить на аккаунты, которые Вам не принадлежат - <span class="text_light_bold">строго запрещено</span>.'
            },
            crash: {
                a1: 'Обратите внимание, что <span class="text_light_bold">режим Crash очень чувствителен к хорошему интернет-соединению</span>.\
                Вы можете оценить качество соединения с помощью индикатора "Пинг" под графиком.\
                Если вывод ставки произошел сразу перед крашем, Ваш запрос мог не достичь наших серверов вовремя, и не был обработан.\
                К сожалению, <span class="text_light_bold">мы не коменсируем Вашу ставку</span> в таких случаях.\
                Мы <span class="text_light_bold">настоятельно рекомендуем использовать авто-вывод</span> во избежание подобных ситуаций. \
                Авто-вывод сработает даже при потере интернет-соединения или сбое работы ПК.',
                a2_refresh: 'Обновите страницу вручную (через Ctrl + F5)',
                a2_check: 'Перепроверьте игру на странице ',
                a3: 'Пожалуйста, используйте современный браузер, обновленный до актуальной версии. Мы рекомендуем <span class="text_light_bold">Google Chrome и Firefox</span>.\
                Также, убедитесь, что сторонние программы (VPN, Proxy, ПО для записи или стриминга) не замедляют Ваше интернет-соединение.',
                a4: 'Вы либо вывели, либо продали, либо обменяли, либо проиграли Ваши предметы. {impossible}.\
                Если Вы думаете, что кто-то получил доступ к Вашему аккаунту, пожалуйста, {change} как можно скорее\
                или {contact} и попросите временно заморозить Ваш аккаунт до тех пор, пока проблема с безопасностью не будет устранена. Обратите внимание, что {compensate}.\
                Вы несете полную ответственность за сохранность Ваших реквизитов для входа и за безопасность Вашего аккаунта.',
                a4_impossible: 'Ваш инвентарь не может измениться без Ваших действий',
                a4_change: 'измените Ваш пароль',
                a4_compensate: 'мы не компенсируем Ваши потери в таких случаях'
            },
            website: {
                a1: 'Эта ошибка означает, что Вы совершили слишком много запросов к нашим серверам за последнее время, и Вам необходимо <span class="text_light_bold">немного подождать</span>, прежде чем Вы опять сможете взаимодействовать с сайтом.',
                a2: 'Пожалуйста, {contact} и воздержитесь от игр на сайте до момента устранения неполадки.'
            },
            bonus: {
                a2_see: 'Более подробная информация находится на странице',
                a3: 'Пожалуйста, <span class="text_light_bold">прочтите внимательно, что написано в ошибке</span>. В большинстве случаев, там будет четкий ответ, причина или указание.'
            },
            partnership: {
                a1: 'You have unique constant referral link (check it on {partnership} page).\
                Each user which registers via your referral link becomes your referral.\
                You earn up to {percent} of each of your referrals.\
                Each of your referrals get an {invitational} after registration.',
                a1: 'Вам выделена уникальная постоянная реферальная ссылка (она находится на странице {partnership}).\
                Каждый пользователь, который регистрируется с помощью Вашей реферальной ссылки, становится Вашим рефералом.\
                Вы зарабатываете до {percent} всех своих рефералов.\
                Какждый Ваш реферал получает {invitational} после регистрации.',
                a1_percent: '5% от каждого пополнения',
                a1_invitational: 'приветственный бонус',
                a2: 'Базой расчета явялется сумма платежа <span class="text_light_bold">до применения промокодов и бонусов</span>. Например, если Ваш реферал пополняет 100$ с промокодом на +100%, он получает 200$, но Ваши реферальные отчисления считаются как процент от 100$.',
                a3: 'Иметь более одного аккаунта <span class="text_light_bold">запрещено</span>. Вы <span class="text_light_bold">будете заблокированы</span> навсегда за мультиаккаунты.'

            }

        },
        menu: {
            general: 'Общее',
            deposit: 'Проблемы с пополнением',
            withdraw: 'Проблемы с выводом',
            crash: 'Crash',
            website: 'Проблемы с сайтом',
            bonus: 'Бонусы',
            partnership: 'Реферальная система'

        }
      },
      tasks: {
        footer: 'Заходите время от времени, чтобы не пропустить новые задания',
        permanent: 'бессрочно',
        claim: 'Забрать',
        claimed: 'Выполнено',
        expired: 'Похоже, срок сдачи данного задания истек',

        social_media: {
            name: 'Привяжите социальные сети',
            link_steam: 'Привяжите свой Steam*.',
            link_steam_no_hint: 'Привяжите свой Steam.',
            subscribe_vk: 'Подпишитесь на наш VK.',
            link_vk: 'Привяжите свой VK.',
            additional: 'Ваш профиль должен быть открытым, уровень {level} и выше, {hours}+ часов в CS:GO'
        },
        deposit: {
          name: 'Пополните свой баланс',
          deposit: 'Совершите депозит {value} или более',
          additional: 'сумма депозита считается без учета промокодов и бонусов'
        },
        repost_vk: {
          name: 'Сделайте репост',
          repost: 'Сделайте репост {link} на свою страницу ВКонтакте**.',
          repost_no_hint: 'Сделайте репост {link} на свою страницу ВКонтакте.',
          link: 'этой записи',
          additional: 'ваш профиль ВКонтакте должен быть открытым',
          wait: 'Подождите {value} часа.',
          link_tg: 'Привяжите свой Telegram.',
          subsribe_tg: 'Подпишитесь на наш Telegram.',
        },
        check: 'Проверить',
        wrong_task: 'Некорректное задание',
        is_not_done: 'Вы не выполнили все условия',
        already_claimed: 'Вы уже получили награду',
        successful_activation: 'Награда получена'
      },
      validation: {
          min_length: 'Минимальная длина: {value}',
          max_length: 'Максимальная длина: {value}',
          min_value: 'Минимальное значение: {value}',
          max_value: 'Максимальное значение: {value}',
          login_not_empty: 'Логин не может быть пустым',
          password_not_empty: 'Пароль не может быть пустым',
          login_min_length: 'Минимальная длина логина: {value} символа',
          login_max_length: 'Максимальная длина логина: {value} символов',
          login_alpha_dash: 'Логин может содержать только латинские буквы, цифры и подчеркивания',
          password_min_length: 'Минимальная длина пароля: {value} символов',
          password_max_length: 'Максимальная длина пароля: {value} символов',
          password_rules: 'Пароль должен включать строчные, заглавные буквы и цифры',
          passwords_match: 'Пароли должны совпадать',
          alpha_dash: 'Только латинские буквы, числа и подчеркивания',
          email: 'Некорректный email'
      },
      mobile: {
        market: 'Маркет',
        chat: 'Чат',
        profile: 'Профиль',
        more: 'Еще'
      },
      itempicker: {
        balance: 'Баланс',
        selected: 'Выбрано',
        store: 'Магазин предметов',
        empty: 'Ваш инвентарь пуст. Откройте Магазин предметов и приобретите что-нибудь, чтобы начать.',
        guest: 'Пожалуйста, войдите, чтобы начать',
        guest_mobile: 'Войдите, чтобы начать',
        cant_load: 'Ошибка загрузки инвентаря. Пожалуйста, обновите страницу'
      },
      profile: {
        name: 'Профиль {name}',
        edit: {
            username: 'Никнейм',
            warning: 'Пожалуйста, не используйте оскорбительные и нецензурные имена, иначе мы будем вынуждены отключить данную функцию для Вас.',
            change: 'Изменить',
            success: 'Изменение успешно',
            username_required: 'Вы не ввели новый никнейм',
            username_min: 'Минимальная длина никнейма: {value} символов',
            username_max: 'Максимальная длина никнейма: {value} символов',
            username_alpha_dash: 'Никнейм может содержать только латинские буквы, цифры и подчеркивания',
        },
        menu: {
            inventory: 'Инвентарь',
            game_history: 'История игр',
            account: 'Аккаунт',
            levels: 'Уровни/Бонусы',
            partnership: 'Реферальная система',
            log_out: 'Выход'
        },
        logout: {
            sure: 'Вы уверены что хотите выйти?',
            multiaccounts: 'Обратите внимание, создание нескольких аккаунтов запрещено'
        },
        inventory: {
            trade_link_placeholder: 'Введите Вашу трейд-ссылку',
            no_withdraws: 'У Вас пока нет завершенных выводов',
            change_link: 'Сохранить',
            incorrect_link: 'Некорректная трейд-ссылка',
            trade_link_updated: 'Трейд-ссылка обновлена',
            trade_link_label: 'Ваша трейд-ссылка',
            trade_link_cant_find: 'Где найти?',
            name: 'Инвентарь',
            withdraws: 'Выводы',
            wrong_sell_data: 'Вы не выбрали предмет',
            can_not_sell_bonus: 'Нельзя продать бонусный предмет',
            successful_sell: 'Предмет продан'
        },
        stats: {
            best_win: 'Топ выигрыш',
            total_bets: 'Всего ставок',
            best_winstreak: 'Топ винстрик',
            games_played: 'Сыграно игр',
            best_multiplier: 'Топ коэф.',
            win_ratio: 'Процент побед'
        },
        history: {
            empty: 'Вы не сыграли ни одной игры',
            game_not_found: 'Произошла ошибка. Данная игра еще не закончилась или еще не началась',
            details: 'Подробнее',
            your_bet: 'Ваша ставка'
        },
        account: {
            add_password_auth: 'Добавить вход по паролю',
            label_login: 'Логин',
            label_password: 'Пароль',
            label_current_password: 'Текущий пароль',
            label_new_password: 'Новый пароль',
            label_password_confirmation: 'Повторите пароль',
            label_password_new_confirmation: 'Повторите новый пароль',
            email_verified: 'Подтвержден',
            success: 'Аккаунт успешно обновлен',
            error: 'Упс, что-то пошло не так... Попробуйте еще раз или свяжитесь с поддержкой',
            update_password: 'Обновить пароль',
            wrong_current_password: 'Введенный пароль не совпадает с Вашим текущим паролем',
            current_password_placeholder: 'Введите текущий пароль',
            social_media: 'Привязка социальных сетей',
            provider_steam: 'Steam',
            provider_vkontakte: 'Vk',
            provider_twitch: 'Twitch',
            provider_google: 'Google',
            provider_facebook: 'Facebook',
            your_account: 'Ваш аккаунт в',
            link_another: 'Привязать другой аккаунт',
            email: 'Ваш email',
            email_placeholder: 'Введите email',
            email_confirm: 'Подтвердить',
            link_sent: 'Ссылка для подтверждения отправлена на Ваш email, проверьте входящие письма',
            email_taken: 'Этот email уже занят',
            wrong_email: 'Неверный email',
            link_telegram: 'Привязка Telegram',
            telegram_instruction: 'Отправьте нашему Telegram-боту {bot} команду ниже',
            telegram_command_label: 'Команда',
            open_telegram: 'Открыть Telegram'
        },
        levels: {
            bonuses_used: 'Бонусов получено',
            bonus_rules: 'Бонусная система',
            levels: 'Уровни',
            bonus_rule_1: 'Существует определенный лимит на количество бонусов, которое Вы можете получить. Когда вы достигнете данного лимита, Вы не сможете использовать промокоды, пока не понизите его.',
            bonus_rule_2: 'Каждый раз, когда Вы активируете {promocode}, Вы {increase} шкалу использованных бонусов на сумму промокода.',
            bonus_rule_3: 'Каждый раз, когда Вы совершаете {deposit}, Вы {decrease} шкалу использованных бонусов на сумму депозита, но не ниже 0.',
            bonus_rule_4: 'Каждый раз, когда Вы увеличививаете свой {level}, шкала использованных бонусов {reset}, а лимит повышается согласно нижеприведенной таблице.',
            bonus_rule_5: 'Ваш уровень зависит от суммы Ваших ставок. Данная информация доступна на {history}.',
            promocode: 'промокод',
            increase: 'увеличиваете',
            decrease: 'уменьшаете',
            deposit: 'депозит',
            level: 'уровень',
            reset: 'обнуляется',
            history_page_link: 'странице истории игр',
            header_level: 'Ур.',
            header_bet_sum: 'Ставок',
            header_bonus_limit: 'Лимит бонусов'
        },
        partnership: {
            widthdraw: 'Вывести',
            level: 'Уровень',
            to_level: 'До уровня',
            your_link: 'Ваша реферальная ссылка',
            referrals: 'Рефералов',
            earned: 'Заработано',
            you_get: 'Ваш процент',
            available: 'Доступный баланс',
            minimal_amount: 'Минимальная сумма к выводу',
            referral_system: 'Реферальная система',
            referral_link: 'реферальной ссылке',
            comission: 'комиссию',
            rule_1: 'Пользователь должен зарегистрироваться по Вашей {link}, чтобы стать Вашим рефералом.',
            rule_2: 'Вы получаете {comission} с каждого пополнения всех Ваших рефералов.',
            rule_3: 'Размер отчислений зависит от уровня, то есть от суммы пополнений всех Ваших рефералов согласно нижеприведенной таблице.',
            rule_4: 'Каждый реферал получает {bonus}.',
            invitational: 'приветственный бонус к депозиту',
            header_level: 'Ур.',
            header_deposit_sum: 'Пополнений',
            header_percent: 'Отчисления',
            header_bonus: 'Бонус',
            to_first_deposit: 'к первому пополнению',
            withdrawed: 'Реферальный баланс добавлен к основному',
            withdrawal_blocked: 'Вы не можете выводить реферальный баланс',
            min_withdraw: 'Минимальная сумма вывода - {value}'
        }
    },
    deposit: {
        deposit_header: 'Пополнение',
        history_header: 'История',
        wrong_phone_number: 'Неправильно указан номер телефона',
        inactive_method: 'Выбранное платежное направление неактивно',
        methods: {
            card: 'Банковская карта',
            card_2: 'Банковская карта #2',
            qiwi: 'QIWI',
            qiwi_2: 'QIWI #2',
            fk: 'FKWallet',
            piastrix: 'Piastrix',
            yoomoney: 'ЮMoney',
            skinpay: 'SkinPay',
            visa: 'Visa',
            mastercard: 'Mastercard',
            mir: 'МИР',
            advcash: 'Advcash',
            sber: 'Сбербанк',
            onlinebank: 'Онлайн банк',
            perfectmoney: 'Perfect Money',
            tether: 'Tether ERC20',
            tether_trc20: 'Tether TRC20',
            bitcoin: 'Bitcoin',
            litecoin: 'Litecoin',
            ethereum: 'Ethereum',
            usdt_erc20: 'USDT (ERC20)',
            usdt_trc20: 'USDT (TRC20)',
            bitcoin_cash: 'Bitcoin Cash',
            dash: 'Dash',
            dogecoin: 'Dogecoin',
            zcash: 'ZCash',
            steampay: 'Steam Pay',
            skins: 'Оплата скинами',
            skins_2: 'Оплата скинами #2',
            google_pay: 'Google Pay',
            apple_pay: 'Apple Pay'
        },
        your_currency: 'Валюта',
        available_methods: 'Способ оплаты',
        sum: 'Сумма',
        invitational_bonus: 'Приветственный бонус',
        have_promocode: 'Есть промокод?',
        deposit: 'К оплате',
        promocode_applied: 'Промокод применен',
        promocode_removed: 'Промокод отменен',
        min_dep_with_promo: 'Минимальная сумма с промокодом: {value}',
        promocode_placeholder: 'Введите промокод',
        warning_1: 'Максимальный бонус с промокодом',
        warning_2: 'Максимальный приветственный бонус',
        warning_3: 'Бонусы не суммируются, будет использован только наиболее выгодный. В таком случае, приветственный бонус сгорит',
        warning_4: 'Минимальная сумма пополнения',
        boost_1: 'При пополнении данным методом, на баланс автоматически будет начислен бонус <span class="text_light_bold">+{percent}%</span> от суммы пополнения',
        history: {
            empty: 'Вы еще не совершали пополнений',
            payment_id: 'ID платежа',
            payed: 'Оплачено',
            credited: 'Зачислено'
        }
    },
    payment: {
      qiwi: {
        heading: 'Оплата QIWI',
        wallet_number: 'Номер кошелька для перевода',
        comment: 'Комментарий (обязательно!)',
        amount: 'Сумма для перевода (₽)',
        warning_1: 'Данные перевода необходимо заполнить <span class="text_light_bold">В ТОЧНОСТИ</span>, как показано ниже, иначе платеж <span class="text_danger">НЕ БУДЕТ ЗАЧИСЛЕН</span>',
        warning_2: 'Значение в поле <span class="text_light_bold">"Сумма"</span> должно совпадать со значением на сайте <span class="text_light_bold">С ТОЧНОСТЬЮ ДО КОПЕЕК</span>',
        warning_3: 'Если вы хотите <span class="text_light_bold">изменить сумму</span> или <span class="text_light_bold">повторить платеж</span> - <span class="text_light_bold">ЗАКРОЙТЕ ЭТО ОКНО</span> и создайте платеж заново',
        success: 'Заказ оплачен. Удачных игр!',
        instruction: 'Для оплаты заказа перейдите в свой QIWI и выполните <span class="text_light_bold">перевод на QIWI кошелек</span>, заполнив данные перевода <span class="text_light_bold">в точности как показано ниже</span>',
        after_1: 'Платеж будет зачислен <span class="text_light_bold">автоматически в течении нескольких минут</span>',
        status_text: 'Статус платежа',
        status_waiting: 'Ожидает оплаты'
      }
    },
    withdraws: {
        can_not_withdraw_bonus: 'Невозможно вывести бонусный предмет',
        blocked: 'Выводы заблокированы. Причина: {value}',
        empty_trade_link: 'Пожалуйста, укажите свою трейд-ссылку',
        min_deposit: 'Вы должны иметь пополнений на ${value} для того, чтобы выводить',
        wrong_data: 'Некорректные параметры запроса',
        cant_request: 'Мы не можем приобрести данный предмет на текущий момент. Пожалуйста, попробуйте вывести другой предмет или попробуйте позже',
        expired: 'Время ожидания сделки истекло. Вы можете вывести предмет заново',
        check_inventory: 'Предмет был отправлен, проверьте свой инвентарь',
        successful_withdraw: 'Вывод успешно завершен',
        requesting: 'Запрашиваем предмет...',
        waiting: 'Ожидание продавца...',
        receive: 'Принять',
        take: 'Вывод',
        sell: 'Продать',
        too_many_failed: 'Выводы заблокированы до {value}. Причина: Вы отклонили слишком много выводов',
        wager: 'Вы должны отыграть свой депозит. Чтобы отыграть депозит, необходимо совершить ставок на сумму {value} на коэффициенты не меньше чем x{value1}',
        overprice: 'Мы не можем приобрести данный предмет на текущий момент. Пожалуйста, попробуйте вывести другой предмет или попробуйте позже',
        trade_closed: 'Мы не смогли отправить предмет. Пожалуйста, убедитесь, что на Вашем аккаунте разрешен обмен вещами',
        max_pending: 'Нельзя выводить более {value} предметов одновременно'
    },
    chat: {
        rules: 'Правила',
        message_placeholder: 'Введите сообщение...',
        required: 'Вы не можете отправить пустое сообщение',
        max: 'Максимальная длина сообщения: {value} символов',
        block: 'Вы заблокированы в чате',
        links_not_allowed: 'Ссылки запрещены',
        not_found: 'Сообщение не найдено',
        muted: 'Пользователь заблокирован до {value}',
        unmuted: 'Пользователь разблокирован',
        already_muted: 'Пользователь уже заблокирован',
        can_not_mute_myself: 'Нельзя замутить себя',
        can_not_unmute_myself: 'Нельзя размутить себя',
        rules_violation: 'Нарушение правил чата',
        reason: 'Причина: {value}',
        you_muted: 'Чат заблокирован до {value}',
        disallowed: 'Запрещается:',
        rule_1: 'Оскорблять других в любой форме, демонстрировать агрессивное или неуважительное поведение',
        rule_1_1: 'Оскорблять сайт и сотрудников сайта',
        rule_2: 'Отправлять промокоды',
        rule_3: 'Спамить',
        rule_4: 'Отправлять ссылки на внешние сайты',
        rule_5: 'Попрошайничать',
        rule_6: 'Мошенничать, обманывать других',
        rule_7: 'Продавать товары, услуги, аккаунты и т.п.',
        commands: 'Вам доступны следующие команды:',
        cmd_1: 'отправляет игровую статистику в чат',
        cmd_2: 'отправляет финансовую статистику в чат',
        be_respectful: 'Пожалуйста, будьте уважительны и доброжелательны к другим пользователям. Не позволяйте злости и эмоциям взять верх. Если вы чувствуете, что много проигрываете - ',
        take_a_break: 'отдохните',
        play_cool: 'Сохраняйте рассудительность и да пребудет с Вами удача.',
        deposits: 'Пополнений',
        withdraws: 'Выводов',
        balance: 'Баланс',
        best_multiplier: 'Топ коэф.',
        best_winstreak: 'Топ винстрик',
        best_win: 'Топ выигрыш',
        total_bets: 'Всего ставок',
        min_deposit: 'Чтобы писать в чат, необходимо иметь пополнений на {value}$'
      },
      market: {
        title: 'Магазин предметов',
        user_pay: 'Отдаете',
        order_total: 'Покупаете',
        residue: 'Остаток',
        buy: 'Купить',
        empty: 'Подходящие предметы не найдены. Пожалуйста, выберите больше предметов из инвентаря или пополните свой баланс.',
        successful_purchase: 'Успешная покупка! Проверьте свой инвентарь',
        max_market_items_per_transaction: "Максимальное число предметов за одну транзакцию: {value}",
        max_stash_items_per_transaction: "Максимальное число предметов инвентаря за одну транзакцию: {value}",
        market_changed: 'Некоторые из выбранных предметов недоступны. Пожалуйста, обновите страницу и попробуйте еще раз',
        prices_changed: 'Цены выбранных предметов изменились. Пожалуйста, обновите страницу и попробуйте еще раз',
        can_not_change_bonus: 'Невозможно использовать бонусный предмет для покупки',
        types: {
          all: 'Все типы',
          weapon: 'Оружие',
          gloves: 'Перчатки',
          graffiti: 'Граффити',
          collectible: 'Коллекционное',
          container: 'Кейс',
          pass: 'Пропуск',
          key: 'Ключ',
          gift: 'Подарок',
          music_kit: 'Набор музыки',
          other: 'Другое',
        },
        rarities: {
          all: 'Все раритетности',
          contraband: 'Контрабанда',
          master: 'Мастерский',
          superior: 'Превосходный',
          distinguished: 'Заслуженный',
          exotic: 'Экзотический',
          covert: 'Тайное',
          industrial_grade: 'Промышленное', 
          milspec_grade: 'Армейское', 
          restricted: 'Запрещенное', 
          classified: 'Засекреченное',
          extraordinary: 'Экстраординарное',
          base_grade: 'Базовый класс', 
          consumer_grade: 'Ширпотреб', 
          high_grade: 'Высший класс',
          remarkable: 'Примечательный', 
          exceptional: 'Исключительный', 
        },
        exteriors: {
          all: 'Все качества', 
          not_painted: 'Не покрашено', 
          battlescarred: 'Закаленное в боях', 
          factory_new: 'Прямо с завода', 
          fieldtested: 'После полевых', 
          minimal_wear: 'Немного поношенное',
          wellworn: 'Поношенное',
        }
    },  
    rank: {
        title: 'LVL'
    },
    filters: {
        name: 'Название',
        price: 'Цена'
    },
    bet: {
        auto_cashout: 'Авто-вывод',
        items: 'предметов'
    },
    crash: {
        name: 'Crash',
        play: 'Играть',
        in_game: 'В игре',
        ping: 'Ping',
        successful_bet: 'Ставка принята',
        game_in_progress: 'Игра идет...',
        cashout: 'Вывести',
        successful_cashout: 'Успешно вывели!',
        game_not_started: 'Игра не началась',
        game_ened: 'Игра уже закончилась',
        did_not_bet: 'Вы не ставили в этой игре',
        bet_inactive: 'Вы не можете вывести эту ставку',
        can_not_cashout: 'Вы не можете вывести эту ставку',
        min_cashout: 'Минимальный вывод: x{value}',
        you_won: 'Вы выиграли',
        going_to_autocashout: 'Ваша ставка будет выведена через несколько секунд...',
        wrong_auto_cashout: 'Неверный параметр авто-вывода',
        crash_bet_max_items: 'Максимальное число предметов в ставке: {value}',
        can_not_mix_bonus: 'Невозможно поставить бонусные и обычные предметы вместе',
        perfomance: 'Проблемы с производительностью? Попробуйте отключить анимацию графика'
    },
    mines: {
        name: 'Mines'
    },
    wheel: {
        name: 'Wheel'
    },
    footer: {
        rights: 'All rights reserved',
        version: 'Version',
        about_us: 'О нас',
        community: 'Сообщество',
        email: 'Электронный адрес'
    },
    util: {
      date: 'Дата',
      loading: 'Загрузка...',
      or: 'или',
      seconds: 'с',
      gifted: 'Вы получили бонусный предмет!',
      ok: 'OK',
      yes: 'Да',
      no: 'Нет',
      next: 'След.',
      prev: 'Пред.',
      save: 'Сохранить',
      apply_changes: 'Сохранить изменения',
      confirm: 'Подтвердить',
      copied: 'Скопировано в буфер обмена',
      copy: 'Копировать',
      usd: 'USD',
      rub: 'RUB',
      min_value: 'Минимальное значение: {value}',
      max_value: 'Максимальное значение: {value}',
      apply: 'Применить',
      no_bets: 'Нет ставок в этом раунде',
      bets_hidden: 'Отображение ставок скрыто в настройках',
      maintenance_header: 'Технические работы',
      maintenance_text: 'Наша команда занимается улучшением и обновлением систем. Пожалуйста, ожидайте, мы вернемся в ближайшее время.',
      payment_success: 'Платеж зачислен на счет!'
  },
  errors: {
      min_bonus_multiplier: 'Нельзя вывести бонусный предмет на коэффициенте ниже х{value}',
      balance_too_low: 'У вас недостаточно средств',
      inventory_changed: 'Ваш инвентарь изменился. Пожалуйста, обновите страницу и попробуйте еще раз',
      generic: 'Упс, что-то пошло не так. Пожалуйста, обновите страницу и попробуйте еще раз',
      game_started: 'Невозможно сделать ставку, игра уже началась',
      bet_already_placed: 'Вы уже сделали ставку в этой игре',
      wrong_items: 'Похоже, вы не выбрали ни одного предмета из инвентаря',
      too_many_requests: 'Не так быстро',
      forbidden: 'Пожалуйста, авторизуйтесь, чтобы продолжить',
      internal: 'Произошла неизвестная ошибка. Технические специалисты уже взялись за нее.',
      permission_denied: 'У вас недостаточно прав для совершения данного действия',
      blocked: 'Ваш аккаунт был заблокирован за нарушение правил',
      payment: 'При оплате возникла ошибка. Пожалуйста, свяжитесь с поддержкой'

  },
      privacy: {
          content: '<div class="text__text">Последнее обновление: {update}</div>\
          <div class="text__text mt-15">\
            Пожалуйста, внимательно прочитайте эту Политику конфиденциальности\
            перед использованием <a href="{url}" class="default-link">{url}</a>.\
          </div>\
          <div class="text__text mt-15">\
            Мы предоставляем доступ к различным играм на нашей платформе.\
            Пользуясь нашими сервисами, Вы доверяете нам свою информацию. Мы\
            понимаем, что это большая ответственность, и прилагаем все усилия,\
            чтобы защитить Ваши данные и дать Вам возможность управлять ими.\
          </div>\
          <div class="text__text mt-15">\
            В этой Политике конфиденциальности описано, какую информацию мы\
            собираем, с какой целью мы это делаем и как Вы можете обновлять,\
            удалять свои данные и управлять ими.\
          </div>\
\
          <div class="text__subheading mt-30">\
            Информация, которую мы собираем\
          </div>\
          <div class="text__text mt-15">\
            Виды персональной информации, которую мы собираем:\
          </div>\
          <div class="text__text mt-15">\
            <ul class="text__list">\
              <li>Имя пользователя</li>\
              <li>Адрес электронной почты (при добровольном указании в профиле)</li>\
              <li>IP-адрес</li>\
              <li>Ссылку на обмен Steam (а также историю ссылок, если вы осуществляли вывод на разные аккаунты в Steam)</li>\
              <li>При создании Вами аккаунта через социальные сети, мы сохраняем Ваше имя пользователя, фотографию профиля, ссылку на личную страницу в данной социальной сети.</li>\
              <li>При создании Вами аккаунта через Steam, помимо информации, указанной выше, мы также сохраняем тип видимости профиля (открый, закрытый), уровень Steam, количество часов, сыгранных в CS:GO.</li>\
            </ul>\
          </div>\
          <div class="text__text mt-15">\
            У каждого пользователя, который создает аккаунт, есть логин и\
            пароль.\
            <span class="text_light_bold"\
              >Мы храним ваш пароль в зашифрованном виде (его хеш), таким\
              образом, никто, кроме Вас, не знает Ваш пароль</span\
            >. Подробнее об этом Вы можете узнать в\
            <a\
              href="https://en.wikipedia.org/wiki/Cryptographic_hash_function#Password_verification"\
              target="_blank"\
              rel="noopener"\
              class="default-link"\
              >Википедии</a\
            >\
          </div>\
          <div class="text__subheading mt-30">\
            Как мы используем информацию, полученную от пользователей\
          </div>\
          <div class="text__text mt-15">\
            Став пользователем, или иным способом предоставив\
            {name} персональную информацию, Вы соглашаетесь с тем, что мы\
            собираем и используем Вашу персональную информацию для\
            предоставления нами услуг.\
          </div>\
          <div class="text__text mt-15">\
            Вы соглашаетесь на предоставление адреса электронной почты третьей\
            стороне для маркетинговых целей.\
          </div>\
          <div class="text__text mt-15">\
            Вы соглашаетесь на предоставление id игры, имени Вашего\
            пользователя, ставки, выигрышного коэффициента и выигрыша для\
            публикации в наших социальных сетях.\
          </div>\
          <div class="text__text mt-15">\
            Вы соглашаетесь с тем, что мы имеем право раскрывать вашу\
            персональную информацию, но лишь с целью и в случаях, если такое\
            требование выдвинуто государственными органами, в предусмотренном\
            действующим законодательством порядке.\
          </div>\
          <div class="text__subheading mt-30">\
            Мы получаем информацию от пользователя для того, чтобы пользователь\
            мог:\
          </div>\
          <div class="text__text mt-15">\
            <ul class="text__list">\
              <li>пользоваться нашими услугами, в частности, играть в игры и приобретать виртуальные предметы;</li>\
              <li>писать в чате, коммуницировать с другими пользователями;</li>\
              <li>принимать предложения об обмене в Steam;</li>\
              <li>создавать, управлять, закрывать аккаунт.</li>\
            </ul>\
          </div>\
          <div class="text__text mt-15">\
            Профиль пользователя по умолчанию является закрытым. Пользователь\
            имеет доступ к своему профилю в полном объеме. Пользователь не имеет\
            доступа к профилю других пользователей.\
          </div>\
          <div class="text__subheading mt-30">\
            Мы получаем информацию от пользователя для того, чтобы мы могли:\
          </div>\
          <div class="text__text mt-15">\
            <ul class="text__list">\
              <li>идентифицировать Вас в качестве пользователя для безопасности и соблюдения наших юридических обязательств;\
              </li>\
              <li>поддерживать учетную запись пользователя;</li>\
              <li>проверять достоверность информации, предоставленную пользователем;</li>\
              <li>получать вопросы и комментарии от пользователя, чтобы реагировать на них и улучшать наши сервисы;</li>\
              <li>периодически направлять письменные сообщения о важных изменениях в сервисе, вопросах технического обновления и т.д.;</li>\
              <li>изучать подозрение на незаконную, мошенническую деятельность, связанную с нашими услугами;</li>\
              <li>сообщать в контролирующие органы о совершении преступлений или подозрения преступлений, включая отмывание денег или мошенничество;</li>\
              <li>повышать безопасность и надежность наших сервисов;</li>\
              <li>выполнять другие задачи, которые необходимы для выполнения договорных обязательств перед пользователем.</li>\
            </ul>\
          </div>\
          <div class="text__subheading mt-30">Маркетинг</div>\
          <div class="text__text mt-15">\
            Время от времени мы можем использовать Вашу персональную информацию,\
            чтобы сообщать Вам о наших продуктах или услугах, или о рекламных\
            акциях, которые, по нашему мнению, могут быть Вам интересны или\
            полезны. Если Вы больше не хотите получать маркетинговые или\
            рекламные материалы от {name} вообще, или в какой-либо\
            конкретной форме, Вы можете в любое время обратиться к нам через\
            оператора поддержки.\
          </div>\
          <div class="text__subheading mt-30">\
            Доступ к персональной информации\
          </div>\
          <div class="text__text mt-15">\
            Вы можете получить доступ к персональной информации, собранной\
            {name}, \
            связавшись с нами через оператора поддержки\
          </div>\
\
          <div class="text__subheading mt-30">Управление информацией</div>\
          <div class="text__text mt-15">\
            Мы примем все разумные меры, чтобы обеспечить сохранность\
            собираемой, используемой или раскрываемой персональной информации\
            пользователя.\
          </div>\
          <div class="text__text mt-15">\
            Доступ к такой информации могут иметь только уполномоченные лица.\
          </div>\
          <div class="text__text mt-15">\
            {name} уничтожит персональную информацию, которой мы владеем,\
            после того, как в ее использовании, хранении или раскрытии больше не\
            будет надобности, но не скорее 5 лет после ее использования.\
          </div>\
\
          <div class="text__subheading mt-30">Безопасность</div>\
          <div class="text__text mt-15">\
            Вы гарантируете, что применяете все возможные способы для\
            сохранности информации, которую Вы нам передаете.\
          </div>\
          <div class="text__text mt-15">\
            Вы несете ответственность за безопасность и доступ к Вашему\
            собственному компьютеру, мобильному устройству или любому другому\
            устройству, используемого для получения доступа к сайту.\
          </div>\
\
          <div class="text__text mt-15">\
            Вы несете полную ответственность за сохранение секретности Вашего\
            имени пользователя, пароля и любой другой информации об аккаунте.\
          </div>\
          <div class="text__text mt-15">\
            Мы предпримем все разумные меры для защиты Вашей персональной\
            информации от неправомерного использования, потери и\
            несанкционированного доступа, изменения и раскрытия, в том числе с\
            использованием защищенных паролем систем, баз данных и технологии\
            Secure Socket Layer (SSL).\
          </div>\
          <div class="text__text mt-15">\
            Сотрудники, агенты и подрядчики {name} обязаны соблюдать\
            конфиденциальность персональной информации пользователей.\
          </div>\
          <div class="text__subheading mt-30">Изменения в этой политике</div>\
          <div class="text__text mt-15">\
            Мы время от времени меняем эту Политику конфиденциальности, однако\
            не ограничиваем Ваши права, описанные в ней, без вашего четкого\
            согласия. Мы всегда указываем дату публикации последних изменений и\
            предоставляем доступ к архивным версиям. Если изменения существенны,\
            мы сделаем более заметное сообщение (в частности, об изменениях в\
            политике конфиденциальности некоторых сервисов мы сообщаем\
            электронной почте).\
          </div>'
      },
      terms: {
          content: '<div class="text__text">Последнее обновление: {update}</div>\
          \
                    <div class="text__text mt-15">\
                      Настоящие Условия и положения (далее – «Соглашение») регулируют\
                      договорные отношения между сторонами, «{name}» с одной стороны\
                      (далее - «{name}», «Мы» или «Наш») и Вами, как пользователем с\
                      другой стороны (далее - «Пользователь», «Вы» или «Ваш»), вместе\
                      «Стороны».\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      «{name}» - интернет-площадка, расположенная в сети Интернет по адресу\
                      <a href="{url}" class="default-link">{url}</a>\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      «Пользователь» - лицо, которое соглашается с этими Условиями и\
                      положениями.\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      Принимая это Соглашение Пользователь тем самым свидетельствует и\
                      гарантирует, что он внимательно ознакомился с настоящим Соглашением\
                      перед использованием Нашего Веб-сайта\
                      <a href="{url}" class="default-link">{url}</a> (далее -\
                      «Веб-сайт» или «Сайт») и обязуется выполнять и придерживаться всех\
                      условий, определённых Сторонами в этом Соглашении.\
                    </div>\
          \
                    <div class="text__subheading mt-30">Предмет соглашения</div>\
          \
                    <div class="text__text mt-15">\
                      {name} предоставляет услуги по организации досуга и отдыха в\
                      игре «{url}» Пользователю в соответствии с условиями и\
                      положениями данного Соглашения. Под такими услугами, в частности,\
                      подразумеваются следующие: услуги по покупке-продаже игрового\
                      инвентаря; ведение учета значимой информации; движения по игровому\
                      счету; обеспечение мер по идентификации и безопасности\
                      Пользователей; разработка программного обеспечения, интегрируемого в\
                      игровую площадку и внешние приложения; информационные и другие\
                      услуги, необходимые для организации игры и обслуживания Пользователя\
                      на Веб-сайте.\
                    </div>\
          \
                    <div class="text__subheading mt-30">Права и обязанности сторон</div>\
          \
                    <div class="text__text mt-15">\
                      Вы несете персональную ответственность за ведение своих финансовых\
                      сделок и операций. {name} не несет ответственности за\
                      совершаемые финансовые действия между Пользователями по передаче\
                      игрового инвентаря и игровой валюты, а также других игровых\
                      атрибутов.\
                    </div>\
                    <div class="text__text mt-15">\
                      Вы несете персональную ответственность за все действия, совершенные\
                      с игровым инвентарём: покупка, продажа, пополнение и вывод средств и\
                      предметов, а также за игровые действия на Сайте: создание,\
                      покупка-продажа, операции со всеми игровыми элементами и другие\
                      игры, атрибуты и объекты, используемые для игрового процесса.\
                    </div>\
                    <div class="text__text mt-15">\
                      Вы не имеете права ограничивать доступ третьим лицам к играм на\
                      Сайте, Вы должны уважительно относиться к Пользователям игры, а\
                      также к {name}, нашим партнерам и сотрудникам.\
                    </div>\
                    <div class="text__text mt-15">\
                      Мы гарантируем, без взимания платы, доступ Пользователя к Веб-сайту\
                      и участие в игре. Пользователь самостоятельно оплачивает доступ к\
                      интернету за свой счет и несет другие расходы, связанные с этим\
                      действием.\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      Мы ведем учет игрового инвентаря в игровой учетной записи\
                      Пользователя.\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      Мы предоставляем пользователю дополнительные платные услуги,\
                      перечень которых, а также порядок и условия их использования,\
                      определяются настоящими Условиями и положениями, правилами игровой\
                      площадки и другими объявлениями {name}. В то же время, мы имеем\
                      право в любой момент изменить количество и объем предлагаемых\
                      платных услуг, их стоимость, название, вид и эффект от\
                      использования.\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      Мы не несем ответственности за неправильное функционирование\
                      игрового программного обеспечения. Мы используем программное\
                      обеспечение по принципу «КАК ЕСТЬ». Если мы определим, что во время\
                      игры на платформе произошла неисправность (ошибка), результаты,\
                      которые произошли во время неправильной работы программного\
                      обеспечения, могут быть отменены или скорректированы по Нашему\
                      усмотрению. Пользователь обязуется не обращаться к {name} о\
                      качестве, количестве, порядке и строках предоставляемых ему игровых\
                      возможностей и услуг.\
                    </div>\
          \
                    <div class="text__subheading mt-30">Ограничения</div>\
                    <div class="text__text mt-15">\
                      Психологическое состояние. Если Вам в любой момент поставили диагноз\
                      компульсивного расстройства, или Вы\
                      чувствуете, что у Вас могут возникнуть проблемы, немедленно покиньте\
                      Наш Веб-сайт.\
                    </div>\
                    <div class="text__text mt-15">\
                      Возраст. Вы можете играть, если Вам больше 18 лет. В некоторых\
                      государствах, где возрастной ценз выше 18 лет, Вы должны достигнуть\
                      совершеннолетия в соответствии законодательства страны Вашего\
                      гражданства, для использования наших услуг.\
                    </div>\
          \
                    <div class="text__subheading mt-30">\
                      Открытие и обслуживание аккаунта\
                    </div>\
                    <div class="text__text mt-15">\
                      Вы должны зарегистрировать аккаунт на нашем Сайте лично, следуя\
                      пошаговым инструкциям, предоставленным Сайтом.\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      Регистрируя учетную запись или по запросу Веб-сайта, Вы\
                      соглашаетесь: предоставлять правдивую, точную, актуальную и полную\
                      информацию о себе. В случае внесения изменений Вы соглашаетесь\
                      обновить эту информацию.\
                    </div>\
                    <div class="text__text mt-15">\
                      Вы несете полную ответственность за любое несанкционированное\
                      использование Вашей учетной записи третьими лицами в случае утраты\
                      пароля и иных сведений для входа в аккаунт, разглашения Вами этой\
                      информации, ненадлежащего хранения такой информации, случайной\
                      утечки вышеуказанных сведений, взлома Вашего аккаунта, а также в\
                      иных случаях. Мы советуем Вам принять все возможные меры для\
                      сохранения конфиденциальности Вашего псевдонима / имени пользователя\
                      / логина и / или пароля. Если Вы заметили несанкционированное\
                      использование Вашей учетной записи, пожалуйста, сообщите Нам об этом\
                      немедленно.\
                    </div>\
                    <div class="text__text mt-15">\
                      Если какое-либо лицо обманным путем получает доступ к учетной\
                      записи, Мы немедленно прекращаем его членство и предпринимаем все\
                      необходимые и надлежащие действия в соответствии с настоящим\
                      Соглашением, и применимыми законами и правилами. Мы не несем\
                      ответственности в связи с мошенническим доступом и использованием\
                      аккаунта пользователя.\
                    </div>\
                    <div class="text__text mt-15">\
                      Если Ваши действия рассматриваются как преднамеренное мошенничество,\
                      Мы можем конфисковать все средства на Вашем счете и принудительно\
                      закрыть аккаунт.\
                    </div>\
                    <div class="text__text mt-15">\
                      Вам разрешено иметь только одну учетную запись. Любые дубликаты\
                      аккаунтов запрещены, и Вы должны немедленно сообщить Нам, если Вы\
                      зарегистрировали дубликат аккаунта. Все транзакции, совершенные с\
                      любого дублирующего аккаунта, будут считаться недействительными. Все\
                      призы и выигрыши с дубликатов аккаунтов будут аннулированы, а все\
                      Ваши аккаунты будут принудительно закрыты.\
                    </div>\
                    <div class="text__text mt-15">\
                      Если Вы хотите открыть новую учетную запись, Вы должны связаться с\
                      Нашей командой поддержки клиентов. После открытия нового счета, под\
                      нашим контролем, старый будет закрыт, внесенные Вами средства и\
                      выигрыши переведены не будут.\
                    </div>\
                    <div class="text__text mt-15">\
                      В случае, если Вам станет известно о любых возможных ошибках в\
                      программном обеспечении Сайта, Вы обязуетесь не использовать их в\
                      своих интересах. Кроме того, Вы обязаны немедленно сообщать в службу\
                      поддержки клиентов о любой ошибке или неполной информации. Если Вы\
                      не выполните вышеуказанные обязательства, Мы имеем право на полное\
                      возмещение Вами всех расходов и убытков, связанных с ошибкой и ее\
                      использованием Вами, путем конфискации средств на Вашем счете, а\
                      также закрытия Вашего аккаунта.\
                    </div>\
                    <div class="text__subheading mt-30">Платежи</div>\
                    <div class="text__text mt-15">\
                      Валюта Вашего счета - доллар США. Вы можете переводить средства в\
                      любой валюте. На Ваш счет на Веб-сайте будет зачислена\
                      конвертированная сумма в долларах США.\
                    </div>\
                    <div class="text__text mt-15">\
                      Как правило, мы не принимаем денежные депозиты и депозиты / снятия\
                      со счетов третьих лиц.\
                    </div>\
                    <div class="text__text mt-15">\
                      Все способы оплаты будут доступны на Веб-сайте, они могут отличаться\
                      в зависимости от страны, а также могут время от времени изменяться\
                      Веб-сайтом.\
                    </div>\
                    <div class="text__text mt-15">\
                      Вы несете ответственность за оплату сборов, которые могут взимать\
                      платежные системы. Средства, внесенные на счет на Веб-сайте, должны\
                      быть очищены от всех таких сборов.\
                    </div>\
                    <div class="text__subheading mt-30">Покупки и инвентарь</div>\
                    <div class="text__text mt-15">\
                      Имеющиеся на Вашем аккаунте средства Вы можете обменивать на\
                      виртуальные предметы на торговой площадке Веб-сайта.\
                    </div>\
                    <div class="text__text mt-15">\
                      Цены на торговой площадке устанавливаются Веб-сайтом, не являются\
                      официальными ценами торговой площадки Steam, и могут меняться со\
                      временем.\
                    </div>\
                    <div class="text__text mt-15">\
                      Осуществляя приобретение виртуального предмета, пользователь\
                      соглашается с ценой, установленной торговой площадкой Веб-сайта на\
                      момент совершения покупки, и отказывается от претензий в случае,\
                      если данная цена не совпадает с ценой торговой площадки Steam или с\
                      ценами на иных торговых площадках.\
                    </div>\
                    <div class="text__text mt-15">\
                      Вознаграждения за любые действия, совершаемые Вами на Веб-сайте,\
                      выдаются в виде виртуальных предметов.\
                    </div>\
                    <div class="text__text mt-15">\
                      При отсутствии нарушений настоящего Соглашения с Вашей стороны, Вы\
                      можете обменять свои виртуальные предметы на предметы инвентаря в\
                      Steam.\
                    </div>\
                    <div class="text__text mt-15">\
                      Такой обмен происходит по курсу, установленному торговой площадкой\
                      Веб-сайта, курсовая разница не возмещается. На период обмена Ваши\
                      виртуальные предметы временно замораживаются.\
                    </div>\
                    <div class="text__text mt-15">\
                      В случае, если вы отклоните обмен предметов от нашего Веб-сайта,\
                      замороженные виртуальные предметы будут разморожены в течении 24\
                      часов с момента создания предложения об обмене.\
                    </div>\
                    <div class="text__text mt-15">\
                      В случае, если вы отклоните предложение обмена от нашего Веб-сайта\
                      слишком много раз, Мы вправе временно или перманентно приостановить\
                      возможность обмена Вами виртуальных предметов.\
                    </div>\
                    <div class="text__subheading mt-30">Запреты и ограничения</div>\
                    <div class="text__text mt-15">\
                      Запрещается использовать Веб-сайт в целях распространения клеветы,\
                      нанесения оскорблений, расизма, сексизма, гомофобии или\
                      дискриминации по отношению к любым Пользователям, третьим лицам\
                      разных религиозных убеждении, расы, цвета кожи, сексуальной\
                      ориентации и так далее. Запрещено использовать нецензурную лексику\
                      на любом языке как словесно так и на изображениях, а так же угрозы,\
                      преследования или оскорбления в отношении любого другого лица /\
                      Пользователя.\
                    </div>\
                    <div class="text__text mt-15">\
                      В чате запрещено: публикация промокодов, спам, вымогательство,\
                      попрошайничество, любые внешние ссылки и кошельки, оскорбления в\
                      любом виде, торговля.\
                    </div>\
                    <div class="text__text mt-15">\
                      Запрещено продавать, передавать и / или приобретать аккаунты у\
                      других Пользователей.\
                    </div>\
                    <div class="text__text mt-15">\
                      Запрещается использовать любые внешние программы для получения\
                      преимуществ в играх.\
                    </div>\
                    <div class="text__text mt-15">\
                      Мы не допускаем мошенничества. Мошенничеством будут считаться\
                      следующие случаи:\
                    </div>\
                    <div class="text__text mt-15">\
                      <ul class="text__list">\
                        <li>работа с другими пользователями для получения прибыли;</li>\
                        <li>использование способов получения выигрыша путем обмана;</li>\
                        <li>возврат средств с кредитной карты;</li>\
                        <li>использование не принадлежащих Вам дебетовых и/или кредитных карт;</li>\
                        <li>другие виды мошенничества;</li>\
                        <li>предоставление Нам ложной информации.</li>\
                      </ul>\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      О любых программных ошибках, обнаруженных Пользователем, следует\
                      сообщать на Веб-сайте в службу поддержки клиентов. Категорически\
                      запрещается злоупотреблять ошибками в Ваших интересах в играх или в\
                      ущерб другим Пользователям. Такое злоупотребление будет\
                      классифицировано как мошенничество.\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      Сайт оставляет за собой право приостановить или удалить любую игру,\
                      в которой ошибка приводила к несправедливым результатам,\
                      некорректному поведению пользователей или процессу в целом.\
                    </div>\
          \
                    <div class="text__text mt-15">\
                      Мы имеем право расторгнуть настоящее Соглашение в одностороннем\
                      порядке, а также совершать другие действия, ограничивающие\
                      возможности в игре, в отношении Пользователя или группы\
                      Пользователей, которые являются соучастниками нарушений условий\
                      настоящего Соглашения. В то же время, все игровые атрибуты, игровое\
                      оборудование, находящиеся в учетной записи и в игровой учетной\
                      записи Пользователя или группы Пользователей, а также все расходы не\
                      подлежат возврату и не возмещаются.\
                    </div>\
          \
                    <div class="text__subheading mt-30">Гарантии</div>\
                    <div class="text__text mt-15">\
                      Мы отказываемся от любой ответственности за убытки Пользователя,\
                      связанные с неправильным пониманием принципов и правил работы Нашего\
                      Сайта.\
                    </div>\
                    <div class="text__text mt-15">\
                      Мы не несем ответственности за несанкционированный доступ к аккаунту\
                      Пользователя третьих лиц, произошедший по вине Пользователя.\
                    </div>\
                    <div class="text__text mt-15">\
                      Мы не несем ответственности за разницу в ценах между торговой\
                      площадкой Веб-сайта и иными тороговыми площадками (включая\
                      официальную торговую площадку Steam).\
                    </div>\
                    <div class="text__text mt-15">\
                      Мы не гарантируем постоянный и непрерывный доступ к игровой площадке\
                      и ее услугам в случае возникновения технических неполадок и/или\
                      непредвиденных обстоятельств, в числе которых: неполноценная работа\
                      или не функционирование интернет–провайдеров, серверов информации,\
                      банковских и платёжных систем, а также неправомерных действий\
                      третьих лиц.\
                    </div>\
                    <div class="text__text mt-15">\
                      Сайт не несет ответственности за нарушения, которые могут возникнуть\
                      в результате каких-либо действий любых Пользователей с любой целью,\
                      которая нарушает какой-либо закон.\
                    </div>\
                    <div class="text__text mt-15">\
                      Мы не несем налогового бремени за Пользователя. Пользователь\
                      обязуется самостоятельно включать возможные полученные доходы в\
                      налоговую декларацию в соответствии с законодательством страны своей\
                      резиденции.\
                    </div>\
                    <div class="text__text mt-15">\
                      Мы не несем никакой ответственности за невыполнение или задержку в\
                      выполнении любого из наших обязательств в соответствии с настоящим\
                      Соглашением, вызванными событиями, которые не находятся под нашим\
                      разумным контролем, включая, помимо прочего, стихийные бедствия,\
                      войны, гражданские волнения, прерывание в сетях или службах\
                      общедоступных коммуникаций, производственные споры или DDoS-атаки и\
                      аналогичные интернет-атаки, которые оказывают неблагоприятное\
                      воздействие. Деятельность Веб-сайта считается приостановленным на\
                      период форс-мажорных обстоятельств, и у нас будет продление времени\
                      для работы в течение этого периода. Мы приложим все усилия, чтобы\
                      завершить форс-мажорные обстоятельства или найти решение, с помощью\
                      которого Наши обязательства могут быть выполнены, несмотря на это\
                      событие.\
                    </div>\
          \
                    <div class="text__subheading mt-30">Изменения в соглашении</div>\
                    <div class="text__text mt-15">\
                      Мы можем изменить Соглашение в одностороннем порядке. В таких\
                      случаях согласие Пользователя не требуется. Мы запросим Ваше\
                      согласие для внесения этих изменений, если такое требование содержит\
                      действующее законодательство.\
                    </div>\
                    <div class="text__text mt-15">\
                      Новая версия Соглашения вступит в силу сразу после Вашего следующего\
                      посещения или входа на Веб-сайт.\
                    </div>\
                    <div class="text__text mt-15">\
                      Продолжая использовать Наш Веб-сайт, Вы принимаете последние\
                      изменения в Соглашении. Если Вы не согласны с условиями и\
                      положениями настоящего Соглашения, Вы не должны использовать Наш\
                      Веб-сайт или получать к нему доступ, а также немедленно сообщить Нам\
                      об этом в письменном виде.\
                    </div>'
      },
        
    }
}
