<template>
    <selectable-control :optionsClass="optionsClass" :btnClass="btnClass" ref="selectable_control">
        <template v-slot:button>
            <img class="selectable-control__selected-option-image" :src="langImage" alt="">
        </template>
        <template v-slot:options>
            <button class="selectable-control__option" @click="changeLang('RU')">
                <img src="/static/images/russia.svg" class="selectable-control__option-image" alt="">
                <span>Русский</span>
            </button>
            <button class="selectable-control__option" @click="changeLang('ENG')">
                <img src="/static/images/united-states.svg" class="selectable-control__option-image" alt="">
                <span>English</span>
            </button>
        </template>
    </selectable-control>
</template>

<script>
import SelectableControl from '@/misc/SelectableControl';

export default {
    props: ['optionsClass', 'btnClass'],
    components: { SelectableControl },
    data() {
        return {
            optionsShown: false
        }
    },
    methods: {
        hideOptions() {
            if (this.optionsShown) this.optionsShown = false;
        },

        changeLang(lang) {
            this.$store.dispatch('settings/setLang', lang, {root: true});
            this.$refs.selectable_control.hide();
            // this.$root.lang = lang;
            // this.$cookie.set('lang', lang);
            // this.$i18n.locale = lang;
        }
    },
    computed: {
        langImage() {
            if (this.$store.state.settings.lang == 'RU') {
                return '/static/images/russia.svg';
            }
            if (this.$store.state.settings.lang == 'ENG') {
                return '/static/images/united-states.svg';
            }
        }
    }
    
}
</script>