<template>
    <nav class="navigation navigation__mobile ml-15">
        <label @click.stop="opened = !opened" class="burger" :class="{'burger_active': opened}">
            <span></span>
            <span></span>
            <span></span>
        </label>
        <transition name="slide-fade">
            <div class="navigation__dropdown" v-show="opened" v-click-outside="close">
                <button @click.stop="openPage('bonus')" class="navigation__link">
                    <span class="icon-bonus navigation__icon"></span>
                    <span class="navigation__title">{{ $t('nav.bonus') }}</span>
                </button>

                <button @click.stop="openPage('fairness')" class="navigation__link mt-20">
                    <span class="icon-fair navigation__icon"></span>
                    <span class="navigation__title">{{ $t('nav.fair') }}</span>
                </button>
            </div>
        </transition>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            opened: false
        }
    },
    methods: {
        open() {
            this.opened = true;
        },
        close() {
            this.opened = false;
        },
        openPage(name) {
            this.close();
            this.$router.push({query: {...this.$route.query, m: name}}).catch(()=>{});
        }
    }
}
</script>

<style>
    
</style>