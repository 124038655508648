import router from "@/router";
import { processValidationError, processAPIError } from "@/util";
import config from '@/_config';
import axios from "axios";
import * as API from '@/services/API';
import i18n from '@/i18n';
import { DateTime } from "luxon";

export const namespaced = true;

export const state = {
    marketLoaded: false,
    marketOpened: false,
    marketItems: [],
    marketItemsSelected: [],
    filteredMarketItems: [],
    currentMarketItemsCount: 20,
    marketItemsLoading: false,
    cartTotal: 0,
    purchaseInProgress: false,
    marketPrices: [],

};

export const mutations = {
    ADD_ITEM_TO_CART(state, item) {
        item.selected = true;
        state.marketItemsSelected.push(item);
        state.cartTotal += item.price;
    },
    
    REMOVE_ITEM_FROM_CART(state, item) {
        item.selected = false;
        state.marketItemsSelected = state.marketItemsSelected.filter(x => x.id != item.id);
        state.cartTotal -= item.price;
    },

    SET_FILTERED_ITEMS(state, items) {
        state.filteredMarketItems = Object.freeze(items);
    },

    SET_DISPLAYED_ITEMS_COUNT(state, value) {
        state.currentMarketItemsCount = value;
    },

    UNSELECT_ALL_ITEMS(state) {
        state.marketItemsSelected.forEach(item => item.selected = false);
        state.marketItemsSelected.splice(0);
        state.cartTotal = 0;
    },

    SET_MARKET_ITEMS(state, items) {
        state.marketItems = Object.freeze(items);
    },
    
    SET_MARKET_LOADED_STATE(state, value) {
        state.marketLoaded = value;
    }, 

    SET_MARKET_OPENED_STATE(state, value) {
        state.marketOpened = value;
    },
    SET_PURCHASE_IN_PROGRESS(state, value) {
        state.purchaseInProgress = value;
    },
    SET_MARKET_PRICES(state, value) {
        state.marketPrices = value;
    } 
};

export const actions = {
    loadMarket({ commit, state }, market_last_update) {
        const cachedUpdate = localStorage.getItem("market_last_update") || 0;
        let prices = [];
        if (cachedUpdate === 0 || cachedUpdate < market_last_update) {
            axios
                .get(config.backend.auth_url + '/assets/static/skins/items.json?t=' + DateTime.now().toMillis())
                .then(response => {
                    
                    let _items = response.data.items;
                    _items.forEach(item => {
                        item.selected = false; 
                        item.marketItem = true;
                        prices.push(item.price);
                    });
                   
                    prices.reverse();
                    commit('SET_MARKET_PRICES', prices.slice());

                    commit('SET_MARKET_ITEMS', _items);
                
                    commit('SET_MARKET_LOADED_STATE', true);
                    localStorage.setItem("market_items", JSON.stringify(_items));
                    localStorage.setItem("market_last_update", market_last_update);
                })
                .catch(error => {
                    commit('SET_MARKET_LOADED_STATE', false);
                });
        }
        else {
            let _items = JSON.parse(localStorage.getItem("market_items"));
            _items.forEach(item => {
                item.selected = false; 
                item.marketItem = true;
                prices.push(item.price);
            });
            prices.reverse();
            commit('SET_MARKET_PRICES', prices.slice());
            commit('SET_MARKET_ITEMS', _items);
            commit('SET_MARKET_LOADED_STATE', true);
        }
        
    },

    filterItems({ commit, state, rootState }, filters) {
        commit('UNSELECT_ALL_ITEMS');
        commit('SET_DISPLAYED_ITEMS_COUNT', 20);
        let searchPhrases = filters.name.match(/\b(\w+)\b/g) || [filters.name];
        searchPhrases = searchPhrases.map(word => word.toLowerCase());
        const amountForPurchase = rootState.auth.user.balance + rootState.stash.userStashSelectedAmount;
        const minItemPrice = rootState.settings.min_item_price;
        let _filteredMarketItems = state.marketItems.filter((item) => {
            return (!filters.name || (filters.name && (searchPhrases.every(word => item.market_hash_name.toLowerCase().includes(word)))))
                   && ((!filters.price && (item.price <= amountForPurchase || amountForPurchase < minItemPrice)) || (filters.price && item.price <= filters.price))
                   && (filters.type == -1 || (filters.type != -1 && item.type === filters.type))
                   && (filters.rarity == -1 || (filters.rarity != -1 && item.rarity === filters.rarity))
                   && (filters.exterior == -1 || (filters.exterior != -1 && item.exterior === filters.exterior));
        });
        commit('SET_FILTERED_ITEMS', _filteredMarketItems);
    },

    openMarket({ commit, state, rootGetters }) {
        if (!state.marketOpened && state.marketLoaded) {
            commit('SET_MARKET_OPENED_STATE', true);
        }
    },

    closeMarket({ commit, state }) {
        if (state.marketOpened) {
            commit('SET_MARKET_OPENED_STATE', false);
        }
    },

    loadMoreMarketItems({ commit, state }) {
        if (state.currentMarketItemsCount < state.filteredMarketItems.length) {
            commit('SET_DISPLAYED_ITEMS_COUNT', state.currentMarketItemsCount + 20);
        }
    },

    addToCart({ commit, state, dispatch }, item) {
        if (state.marketItemsSelected.length < config.market.max_market_items_per_transaction) {
            commit('ADD_ITEM_TO_CART', item);
        }
        else {
            dispatch('notification/show', 
            {
                type: 'error',
                message: i18n.t('market.max_market_items_per_transaction', {value: config.market.max_market_items_per_transaction})}, 
                { root: true }
            );
        }
    },
    removeFromCart({ commit, state }, item) {
        commit('REMOVE_ITEM_FROM_CART', item);
    },

    purchase({ commit, state, rootState, dispatch }) {
        commit('SET_PURCHASE_IN_PROGRESS', true);
        API.apiClient.post('items/purchase', {
            stash_items: rootState.stash.userStashSelected.map(item => item.pivot_id),
            market_items: state.marketItemsSelected.map(item => item.id),
            total_price: state.cartTotal
        })
        .then(response => {
            if (response.data.success) {
                dispatch('notification/show', {type: 'success', message: i18n.t('market.successful_purchase')}, { root: true });
                dispatch('stash/setStash', response.data.data.items, { root: true });
                dispatch('auth/updateBalance', response.data.data.balance, { root: true });
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});

        })
        .finally(() => {
            commit('SET_PURCHASE_IN_PROGRESS', false);  
        });
    },
    
    socket_marketUpdated({dispatch}, data) {
        dispatch('loadMarket', data.last_items_update);
    }

};

export const getters = {
    displayedMarketItems: state => {
        return state.filteredMarketItems.slice(0, state.currentMarketItemsCount);
    },
    residue: (state, getters, rootState, rootGetters) => {
        return rootGetters["auth/authUser"].balance + rootState.stash.userStashSelectedAmount - state.cartTotal;
    },
};