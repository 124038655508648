<template>
  <div class="game-panel game-panel_crash">

        <div class="crash__graph">

            <div id="canv" :style="{bottom: bottom}" v-show="chickenReady"  :class="{'no__transition': state == 0, 'hidden': bottom == null}" >
            </div>
         
            <crash-graph :key="crashGraphIsVisible" class="crash__canvas" ref="graph" :crashHeight="height" v-show="crashGraphIsVisible" :visible="crashGraphIsVisible" :value="value_value < 1 ? 1 : value_value" :state="state"/>
            <div v-if="!crashGraphIsVisible" class="crash__stub" ref="stub"></div>
            
            
            
            <!-- <div id="crash__chicken" :style="{bottom: bottom}" v-show="bottom != null && DOMLoaded && crashGraphIsVisible && chickenIsVisible" :class="{'no__transition': state == 0}">
            </div>
             -->
 
            <div class="crash__label" :class="{'crash__label_crashed': state == 2}">
                
                <span v-if="timer_active">
                    <span v-if="timer_value == -1">
                        <loader class="loader__huge mt-15" style="transform: translateY(-15px);" />
                    </span>
                    <span v-else>
                        {{ timer_prettified }} 
                    </span>
                </span>
                <span v-else>
                     <span v-if="value_value == 0">
                        <loader class="loader__huge mt-15" style="transform: translateY(-15px);" />
                    </span>
                    <span v-else>
                        {{ value_prettified }} 
                    </span>
                </span>
               
            </div>

            <crash-ping />
        </div>
    </div>
</template>

<script>
import CrashGraph from '@/components/crash/CrashGraph.vue';
import CrashPing from '@/components/crash/CrashPing.vue';
import lottie from 'lottie-web';
import Loader from '@/misc/Loader';
import config from '@/_config';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { mapGetters, mapState } from 'vuex';
import { io } from "socket.io-client";
import parser from "socket.io-msgpack-parser";

export default {
    data() {
        return {
            label: 0,
            anim: null,
            animationStage: '',
            bottom: null,
            DOMLoaded: false,
            socket: null,

            label_previous: -1,
            label_value: 1,
            label_interval: null,


            timer_previous: -1,
            timer_value: -1,
            timer_interval: null,


            value_previous: -1,
            value_interval: null,
            value_prev_previous: -1,

            timer_active: false,
            lottieInitialized: false,
            component_mounted: false
        
        }
    },
    components: {
        CrashGraph,
        CrashPing,
        Loader
    },

    methods: {
        getCrashHeight() {
            const h = this.$refs.graph.$refs.canvas.clientHeight;
            if (h < 10) {
                return this.$refs.stub.clientHeight;
            }
            return h;
        },

        calcValue(cycle) {
            return parseFloat(Math.pow(Math.E, 0.00006 * cycle * 1000 / 17));
        },

        prettifyLabel(label, is_timer) {
            return is_timer ? `${(label / 100).toFixed(1)} ${this.$t('util.seconds')}` : `x${(label / 100).toFixed(2)}`;
        },

        convertToInteger(value) {
            return Math.round(parseFloat(value.toFixed(2)) * 100);
        },

        interpolate(start, end, duration, minStepTime, what, setter) {
            const _interval = what + '_interval';
            const _previous = what + '_previous';
            clearInterval(this[_interval]);
            setter(start);
            if (start === end) {
                this[_previous] = end;
                return;
            };
            const range     = end - start;
            const stepTime  = Math.max(minStepTime, Math.abs(Math.floor(duration / range)));
            const steps     = duration / stepTime;
            const intv      = Math.round(range / steps);

            let current = start;
            
            
            this[_interval] = setInterval(() => {
                current += intv;
                if ((intv > 0 && current >= end) || (intv < 0 && current <= end)) {
                    current = end;
                    clearInterval(this[_interval]);
                }
                setter(current);
            }, stepTime);

            this[_previous] = end;

        },

        setChickenSettings(speed, stage) {
            if (this.DOMLoaded && this.chickenIsVisible) {
                this.anim.setSpeed(speed);
                this.animationStage = stage;
            }
        },
        
        tryToCashout() {
            if (this.betIsPlaced && this.value_value >= this.userBet.auto_cashout) {
                this.$store.dispatch('crash/cashout');
            }
        },

        adjustChicken() {
            this.setChickenSettings(1 + Math.min(0.4, ((this.value_value - 1) / 50) * 0.4), 'run');
            this.adjustChickenHeight();
        },

        adjustChickenHeight() {
            if (this.chickenIsVisible) {
                const crashHeight = this.getCrashHeight();
                this.bottom = Math.floor(Math.min(crashHeight - 60, 29 + Math.min(1, (this.value_value - 1) / (config.crash.maxInitialCoeff - 1)) * (crashHeight - 85))) + 'px';
            }
        },

        processCrash(eventType, data) {
            if (eventType == 0) {
                data *= 10;
                this.timer_active = true;
                this.interpolate(this.timer_previous == -1 ? data : this.timer_previous, 
                                    data, 
                                    config.crash.timerFrequency, 
                                    config.crash.emulatedFrequencyTimer, 
                                    'timer', 
                                    (value) => this.timer_value = value
                                );

                this.value_value = 1;
                this.value_previous = -1;
                this.state = 0;
                this.setChickenSettings(1, 'idle');
            } 

            else {
                this.timer_active = false;
                this.timer_previous = config.crash.timer * 100;
                if (eventType == 1) {
                    this.interpolate(
                        this.value_previous == -1 ? data : this.value_previous, 
                        data, 
                        config.crash.frequency, 
                        config.crash.emulatedFrequency, 
                        'value',
                        (value) => {
                            this.value_value = this.calcValue(value);
                            this.tryToCashout();
                        }
                    );

                    // this.interpolate(
                    //     this.label_previous == -1 ? data : this.label_previous, 
                    //     data, 
                    //     config.crash.frequency, 
                    //     10, 
                    //     'label',
                    //     (value) => {
                    //         this.label_value = this.calcValue(value);
                    //     }
                    // );

                    this.adjustChicken();
                    this.state = 1;
                }
                else {
                    data /= 100;
                    const calculatedValue = this.convertToInteger(data);
                    this.interpolate(
                        this.value_previous == -1 ? calculatedValue : this.convertToInteger(this.calcValue(this.value_previous)), 
                        calculatedValue, 
                        config.crash.emulatedDelayOnCrash, 
                        config.crash.emulatedFrequency, 
                        'value', 
                        (value) => {
                            this.value_value = value / 100;
                            this.tryToCashout();
                        }
                    );
                    setTimeout(() => {
                        this.state = 2;
                        this.setChickenSettings(1, 'blow');
                        this.$store.dispatch('crash/crashed');
                    }, config.crash.emulatedDelayOnCrash)
                }
            }
        },

        initPing() {
            if (config.crash.crashProvider == 'socketio') {
                const fn = () => {
                    const start = Date.now();
                    this.socket.emit("ping", () => {
                        const ping = Date.now() - start;
                        this.$store.commit('crash/SET', {key: 'ping', value: ping});
                    });
                };
                setInterval(fn, config.crash.pingEvery);
            }
        }
    },

    created() {
        if (config.crash.crashProvider == 'socketio') {
            this.socket = io(config.backend.node_url_crash, {
                parser
            });

            this.socket.on('c', (event) => {
                const data = new Uint32Array(event[1]);
                this.processCrash(data[0], data[1]);
            });

            this.initPing();
        }

        else {
            if (config.crash.crashWebsocketReconnecting) {
                this.socket = new ReconnectingWebSocket(config.backend.wss_url_crash, [], {
                    reconnectionDelayGrowFactor: 1
                });
            }
            else {
                this.socket = new WebSocket(config.backend.wss_url_crash);
            }
            this.socket.binaryType = "arraybuffer";

            this.socket.onmessage = (event) => {
                if (this.socket.readyState === WebSocket.OPEN) {
                    const data = new Uint32Array(event.data);
                    if (data[0] < config.crash.pingMessageCode) {
                        this.processCrash(data[0], data[1]);
                    }
                    else if (data[0] == config.crash.pongMessageCode){
                        const time = data[1] % config.crash.pingModulo;
                        const ping = (Date.now() % config.crash.pingModulo) - time;
                        if (ping < 0) ping += config.crash.pingModulo;
                        this.$store.commit('crash/SET', {key: 'ping', value: ping});
                    }
                }  
            }


            setInterval(() => {
                const buf = new ArrayBuffer(8);
                let data = new Uint32Array(buf);
                data[0] = config.crash.pingMessageCode;
                data[1] = Date.now() % config.crash.pingModulo;
                this.socket.send(data);
            }, config.crash.pingEvery);

        }


        
        
    },
    
    mounted() {
            this.component_mounted = true;
            // if (this.chickenIsVisible) {
            //     console.log(this.bottom != null && this.DOMLoaded && this.crashGraphIsVisible && this.chickenIsVisible);
            //     let data = {
            //         container: document.getElementById('canv'), 
            //         renderer: 'canvas',
            //         loop: true,
            //         autoplay: false,
            //         path: '/static/lottie/chicken.json',
            //     };
            //     this.anim = lottie.loadAnimation(data);    

            //     this.anim.addEventListener('DOMLoaded', () => {
            //         this.DOMLoaded = true;
            //     });
            // }
    },

    destroyed() {
        clearInterval(this.label_interval);
        clearInterval(this.timer_interval);
        clearInterval(this.value_interval);
        this.$store.dispatch('crash/reset');
        this.socket.close();
    },

    computed: {
        height() {
            if (this.$mq == 'sm') return 260;
            if (this.$mq == 'lg' || this.$mq == 'md') return 400;
            return 445;
        },
        chickenIsVisible() {
            return ['lg', 'xlg', 'xxlg'].includes(this.$mq);
        },
        chickenReady() {
            return  this.crashGraphIsVisible && this.chickenIsVisible && this.component_mounted;
        },  
        state: {
            get: function () {
                return this.$store.state.crash.state;
            },
            set: function (value) {
                if (value != this.$store.state.crash.state)
                    this.$store.dispatch('crash/changeState', value)
            }
        },
        value_value: {
            get: function () {
                return this.$store.state.crash.coeff;
            },
            set: function (value) {
                if (value != this.$store.state.crash.coeff) {
                    this.$store.commit('crash/SET_COEFF', value)
                }
            }
        },
        value_prettified() {
            return `x${(this.value_value).toFixed(2)}`;
        },
        timer_prettified() {
            return `${(this.timer_value / 100).toFixed(1)} ${this.$t('util.seconds')}`;
        },
        ...mapGetters('crash', {betIsPlaced: 'betIsPlaced'}),
        ...mapState('crash', { userBet: 'userBet' }),
        ...mapGetters('settings', { crashGraphIsVisible: 'crashGraphIsVisible' }),
    },

    sockets: {
        crash(data) {
            if (data.t == 'ng') {
                this.$store.commit('crash/ADD_TO_HISTORY', data.d);
                this.$store.commit('crash/SET', {key: 'current_client_seed', value: data.current_seeds.client});
                this.$store.commit('crash/SET', {key: 'current_server_seed', value: data.current_seeds.server});
            }
        }
    },

    watch: {
        animationStage(val) {
            if (!this.DOMLoaded && crashGraphIsVisible) return;
            if (val == 'idle') {
                this.bottom = '29px';
                this.anim.playSegments([ [0, 60] ], true);
            }
            if (val == 'run') {
                this.anim.playSegments([[60, 65] ], true);
                this.anim.playSegments([[65, 95] ], false);
            }
            if (val == 'blow') {
                this.anim.playSegments([ [145, 215] ], true);
                this.anim.playSegments([ [214, 215] ], false);
            }
        },
        chickenReady: {
            immediate: true,
            handler(val) {
                const container = document.getElementById('canv');
                if (val && !this.lottieInitialized && container) {
                    this.lottieInitialized = true;
                    let data = {
                        container: container, 
                        renderer: 'canvas',
                        loop: true,
                        autoplay: false,
                        path: '/static/lottie/chicken.json',
                    };
                    this.anim = lottie.loadAnimation(data);    

                    this.anim.addEventListener('DOMLoaded', () => {
                        this.DOMLoaded = true;
                    });
                }
            }
        }
        
    }
}
</script>

