<template>
    <util-button :addClass="addClass" :isBig="isBig" :height="height" :width="width" :px="px" :variant="variant" @click.stop="$router.push({query: {...$route.query, m: modal}})">
          <slot></slot>
    </util-button>
</template>

<script>
import utilButton from '@/misc/Button';

export default {
    props: {
        'modal': String,
        'addClass': String,
        'isBig': {
            type: Boolean,
            default: false
        },
        'height': {
            type: String,
            default: null
        },
        'width': {
            type: String,
            default: null
        },
        'px': {
            type: String,
            default: '0px'
        },
        'variant': {
            type: String,
            default: 'whole'
        }
    },
    components: {
        utilButton
    }
}
</script>
