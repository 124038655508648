<template>
    <selectable-control ref="selectable_control" btnClass="selectable-control__btn_full selectable-control__btn_dark selectable-control__btn_h40" :optionsClass="`${optionsClass} selectable-control__options_full selectable-control__options_h40`">
        <template v-slot:button>
            <img class="selectable-control__selected-option-image" :src="`/static/images/${chosen_method.icon}.png`" alt="">
            <span class="selectable-control__currency">{{ $t('deposit.methods.' + chosen_method.title) }}
                                    <span class="deposit__method-boost" v-if="chosen_method.amount_increment_percent > 0">+{{ chosen_method.amount_increment_percent }}%</span>

            </span>
        </template>
        <template v-slot:options>
            <button class="selectable-control__option" v-for="method in filtered_methods" :key="method.id" @click="select(method.id)">
                <img :src="`/static/images/${method.icon}.png`" class="selectable-control__option-image" alt="">
                <span class="relative">{{ $t('deposit.methods.' + method.title) }}
                                    <span class="deposit__method-boost" v-if="method.amount_increment_percent > 0">+{{ method.amount_increment_percent }}%</span>
                </span>
            </button>
        </template>
    </selectable-control>

</template>

<script>

import { mapGetters } from 'vuex'
import SelectableControl from '@/misc/SelectableControl.vue';

export default {
    props: ['optionsClass'],
    components: {
        SelectableControl
    },
    methods: {
        select(id) {
            this.$store.dispatch('deposit/selectMethod', id);
            this.$refs.selectable_control.hide();
        },
    },
    computed: {
        ...mapGetters('deposit', ['filtered_methods', 'chosen_method']),
    }
}
</script>

<style>

</style>