<template>
    <div class="trade-link dark-panel">
        <div class="text_light_bold flex-align">
            <div class="trade-link__label">
                {{ $t('profile.inventory.trade_link_label') }}
            </div>
            <a class="trade-link__howto default-link" target="_blank" rel="noopener" :href="steamID != null ? `https://steamcommunity.com/profiles/${steamID}/tradeoffers/privacy` : 'https://steamcommunity.com/sharedfiles/filedetails/?id=354215515'">
                {{ $t('profile.inventory.trade_link_cant_find') }}
            </a>
        </div>
        <div class="promocode mt-10">
            <div class="bet__input-validation" v-if="hasErrors">
                <div class="bet__input-validation-msg">{{ error }}</div>
            </div>
            
            <input autocomplete="off" :class="{'input-field_error': hasErrors}" v-model="trade_link" type="text" class="input-field promocode__input" :placeholder="$t('profile.inventory.trade_link_placeholder')" @input="validate" @blur="validate">
            <button @click.stop="change" class="promocode__activate" :disabled="loading || hasErrors || same">
                <div v-if="!loading">{{ $t('profile.inventory.change_link') }}</div> 
                <div v-else class="loader"></div>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            error: '',
            trade_link: '',
            loading: false
        }
    },
    computed: {
        hasErrors() {
            return this.error.trim() !== '';
        },

        ...mapGetters('auth', ['authUser', 'tradeLink', 'steamID']),
        same() {
            return this.trade_link === this.tradeLink;
        }
    },
    created() {
        this.validate();
    }, 
    methods: {
        validate() {
            if (!this.trade_link.match(/^https:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=[\d]+&token=[a-zA-Z0-9_-]+$/)) {
                this.error = this.$t('profile.inventory.incorrect_link');
            }
            else {
                this.error = '';
            }
            
            return this.error === '';
        },
        change() {
            if (this.validate()) {
                this.loading = true;
                this.$store.dispatch('inventory/saveTradeLink', this.trade_link).finally(() => this.loading = false);
            }
        }
    },
    watch: {
        tradeLink: {
            immediate: true,
            handler(val) {
                if (val === null) {
                    this.trade_link = '';
                }
                else {
                    this.trade_link = val;
                }
            }
        }
    }
}
</script>

<style>

</style>