<template>
    <div class="account__blocked">
        <logo></logo>
        <h2 class="text__subheading mt-25"> {{ $t('errors.blocked') }}</h2>
    </div>
</template>

<script>
import Logo from '@/components/header/Logo.vue';

export default {
    components: {
        Logo
    } 
}
</script>

<style>

</style>