<template>
  <div class="sidebar-panel">
        <items-picker />
        <market-open-btn v-if="$store.getters['auth/loggedIn']"/>
    </div>
</template>

<script>
import MarketOpenBtn from '../market/MarketOpenBtn.vue'
import ItemsPicker from './ItemsPicker.vue'

export default {
    components: {
        MarketOpenBtn,
        ItemsPicker
    }
}
</script>

