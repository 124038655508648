import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VTooltip from 'v-tooltip'

const options = {
    
};

Vue.use(Toast, options);
Vue.use(VTooltip, {
    defaultTrigger: 'hover focus click'
})