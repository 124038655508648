import * as API from '@/services/API';
import i18n from '@/i18n';
import  config  from '@/_config';
import { processAPIError, processValidationError, beautifyTimestamp } from "@/util";
import Vue from 'vue';

export const namespaced = true;

const getDefaultState = () => {
    return {
        editModalOpened: false,
        usernameUpdating: false,
        logOutModalOpened: false
    }
}

export const state = getDefaultState

export const mutations = {
    RESET(state) {
        const initState = getDefaultState()
        Object.keys(initState).forEach(key => {
            state[key] = initState[key]
        })
    },
    SET(state, {key, value}) {
        state[key] = value;
    }
};

export const actions = {
    saveTradeLink({ commit, dispatch }, link) {
        return API.apiClient.post('user/trade/update', {
            link: link
        })
        .then(response => {
            if (response.data.success) {
                dispatch('notification/show', {type: 'success', message: i18n.t('profile.inventory.trade_link_updated')}, { root: true });
                dispatch('auth/changeTradeLink', response.data.data.trade_link, { root: true });
            }
            else {
                const errorData = processAPIError(response);
                dispatch('notification/showError', errorData, {root: true});
            }
        })
        .catch(error => {
            const errorData = processValidationError(error);
            dispatch('notification/showError', errorData, {root: true});
        })
    }
};

export const getters = {
  
};