<template>
    <div class="skin__wrapper skin__wrapper_mini" :class="computeClass">
        <div class="skin skin_mini">
            <div class="skin__image-wrapper skin__image-wrapper_mini">
                <img :src="pathToItemImage(item.id)" class="skin__image skin__image_mini" alt="">
            </div>
            <div v-if="price" class="skin_mini__price">
                ${{ price.convertToDollars() }}
            </div>
        </div>
        
    </div>

</template>

<script>
export default {
    props: ['item', 'itemClass', 'price'],
    computed: {
        computeClass() {
            let _cl = 'skin__wrapper_' + this.rarityClass(this.item.rarity);
            if (this.itemClass) _cl += ' ' + this.itemClass;
            if (this.item.marketItem && !this.item.selected && this.residue < this.item.price) _cl += ' skin__wrapper_disabled'; 
            return _cl; 
        }
    }
}
</script>
