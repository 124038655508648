
<script>
import VueCharts from 'vue-chartjs'
import { Bar, Line } from 'vue-chartjs'
import config from '@/_config';
import Vue from 'vue';
export default {
    data() {
        return {
            options: {
                hover: {mode: null},
                layout: {
                    padding: {
                        right: 15
                    }
                },
                tooltips: {
                    enabled: false
                }, 
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                animation: {
                    duration: 500,
                },
                animations: {
                    
                },
                scales: {
                    yAxes: [{
                        // position: 'right',
                        gridLines: {
                            color: "#292b3e",
                            drawTicks: false,
                            borderDash: [2, 5],
                            drawBorder: false,
                            lineWidth: 2
                        },
                        ticks: {
                            padding: 10,
                            fontColor: "#5f6278",
                            fontSize: 12,
                            fontStyle: 'bold', 
                            min: 1,
                            max: config.crash.maxInitialCoeff,
                            maxTicksLimit: 6,
                            callback: function(tick, index, ticks) {
                                return tick.toFixed(2) + 'x';
                            }
                        }
                    }],
                    xAxes: [{
                        id: 'A',
                        position: 'top',
                        gridLines: {
                            zeroLineColor: '#1e222e',
                            color: "#1e222e",
                            drawTicks: true,
                            drawBorder: false,
                            tickMarkLength: 25
                        },
                        ticks: {
                            display: true,
                            fontColor: 'rgba(0,0,0,0)',
                            maxTicksLimit: 5
                        }
                    },
                    {
                        id: 'B',
                        position: 'bottom',
                        gridLines: {
                            zeroLineColor: '#1e222e',
                            color: "#1e222e",
                            drawTicks: true,
                            drawBorder: false,
                            tickMarkLength: 25
                        },
                        ticks: {
                            display: true,
                            fontColor: 'rgba(0,0,0,0)',
                            maxTicksLimit: 5
                        }
                    }
                    ]
                },
                elements: {
                    point:{
                        radius: 0
                    }
                }
            },
            data: {
                labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                datasets: [{
                    borderColor: '#4eae2e',

                    borderWidth: 3,
                    data: [],
                    pointRadius: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5],
                    pointStyle: ['', '', '', '', '', '', '', '', '', '', 'circle'],
                    pointBackgroundColor: ['', '', '', '', '', '', '', '', '', '', '#4eae2e'],
                    pointBorderColor: ['', '', '', '', '', '', '', '', '', '', '#4eae2e4a'],
                    pointBorderWidth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 15],
                }]
            },
            interpolate_coefs: [
                1.37536023,
                1.90518962,
                2.66248257,
                3.78019802,
                5.48563218,
                8.22844828,
                13.0753425,
                23.2804878,
                54.5428571
            ],
            green_gradient: null,
            red_gradient: null
        }
    },
    props: ['value', 'state', 'updatable'],
    extends: Line,
    mounted () {
        const ctx = document.getElementById(this.chartId).getContext("2d")
        
        let res = [this.value];
        let y = res[0]
        if (y > config.crash.maxInitialCoeff){
            this.options.scales.yAxes[0].ticks.max = y;
        }
        for (let i = 1; i <= 9; ++i){
            res.push(1 + (y - 1) / this.interpolate_coefs[i - 1]);
        }
        res.push(1);
        res.reverse();
        this.data.datasets[0].data = res;

        
        this.renderChart(this.data, this.options);
        
        this.green_gradient = ctx.createLinearGradient(0, 0, 0, this.$data._chart.canvas.clientHeight)
        this.green_gradient.addColorStop(0, 'rgba(78,174,46,0.5)')
        this.green_gradient.addColorStop(1, 'rgba(25,26,40,0.5)')

        this.red_gradient = ctx.createLinearGradient(0, 0, 0, this.$data._chart.canvas.clientHeight)
        this.red_gradient.addColorStop(0, 'rgba(173,57,61, 0.4)')
        this.red_gradient.addColorStop(1, 'rgba(56,46,46, 0.25)')

        if (this.state == 2) {
            this.data.datasets[0].backgroundColor = this.red_gradient;
            this.data.datasets[0].pointBackgroundColor[10] = '#c73a3f';
            this.data.datasets[0].pointBorderColor[10] = '#c73a3f4a';
            this.data.datasets[0].borderColor = '#c73a3f';
        }
        else {
            this.data.datasets[0].backgroundColor = this.green_gradient;
        }

        this.$data._chart.update();

    },


    methods: {
        update(value) {
            let res = [value];
            let y = res[0]
            if (y > config.crash.maxInitialCoeff){
                this.$data._chart.options.scales.yAxes[0].ticks.max = y;
            }
            else {
                this.$data._chart.options.scales.yAxes[0].ticks.max = 3;
            }
            for (let i = 1; i <= 9; ++i){
                res.push(1 + (y - 1) / this.interpolate_coefs[i - 1]);
            }
            res.push(1);
            res.reverse();
            this.data.datasets[0].data = res;
            this.$data._chart.update();
        },
        reset() {
            this.data.datasets[0].backgroundColor = this.green_gradient;
            this.data.datasets[0].pointBackgroundColor[10] = '#4eae2e';
            this.data.datasets[0].pointBorderColor[10] = '#4eae2e4a';
            this.data.datasets[0].borderColor = '#4eae2e';
            this.renderChart(this.data, this.options);
        },
        lose() {
            this.data.datasets[0].backgroundColor = this.red_gradient;
            this.data.datasets[0].pointBackgroundColor[10] = '#c73a3f';
            this.data.datasets[0].pointBorderColor[10] = '#c73a3f4a';
            this.data.datasets[0].borderColor = '#c73a3f';
         
            this.$data._chart.update();
        }
    },
    watch: {
        value: {
            immediate: false, 
            handler(newVal) {
                if (this.updatable) {
                    this.update(newVal);
                }
            }
        }
    }
}
</script>

