<template>
    <modal :show="showModal" @close="closeModal" maxWidth="700px" closeClass="modal__close_with-header"> 
       <div class="modal__header modal__tabs">
           <div @click.stop="$router.push({query: {...$route.query, m: 'deposit'}})" class="modal__tab flex-align" :class="{'modal__tab_active': activeModal === 'deposit'}">
                <span class="icon-wallet modal__tab-icon"></span> <span>{{ $t('deposit.deposit_header') }}</span>
           </div>
           <div @click.stop="$router.push({query: {...$route.query, m: 'deposit-history'}})" class="modal__tab flex-align" :class="{'modal__tab_active': activeModal === 'deposit-history'}">
                <span  class="icon-clock-1 modal__tab-icon"></span> <span> {{ $t('deposit.history_header') }} </span>
           </div>
       </div>

       <div v-if="loading" class="modal__loading">
           <loader />
       </div>

       <div v-else>
            <deposit-tab v-if="activeModal === 'deposit'"/>
            <deposit-history-tab v-if="activeModal === 'deposit-history'"/>
       </div>


    </modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '../../misc/Modal';
import DepositTab from './DepositTab.vue';
import DepositHistoryTab from './DepositHistoryTab.vue';
import Loader from '@/misc/Loader.vue';


export default {
    components: {
        Modal,
        DepositTab,
        Loader,
        DepositHistoryTab
    },
    computed: {
        ...mapState('deposit', ['loading']),
        activeModal() {
            return this.$store.state.modal.activeModal;
        },
        showModal() {
            return this.activeModal === 'deposit' ||  this.activeModal === 'deposit-history';
        }
    },

    created() {
       
    },

    data() {
        return {
        }
    },
    methods: {
        closeModal() {

        }
    },
    watch: {
        activeModal: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal === 'deposit') this.$store.dispatch('deposit/loadDepositInfo');
                // if (newVal === 'deposit-history') this.$store.dispatch('deposit/loadPaymentHistory');
            }
        },

    }
}
</script>