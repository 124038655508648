<template>
    <footer class="footer">
        <div class="footer__info">
            <div class="footer__left">
                <div class="text__subheading footer__subheading show-md mb-10">{{ $t('footer.about_us') }}</div>
                <div class="footer-nav">
                    <router-link tag="a" class="footer-nav__link" :to="{name: 'support'}">
                        {{ $t('nav.help') }}
                    </router-link>
                    <button @click.stop="$router.push({query: {...$route.query, m: 'fairness'}}).catch(()=>{})" class="footer-nav__link">
                        {{ $t('nav.fair') }}
                    </button>
                    <router-link tag="a" class="footer-nav__link" :to="{name: 'terms'}">
                        {{ $t('nav.terms') }}
                    </router-link>
                    <router-link tag="a" class="footer-nav__link" :to="{name: 'privacy'}">
                        {{ $t('nav.privacy') }}
                    </router-link>
                </div>
                <div class="footer__copyright hide-md">
                    &copy; Copyright {{ year }} {{ name }} | {{ $t('footer.rights') }}
                </div>
            </div>
            <div class="footer__right">
                <div class="text__subheading footer__subheading show-md mb-10">{{ $t('footer.community') }}</div>
                <div class="footer__social hide-md">
                    <a :href="link.link" target="_blank" rel="noopener" v-for="link in $store.state.settings.social_links" :key="link.id" class="footer__social-link">
                        <span :class="`icon-${link.icon_name}`" class="footer__social-icon"></span>
                        <span class="footer__social-name">{{ link.title }}</span>
                    </a>
                </div>
                <div class="footer__social show-md">
                    <icon-btn class="footer__social-mobile-icon" :href="link.link" :icon="link.icon_name" v-for="link in $store.getters['settings/social_links_without_email']" :key="link.id">
                    </icon-btn>
                </div>
 
                <div class="footer__misc">
                    <div class="footer__version">
                        {{ $t('footer.version') }}: 
                        <span class="footer__version-val">
                            v{{ver}}
                        </span>
                    </div>
                </div>
                
               

            </div>
            
        </div>
         <div class="footer__mobile show-md">
              <div>
                 {{ $t('footer.email') }}: <a :href="$store.getters['settings/social_links_only_email'].link" target="_blank" rel="noopener" class="text_light_bold"> {{ $store.getters['settings/social_links_only_email'].title }}</a>
             </div>
             <div class="mt-10">
                &copy; Copyright {{ year }} {{ name }} | {{ $t('footer.rights') }}
             </div>
            
        </div>
    </footer>
</template>

<script>
import config from "@/_config";
import IconBtn from '@/misc/IconBtn.vue';
export default {
    components: {
        IconBtn
    },
    computed: {
       
        name() {
            return config.app.name;
        },
        year() {
            return config.app.year;
        },
        ver() {
            return config.app.v;
        }
    }
}
</script>

<style>

</style>