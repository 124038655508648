<template>
    <div class="bet__skin" :class="{'bet__skin_locked': isPedestalLocked}">
        <div class="bet__slider" v-if="pedestalItems.length > 1">
            <div class="bet__skin-count">
                {{ this.pedestalIndex + 1 }} / {{ pedestalItems.length }} {{ $t('bet.items') }}
            </div>
            <div class="bet__slider-controls">
                <button class="bet__control bet__control_prev" :disabled="!hasPrev" @click="prevItem">
                    <span class="icon-left-arrow"></span>
                </button>
                <button class="bet__control bet__control_next" :disabled="!hasNext" @click="nextItem">
                    <div class="icon-right-arrow"></div>
                </button>
            </div>
        </div>
        <div class="bet__pedestal" :class="rarity ? `bet__pedestal_${rarity}` : ''">
            <div class="bet__light" :class="rarity ? `bet__light_${rarity}` : ''"></div>
        </div>
        <div class="bet__item">
            <div class="bet__shadow" :class="rarity ? `bet__shadow_${rarity}` : ''"></div>
            <div class="bet__loader" v-if="!pedestalItem">
                <div class="bet__inner bet__inner_one"></div>
                <div class="bet__inner bet__inner_two"></div>
                <div class="bet__inner bet__inner_three"></div>
            </div>
            <div class="bet__item-wrapper" v-else>
                <img :src="pathToItemImage(pedestalItem.id)" alt="">
            </div>
        </div> 
        <!-- <div class="bet__info" v-if="pedestalItem">
            <div class="skin__weapon skin__weapon_big" style="font-size:13px;">{{ pedestalItem.item_name }}</div>
            <div class="skin__name skin__name_big">{{ pedestalItem.skin_name }}</div>
            <div class="skin__condition skin__condition_big">{{ exteriorName(pedestalItem.exterior) }}</div>
        </div>        -->

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            pedestalIndex: 0
        }
    },
    computed: {
        ...mapGetters('stash', {pedestalItems: 'pedestalItems', isPedestalLocked: 'isPedestalLocked'}),
        pedestalItem() {
            return this.pedestalItems[this.pedestalIndex];
        },
        userStash() {
            return this.pedestalItems;
        },
        rarity() {
            if (!this.pedestalItem) return '';
            return this.rarityClass(this.pedestalItem.rarity);
        },
        hasNext() {
            return this.pedestalIndex < this.userStash.length - 1;
        },
        hasPrev() {
            return this.pedestalIndex > 0;
        }
    },
    methods: {
        nextItem() {
            this.pedestalIndex = Math.min(Math.max(this.pedestalItems.length - 1, 0), this.pedestalIndex + 1);
        },  
        prevItem() {
            this.pedestalIndex = Math.max(this.pedestalIndex - 1, 0);
        },
    },
    watch: {
        userStash() {
            this.pedestalIndex = 0;
        }
    }
}

</script>
