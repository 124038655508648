<template>
    <div class="deposit__currency">
        <selectable-control ref="selectable_control" btnClass="selectable-control__btn_full selectable-control__btn_dark selectable-control__btn_h40" :optionsClass="`${optionsClass} selectable-control__options_full selectable-control__options_h40`">
            <template v-slot:button>
                <img class="selectable-control__selected-option-image" :src="image" alt="">
                <span class="selectable-control__currency">{{ currency }}</span>
            </template>
            <template v-slot:options>
                <button class="selectable-control__option" v-for="opt in list" :key="opt.name" @click="changeCurrency(opt.name)">
                    <img :src="opt.src" class="selectable-control__option-image" alt="">
                    <span>{{ opt.title }}</span>
                </button>
            </template>
        </selectable-control>
    </div>
</template>

<script>
import SelectableControl from '@/misc/SelectableControl.vue';
import { mapState } from 'vuex';

export default {
    props: ['optionsClass'],
    components: {
        SelectableControl
    },
    data() {
        return {
            list: [
                {
                    'name': 'RUB',
                    'title': 'RUB',
                    'src': '/static/images/russia.svg'
                },
                {
                    'name': 'USD',
                    'title': 'USD',
                    'src': '/static/images/united-states.svg'
                },
                {
                    'name': 'UAH',
                    'title': 'UAH',
                    'src': '/static/images/ukraine.svg'
                }
            ]
        }
    },
    methods: {
        changeCurrency(currency) {
            this.$store.dispatch('deposit/changeCurrency', currency, {root: true});
            this.$refs.selectable_control.hide();
        },
    },
    computed: {
        image() {
            return this.list.filter(x => x.name === this.currency)[0].src;
        },
        ...mapState('deposit', ['currency']),

    }
}
</script>

<style>

</style>