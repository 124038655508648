import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from "@/pages/index/Index.vue";
import Support from "@/pages/index/Support.vue";
import Maintenance from "@/pages/index/Maintenance.vue";
import Profile from "@/pages/index/Profile.vue";
import Terms from "@/pages/index/Terms.vue";
import Privacy from "@/pages/index/Privacy.vue";
import store from "@/store";
import i18n from '@/i18n';
import VueCookie from 'vue-cookie';
import config from '@/_config';
import Meta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(Meta);

const routes =  [
    {
        path: '/',
        name: 'index',
        component: Index,
        beforeEnter: (to, from, next) => {
            const verified = to.query.verified;
            if (verified) {
                store.dispatch('notification/show', {type: 'success', message: i18n.tc('auth.email_verified')}, {root: true});
                next({name: 'profile', query: {t: 'account'}});
            }
            else {
                next();
            }
        }
    },
    {
        path: '/r/:code',
        beforeEnter: (to, from, next) => {
            const authUser = store.getters["auth/authUser"];
            if (!authUser) {
                localStorage.setItem('rp__referral__code', to.params.code);
            }
            next({name: 'index'});
        }
    },
    {
        path: '/payment/error',
        beforeEnter: (to, from, next) => {
            store.dispatch('notification/show', {type: 'error', message: i18n.t('errors.payment')}, {root: true});
            next({name: 'index'});
        }
    },
    {
        path: '/payment/success',
        beforeEnter: (to, from, next) => {
            store.dispatch('notification/show', {type: 'success', message: i18n.t('util.payment_success')}, {root: true});
            next({name: 'index'});
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/support',
        name: 'support',
        component: Support,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/terms-of-use',
        name: 'terms',
        component: Terms,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy',
        component: Privacy,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/auth',
        beforeEnter: (to, from, next) => {
            const authStatus = to.query.status;
            let failed = false;
            if (authStatus === 'failed') {
                store.dispatch('notification/show', {type: 'error', message: i18n.tc('auth.error_during_auth')}, {root: true});
                failed = true;
            }
            if (authStatus === 'already_linked') {
                store.dispatch('notification/show', {type: 'error', message: i18n.tc('auth.already_linked')}, {root: true});
            }
            if (authStatus === 'successfully_linked') {
                store.dispatch('notification/show', {type: 'success', message: i18n.tc('auth.successfully_linked')}, {root: true});
            }
            if (!failed)
                localStorage.removeItem('rp__referral__code');
            next({name: 'index'});
        }
    },

    {
        path: '*',
        redirect: '/'
    }
];



const router =  new VueRouter({
    mode: 'history',
    routes
})

const hideAuthModals = ['login', 'signup', 'forgot', 'reset-password'];
const onlyAuthModals = ['bonus', 'crash', 'deposit', 'deposit-history', 'qiwi-payment'];

router.beforeEach((to, from, next) => {
    const authUser = store.getters["auth/authUser"];
    const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
    const loginQuery = { path: '?m=login' };
    const activeModal = to.query.m;

    if (activeModal) {
        if (hideAuthModals.includes(activeModal) && authUser) {
            next({path: to.path, query: { ...to.query, m: undefined, token: undefined, email: undefined }});
            return;
        }
        else {
            if (onlyAuthModals.includes(activeModal) && !authUser) {
                next(loginQuery);
            } 
            else {
                store.commit('modal/SET_ACTIVE_MODAL', activeModal, {root: true});
            }
        }
    }
    else {
        store.commit('modal/REMOVE_ACTIVE_MODAL', null, {root: true});
    }

    if (reqAuth && !authUser) {
        next(loginQuery);
    } 
    else {
      next(); 
    }
});

export default router;
